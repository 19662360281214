import {Question, QuestionLanguage, QuestionAge, QuestionCategory} from "./model";


export const FRENCH_QUESTIONS: Question[] = [
    {id:"fr_0", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel animal vit dans l'eau?", choices:["Chat","Poisson","Oiseau"], correctChoiceIndex:1},
    {id:"fr_1", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel animal vit dans un terrier?", choices:["Lapin","Girafe","Lion"], correctChoiceIndex:0},
    {id:"fr_2", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel animal est le plus grand?", choices:["Souris","Elephant","Chien"], correctChoiceIndex:1},
    {id:"fr_3", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel animal vit dans la jungle?", choices:["Lion","Panda","Girafe"], correctChoiceIndex:0},
    {id:"fr_4", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel animal vit dans un nid?", choices:["Poisson","Oiseau","Chat"], correctChoiceIndex:1},
    {id:"fr_5", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel animal vit dans la forêt?", choices:["Lion","Girafe","Ours"], correctChoiceIndex:2},
    {id:"fr_6", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel animal vit dans le désert?", choices:["Chameau","Lapin","Poisson"], correctChoiceIndex:0},
    {id:"fr_7", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel animal vit dans l'Arctique?", choices:["Panda","Ours polaire","Lion"], correctChoiceIndex:1},
    {id:"fr_8", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel animal vit dans un étang?", choices:["Grenouille","Lion","Chat"], correctChoiceIndex:0},
    {id:"fr_9", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel animal vit dans un zoo?", choices:["Girafe","Poisson","Oiseau"], correctChoiceIndex:0},
    {id:"fr_10", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal est un carnivore?", choices:["Lion","Vache","Lapin"], correctChoiceIndex:0},
    {id:"fr_11", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal est un herbivore?", choices:["Lion","Vache","Lapin"], correctChoiceIndex:1},
    {id:"fr_12", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal est un omnivore?", choices:["Lion","Vache","Ours"], correctChoiceIndex:2},
    {id:"fr_13", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal est un prédateur?", choices:["Lion","Vache","Lapin"], correctChoiceIndex:0},
    {id:"fr_14", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal est une proie?", choices:["Lion","Vache","Lapin"], correctChoiceIndex:2},
    {id:"fr_15", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des plumes?", choices:["Lion","Oiseau","Chat"], correctChoiceIndex:1},
    {id:"fr_16", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des écailles?", choices:["Poisson","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_17", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des poils?", choices:["Poisson","Oiseau","Chat"], correctChoiceIndex:2},
    {id:"fr_18", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des ailes?", choices:["Poisson","Oiseau","Chat"], correctChoiceIndex:1},
    {id:"fr_19", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des griffes?", choices:["Poisson","Oiseau","Chat"], correctChoiceIndex:2},
    {id:"fr_20", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des cornes?", choices:["Vache","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_21", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a une queue?", choices:["Poisson","Oiseau","Chat"], correctChoiceIndex:2},
    {id:"fr_22", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des dents?", choices:["Poisson","Oiseau","Chat"], correctChoiceIndex:2},
    {id:"fr_23", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des pattes?", choices:["Poisson","Oiseau","Chat"], correctChoiceIndex:2},
    {id:"fr_24", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des yeux?", choices:["Poisson","Oiseau","Chat"], correctChoiceIndex:2},
    {id:"fr_25", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a un bec?", choices:["Poisson","Oiseau","Chat"], correctChoiceIndex:1},
    {id:"fr_26", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a une trompe?", choices:["Elephant","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_27", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des sabots?", choices:["Vache","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_28", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des antennes?", choices:["Poisson","Oiseau","Chat"], correctChoiceIndex:2},
    {id:"fr_29", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des tentacules?", choices:["Poulpe","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_30", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des pinces?", choices:["Crabe","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_31", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des nageoires?", choices:["Poisson","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_32", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des branchies?", choices:["Poisson","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_33", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des oreilles?", choices:["Lapin","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_34", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des moustaches?", choices:["Chat","Oiseau","Lapin"], correctChoiceIndex:0},
    {id:"fr_35", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des rayures?", choices:["Zèbre","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_36", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des taches?", choices:["Girafe","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_37", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des plumes colorées?", choices:["Paon","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_38", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des cornes courbées?", choices:["Bélier","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_39", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a des piquants?", choices:["Hérisson","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_40", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a une carapace?", choices:["Tortue","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_41", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a une longue langue?", choices:["Girafe","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_42", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a une longue queue?", choices:["Singe","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_43", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a une crinière?", choices:["Lion","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_44", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a une bosse?", choices:["Chameau","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_45", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a une longue trompe?", choices:["Elephant","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_46", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a une longue queue touffue?", choices:["Renard","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_47", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a une longue queue rayée?", choices:["Lémurien","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_48", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a une longue queue préhensile?", choices:["Singe","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_49", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel animal a une longue langue bleue?", choices:["Girafe","Oiseau","Chat"], correctChoiceIndex:0},
    {id:"fr_50", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel organe nous aide à respirer?", choices:["Cœur","Poumons","Estomac"], correctChoiceIndex:1},
    {id:"fr_51", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel organe pompe le sang dans notre corps?", choices:["Cerveau","Cœur","Pied"], correctChoiceIndex:1},
    {id:"fr_52", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel sens nous permet de goûter les aliments?", choices:["Ouïe","Goût","Toucher"], correctChoiceIndex:1},
    {id:"fr_53", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel sens nous permet d'entendre les sons?", choices:["Ouïe","Goût","Toucher"], correctChoiceIndex:0},
    {id:"fr_54", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel sens nous permet de sentir les odeurs?", choices:["Ouïe","Goût","Odorat"], correctChoiceIndex:2},
    {id:"fr_55", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel est le plus grand organe du corps humain?", choices:["Cerveau","Peau","Cœur"], correctChoiceIndex:1},
    {id:"fr_56", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Combien de dents ont généralement les enfants?", choices:["20","32","28"], correctChoiceIndex:0},
    {id:"fr_57", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel organe nous aide à digérer les aliments?", choices:["Estomac","Cœur","Poumons"], correctChoiceIndex:0},
    {id:"fr_58", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel organe nous aide à penser et à apprendre?", choices:["Cerveau","Cœur","Poumons"], correctChoiceIndex:0},
    {id:"fr_59", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quel est le rôle du cœur?", choices:["Pomper le sang","Aider à respirer","Aider à penser"], correctChoiceIndex:0},
    {id:"fr_60", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel est le plus petit os du corps humain?", choices:["Fémur","Stapès","Vertèbre"], correctChoiceIndex:1},
    {id:"fr_61", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel organe filtre le sang pour éliminer les déchets?", choices:["Cœur","Reins","Poumons"], correctChoiceIndex:1},
    {id:"fr_62", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel organe produit l'insuline?", choices:["Pancréas","Cœur","Poumons"], correctChoiceIndex:0},
    {id:"fr_63", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel est le plus grand os du corps humain?", choices:["Fémur","Cubitus","Vertèbre"], correctChoiceIndex:0},
    {id:"fr_64", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel organe est responsable de la vision?", choices:["Oreille","Œil","Nez"], correctChoiceIndex:1},
    {id:"fr_65", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel organe est responsable de l'audition?", choices:["Oreille","Œil","Nez"], correctChoiceIndex:0},
    {id:"fr_66", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel organe est responsable de l'odorat?", choices:["Oreille","Œil","Nez"], correctChoiceIndex:2},
    {id:"fr_67", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel organe est responsable du goût?", choices:["Langue","Cœur","Poumons"], correctChoiceIndex:0},
    {id:"fr_68", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel organe est responsable du toucher?", choices:["Peau","Cœur","Poumons"], correctChoiceIndex:0},
    {id:"fr_69", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel est le rôle des poumons?", choices:["Aider à penser","Pomper le sang","Aider à respirer"], correctChoiceIndex:2},
    {id:"fr_70", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel est le rôle du cerveau?", choices:["Aider à respirer","Aider à penser","Pomper le sang"], correctChoiceIndex:1},
    {id:"fr_71", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel est le rôle de l'estomac?", choices:["Aider à respirer","Aider à penser","Aider à digérer les aliments"], correctChoiceIndex:2},
    {id:"fr_72", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel est le rôle des reins?", choices:["Filtrer le sang","Aider à respirer","Pomper le sang"], correctChoiceIndex:0},
    {id:"fr_73", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel est le rôle du pancréas?", choices:["Produire l'insuline","Aider à respirer","Pomper le sang"], correctChoiceIndex:0},
    {id:"fr_74", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel est le rôle de la peau?", choices:["Aider à respirer","Protéger le corps","Pomper le sang"], correctChoiceIndex:1},
    {id:"fr_75", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel est le rôle de la langue?", choices:["Aider à respirer","Aider à penser","Goûter les aliments"], correctChoiceIndex:2},
    {id:"fr_76", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel est le rôle de l'œil?", choices:["Aider à respirer","Voir","Pomper le sang"], correctChoiceIndex:1},
    {id:"fr_77", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel est le rôle de l'oreille?", choices:["Aider à respirer","Aider à penser","Entendre les sons"], correctChoiceIndex:2},
    {id:"fr_78", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quel est le rôle du nez?", choices:["Aider à respirer","Sentir les odeurs","Pomper le sang"], correctChoiceIndex:1},
    {id:"fr_79", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Quelle est l'importance de se brosser les dents?", choices:["Pour avoir une haleine fraîche","Pour éviter les caries","Pour avoir de belles dents"], correctChoiceIndex:1},
    {id:"fr_80", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle est la plus grande montagne du monde?", choices:["Mont Everest","Mont Fuji","Mont Kilimandjaro"], correctChoiceIndex:0},
    {id:"fr_81", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Qu'est-ce qu'un volcan?", choices:["Un type de montagne","Un type de rivière","Un type de nuage"], correctChoiceIndex:0},
    {id:"fr_82", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Qu'est-ce qu'une île?", choices:["Un morceau de terre entouré d'eau","Un morceau de terre avec de l'eau au milieu","Un morceau de terre sans eau"], correctChoiceIndex:0},
    {id:"fr_83", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Qu'est-ce qu'une forêt?", choices:["Un endroit avec beaucoup d'arbres","Un endroit avec beaucoup de fleurs","Un endroit avec beaucoup de sable"], correctChoiceIndex:0},
    {id:"fr_84", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Qu'est-ce qu'un désert?", choices:["Un endroit très sec et chaud","Un endroit très humide et froid","Un endroit avec beaucoup d'arbres"], correctChoiceIndex:0},
    {id:"fr_85", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Qu'est-ce qu'une rivière?", choices:["Un cours d'eau","Un type de montagne","Un type de nuage"], correctChoiceIndex:0},
    {id:"fr_86", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Qu'est-ce qu'un lac?", choices:["Un grand étang d'eau","Un grand étang de sable","Un grand étang de rochers"], correctChoiceIndex:0},
    {id:"fr_87", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Qu'est-ce qu'un océan?", choices:["Un très grand corps d'eau salée","Un très grand corps d'eau douce","Un très grand corps de sable"], correctChoiceIndex:0},
    {id:"fr_88", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Qu'est-ce qu'une plage?", choices:["Un endroit avec du sable près de l'eau","Un endroit avec des arbres près de l'eau","Un endroit avec des rochers près de l'eau"], correctChoiceIndex:0},
    {id:"fr_89", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Qu'est-ce qu'une colline?", choices:["Une petite montagne","Une grande rivière","Un grand lac"], correctChoiceIndex:0},
    {id:"fr_90", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'un glacier?", choices:["Une grande masse de glace","Une grande masse d'eau","Une grande masse de rochers"], correctChoiceIndex:0},
    {id:"fr_91", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une vallée?", choices:["Une zone basse entre deux montagnes","Une zone haute entre deux montagnes","Une zone plate entre deux montagnes"], correctChoiceIndex:0},
    {id:"fr_92", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'un plateau?", choices:["Une zone plate en hauteur","Une zone plate au niveau de la mer","Une zone plate sous la mer"], correctChoiceIndex:0},
    {id:"fr_93", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'un canyon?", choices:["Une vallée profonde avec des parois abruptes","Une montagne haute avec des parois abruptes","Un lac profond avec des parois abruptes"], correctChoiceIndex:0},
    {id:"fr_94", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une grotte?", choices:["Un trou dans la terre ou une montagne","Un trou dans l'eau","Un trou dans le ciel"], correctChoiceIndex:0},
    {id:"fr_95", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une cascade?", choices:["De l'eau qui tombe d'une hauteur","De l'eau qui monte en hauteur","De l'eau qui reste à la même hauteur"], correctChoiceIndex:0},
    {id:"fr_96", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'un marais?", choices:["Une zone humide","Une zone sèche","Une zone froide"], correctChoiceIndex:0},
    {id:"fr_97", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une jungle?", choices:["Une forêt tropicale dense","Une forêt de conifères","Une forêt de feuillus"], correctChoiceIndex:0},
    {id:"fr_98", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'un delta?", choices:["L'endroit où une rivière se divise avant de se jeter dans la mer","L'endroit où une rivière commence","L'endroit où une rivière se termine sans se diviser"], correctChoiceIndex:0},
    {id:"fr_99", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une dune de sable?", choices:["Une colline de sable formée par le vent","Une colline de sable formée par l'eau","Une colline de sable formée par les arbres"], correctChoiceIndex:0},
    {id:"fr_100", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'un tremblement de terre?", choices:["Le mouvement du sol causé par les plaques tectoniques","Le mouvement du sol causé par le vent","Le mouvement du sol causé par l'eau"], correctChoiceIndex:0},
    {id:"fr_101", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'un ouragan?", choices:["Une tempête avec des vents très forts","Une tempête avec de la pluie très forte","Une tempête avec de la neige très forte"], correctChoiceIndex:0},
    {id:"fr_102", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une tornade?", choices:["Un tourbillon de vent très puissant","Un tourbillon d'eau très puissant","Un tourbillon de feu très puissant"], correctChoiceIndex:0},
    {id:"fr_103", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une avalanche?", choices:["Une grande quantité de neige qui descend rapidement une montagne","Une grande quantité de sable qui descend rapidement une dune","Une grande quantité d'eau qui descend rapidement une rivière"], correctChoiceIndex:0},
    {id:"fr_104", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'un tsunami?", choices:["Une grande vague causée par un tremblement de terre sous-marin","Une grande vague causée par le vent","Une grande vague causée par la pluie"], correctChoiceIndex:0},
    {id:"fr_105", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une éruption volcanique?", choices:["Quand un volcan expulse de la lave, des cendres et des gaz","Quand un volcan expulse de l'eau","Quand un volcan expulse de la neige"], correctChoiceIndex:0},
    {id:"fr_106", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une inondation?", choices:["Quand une zone devient submergée par l'eau","Quand une zone devient submergée par le sable","Quand une zone devient submergée par la neige"], correctChoiceIndex:0},
    {id:"fr_107", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une sécheresse?", choices:["Quand il ne pleut pas pendant une longue période","Quand il pleut beaucoup pendant une longue période","Quand il neige pendant une longue période"], correctChoiceIndex:0},
    {id:"fr_108", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'un arc-en-ciel?", choices:["Un phénomène lumineux et coloré dans le ciel après la pluie","Un phénomène lumineux et coloré dans le ciel pendant la nuit","Un phénomène lumineux et coloré dans le ciel pendant une éclipse"], correctChoiceIndex:0},
    {id:"fr_109", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une aurore boréale?", choices:["Un phénomène lumineux dans le ciel près des pôles","Un phénomène lumineux dans le ciel près de l'équateur","Un phénomène lumineux dans le ciel pendant le jour"], correctChoiceIndex:0},
    {id:"fr_110", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle partie de la plante est sous terre?", choices:["Feuilles","Racines","Fleurs"], correctChoiceIndex:1},
    {id:"fr_111", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle partie de la plante fait de la photosynthèse?", choices:["Fleurs","Feuilles","Racines"], correctChoiceIndex:1},
    {id:"fr_112", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle partie de la plante attire les insectes pour la pollinisation?", choices:["Racines","Feuilles","Fleurs"], correctChoiceIndex:2},
    {id:"fr_113", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle partie de la plante absorbe l'eau?", choices:["Fleurs","Feuilles","Racines"], correctChoiceIndex:2},
    {id:"fr_114", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle est la couleur des feuilles en général?", choices:["Rouge","Vert","Bleu"], correctChoiceIndex:1},
    {id:"fr_115", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle partie de la plante porte les graines?", choices:["Fleurs","Feuilles","Fruit"], correctChoiceIndex:2},
    {id:"fr_116", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle partie de la plante est généralement verte?", choices:["Fleurs","Feuilles","Racines"], correctChoiceIndex:1},
    {id:"fr_117", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle partie de la plante est généralement colorée?", choices:["Fleurs","Feuilles","Racines"], correctChoiceIndex:0},
    {id:"fr_118", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle partie de la plante est généralement au-dessus du sol?", choices:["Racines","Feuilles","Fleurs"], correctChoiceIndex:1},
    {id:"fr_119", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle partie de la plante est généralement sous le sol?", choices:["Racines","Feuilles","Fleurs"], correctChoiceIndex:0},
    {id:"fr_120", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle partie de la plante est généralement colorée et attire les insectes?", choices:["Racines","Feuilles","Fleurs"], correctChoiceIndex:2},
    {id:"fr_121", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle partie de la plante porte les graines?", choices:["Fleurs","Feuilles","Fruit"], correctChoiceIndex:2},
    {id:"fr_122", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle partie de la plante est généralement verte?", choices:["Fleurs","Feuilles","Racines"], correctChoiceIndex:1},
    {id:"fr_123", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle partie de la plante est généralement colorée?", choices:["Fleurs","Feuilles","Racines"], correctChoiceIndex:0},
    {id:"fr_124", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle partie de la plante est généralement au-dessus du sol?", choices:["Racines","Feuilles","Fleurs"], correctChoiceIndex:1},
    {id:"fr_125", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une plante à fleurs appelée?", choices:["Herbacée","Arbuste","Angiosperme"], correctChoiceIndex:2},
    {id:"fr_126", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une plante qui vit plus de deux ans?", choices:["Annuelle","Bisannuelle","Pérenne"], correctChoiceIndex:2},
    {id:"fr_127", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une plante qui perd ses feuilles en automne?", choices:["Caduque","Persistante","Annuelle"], correctChoiceIndex:0},
    {id:"fr_128", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une plante qui garde ses feuilles toute l'année?", choices:["Caduque","Persistante","Annuelle"], correctChoiceIndex:1},
    {id:"fr_129", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une plante qui ne vit qu'un an?", choices:["Annuelle","Bisannuelle","Pérenne"], correctChoiceIndex:0},
    {id:"fr_130", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une plante qui vit deux ans?", choices:["Annuelle","Bisannuelle","Pérenne"], correctChoiceIndex:1},
    {id:"fr_131", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une plante qui produit des graines à l'intérieur d'un fruit?", choices:["Gymnosperme","Angiosperme","Fougère"], correctChoiceIndex:1},
    {id:"fr_132", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une plante qui produit des graines sans fruit?", choices:["Gymnosperme","Angiosperme","Fougère"], correctChoiceIndex:0},
    {id:"fr_133", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une plante qui a des feuilles toute l'année?", choices:["Caduque","Persistante","Annuelle"], correctChoiceIndex:1},
    {id:"fr_134", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une plante qui perd ses feuilles en automne?", choices:["Caduque","Persistante","Annuelle"], correctChoiceIndex:0},
    {id:"fr_135", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une plante qui vit plus de deux ans?", choices:["Annuelle","Bisannuelle","Pérenne"], correctChoiceIndex:2},
    {id:"fr_136", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une plante qui ne vit qu'un an?", choices:["Annuelle","Bisannuelle","Pérenne"], correctChoiceIndex:0},
    {id:"fr_137", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une plante qui vit deux ans?", choices:["Annuelle","Bisannuelle","Pérenne"], correctChoiceIndex:1},
    {id:"fr_138", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une plante qui produit des graines à l'intérieur d'un fruit?", choices:["Gymnosperme","Angiosperme","Fougère"], correctChoiceIndex:1},
    {id:"fr_139", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une plante qui produit des graines sans fruit?", choices:["Gymnosperme","Angiosperme","Fougère"], correctChoiceIndex:0},
    {id:"fr_140", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle est la plus grande planète de notre système solaire?", choices:["Terre","Mars","Jupiter"], correctChoiceIndex:2},
    {id:"fr_141", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle planète est connue comme la planète rouge?", choices:["Mars","Vénus","Mercure"], correctChoiceIndex:0},
    {id:"fr_142", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Combien de planètes y a-t-il dans notre système solaire?", choices:["8","9","10"], correctChoiceIndex:0},
    {id:"fr_143", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle est la plus petite planète de notre système solaire?", choices:["Mercure","Jupiter","Saturne"], correctChoiceIndex:0},
    {id:"fr_144", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle planète est la plus proche du soleil?", choices:["Mercure","Vénus","Terre"], correctChoiceIndex:0},
    {id:"fr_145", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle planète est la plus éloignée du soleil?", choices:["Neptune","Uranus","Saturne"], correctChoiceIndex:0},
    {id:"fr_146", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle planète est connue pour ses anneaux?", choices:["Saturne","Jupiter","Mars"], correctChoiceIndex:0},
    {id:"fr_147", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle est la troisième planète du soleil?", choices:["Terre","Mars","Vénus"], correctChoiceIndex:0},
    {id:"fr_148", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle planète est la plus chaude?", choices:["Vénus","Mercure","Mars"], correctChoiceIndex:0},
    {id:"fr_149", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Quelle planète est la plus froide?", choices:["Neptune","Uranus","Saturne"], correctChoiceIndex:0},
    {id:"fr_150", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une étoile filante?", choices:["Un astéroïde","Une comète","Un météore"], correctChoiceIndex:2},
    {id:"fr_151", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une constellation?", choices:["Un groupe d'étoiles","Une planète","Une galaxie"], correctChoiceIndex:0},
    {id:"fr_152", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une galaxie?", choices:["Un groupe d'étoiles","Une planète","Une comète"], correctChoiceIndex:0},
    {id:"fr_153", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une nébuleuse?", choices:["Un nuage de gaz et de poussière dans l'espace","Une étoile","Une planète"], correctChoiceIndex:0},
    {id:"fr_154", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une comète?", choices:["Un corps céleste composé de glace et de roche","Une étoile","Une planète"], correctChoiceIndex:0},
    {id:"fr_155", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'un astéroïde?", choices:["Un petit corps rocheux qui orbite autour du soleil","Une étoile","Une planète"], correctChoiceIndex:0},
    {id:"fr_156", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'un météore?", choices:["Un morceau de roche ou de métal qui brûle dans l'atmosphère de la Terre","Une étoile","Une planète"], correctChoiceIndex:0},
    {id:"fr_157", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une éclipse solaire?", choices:["Quand la lune passe entre la Terre et le soleil","Quand la Terre passe entre la lune et le soleil","Quand le soleil passe entre la Terre et la lune"], correctChoiceIndex:0},
    {id:"fr_158", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une éclipse lunaire?", choices:["Quand la Terre passe entre le soleil et la lune","Quand la lune passe entre la Terre et le soleil","Quand le soleil passe entre la Terre et la lune"], correctChoiceIndex:0},
    {id:"fr_159", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une planète naine?", choices:["Un corps céleste qui orbite autour du soleil, mais qui n'est pas une planète","Une petite étoile","Une petite planète"], correctChoiceIndex:0},
    {id:"fr_160", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'un trou noir?", choices:["Un endroit dans l'espace où la gravité est si forte que rien ne peut en sortir","Une étoile morte","Une planète sans lumière"], correctChoiceIndex:0},
    {id:"fr_161", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une supernova?", choices:["L'explosion d'une étoile","Une étoile très brillante","Une étoile très grande"], correctChoiceIndex:0},
    {id:"fr_162", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une étoile naine?", choices:["Une petite étoile","Une étoile très brillante","Une étoile très grande"], correctChoiceIndex:0},
    {id:"fr_163", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une étoile géante?", choices:["Une étoile très grande","Une étoile très brillante","Une petite étoile"], correctChoiceIndex:0},
    {id:"fr_164", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une étoile à neutrons?", choices:["Les restes d'une étoile qui a explosé","Une étoile très brillante","Une étoile très grande"], correctChoiceIndex:0},
    {id:"fr_165", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une étoile variable?", choices:["Une étoile dont la luminosité change","Une étoile qui se déplace","Une étoile qui change de couleur"], correctChoiceIndex:0},
    {id:"fr_166", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une étoile double?", choices:["Deux étoiles qui orbitent l'une autour de l'autre","Deux étoiles qui sont très proches l'une de l'autre","Deux étoiles qui ont la même taille"], correctChoiceIndex:0},
    {id:"fr_167", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une étoile binaire?", choices:["Deux étoiles qui orbitent l'une autour de l'autre","Deux étoiles qui sont très proches l'une de l'autre","Deux étoiles qui ont la même taille"], correctChoiceIndex:0},
    {id:"fr_168", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une étoile triple?", choices:["Trois étoiles qui orbitent l'une autour de l'autre","Trois étoiles qui sont très proches l'une de l'autre","Trois étoiles qui ont la même taille"], correctChoiceIndex:0},
    {id:"fr_169", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Qu'est-ce qu'une étoile multiple?", choices:["Plusieurs étoiles qui orbitent l'une autour de l'autre","Plusieurs étoiles qui sont très proches l'une de l'autre","Plusieurs étoiles qui ont la même taille"], correctChoiceIndex:0},
    {id:"fr_170", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'une cellule?", choices:["Un animal","Un type de nourriture","La plus petite unité de vie"], correctChoiceIndex:2},
    {id:"fr_171", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'une plante a besoin pour grandir?", choices:["Eau, lumière du soleil et sol","Bonbons et jouets","Lait et biscuits"], correctChoiceIndex:0},
    {id:"fr_172", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Où vivent les poissons?", choices:["Dans l'eau","Dans les arbres","Dans le désert"], correctChoiceIndex:0},
    {id:"fr_173", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un arbre respire?", choices:["Air","Eau","Lumière du soleil"], correctChoiceIndex:0},
    {id:"fr_174", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un oiseau a que les humains n'ont pas?", choices:["Des plumes","Des dents","Des cheveux"], correctChoiceIndex:0},
    {id:"fr_175", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un insecte respire?", choices:["Air","Eau","Lumière du soleil"], correctChoiceIndex:0},
    {id:"fr_176", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un mammifère a que les oiseaux n'ont pas?", choices:["Des plumes","Des dents","Des cheveux"], correctChoiceIndex:1},
    {id:"fr_177", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un reptile a que les mammifères n'ont pas?", choices:["Des écailles","Des dents","Des cheveux"], correctChoiceIndex:0},
    {id:"fr_178", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un amphibien a que les reptiles n'ont pas?", choices:["Des écailles","La capacité de vivre dans l'eau et sur terre","Des cheveux"], correctChoiceIndex:1},
    {id:"fr_179", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un oiseau a que les reptiles n'ont pas?", choices:["Des écailles","Des dents","Des plumes"], correctChoiceIndex:2},
    {id:"fr_180", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un mammifère a que les insectes n'ont pas?", choices:["Des ailes","Des dents","Des antennes"], correctChoiceIndex:1},
    {id:"fr_181", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un poisson a que les mammifères n'ont pas?", choices:["Des écailles","Des dents","Des cheveux"], correctChoiceIndex:0},
    {id:"fr_182", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un insecte a que les oiseaux n'ont pas?", choices:["Des plumes","Des ailes","Des antennes"], correctChoiceIndex:2},
    {id:"fr_183", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qu'un écosystème?", choices:["Un type de cellule","Un endroit où vivent différents organismes","Un type de plante"], correctChoiceIndex:1},
    {id:"fr_184", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qu'un herbivore?", choices:["Un animal qui mange des plantes","Un animal qui mange de la viande","Un animal qui mange des bonbons"], correctChoiceIndex:0},
    {id:"fr_185", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qu'un carnivore?", choices:["Un animal qui mange des plantes","Un animal qui mange de la viande","Un animal qui mange des bonbons"], correctChoiceIndex:1},
    {id:"fr_186", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qu'un omnivore?", choices:["Un animal qui mange des plantes","Un animal qui mange de la viande","Un animal qui mange des plantes et de la viande"], correctChoiceIndex:2},
    {id:"fr_187", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qu'une cellule végétale a que les cellules animales n'ont pas?", choices:["Chloroplastes","Noyau","Cytoplasme"], correctChoiceIndex:0},
    {id:"fr_188", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qu'un prédateur?", choices:["Un animal qui est chassé","Un animal qui chasse","Un animal qui mange des plantes"], correctChoiceIndex:1},
    {id:"fr_189", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qu'une proie?", choices:["Un animal qui est chassé","Un animal qui chasse","Un animal qui mange des plantes"], correctChoiceIndex:0},
    {id:"fr_190", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qu'un producteur dans un écosystème?", choices:["Un animal qui mange des plantes","Un animal qui mange de la viande","Une plante qui produit de la nourriture"], correctChoiceIndex:2},
    {id:"fr_191", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qu'un consommateur dans un écosystème?", choices:["Un animal qui mange des plantes ou d'autres animaux","Une plante qui produit de la nourriture","Un animal qui ne mange pas"], correctChoiceIndex:0},
    {id:"fr_192", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qu'un décomposeur dans un écosystème?", choices:["Un animal qui mange des plantes","Un organisme qui décompose les matières organiques mortes","Un animal qui ne mange pas"], correctChoiceIndex:1},
    {id:"fr_193", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qu'un parasite?", choices:["Un organisme qui vit aux dépens d'un autre organisme","Un organisme qui décompose les matières organiques mortes","Un organisme qui vit en symbiose avec un autre organisme"], correctChoiceIndex:0},
    {id:"fr_194", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qu'un symbiote?", choices:["Un organisme qui vit aux dépens d'un autre organisme","Un organisme qui décompose les matières organiques mortes","Un organisme qui vit en symbiose avec un autre organisme"], correctChoiceIndex:2},
    {id:"fr_195", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce que l'eau à l'état solide?", choices:["Glace","Vapeur","Liquide"], correctChoiceIndex:0},
    {id:"fr_196", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange du bicarbonate de soude et du vinaigre?", choices:["Il explose","Il devient chaud","Il mousse"], correctChoiceIndex:2},
    {id:"fr_197", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce que l'eau à l'état liquide?", choices:["Glace","Eau","Vapeur"], correctChoiceIndex:1},
    {id:"fr_198", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce que l'eau à l'état gazeux?", choices:["Vapeur","Glace","Eau"], correctChoiceIndex:0},
    {id:"fr_199", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on chauffe de l'eau?", choices:["Elle gèle","Elle bout","Elle devient solide"], correctChoiceIndex:1},
    {id:"fr_200", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qu'une réaction chimique?", choices:["Quand deux substances se mélangent","Quand une substance change de couleur","Quand une substance change en une autre"], correctChoiceIndex:2},
    {id:"fr_201", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange du bicarbonate de soude et du vinaigre?", choices:["Il se forme du gaz","Il se forme un solide","Il ne se passe rien"], correctChoiceIndex:0},
    {id:"fr_202", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on chauffe de l'eau jusqu'à 100 degrés Celsius?", choices:["Elle gèle","Elle bout","Elle devient solide"], correctChoiceIndex:1},
    {id:"fr_203", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on refroidit de l'eau jusqu'à 0 degré Celsius?", choices:["Elle gèle","Elle bout","Elle devient gazeuse"], correctChoiceIndex:0},
    {id:"fr_204", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange de l'eau et de l'huile?", choices:["Ils se mélangent","L'huile flotte sur l'eau","L'eau flotte sur l'huile"], correctChoiceIndex:1},
    {id:"fr_205", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange du sel et de l'eau?", choices:["Le sel flotte","Le sel se dissout","Le sel change de couleur"], correctChoiceIndex:1},
    {id:"fr_206", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange du sucre et de l'eau?", choices:["Le sucre flotte","Le sucre se dissout","Le sucre change de couleur"], correctChoiceIndex:1},
    {id:"fr_207", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange du vinaigre et de l'huile?", choices:["Ils se mélangent","L'huile flotte sur le vinaigre","Le vinaigre flotte sur l'huile"], correctChoiceIndex:1},
    {id:"fr_208", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange du bicarbonate de soude et de l'eau?", choices:["Il se forme du gaz","Il se forme un solide","Le bicarbonate de soude se dissout"], correctChoiceIndex:2},
    {id:"fr_209", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange du jus de citron et du bicarbonate de soude?", choices:["Il se forme du gaz","Il se forme un solide","Il ne se passe rien"], correctChoiceIndex:0},
    {id:"fr_210", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange du jus de citron et de l'eau?", choices:["Ils se mélangent","Le jus de citron flotte sur l'eau","L'eau flotte sur le jus de citron"], correctChoiceIndex:0},
    {id:"fr_211", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange du jus de citron et de l'huile?", choices:["Ils se mélangent","L'huile flotte sur le jus de citron","Le jus de citron flotte sur l'huile"], correctChoiceIndex:1},
    {id:"fr_212", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange du jus de citron et du sel?", choices:["Le sel se dissout","Le sel flotte","Le sel change de couleur"], correctChoiceIndex:0},
    {id:"fr_213", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange du jus de citron et du sucre?", choices:["Le sucre se dissout","Le sucre flotte","Le sucre change de couleur"], correctChoiceIndex:0},
    {id:"fr_214", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange du jus de citron et du vinaigre?", choices:["Ils se mélangent","Le vinaigre flotte sur le jus de citron","Le jus de citron flotte sur le vinaigre"], correctChoiceIndex:0},
    {id:"fr_215", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange du vinaigre et du sel?", choices:["Le sel se dissout","Le sel flotte","Le sel change de couleur"], correctChoiceIndex:0},
    {id:"fr_216", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange du vinaigre et du sucre?", choices:["Le sucre se dissout","Le sucre flotte","Le sucre change de couleur"], correctChoiceIndex:0},
    {id:"fr_217", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange du vinaigre et de l'eau?", choices:["Ils se mélangent","Le vinaigre flotte sur l'eau","L'eau flotte sur le vinaigre"], correctChoiceIndex:0},
    {id:"fr_218", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange du vinaigre et de l'huile?", choices:["Ils se mélangent","L'huile flotte sur le vinaigre","Le vinaigre flotte sur l'huile"], correctChoiceIndex:1},
    {id:"fr_219", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui se passe quand on mélange du vinaigre et du bicarbonate de soude?", choices:["Il se forme du gaz","Il se forme un solide","Il ne se passe rien"], correctChoiceIndex:0},
    {id:"fr_220", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce que le recyclage?", choices:["Jeter les déchets","Réutiliser les choses","Manger des légumes"], correctChoiceIndex:1},
    {id:"fr_221", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce que la pollution?", choices:["Jouer dans le parc","Faire du bruit","Endommager l'environnement"], correctChoiceIndex:2},
    {id:"fr_222", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un arbre donne?", choices:["Des bonbons","De l'oxygène","Des jouets"], correctChoiceIndex:1},
    {id:"fr_223", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Où devrions-nous jeter les déchets?", choices:["Dans la rue","Dans la poubelle","Dans le jardin"], correctChoiceIndex:1},
    {id:"fr_224", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'une éolienne?", choices:["Un type de voiture","Un type de maison","Un type de machine à énergie"], correctChoiceIndex:2},
    {id:"fr_225", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un parc national?", choices:["Un endroit pour protéger la nature","Un endroit pour jouer au football","Un endroit pour faire du shopping"], correctChoiceIndex:0},
    {id:"fr_226", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un panneau solaire?", choices:["Un type de fenêtre","Un type de porte","Un dispositif qui utilise l'énergie du soleil"], correctChoiceIndex:2},
    {id:"fr_227", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un sac réutilisable?", choices:["Un sac pour les bonbons","Un sac que nous pouvons utiliser plusieurs fois","Un sac pour les jouets"], correctChoiceIndex:1},
    {id:"fr_228", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'une bouteille réutilisable?", choices:["Une bouteille pour les bonbons","Une bouteille que nous pouvons utiliser plusieurs fois","Une bouteille pour les jouets"], correctChoiceIndex:1},
    {id:"fr_229", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un véhicule électrique?", choices:["Un véhicule qui fonctionne avec de l'électricité","Un véhicule qui fonctionne avec de l'eau","Un véhicule qui fonctionne avec du vent"], correctChoiceIndex:0},
    {id:"fr_230", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un déchet organique?", choices:["Un déchet qui vient des plantes ou des animaux","Un déchet qui vient des jouets","Un déchet qui vient des voitures"], correctChoiceIndex:0},
    {id:"fr_231", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un produit biodégradable?", choices:["Un produit qui peut être décomposé par des organismes","Un produit qui peut être mangé","Un produit qui peut être peint"], correctChoiceIndex:0},
    {id:"fr_232", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce que l'effet de serre?", choices:["Un type de jardin","Un phénomène climatique","Un type de maison"], correctChoiceIndex:1},
    {id:"fr_233", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce que la déforestation?", choices:["Planter des arbres","Couper des arbres","Peindre des arbres"], correctChoiceIndex:1},
    {id:"fr_234", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce que l'énergie solaire?", choices:["Energie du soleil","Energie des plantes","Energie des animaux"], correctChoiceIndex:0},
    {id:"fr_235", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce que la conservation de l'eau?", choices:["Boire beaucoup d'eau","Économiser l'eau","Nager dans l'eau"], correctChoiceIndex:1},
    {id:"fr_236", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce que la biodiversité?", choices:["Différents types de bonbons","Différents types de jeux","Différents types de vie sur Terre"], correctChoiceIndex:2},
    {id:"fr_237", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce que le compostage?", choices:["Un moyen de recycler les déchets organiques","Un moyen de cuisiner","Un moyen de nettoyer la maison"], correctChoiceIndex:0},
    {id:"fr_238", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce que l'érosion du sol?", choices:["Cultiver des plantes","Peindre le sol","Perte de sol due à l'eau ou au vent"], correctChoiceIndex:2},
    {id:"fr_239", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce que la pollution de l'eau?", choices:["Nager dans l'eau","Boire de l'eau","Contamination de l'eau par des substances nocives"], correctChoiceIndex:2},
    {id:"fr_240", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce que la pollution de l'air?", choices:["Respirer de l'air frais","Contamination de l'air par des substances nocives","Jouer avec un ballon d'air"], correctChoiceIndex:1},
    {id:"fr_241", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce que la pollution sonore?", choices:["Écouter de la musique","Faire du bruit excessif","Parler à des amis"], correctChoiceIndex:1},
    {id:"fr_242", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce que l'énergie renouvelable?", choices:["Energie qui peut être reconstituée","Energie qui vient des bonbons","Energie qui vient des jouets"], correctChoiceIndex:0},
    {id:"fr_243", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce que la conservation de la nature?", choices:["Jouer dans la nature","Protéger et préserver la nature","Peindre la nature"], correctChoiceIndex:1},
    {id:"fr_244", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce que la pollution lumineuse?", choices:["Regarder les étoiles","Excès de lumière artificielle la nuit","Jouer avec une lampe de poche"], correctChoiceIndex:1},
    {id:"fr_245", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'une pente?", choices:["Un type de machine simple","Un type de force","Un type de friction"], correctChoiceIndex:0},
    {id:"fr_246", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce que la gravité?", choices:["Une force qui nous tire vers le bas","Une machine simple","Une force qui nous pousse vers le haut"], correctChoiceIndex:0},
    {id:"fr_247", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce que la friction?", choices:["Une force qui ralentit les choses","Une machine simple","Une force qui accélère les choses"], correctChoiceIndex:0},
    {id:"fr_248", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un levier?", choices:["Un type de machine simple","Un type de force","Un type de friction"], correctChoiceIndex:0},
    {id:"fr_249", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'une vis?", choices:["Un type de machine simple","Un type de force","Un type de friction"], correctChoiceIndex:0},
    {id:"fr_250", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'une roue et un essieu?", choices:["Un type de machine simple","Un type de force","Un type de friction"], correctChoiceIndex:0},
    {id:"fr_251", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'une poulie?", choices:["Un type de machine simple","Un type de force","Un type de friction"], correctChoiceIndex:0},
    {id:"fr_252", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce qu'un coin?", choices:["Un type de machine simple","Un type de force","Un type de friction"], correctChoiceIndex:0},
    {id:"fr_253", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui peut augmenter la friction?", choices:["Une surface rugueuse","Une surface lisse","Une surface mouillée"], correctChoiceIndex:0},
    {id:"fr_254", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce qui peut diminuer la friction?", choices:["Une surface lisse","Une surface rugueuse","Une surface sèche"], correctChoiceIndex:0},
    {id:"fr_255", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle force nous maintient sur la terre?", choices:["La gravité","La friction","La force de levier"], correctChoiceIndex:0},
    {id:"fr_256", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour soulever une charge lourde?", choices:["Une poulie","Une vis","Un coin"], correctChoiceIndex:0},
    {id:"fr_257", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour diviser quelque chose?", choices:["Un coin","Une poulie","Une vis"], correctChoiceIndex:0},
    {id:"fr_258", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour tenir quelque chose ensemble?", choices:["Une vis","Une poulie","Un coin"], correctChoiceIndex:0},
    {id:"fr_259", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour déplacer quelque chose facilement?", choices:["Une roue et un essieu","Une vis","Un coin"], correctChoiceIndex:0},
    {id:"fr_260", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour augmenter votre force?", choices:["Un levier","Une vis","Une poulie"], correctChoiceIndex:0},
    {id:"fr_261", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour atteindre quelque chose de haut?", choices:["Une poulie","Une vis","Un levier"], correctChoiceIndex:0},
    {id:"fr_262", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour ouvrir une porte?", choices:["Un levier","Une vis","Une poulie"], correctChoiceIndex:0},
    {id:"fr_263", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour visser quelque chose?", choices:["Une vis","Une poulie","Un levier"], correctChoiceIndex:0},
    {id:"fr_264", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour couper quelque chose?", choices:["Un coin","Une vis","Une poulie"], correctChoiceIndex:0},
    {id:"fr_265", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour rouler quelque chose?", choices:["Une roue et un essieu","Une vis","Un coin"], correctChoiceIndex:0},
    {id:"fr_266", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour soulever quelque chose de lourd?", choices:["Une poulie","Une vis","Un levier"], correctChoiceIndex:0},
    {id:"fr_267", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour diviser quelque chose en deux?", choices:["Un coin","Une vis","Une poulie"], correctChoiceIndex:0},
    {id:"fr_268", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour tenir deux choses ensemble?", choices:["Une vis","Une poulie","Un levier"], correctChoiceIndex:0},
    {id:"fr_269", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour déplacer quelque chose de lourd?", choices:["Une roue et un essieu","Une vis","Un coin"], correctChoiceIndex:0},
    {id:"fr_270", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour augmenter votre force de levage?", choices:["Un levier","Une vis","Une poulie"], correctChoiceIndex:0},
    {id:"fr_271", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour atteindre quelque chose en hauteur?", choices:["Une poulie","Une vis","Un levier"], correctChoiceIndex:0},
    {id:"fr_272", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour ouvrir une porte lourde?", choices:["Un levier","Une vis","Une poulie"], correctChoiceIndex:0},
    {id:"fr_273", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour visser une vis?", choices:["Une vis","Une poulie","Un levier"], correctChoiceIndex:0},
    {id:"fr_274", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour couper un morceau de bois?", choices:["Un coin","Une vis","Une poulie"], correctChoiceIndex:0},
    {id:"fr_275", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour rouler une charge lourde?", choices:["Une roue et un essieu","Une vis","Un coin"], correctChoiceIndex:0},
    {id:"fr_276", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour soulever une charge lourde à une hauteur?", choices:["Une poulie","Une vis","Un levier"], correctChoiceIndex:0},
    {id:"fr_277", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour diviser un morceau de bois en deux?", choices:["Un coin","Une vis","Une poulie"], correctChoiceIndex:0},
    {id:"fr_278", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour tenir deux morceaux de bois ensemble?", choices:["Une vis","Une poulie","Un levier"], correctChoiceIndex:0},
    {id:"fr_279", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle machine simple utiliseriez-vous pour déplacer une charge lourde sur une distance?", choices:["Une roue et un essieu","Une vis","Un coin"], correctChoiceIndex:0},
    {id:"fr_280", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Quel type de nuage est généralement associé à la pluie?", choices:["Cumulus","Cirrus","Stratus"], correctChoiceIndex:2},
    {id:"fr_281", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Quelle saison vient après l'été?", choices:["Printemps","Automne","Hiver"], correctChoiceIndex:1},
    {id:"fr_282", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce que la neige?", choices:["Un type de nuage","Précipitation solide","Un type de vent"], correctChoiceIndex:1},
    {id:"fr_283", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Quelle saison vient après l'hiver?", choices:["Printemps","Été","Automne"], correctChoiceIndex:0},
    {id:"fr_284", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Quel type de nuage est haut et mince?", choices:["Cumulus","Cirrus","Stratus"], correctChoiceIndex:1},
    {id:"fr_285", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Quel type de nuage ressemble à un mouton?", choices:["Cumulus","Cirrus","Stratus"], correctChoiceIndex:0},
    {id:"fr_286", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce que la pluie?", choices:["Un type de nuage","Précipitation liquide","Un type de vent"], correctChoiceIndex:1},
    {id:"fr_287", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Quelle est la saison la plus froide?", choices:["Printemps","Été","Hiver"], correctChoiceIndex:2},
    {id:"fr_288", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Quel type de nuage est souvent associé à un temps clair et ensoleillé?", choices:["Cumulus","Cirrus","Stratus"], correctChoiceIndex:0},
    {id:"fr_289", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Quel type de nuage est souvent associé à un temps froid et gris?", choices:["Cumulus","Cirrus","Stratus"], correctChoiceIndex:2},
    {id:"fr_290", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Qu'est-ce que le vent?", choices:["Un type de nuage","Précipitation liquide","Mouvement de l'air"], correctChoiceIndex:2},
    {id:"fr_291", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Quelle est la saison la plus chaude?", choices:["Printemps","Été","Hiver"], correctChoiceIndex:1},
    {id:"fr_292", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Quel type de nuage est souvent associé à un temps sec et ensoleillé?", choices:["Cumulus","Cirrus","Stratus"], correctChoiceIndex:1},
    {id:"fr_293", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle est la forme d'un nuage Cumulus?", choices:["Plat et étalé","Haut et mince","Gros et moelleux"], correctChoiceIndex:2},
    {id:"fr_294", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle saison vient après le printemps?", choices:["Été","Automne","Hiver"], correctChoiceIndex:0},
    {id:"fr_295", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce que la grêle?", choices:["Précipitation liquide","Précipitation solide","Un type de nuage"], correctChoiceIndex:1},
    {id:"fr_296", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quel type de nuage est plat et étalé?", choices:["Cumulus","Cirrus","Stratus"], correctChoiceIndex:2},
    {id:"fr_297", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle saison vient après l'automne?", choices:["Hiver","Printemps","Été"], correctChoiceIndex:0},
    {id:"fr_298", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle est la forme d'un nuage Stratus?", choices:["Plat et étalé","Haut et mince","Gros et moelleux"], correctChoiceIndex:0},
    {id:"fr_299", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle saison est associée à la floraison des fleurs?", choices:["Printemps","Été","Automne"], correctChoiceIndex:0},
    {id:"fr_300", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Qu'est-ce que le brouillard?", choices:["Précipitation liquide","Un type de nuage","Un type de vent"], correctChoiceIndex:1},
    {id:"fr_301", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quel type de nuage est souvent associé à un temps orageux?", choices:["Cumulus","Cirrus","Stratus"], correctChoiceIndex:0},
    {id:"fr_302", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle saison est associée à la chute des feuilles des arbres?", choices:["Printemps","Été","Automne"], correctChoiceIndex:2},
    {id:"fr_303", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle est la forme d'un nuage Cirrus?", choices:["Plat et étalé","Haut et mince","Gros et moelleux"], correctChoiceIndex:1},
    {id:"fr_304", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Quelle saison est associée à la neige?", choices:["Printemps","Été","Hiver"], correctChoiceIndex:2},
    {id:"fr_305", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel est cet appareil qui nous permet de parler à quelqu'un loin de nous?", choices:["Un téléphone","Une brosse à dents","Une cuillère"], correctChoiceIndex:0},
    {id:"fr_306", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel appareil utilisons-nous pour envoyer des emails?", choices:["Un four","Un ordinateur","Une chaise"], correctChoiceIndex:1},
    {id:"fr_307", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel est cet appareil qui nous permet de voir et de parler à quelqu'un loin de nous?", choices:["Un livre","Une télévision","Un ordinateur"], correctChoiceIndex:2},
    {id:"fr_308", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Qu'est-ce qu'un smartphone?", choices:["Un type de chaussure","Un type de nourriture","Un type de téléphone"], correctChoiceIndex:2},
    {id:"fr_309", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Qu'est-ce qu'une tablette?", choices:["Un type de dessert","Un type de téléphone","Un type d'ordinateur"], correctChoiceIndex:2},
    {id:"fr_310", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce que le wifi?", choices:["Un type de danse","Un moyen de se connecter à Internet","Un type de voiture"], correctChoiceIndex:1},
    {id:"fr_311", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un mot de passe?", choices:["Une clé pour ouvrir une porte","Une phrase secrète pour protéger nos informations","Une chanson populaire"], correctChoiceIndex:1},
    {id:"fr_312", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Pourquoi devrions-nous garder nos mots de passe secrets?", choices:["Pour que personne ne vole nos jouets","Pour que personne ne puisse accéder à nos informations personnelles","Pour que personne ne mange notre nourriture"], correctChoiceIndex:1},
    {id:"fr_313", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un virus informatique?", choices:["Une maladie que les ordinateurs attrapent","Un programme qui peut endommager votre ordinateur","Un insecte qui vit dans les ordinateurs"], correctChoiceIndex:1},
    {id:"fr_314", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un courrier électronique?", choices:["Un type de boîte aux lettres","Un moyen d'envoyer des messages sur Internet","Un type de jeu vidéo"], correctChoiceIndex:1},
    {id:"fr_315", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un réseau social?", choices:["Un endroit où nous pouvons partager des photos et des messages avec nos amis en ligne","Un endroit où nous pouvons acheter des vêtements","Un endroit où nous pouvons regarder des films"], correctChoiceIndex:0},
    {id:"fr_316", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un navigateur web?", choices:["Un type de bateau","Un programme qui nous permet de voir des sites web","Un type de voiture"], correctChoiceIndex:1},
    {id:"fr_317", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un lien?", choices:["Une chaîne qui relie deux choses","Une adresse web qui nous emmène à une autre page","Une sorte de saucisse"], correctChoiceIndex:1},
    {id:"fr_318", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un moteur de recherche?", choices:["Un type de voiture","Un outil qui nous aide à trouver des informations sur Internet","Un type de machine à laver"], correctChoiceIndex:1},
    {id:"fr_319", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un spam?", choices:["Un type de nourriture","Des emails indésirables ou des messages publicitaires","Un type de jeu"], correctChoiceIndex:1},
    {id:"fr_320", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un blog?", choices:["Un type de grenouille","Un site web où les gens peuvent partager leurs pensées et leurs idées","Un type de chien"], correctChoiceIndex:1},
    {id:"fr_321", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un podcast?", choices:["Un type de poisson","Un enregistrement audio que vous pouvez écouter sur Internet","Un type de jeu vidéo"], correctChoiceIndex:1},
    {id:"fr_322", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'une application?", choices:["Un type de pomme","Un programme que vous pouvez utiliser sur un smartphone ou une tablette","Un type de vêtement"], correctChoiceIndex:1},
    {id:"fr_323", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un emoji?", choices:["Un type de bonbon","Un petit dessin que vous pouvez envoyer dans un message","Un type de chapeau"], correctChoiceIndex:1},
    {id:"fr_324", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un chat en ligne?", choices:["Un animal qui vit sur Internet","Un endroit où vous pouvez parler avec vos amis sur Internet","Un type de jeu vidéo"], correctChoiceIndex:1},
    {id:"fr_325", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Qu'est-ce qu'un ordinateur?", choices:["Un animal","Un jouet","Une machine"], correctChoiceIndex:2},
    {id:"fr_326", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quelle partie de l'ordinateur utilise-t-on pour taper?", choices:["La souris","Le clavier","L'écran"], correctChoiceIndex:1},
    {id:"fr_327", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quelle partie de l'ordinateur utilise-t-on pour voir les informations?", choices:["Le clavier","La souris","L'écran"], correctChoiceIndex:2},
    {id:"fr_328", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quelle partie de l'ordinateur utilise-t-on pour cliquer sur les choses?", choices:["La souris","Le clavier","L'écran"], correctChoiceIndex:0},
    {id:"fr_329", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Qu'est-ce qu'un logiciel?", choices:["Un jeu","Un programme sur un ordinateur","Un type de dessert"], correctChoiceIndex:1},
    {id:"fr_330", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce que le codage?", choices:["Écrire des instructions pour l'ordinateur","Cuisiner un repas","Dessiner une image"], correctChoiceIndex:0},
    {id:"fr_331", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un bug dans le codage?", choices:["Un insecte","Une erreur dans le code","Un type de jeu"], correctChoiceIndex:1},
    {id:"fr_332", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un site web?", choices:["Un lieu dans une forêt","Une page sur Internet","Un type de livre"], correctChoiceIndex:1},
    {id:"fr_333", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un navigateur web?", choices:["Un type de bateau","Un outil pour voir les sites web","Un animal"], correctChoiceIndex:1},
    {id:"fr_334", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un moteur de recherche?", choices:["Une partie d'une voiture","Un outil pour trouver des informations sur Internet","Un type de jeu"], correctChoiceIndex:1},
    {id:"fr_335", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un algorithme en codage?", choices:["Une chanson","Une recette de cuisine","Une série d'instructions pour l'ordinateur"], correctChoiceIndex:2},
    {id:"fr_336", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'une boucle en codage?", choices:["Un type de nœud","Une instruction qui se répète","Un type de jeu"], correctChoiceIndex:1},
    {id:"fr_337", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'une variable en codage?", choices:["Une partie d'une équation mathématique","Un type de jeu","Un endroit pour stocker des informations dans le code"], correctChoiceIndex:2},
    {id:"fr_338", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'une fonction en codage?", choices:["Un type de fête","Un ensemble d'instructions qui peut être utilisé encore et encore","Un type de jeu"], correctChoiceIndex:1},
    {id:"fr_339", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un pixel?", choices:["Un type de peinture","Un petit point sur un écran","Un type de jeu"], correctChoiceIndex:1},
    {id:"fr_340", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'une base de données?", choices:["Un type de jeu","Un endroit pour stocker des informations","Un type de bateau"], correctChoiceIndex:1},
    {id:"fr_341", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un fichier?", choices:["Un outil pour lisser les choses","Un document stocké sur un ordinateur","Un type de jeu"], correctChoiceIndex:1},
    {id:"fr_342", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un dossier sur un ordinateur?", choices:["Un endroit pour stocker des fichiers","Un type de jeu","Un outil pour lisser les choses"], correctChoiceIndex:0},
    {id:"fr_343", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un système d'exploitation?", choices:["Un type de jeu","Un programme qui gère l'ordinateur","Un type de bateau"], correctChoiceIndex:1},
    {id:"fr_344", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un réseau?", choices:["Un type de jeu","Un groupe d'amis","Un ensemble d'ordinateurs connectés"], correctChoiceIndex:2},
    {id:"fr_345", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour cuisiner ?", choices:["Téléphone","Four","Lampe"], correctChoiceIndex:1},
    {id:"fr_346", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour regarder des dessins animés ?", choices:["Four","Téléphone","Télévision"], correctChoiceIndex:2},
    {id:"fr_347", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour laver les vêtements ?", choices:["Machine à laver","Four","Téléphone"], correctChoiceIndex:0},
    {id:"fr_348", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour garder les aliments au frais ?", choices:["Réfrigérateur","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_349", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour allumer la pièce ?", choices:["Téléphone","Four","Lampe"], correctChoiceIndex:2},
    {id:"fr_350", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire cuire le riz ?", choices:["Cuiseur de riz","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_351", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire du jus ?", choices:["Presse-agrumes","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_352", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire chauffer l'eau ?", choices:["Bouilloire","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_353", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire cuire les gâteaux ?", choices:["Four","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_354", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire du popcorn ?", choices:["Machine à popcorn","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_355", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire des smoothies ?", choices:["Blender","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_356", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire des crêpes ?", choices:["Crêpière","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_357", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire des gaufres ?", choices:["Gaufrier","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_358", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire des sandwichs ?", choices:["Machine à sandwich","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_359", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire des hot-dogs ?", choices:["Machine à hot-dog","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_360", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire des hamburgers ?", choices:["Grill","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_361", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour sécher les vêtements ?", choices:["Sèche-linge","Four","Téléphone"], correctChoiceIndex:0},
    {id:"fr_362", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire du café ?", choices:["Machine à café","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_363", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire du pain grillé ?", choices:["Grille-pain","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_364", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour chauffer la pièce ?", choices:["Radiateur","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_365", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour nettoyer les sols ?", choices:["Aspirateur","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_366", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire cuire les œufs ?", choices:["Cuiseur à œufs","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_367", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire cuire les pâtes ?", choices:["Cuisinière","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_368", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire cuire les légumes ?", choices:["Cuisinière","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_369", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire cuire les viandes ?", choices:["Cuisinière","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_370", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire des frites ?", choices:["Friteuse","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_371", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire des glaces ?", choices:["Machine à glace","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_372", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire des yaourts ?", choices:["Yaourtière","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_373", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire des pizzas ?", choices:["Four à pizza","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_374", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel appareil utilise-t-on pour faire des donuts ?", choices:["Machine à donuts","Téléphone","Lampe"], correctChoiceIndex:0},
    {id:"fr_375", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Qu'est-ce qu'un robot?", choices:["Un animal","Une machine","Un fruit"], correctChoiceIndex:1},
    {id:"fr_376", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Les robots peuvent-ils aider les gens?", choices:["Non","Oui","Seulement les adultes"], correctChoiceIndex:1},
    {id:"fr_377", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel est le travail d'un robot aspirateur?", choices:["Cuisiner","Nettoyer le sol","Lire des histoires"], correctChoiceIndex:1},
    {id:"fr_378", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Les robots peuvent-ils parler?", choices:["Non","Oui","Seulement en anglais"], correctChoiceIndex:1},
    {id:"fr_379", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Un robot peut-il marcher?", choices:["Non","Oui","Seulement en courant"], correctChoiceIndex:1},
    {id:"fr_380", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un robot programmable?", choices:["Un robot qui peut cuisiner","Un robot qui peut être contrôlé par un ordinateur","Un robot qui peut voler"], correctChoiceIndex:1},
    {id:"fr_381", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un robot humanoïde?", choices:["Un robot qui ressemble à un humain","Un robot qui ressemble à un animal","Un robot qui ressemble à une voiture"], correctChoiceIndex:0},
    {id:"fr_382", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un robot industriel?", choices:["Un robot qui travaille dans les usines","Un robot qui travaille dans les écoles","Un robot qui travaille dans les hôpitaux"], correctChoiceIndex:0},
    {id:"fr_383", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un robot de service?", choices:["Un robot qui aide les gens","Un robot qui joue au football","Un robot qui nage"], correctChoiceIndex:0},
    {id:"fr_384", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un robot télécommandé?", choices:["Un robot qui peut être contrôlé à distance","Un robot qui peut voler","Un robot qui peut nager"], correctChoiceIndex:0},
    {id:"fr_385", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un robot autonome?", choices:["Un robot qui peut fonctionner sans aide humaine","Un robot qui a besoin d'être contrôlé par un humain","Un robot qui ne peut pas bouger"], correctChoiceIndex:0},
    {id:"fr_386", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un robot médical?", choices:["Un robot qui aide les médecins","Un robot qui aide les enseignants","Un robot qui aide les cuisiniers"], correctChoiceIndex:0},
    {id:"fr_387", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un robot de recherche?", choices:["Un robot qui aide les scientifiques","Un robot qui aide les agriculteurs","Un robot qui aide les artistes"], correctChoiceIndex:0},
    {id:"fr_388", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un robot de divertissement?", choices:["Un robot qui fait rire les gens","Un robot qui fait peur aux gens","Un robot qui fait pleurer les gens"], correctChoiceIndex:0},
    {id:"fr_389", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un robot de sauvetage?", choices:["Un robot qui aide à sauver des vies","Un robot qui aide à construire des maisons","Un robot qui aide à faire du shopping"], correctChoiceIndex:0},
    {id:"fr_390", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un robot de surveillance?", choices:["Un robot qui aide à surveiller les lieux","Un robot qui aide à nettoyer les lieux","Un robot qui aide à décorer les lieux"], correctChoiceIndex:0},
    {id:"fr_391", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un robot de transport?", choices:["Un robot qui aide à transporter des choses","Un robot qui aide à cuisiner","Un robot qui aide à peindre"], correctChoiceIndex:0},
    {id:"fr_392", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un robot d'exploration?", choices:["Un robot qui aide à explorer des lieux inaccessibles","Un robot qui aide à explorer la cuisine","Un robot qui aide à explorer le jardin"], correctChoiceIndex:0},
    {id:"fr_393", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un robot de construction?", choices:["Un robot qui aide à construire des bâtiments","Un robot qui aide à construire des jouets","Un robot qui aide à construire des vêtements"], correctChoiceIndex:0},
    {id:"fr_394", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Qu'est-ce qu'un robot agricole?", choices:["Un robot qui aide à cultiver des plantes","Un robot qui aide à cultiver des poissons","Un robot qui aide à cultiver des idées"], correctChoiceIndex:0},
    {id:"fr_395", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel est le moyen de transport qui vole dans le ciel?", choices:["Voiture","Avion","Bateau"], correctChoiceIndex:1},
    {id:"fr_396", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel moyen de transport utilise des rails?", choices:["Voiture","Bateau","Train"], correctChoiceIndex:2},
    {id:"fr_397", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel moyen de transport utilise des roues?", choices:["Avion","Bateau","Bus"], correctChoiceIndex:2},
    {id:"fr_398", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel moyen de transport peut naviguer sur l'eau?", choices:["Bateau","Bus","Vélo"], correctChoiceIndex:0},
    {id:"fr_399", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel moyen de transport peut-on utiliser pour aller à l'école?", choices:["Avion","Bus","Bateau"], correctChoiceIndex:1},
    {id:"fr_400", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel moyen de transport utilise du carburant pour se déplacer?", choices:["Vélo","Voiture","Trottinette"], correctChoiceIndex:1},
    {id:"fr_401", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel moyen de transport peut-on utiliser pour faire du sport?", choices:["Voiture","Bus","Vélo"], correctChoiceIndex:2},
    {id:"fr_402", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel moyen de transport peut-on utiliser pour voyager dans l'espace?", choices:["Voiture","Bateau","Fusée"], correctChoiceIndex:2},
    {id:"fr_403", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel moyen de transport peut-on utiliser pour aller sous l'eau?", choices:["Sous-marin","Bus","Vélo"], correctChoiceIndex:0},
    {id:"fr_404", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Quel moyen de transport peut-on utiliser pour aller sur la lune?", choices:["Voiture","Fusée","Bateau"], correctChoiceIndex:1},
    {id:"fr_405", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à réaction?", choices:["Voiture","Avion","Bateau"], correctChoiceIndex:1},
    {id:"fr_406", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur électrique?", choices:["Voiture électrique","Bateau à voiles","Vélo"], correctChoiceIndex:0},
    {id:"fr_407", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise des voiles?", choices:["Voiture","Bateau à voiles","Bus"], correctChoiceIndex:1},
    {id:"fr_408", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à vapeur?", choices:["Voiture","Train à vapeur","Avion"], correctChoiceIndex:1},
    {id:"fr_409", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur diesel?", choices:["Voiture diesel","Bateau à voiles","Vélo"], correctChoiceIndex:0},
    {id:"fr_410", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à essence?", choices:["Voiture à essence","Bateau à voiles","Vélo"], correctChoiceIndex:0},
    {id:"fr_411", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à pédales?", choices:["Voiture","Bateau","Vélo"], correctChoiceIndex:2},
    {id:"fr_412", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à hélice?", choices:["Voiture","Avion à hélice","Bateau"], correctChoiceIndex:1},
    {id:"fr_413", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à réaction?", choices:["Voiture","Avion à réaction","Bateau"], correctChoiceIndex:1},
    {id:"fr_414", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à combustion interne?", choices:["Voiture","Bateau à voiles","Vélo"], correctChoiceIndex:0},
    {id:"fr_415", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à hydrogène?", choices:["Voiture à hydrogène","Bateau à voiles","Vélo"], correctChoiceIndex:0},
    {id:"fr_416", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à gaz?", choices:["Voiture à gaz","Bateau à voiles","Vélo"], correctChoiceIndex:0},
    {id:"fr_417", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à air comprimé?", choices:["Voiture à air comprimé","Bateau à voiles","Vélo"], correctChoiceIndex:0},
    {id:"fr_418", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à énergie solaire?", choices:["Voiture solaire","Bateau à voiles","Vélo"], correctChoiceIndex:0},
    {id:"fr_419", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à énergie éolienne?", choices:["Voiture","Bateau à énergie éolienne","Vélo"], correctChoiceIndex:1},
    {id:"fr_420", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à énergie nucléaire?", choices:["Voiture","Bateau à énergie nucléaire","Vélo"], correctChoiceIndex:1},
    {id:"fr_421", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à énergie hydraulique?", choices:["Voiture","Bateau","Train à énergie hydraulique"], correctChoiceIndex:2},
    {id:"fr_422", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à énergie thermique?", choices:["Voiture à énergie thermique","Bateau à voiles","Vélo"], correctChoiceIndex:0},
    {id:"fr_423", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à énergie géothermique?", choices:["Voiture","Bateau","Train à énergie géothermique"], correctChoiceIndex:2},
    {id:"fr_424", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à énergie marémotrice?", choices:["Voiture","Bateau à énergie marémotrice","Vélo"], correctChoiceIndex:1},
    {id:"fr_425", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à énergie houlomotrice?", choices:["Voiture","Bateau à énergie houlomotrice","Vélo"], correctChoiceIndex:1},
    {id:"fr_426", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à énergie biomasse?", choices:["Voiture à énergie biomasse","Bateau à voiles","Vélo"], correctChoiceIndex:0},
    {id:"fr_427", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à énergie fossile?", choices:["Voiture à énergie fossile","Bateau à voiles","Vélo"], correctChoiceIndex:0},
    {id:"fr_428", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à énergie renouvelable?", choices:["Voiture à énergie renouvelable","Bateau à voiles","Vélo"], correctChoiceIndex:0},
    {id:"fr_429", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à énergie non renouvelable?", choices:["Voiture à énergie non renouvelable","Bateau à voiles","Vélo"], correctChoiceIndex:0},
    {id:"fr_430", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à énergie cinétique?", choices:["Voiture","Bateau","Train à énergie cinétique"], correctChoiceIndex:2},
    {id:"fr_431", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à énergie potentielle?", choices:["Voiture","Bateau","Train à énergie potentielle"], correctChoiceIndex:2},
    {id:"fr_432", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à énergie mécanique?", choices:["Voiture à énergie mécanique","Bateau à voiles","Vélo"], correctChoiceIndex:0},
    {id:"fr_433", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à énergie électrique?", choices:["Voiture à énergie électrique","Bateau à voiles","Vélo"], correctChoiceIndex:0},
    {id:"fr_434", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Quel moyen de transport utilise un moteur à énergie chimique?", choices:["Voiture à énergie chimique","Bateau à voiles","Vélo"], correctChoiceIndex:0},
    {id:"fr_435", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel est le travail d'un pompier?", choices:["Cuisiner","Éteindre les incendies","Peindre"], correctChoiceIndex:1},
    {id:"fr_436", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel est le travail d'un docteur?", choices:["Réparer les voitures","Soigner les gens","Jouer du piano"], correctChoiceIndex:1},
    {id:"fr_437", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel est le travail d'un enseignant?", choices:["Vendre des vêtements","Enseigner","Conduire un bus"], correctChoiceIndex:1},
    {id:"fr_438", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Où travaille un pompier?", choices:["Dans une école","Dans une bibliothèque","Dans une caserne de pompiers"], correctChoiceIndex:2},
    {id:"fr_439", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Où travaille un docteur?", choices:["Dans un hôpital","Dans une boulangerie","Dans une banque"], correctChoiceIndex:0},
    {id:"fr_440", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Où travaille un enseignant?", choices:["Dans une école","Dans une piscine","Dans un zoo"], correctChoiceIndex:0},
    {id:"fr_441", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Qu'est-ce qu'un pompier porte pour se protéger?", choices:["Un maillot de bain","Un costume de super-héros","Un équipement de protection"], correctChoiceIndex:2},
    {id:"fr_442", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Qu'est-ce qu'un docteur utilise pour écouter votre cœur?", choices:["Un stéthoscope","Une loupe","Une baguette magique"], correctChoiceIndex:0},
    {id:"fr_443", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Qu'est-ce qu'un enseignant utilise pour écrire sur le tableau?", choices:["Une brosse à dents","Une craie","Un bâton de hockey"], correctChoiceIndex:1},
    {id:"fr_444", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Pourquoi les pompiers ont-ils besoin d'un camion de pompiers?", choices:["Pour aller faire du shopping","Pour éteindre les incendies","Pour aller à la plage"], correctChoiceIndex:1},
    {id:"fr_445", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Pourquoi les docteurs portent-ils des blouses blanches?", choices:["Pour faire de la peinture","Pour se protéger et protéger leurs patients","Pour faire de la cuisine"], correctChoiceIndex:1},
    {id:"fr_446", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Pourquoi les enseignants ont-ils besoin de livres?", choices:["Pour faire du sport","Pour enseigner","Pour faire de la musique"], correctChoiceIndex:1},
    {id:"fr_447", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel est le rôle d'un pompier lors d'un incendie?", choices:["Regarder la télévision","Sauver les gens et éteindre l'incendie","Jouer au football"], correctChoiceIndex:1},
    {id:"fr_448", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel est le rôle d'un docteur lorsqu'une personne est malade?", choices:["Lire un livre","Soigner la personne","Jouer à des jeux vidéo"], correctChoiceIndex:1},
    {id:"fr_449", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel est le rôle d'un enseignant dans une école?", choices:["Nettoyer l'école","Enseigner aux élèves","Conduire le bus scolaire"], correctChoiceIndex:1},
    {id:"fr_450", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce qu'un pompier fait pour se préparer à un incendie?", choices:["Il fait du yoga","Il s'entraîne et apprend à utiliser son équipement","Il joue aux échecs"], correctChoiceIndex:1},
    {id:"fr_451", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce qu'un docteur fait pour se préparer à soigner les gens?", choices:["Il fait du jardinage","Il étudie et apprend à utiliser ses outils","Il fait de la danse"], correctChoiceIndex:1},
    {id:"fr_452", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce qu'un enseignant fait pour se préparer à enseigner?", choices:["Il fait du vélo","Il planifie ses leçons et apprend à utiliser ses outils d'enseignement","Il fait de la natation"], correctChoiceIndex:1},
    {id:"fr_453", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce qu'un pompier fait quand il n'y a pas d'incendie?", choices:["Il dort tout le temps","Il s'entraîne et maintient son équipement en bon état","Il fait du shopping"], correctChoiceIndex:1},
    {id:"fr_454", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce qu'un docteur fait quand il n'a pas de patients?", choices:["Il regarde des films","Il continue à apprendre et à se tenir au courant des nouvelles techniques médicales","Il joue au football"], correctChoiceIndex:1},
    {id:"fr_455", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce qu'un enseignant fait quand il n'a pas d'élèves?", choices:["Il va à la plage","Il planifie ses leçons et corrige les travaux des élèves","Il fait du ski"], correctChoiceIndex:1},
    {id:"fr_456", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce qu'un pompier fait pour aider la communauté?", choices:["Il vend des glaces","Il éteint les incendies et aide lors des urgences","Il fait des tours de magie"], correctChoiceIndex:1},
    {id:"fr_457", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce qu'un docteur fait pour aider la communauté?", choices:["Il fait des gâteaux","Il soigne les gens et donne des conseils de santé","Il fait du théâtre"], correctChoiceIndex:1},
    {id:"fr_458", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce qu'un enseignant fait pour aider la communauté?", choices:["Il fait du cirque","Il enseigne et aide les élèves à apprendre","Il fait de la plongée"], correctChoiceIndex:1},
    {id:"fr_459", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce qu'un pompier, un docteur et un enseignant ont en commun?", choices:["Ils aiment tous les bonbons","Ils aident tous la communauté","Ils jouent tous au tennis"], correctChoiceIndex:1},
    {id:"fr_460", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel est le meilleur moment pour manger des fruits?", choices:["Avant le repas","Après le repas","Pendant le repas"], correctChoiceIndex:0},
    {id:"fr_461", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel légume est orange?", choices:["Tomate","Carotte","Chou"], correctChoiceIndex:1},
    {id:"fr_462", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel est le goût du citron?", choices:["Doux","Salé","Acide"], correctChoiceIndex:2},
    {id:"fr_463", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel aliment nous donne du lait?", choices:["Vache","Poulet","Chèvre"], correctChoiceIndex:0},
    {id:"fr_464", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel fruit est jaune et courbé?", choices:["Pomme","Banane","Orange"], correctChoiceIndex:1},
    {id:"fr_465", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel aliment est bon pour les yeux?", choices:["Carotte","Pomme","Banane"], correctChoiceIndex:0},
    {id:"fr_466", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel aliment est une bonne source de calcium?", choices:["Lait","Jus d'orange","Eau"], correctChoiceIndex:0},
    {id:"fr_467", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel aliment est une bonne source de fer?", choices:["Poulet","Pain","Légumes verts"], correctChoiceIndex:2},
    {id:"fr_468", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel aliment est une bonne source de vitamine D?", choices:["Poisson","Pomme","Pain"], correctChoiceIndex:0},
    {id:"fr_469", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Combien de groupes alimentaires y a-t-il dans la pyramide alimentaire?", choices:["3","5","7"], correctChoiceIndex:1},
    {id:"fr_470", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel groupe alimentaire les pommes de terre appartiennent-elles?", choices:["Fruits et légumes","Viandes et substituts","Produits céréaliers"], correctChoiceIndex:2},
    {id:"fr_471", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel est le rôle des protéines dans notre corps?", choices:["Aide à la croissance","Fournit de l'énergie","Aide à la digestion"], correctChoiceIndex:0},
    {id:"fr_472", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel aliment est une bonne source de vitamine C?", choices:["Pomme","Orange","Banane"], correctChoiceIndex:1},
    {id:"fr_473", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel est le rôle des fibres dans notre corps?", choices:["Aide à la digestion","Fournit de l'énergie","Aide à la croissance"], correctChoiceIndex:0},
    {id:"fr_474", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel est le rôle des glucides dans notre corps?", choices:["Fournit de l'énergie","Aide à la croissance","Aide à la digestion"], correctChoiceIndex:0},
    {id:"fr_475", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel aliment est une bonne source de vitamine A?", choices:["Carotte","Pomme","Pain"], correctChoiceIndex:0},
    {id:"fr_476", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel aliment est une bonne source de vitamine B?", choices:["Pain","Pomme","Poisson"], correctChoiceIndex:0},
    {id:"fr_477", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel aliment est une bonne source de vitamine E?", choices:["Noix","Pomme","Pain"], correctChoiceIndex:0},
    {id:"fr_478", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel aliment est une bonne source de vitamine K?", choices:["Légumes verts","Pomme","Pain"], correctChoiceIndex:0},
    {id:"fr_479", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel aliment est une bonne source de zinc?", choices:["Viande","Pomme","Pain"], correctChoiceIndex:0},
    {id:"fr_480", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quelle est la valeur d'une pièce de 1 euro?", choices:["1 euro","2 euros","3 euros"], correctChoiceIndex:0},
    {id:"fr_481", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Si tu as 2 euros et que tu dépenses 1 euro, combien te reste-t-il?", choices:["1 euro","2 euros","3 euros"], correctChoiceIndex:0},
    {id:"fr_482", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quelle est la valeur d'un billet de 5 euros?", choices:["5 euros","10 euros","20 euros"], correctChoiceIndex:0},
    {id:"fr_483", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Si tu as 5 euros et que tu dépenses 2 euros, combien te reste-t-il?", choices:["3 euros","2 euros","1 euro"], correctChoiceIndex:0},
    {id:"fr_484", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quelle est la valeur d'une pièce de 2 euros?", choices:["1 euro","2 euros","3 euros"], correctChoiceIndex:1},
    {id:"fr_485", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Si tu as 10 euros et que tu dépenses 3 euros, combien te reste-t-il?", choices:["7 euros","6 euros","5 euros"], correctChoiceIndex:0},
    {id:"fr_486", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quelle est la valeur d'un billet de 10 euros?", choices:["5 euros","10 euros","20 euros"], correctChoiceIndex:1},
    {id:"fr_487", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Si tu as 20 euros et que tu dépenses 5 euros, combien te reste-t-il?", choices:["15 euros","10 euros","5 euros"], correctChoiceIndex:0},
    {id:"fr_488", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quelle est la valeur d'un billet de 20 euros?", choices:["10 euros","20 euros","30 euros"], correctChoiceIndex:1},
    {id:"fr_489", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Si tu as 50 euros et que tu dépenses 20 euros, combien te reste-t-il?", choices:["30 euros","20 euros","10 euros"], correctChoiceIndex:0},
    {id:"fr_490", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quelle est la valeur d'un billet de 50 euros?", choices:["50 euros","100 euros","150 euros"], correctChoiceIndex:0},
    {id:"fr_491", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Si tu as 100 euros et que tu dépenses 50 euros, combien te reste-t-il?", choices:["50 euros","40 euros","30 euros"], correctChoiceIndex:0},
    {id:"fr_492", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quelle est la valeur d'un billet de 100 euros?", choices:["50 euros","100 euros","150 euros"], correctChoiceIndex:1},
    {id:"fr_493", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Si tu as 2 euros et que tu en gagnes 3 de plus, combien as-tu maintenant?", choices:["5 euros","4 euros","3 euros"], correctChoiceIndex:0},
    {id:"fr_494", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Si tu as 5 euros et que tu en gagnes 5 de plus, combien as-tu maintenant?", choices:["10 euros","9 euros","8 euros"], correctChoiceIndex:0},
    {id:"fr_495", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Si tu as 10 euros et que tu en gagnes 10 de plus, combien as-tu maintenant?", choices:["20 euros","15 euros","10 euros"], correctChoiceIndex:0},
    {id:"fr_496", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Si tu as 20 euros et que tu en gagnes 20 de plus, combien as-tu maintenant?", choices:["40 euros","30 euros","20 euros"], correctChoiceIndex:0},
    {id:"fr_497", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Si tu as 50 euros et que tu en gagnes 50 de plus, combien as-tu maintenant?", choices:["100 euros","75 euros","50 euros"], correctChoiceIndex:0},
    {id:"fr_498", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Si tu as 100 euros et que tu en gagnes 100 de plus, combien as-tu maintenant?", choices:["200 euros","150 euros","100 euros"], correctChoiceIndex:0},
    {id:"fr_499", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Si tu as 200 euros et que tu en gagnes 200 de plus, combien as-tu maintenant?", choices:["400 euros","300 euros","200 euros"], correctChoiceIndex:0},
    {id:"fr_500", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel numéro appelles-tu en cas d'urgence?", choices:["100","112","911"], correctChoiceIndex:1},
    {id:"fr_501", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Que fais-tu quand le feu rouge clignote au passage piéton?", choices:["Je cours","Je m'arrête","Je danse"], correctChoiceIndex:1},
    {id:"fr_502", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Où est l'endroit le plus sûr pour traverser la route?", choices:["Entre les voitures garées","Sur le passage piéton","Au milieu de la route"], correctChoiceIndex:1},
    {id:"fr_503", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Que fais-tu si tu vois de la fumée à la maison?", choices:["Je joue avec","Je crie et je sors de la maison","Je l'ignore"], correctChoiceIndex:1},
    {id:"fr_504", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Qu'est-ce que tu portes pour être en sécurité à vélo?", choices:["Un chapeau","Un casque","Des lunettes de soleil"], correctChoiceIndex:1},
    {id:"fr_505", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Qu'est-ce que tu fais si tu trouves une allumette ou un briquet?", choices:["Je joue avec","Je le donne à un adulte","Je le cache"], correctChoiceIndex:1},
    {id:"fr_506", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Qu'est-ce que tu fais si tu vois un chien que tu ne connais pas?", choices:["Je le caresse","Je lui donne à manger","Je ne le touche pas"], correctChoiceIndex:2},
    {id:"fr_507", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Qu'est-ce que tu fais si tu te perds?", choices:["Je pleure","Je cherche mes parents","Je reste où je suis et j'appelle à l'aide"], correctChoiceIndex:2},
    {id:"fr_508", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Qu'est-ce que tu fais si tu vois de l'eau sur le sol?", choices:["Je glisse dessus","Je la nettoie","Je dis à un adulte"], correctChoiceIndex:2},
    {id:"fr_509", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Qu'est-ce que tu fais si tu vois un fil électrique dénudé?", choices:["Je le touche","Je le donne à un adulte","Je dis à un adulte"], correctChoiceIndex:2},
    {id:"fr_510", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Qu'est-ce que tu fais si tu vois un objet enflammé?", choices:["Je le touche","Je le donne à un adulte","Je dis à un adulte"], correctChoiceIndex:2},
    {id:"fr_511", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce que tu fais avant de traverser la route?", choices:["Je regarde à gauche et à droite","Je cours rapidement","Je ferme les yeux"], correctChoiceIndex:0},
    {id:"fr_512", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce que tu fais si tu te perds dans un magasin?", choices:["Je crie","Je cherche mes parents","Je vais à la caisse et je demande de l'aide"], correctChoiceIndex:2},
    {id:"fr_513", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce que tu fais si un inconnu t'offre des bonbons?", choices:["Je les prends","Je dis non merci","Je lui demande son nom"], correctChoiceIndex:1},
    {id:"fr_514", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce que tu fais si tu vois un feu à la maison?", choices:["Je l'éteins avec de l'eau","Je sors de la maison et j'appelle les pompiers","Je le regarde"], correctChoiceIndex:1},
    {id:"fr_515", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce que tu fais si quelqu'un te fait du mal?", choices:["Je le frappe","Je le dis à un adulte de confiance","Je l'ignore"], correctChoiceIndex:1},
    {id:"fr_516", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce que tu fais si tu es seul à la maison et que quelqu'un frappe à la porte?", choices:["J'ouvre la porte","Je ne réponds pas","Je demande qui c'est sans ouvrir la porte"], correctChoiceIndex:2},
    {id:"fr_517", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce que tu fais si tu vois quelqu'un se blesser?", choices:["Je ris","Je vais chercher de l'aide","Je l'ignore"], correctChoiceIndex:1},
    {id:"fr_518", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce que tu fais si tu vois un objet tranchant?", choices:["Je le touche","Je le donne à un adulte","Je joue avec"], correctChoiceIndex:1},
    {id:"fr_519", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Qu'est-ce que tu fais si tu vois un médicament?", choices:["Je le mange","Je le donne à un adulte","Je le cache"], correctChoiceIndex:1},
    {id:"fr_520", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel jour vient après lundi?", choices:["Mardi","Mercredi","Jeudi"], correctChoiceIndex:0},
    {id:"fr_521", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel jour vient avant vendredi?", choices:["Jeudi","Mercredi","Samedi"], correctChoiceIndex:0},
    {id:"fr_522", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel est le premier mois de l'année?", choices:["Février","Janvier","Mars"], correctChoiceIndex:1},
    {id:"fr_523", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel est le dernier mois de l'année?", choices:["Novembre","Décembre","Octobre"], correctChoiceIndex:1},
    {id:"fr_524", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Combien de jours y a-t-il dans une semaine?", choices:["7","5","6"], correctChoiceIndex:0},
    {id:"fr_525", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel jour vient après jeudi?", choices:["Vendredi","Samedi","Dimanche"], correctChoiceIndex:0},
    {id:"fr_526", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel jour vient avant mardi?", choices:["Lundi","Dimanche","Mercredi"], correctChoiceIndex:0},
    {id:"fr_527", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel est le cinquième mois de l'année?", choices:["Mai","Juin","Avril"], correctChoiceIndex:0},
    {id:"fr_528", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel est le neuvième mois de l'année?", choices:["Septembre","Octobre","Août"], correctChoiceIndex:0},
    {id:"fr_529", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Combien de mois y a-t-il dans une année?", choices:["12","10","11"], correctChoiceIndex:0},
    {id:"fr_530", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quelle heure est-il quand la grande aiguille est sur le 12 et la petite aiguille sur le 1?", choices:["1 heure","2 heures","12 heures"], correctChoiceIndex:0},
    {id:"fr_531", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quelle heure est-il quand la grande aiguille est sur le 6 et la petite aiguille sur le 3?", choices:["3 heures et demie","3 heures","6 heures"], correctChoiceIndex:0},
    {id:"fr_532", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quelle heure est-il quand la grande aiguille est sur le 12 et la petite aiguille sur le 6?", choices:["6 heures","12 heures","1 heure"], correctChoiceIndex:0},
    {id:"fr_533", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quelle heure est-il quand la grande aiguille est sur le 3 et la petite aiguille sur le 9?", choices:["9 heures et quart","9 heures","3 heures"], correctChoiceIndex:0},
    {id:"fr_534", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quelle heure est-il quand la grande aiguille est sur le 9 et la petite aiguille sur le 12?", choices:["12 heures et 45 minutes","9 heures","12 heures"], correctChoiceIndex:0},
    {id:"fr_535", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quelle heure est-il quand la grande aiguille est sur le 6 et la petite aiguille sur le 12?", choices:["12 heures et demie","6 heures","12 heures"], correctChoiceIndex:0},
    {id:"fr_536", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quelle heure est-il quand la grande aiguille est sur le 12 et la petite aiguille sur le 3?", choices:["3 heures","12 heures","1 heure"], correctChoiceIndex:0},
    {id:"fr_537", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quelle heure est-il quand la grande aiguille est sur le 6 et la petite aiguille sur le 9?", choices:["9 heures et demie","9 heures","6 heures"], correctChoiceIndex:0},
    {id:"fr_538", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quelle heure est-il quand la grande aiguille est sur le 12 et la petite aiguille sur le 9?", choices:["9 heures","12 heures","1 heure"], correctChoiceIndex:0},
    {id:"fr_539", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quelle heure est-il quand la grande aiguille est sur le 3 et la petite aiguille sur le 6?", choices:["6 heures et quart","6 heures","3 heures"], correctChoiceIndex:0},
    {id:"fr_540", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour couper du papier?", choices:["Ciseaux","Marteau","Pinceau"], correctChoiceIndex:0},
    {id:"fr_541", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour peindre?", choices:["Couteau","Pinceau","Fourchette"], correctChoiceIndex:1},
    {id:"fr_542", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour coller des choses?", choices:["Colle","Crayon","Règle"], correctChoiceIndex:0},
    {id:"fr_543", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour dessiner?", choices:["Crayon","Pelle","Brosse"], correctChoiceIndex:0},
    {id:"fr_544", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour mesurer des choses?", choices:["Règle","Ciseaux","Pinceau"], correctChoiceIndex:0},
    {id:"fr_545", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour écrire?", choices:["Crayon","Pelle","Brosse"], correctChoiceIndex:0},
    {id:"fr_546", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour nettoyer le sol?", choices:["Balai","Ciseaux","Pinceau"], correctChoiceIndex:0},
    {id:"fr_547", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour faire un trou dans le mur?", choices:["Perceuse","Crayon","Règle"], correctChoiceIndex:0},
    {id:"fr_548", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour nettoyer les vitres?", choices:["Raclette","Ciseaux","Pinceau"], correctChoiceIndex:0},
    {id:"fr_549", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour faire un gâteau?", choices:["Fouet","Crayon","Règle"], correctChoiceIndex:0},
    {id:"fr_550", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour faire des trous dans le sol?", choices:["Bêche","Ciseaux","Pinceau"], correctChoiceIndex:0},
    {id:"fr_551", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour faire de la musique?", choices:["Tambour","Ciseaux","Pinceau"], correctChoiceIndex:0},
    {id:"fr_552", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour faire des bulles de savon?", choices:["Baguette à bulles","Crayon","Règle"], correctChoiceIndex:0},
    {id:"fr_553", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour faire du jardinage?", choices:["Pelle","Ciseaux","Pinceau"], correctChoiceIndex:0},
    {id:"fr_554", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour couper du bois?", choices:["Scie","Pinceau","Crayon"], correctChoiceIndex:0},
    {id:"fr_555", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour fixer des choses?", choices:["Marteau","Ciseaux","Pinceau"], correctChoiceIndex:0},
    {id:"fr_556", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour creuser un trou?", choices:["Pelle","Crayon","Règle"], correctChoiceIndex:0},
    {id:"fr_557", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour visser des choses?", choices:["Tournevis","Ciseaux","Pinceau"], correctChoiceIndex:0},
    {id:"fr_558", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour peindre une grande surface?", choices:["Rouleau de peinture","Ciseaux","Crayon"], correctChoiceIndex:0},
    {id:"fr_559", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour couper du métal?", choices:["Scie à métaux","Pinceau","Crayon"], correctChoiceIndex:0},
    {id:"fr_560", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour serrer des choses?", choices:["Pince","Ciseaux","Pinceau"], correctChoiceIndex:0},
    {id:"fr_561", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour ratisser les feuilles?", choices:["Râteau","Crayon","Règle"], correctChoiceIndex:0},
    {id:"fr_562", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour planter des graines?", choices:["Pelle","Ciseaux","Pinceau"], correctChoiceIndex:0},
    {id:"fr_563", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour couper les branches d'un arbre?", choices:["Sécateur","Ciseaux","Crayon"], correctChoiceIndex:0},
    {id:"fr_564", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour couper les cheveux?", choices:["Ciseaux","Pelle","Brosse"], correctChoiceIndex:0},
    {id:"fr_565", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour faire un feu?", choices:["Allumettes","Crayon","Règle"], correctChoiceIndex:0},
    {id:"fr_566", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour faire du bricolage?", choices:["Marteau","Ciseaux","Pinceau"], correctChoiceIndex:0},
    {id:"fr_567", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour faire de la couture?", choices:["Aiguille","Crayon","Règle"], correctChoiceIndex:0},
    {id:"fr_568", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour faire de la sculpture?", choices:["Ciseau à bois","Crayon","Règle"], correctChoiceIndex:0},
    {id:"fr_569", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Quel outil utilisons-nous pour faire de la poterie?", choices:["Tour de potier","Ciseaux","Pinceau"], correctChoiceIndex:0},
    {id:"fr_570", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quelle est la couleur primaire?", choices:["Rouge","Rose","Marron"], correctChoiceIndex:0},
    {id:"fr_571", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quelle est la couleur obtenue en mélangeant du bleu et du jaune?", choices:["Vert","Orange","Violet"], correctChoiceIndex:0},
    {id:"fr_572", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quelle est la couleur obtenue en mélangeant du rouge et du bleu?", choices:["Violet","Vert","Orange"], correctChoiceIndex:0},
    {id:"fr_573", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quelle est la couleur obtenue en mélangeant du rouge et du jaune?", choices:["Orange","Vert","Violet"], correctChoiceIndex:0},
    {id:"fr_574", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quelle est la couleur obtenue en mélangeant du bleu et du rouge?", choices:["Violet","Vert","Orange"], correctChoiceIndex:0},
    {id:"fr_575", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qu'est-ce qu'une sculpture?", choices:["Une image dessinée","Une forme tridimensionnelle","Une chanson"], correctChoiceIndex:1},
    {id:"fr_576", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qu'est-ce qu'une peinture?", choices:["Une forme tridimensionnelle","Une image dessinée","Une chanson"], correctChoiceIndex:1},
    {id:"fr_577", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qu'est-ce qu'un autoportrait?", choices:["Une peinture de quelqu'un d'autre","Une peinture de soi-même","Une peinture d'un animal"], correctChoiceIndex:1},
    {id:"fr_578", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qu'est-ce qu'une nature morte en art?", choices:["Une peinture d'objets inanimés","Une peinture d'animaux","Une peinture de personnes"], correctChoiceIndex:0},
    {id:"fr_579", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qu'est-ce qu'un paysage en art?", choices:["Une peinture de personnes","Une peinture d'animaux","Une peinture de la nature"], correctChoiceIndex:2},
    {id:"fr_580", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qu'est-ce qu'une sculpture en relief?", choices:["Une sculpture plate","Une sculpture qui ressort d'une surface","Une sculpture d'un animal"], correctChoiceIndex:1},
    {id:"fr_581", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qu'est-ce qu'une sculpture en ronde-bosse?", choices:["Une sculpture plate","Une sculpture qui peut être vue de tous les côtés","Une sculpture d'un animal"], correctChoiceIndex:1},
    {id:"fr_582", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qu'est-ce qu'une mosaïque?", choices:["Une image faite de petits morceaux de verre ou de pierre","Une image dessinée","Une image peinte"], correctChoiceIndex:0},
    {id:"fr_583", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qu'est-ce qu'un collage en art?", choices:["Une image faite de morceaux de papier collés ensemble","Une image dessinée","Une image peinte"], correctChoiceIndex:0},
    {id:"fr_584", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qu'est-ce qu'une fresque?", choices:["Une peinture sur un mur humide","Une peinture sur toile","Une peinture sur papier"], correctChoiceIndex:0},
    {id:"fr_585", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qu'est-ce qu'une aquarelle?", choices:["Une peinture à l'huile","Une peinture à l'eau","Une peinture sur mur"], correctChoiceIndex:1},
    {id:"fr_586", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qu'est-ce qu'une peinture à l'huile?", choices:["Une peinture à l'eau","Une peinture sur mur","Une peinture avec des pigments mélangés à de l'huile"], correctChoiceIndex:2},
    {id:"fr_587", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qu'est-ce qu'une peinture acrylique?", choices:["Une peinture à l'eau","Une peinture avec des pigments mélangés à une résine acrylique","Une peinture sur mur"], correctChoiceIndex:1},
    {id:"fr_588", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qu'est-ce qu'un pastel en art?", choices:["Une peinture à l'eau","Une peinture à l'huile","Un bâton de pigment utilisé pour dessiner"], correctChoiceIndex:2},
    {id:"fr_589", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qu'est-ce qu'un crayon de couleur en art?", choices:["Un bâton de pigment utilisé pour dessiner","Une peinture à l'eau","Un outil pour dessiner avec des couleurs"], correctChoiceIndex:2},
    {id:"fr_590", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel type de danse est associé à l'Espagne?", choices:["Ballet","Salsa","Flamenco"], correctChoiceIndex:2},
    {id:"fr_591", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à l'Irlande?", choices:["Danse du ventre","Danse de la rivière","Danse du robot"], correctChoiceIndex:1},
    {id:"fr_592", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à l'Inde?", choices:["Bollywood","Hip-hop","Tango"], correctChoiceIndex:0},
    {id:"fr_593", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à l'Argentine?", choices:["Salsa","Tango","Ballet"], correctChoiceIndex:1},
    {id:"fr_594", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à la Russie?", choices:["Ballet","Salsa","Flamenco"], correctChoiceIndex:0},
    {id:"fr_595", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à l'Afrique?", choices:["Danse africaine","Ballet","Tango"], correctChoiceIndex:0},
    {id:"fr_596", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à la Chine?", choices:["Danse du dragon","Ballet","Tango"], correctChoiceIndex:0},
    {id:"fr_597", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à l'Australie?", choices:["Danse aborigène","Ballet","Tango"], correctChoiceIndex:0},
    {id:"fr_598", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à l'Angleterre?", choices:["Danse Morris","Ballet","Tango"], correctChoiceIndex:0},
    {id:"fr_599", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à l'Égypte?", choices:["Danse du ventre","Tango","Ballet"], correctChoiceIndex:0},
    {id:"fr_600", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à la Jamaïque?", choices:["Reggae","Ballet","Tango"], correctChoiceIndex:0},
    {id:"fr_601", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à l'Amérique?", choices:["Hip-hop","Ballet","Tango"], correctChoiceIndex:0},
    {id:"fr_602", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à Cuba?", choices:["Salsa","Ballet","Tango"], correctChoiceIndex:0},
    {id:"fr_603", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à la France?", choices:["Can-can","Ballet","Tango"], correctChoiceIndex:0},
    {id:"fr_604", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à l'Écosse?", choices:["Danse écossaise","Ballet","Tango"], correctChoiceIndex:0},
    {id:"fr_605", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à la Grèce?", choices:["Sirtaki","Ballet","Tango"], correctChoiceIndex:0},
    {id:"fr_606", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à l'Italie?", choices:["Tarantelle","Ballet","Tango"], correctChoiceIndex:0},
    {id:"fr_607", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à la Polynésie française?", choices:["Hula","Ballet","Tango"], correctChoiceIndex:0},
    {id:"fr_608", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à la Nouvelle-Zélande?", choices:["Haka","Ballet","Tango"], correctChoiceIndex:0},
    {id:"fr_609", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quelle danse est souvent associée à l'Autriche?", choices:["Valse","Ballet","Tango"], correctChoiceIndex:0},
    {id:"fr_610", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel est le personnage principal dans le conte de fées Cendrillon?", choices:["La Belle au bois dormant","Cendrillon","Blanche Neige"], correctChoiceIndex:1},
    {id:"fr_611", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Qui a écrit Le Petit Chaperon Rouge?", choices:["Charles Perrault","J.K. Rowling","Roald Dahl"], correctChoiceIndex:0},
    {id:"fr_612", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Dans quel conte de fées les personnages principaux grimpent-ils un haricot magique?", choices:["Jack et le haricot magique","Cendrillon","La Belle et la Bête"], correctChoiceIndex:0},
    {id:"fr_613", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel animal est le personnage principal dans Le Vilain Petit Canard?", choices:["Un canard","Un chat","Un chien"], correctChoiceIndex:0},
    {id:"fr_614", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel est le nom du loup dans Le Petit Chaperon Rouge?", choices:["Loup","Renard","Ours"], correctChoiceIndex:0},
    {id:"fr_615", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel est le nom du prince dans La Belle au bois dormant?", choices:["Prince Charmant","Prince Philippe","Prince Eric"], correctChoiceIndex:1},
    {id:"fr_616", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Dans quel conte de fées une fille parle-t-elle à des animaux de la forêt?", choices:["Blanche Neige","Cendrillon","La Belle et la Bête"], correctChoiceIndex:0},
    {id:"fr_617", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel est le nom de la sorcière dans La Petite Sirène?", choices:["Ursula","Maléfique","La Reine des Neiges"], correctChoiceIndex:0},
    {id:"fr_618", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel est le nom du chat dans Le Chat Botté?", choices:["Puss","Felix","Tom"], correctChoiceIndex:0},
    {id:"fr_619", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Qui a écrit Le Chat Botté?", choices:["Charles Perrault","Les frères Grimm","Hans Christian Andersen"], correctChoiceIndex:0},
    {id:"fr_620", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qui a écrit Les Trois Petits Cochons?", choices:["Charles Perrault","Les frères Grimm","Hans Christian Andersen"], correctChoiceIndex:0},
    {id:"fr_621", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Dans quel conte de fées une fille perd-elle sa chaussure de verre?", choices:["Cendrillon","Blanche Neige","La Belle au bois dormant"], correctChoiceIndex:0},
    {id:"fr_622", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est le nom de la sorcière dans Hansel et Gretel?", choices:["Maléfique","La Reine des Neiges","La sorcière de la forêt"], correctChoiceIndex:2},
    {id:"fr_623", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qui a écrit Le Vilain Petit Canard?", choices:["Hans Christian Andersen","Charles Perrault","Les frères Grimm"], correctChoiceIndex:0},
    {id:"fr_624", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Dans quel conte de fées un prince se transforme-t-il en bête?", choices:["La Belle et la Bête","Cendrillon","Blanche Neige"], correctChoiceIndex:0},
    {id:"fr_625", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qui a écrit La Belle et la Bête?", choices:["Gabrielle-Suzanne Barbot de Villeneuve","Charles Perrault","Les frères Grimm"], correctChoiceIndex:0},
    {id:"fr_626", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Dans quel conte de fées un garçon ne grandit-il jamais?", choices:["Peter Pan","Cendrillon","Blanche Neige"], correctChoiceIndex:0},
    {id:"fr_627", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est le nom du garçon qui ne grandit jamais dans Peter Pan?", choices:["Peter Pan","Jack","Hansel"], correctChoiceIndex:0},
    {id:"fr_628", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Qui a écrit Peter Pan?", choices:["J.M. Barrie","Charles Perrault","Les frères Grimm"], correctChoiceIndex:0},
    {id:"fr_629", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Dans quel conte de fées une fille vit-elle avec sept nains?", choices:["Blanche Neige","Cendrillon","La Belle au bois dormant"], correctChoiceIndex:0},
    {id:"fr_630", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel est cet instrument qui a des touches noires et blanches?", choices:["Guitare","Piano","Tambour"], correctChoiceIndex:1},
    {id:"fr_631", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel instrument utilise-t-on pour frapper?", choices:["Flûte","Tambour","Violon"], correctChoiceIndex:1},
    {id:"fr_632", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel instrument a des cordes que l'on peut pincer ou frotter?", choices:["Piano","Tambour","Violon"], correctChoiceIndex:2},
    {id:"fr_633", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel instrument souffle-t-on pour faire du son?", choices:["Flûte","Piano","Tambour"], correctChoiceIndex:0},
    {id:"fr_634", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel instrument utilise-t-on pour gratter?", choices:["Guitare","Flûte","Tambour"], correctChoiceIndex:0},
    {id:"fr_635", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel est le nom de ce rythme rapide?", choices:["Lento","Allegro","Andante"], correctChoiceIndex:1},
    {id:"fr_636", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel est le nom de ce rythme lent?", choices:["Lento","Allegro","Andante"], correctChoiceIndex:0},
    {id:"fr_637", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel est le nom de ce rythme moyen?", choices:["Lento","Allegro","Andante"], correctChoiceIndex:2},
    {id:"fr_638", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est cet instrument à vent en bois?", choices:["Flûte","Trompette","Violon"], correctChoiceIndex:0},
    {id:"fr_639", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est cet instrument à cordes joué avec un archet?", choices:["Guitare","Piano","Violon"], correctChoiceIndex:2},
    {id:"fr_640", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est cet instrument à percussion joué avec les mains?", choices:["Piano","Tambour","Violon"], correctChoiceIndex:1},
    {id:"fr_641", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est cet instrument à cordes pincées?", choices:["Guitare","Flûte","Tambour"], correctChoiceIndex:0},
    {id:"fr_642", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est cet instrument à clavier?", choices:["Guitare","Piano","Tambour"], correctChoiceIndex:1},
    {id:"fr_643", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est le nom de ce rythme très rapide?", choices:["Presto","Allegro","Andante"], correctChoiceIndex:0},
    {id:"fr_644", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est le nom de ce rythme très lent?", choices:["Lento","Adagio","Andante"], correctChoiceIndex:1},
    {id:"fr_645", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est le nom de ce rythme modéré?", choices:["Lento","Allegro","Moderato"], correctChoiceIndex:2},
    {id:"fr_646", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est le nom de ce rythme vif et joyeux?", choices:["Lento","Vivace","Andante"], correctChoiceIndex:1},
    {id:"fr_647", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est le nom de ce rythme doux et paisible?", choices:["Dolce","Allegro","Andante"], correctChoiceIndex:0},
    {id:"fr_648", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est le nom de ce rythme marchant?", choices:["Lento","Allegro","Andante"], correctChoiceIndex:2},
    {id:"fr_649", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est le nom de ce rythme animé et rapide?", choices:["Lento","Allegro","Vivace"], correctChoiceIndex:1},
    {id:"fr_650", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quelle est la langue officielle de l'Espagne?", choices:["Anglais","Espagnol","Français"], correctChoiceIndex:1},
    {id:"fr_651", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel est le festival des couleurs en Inde?", choices:["Holi","Diwali","Navratri"], correctChoiceIndex:0},
    {id:"fr_652", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel est le nom du nouvel an chinois?", choices:["Diwali","Hanukkah","Fête du Printemps"], correctChoiceIndex:2},
    {id:"fr_653", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel est le plat traditionnel japonais?", choices:["Pizza","Sushi","Hamburger"], correctChoiceIndex:1},
    {id:"fr_654", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quelle danse est célèbre en Irlande?", choices:["Tango","Danse irlandaise","Ballet"], correctChoiceIndex:1},
    {id:"fr_655", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quelle est la langue officielle de l'Allemagne?", choices:["Anglais","Espagnol","Allemand"], correctChoiceIndex:2},
    {id:"fr_656", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel est le festival des lanternes en Thaïlande?", choices:["Loi Krathong","Diwali","Navratri"], correctChoiceIndex:0},
    {id:"fr_657", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel est le nom du nouvel an russe?", choices:["Diwali","Hanukkah","Novy God"], correctChoiceIndex:2},
    {id:"fr_658", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quel est le plat traditionnel français?", choices:["Pizza","Sushi","Baguette"], correctChoiceIndex:2},
    {id:"fr_659", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Quelle danse est célèbre en Russie?", choices:["Tango","Danse du ballet","Ballet"], correctChoiceIndex:1},
    {id:"fr_660", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quelle est la langue officielle du Brésil?", choices:["Espagnol","Portugais","Anglais"], correctChoiceIndex:1},
    {id:"fr_661", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est le festival des lumières en Inde?", choices:["Holi","Diwali","Navratri"], correctChoiceIndex:1},
    {id:"fr_662", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est le nom du nouvel an juif?", choices:["Hanukkah","Rosh Hashanah","Fête du Printemps"], correctChoiceIndex:1},
    {id:"fr_663", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est le plat traditionnel italien?", choices:["Pizza","Sushi","Hamburger"], correctChoiceIndex:0},
    {id:"fr_664", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quelle danse est célèbre en Espagne?", choices:["Tango","Danse irlandaise","Flamenco"], correctChoiceIndex:2},
    {id:"fr_665", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quelle est la langue officielle de l'Italie?", choices:["Italien","Portugais","Anglais"], correctChoiceIndex:0},
    {id:"fr_666", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est le festival des cerisiers en fleurs au Japon?", choices:["Holi","Hanami","Navratri"], correctChoiceIndex:1},
    {id:"fr_667", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est le nom du nouvel an iranien?", choices:["Hanukkah","Nowruz","Fête du Printemps"], correctChoiceIndex:1},
    {id:"fr_668", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quel est le plat traditionnel chinois?", choices:["Pizza","Sushi","Dim Sum"], correctChoiceIndex:2},
    {id:"fr_669", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Quelle danse est célèbre en Argentine?", choices:["Tango","Danse irlandaise","Flamenco"], correctChoiceIndex:0},
    {id:"fr_670", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle est la plus longue?", choices:["Une voiture","Un crayon","Un bus"], correctChoiceIndex:2},
    {id:"fr_671", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle est la plus courte?", choices:["Une souris","Un chat","Un éléphant"], correctChoiceIndex:0},
    {id:"fr_672", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus lourd?", choices:["Une pomme","Une voiture","Un livre"], correctChoiceIndex:1},
    {id:"fr_673", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus léger?", choices:["Une plume","Un rocher","Une voiture"], correctChoiceIndex:0},
    {id:"fr_674", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus grand?", choices:["Un arbre","Une fleur","Un buisson"], correctChoiceIndex:0},
    {id:"fr_675", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus petit?", choices:["Un grain de sable","Une montagne","Un bâtiment"], correctChoiceIndex:0},
    {id:"fr_676", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus long?", choices:["Un jour","Une semaine","Un mois"], correctChoiceIndex:2},
    {id:"fr_677", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus court?", choices:["Une minute","Une heure","Un jour"], correctChoiceIndex:0},
    {id:"fr_678", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus lourd?", choices:["Un éléphant","Un chat","Une souris"], correctChoiceIndex:0},
    {id:"fr_679", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus léger?", choices:["Une plume","Un oiseau","Un avion"], correctChoiceIndex:0},
    {id:"fr_680", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus grand?", choices:["Un grain de riz","Une pomme","Une pastèque"], correctChoiceIndex:2},
    {id:"fr_681", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus petit?", choices:["Une montagne","Un arbre","Une fourmi"], correctChoiceIndex:2},
    {id:"fr_682", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus long?", choices:["Un mois","Une semaine","Un jour"], correctChoiceIndex:0},
    {id:"fr_683", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus court?", choices:["Une année","Un mois","Une semaine"], correctChoiceIndex:2},
    {id:"fr_684", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus lourd?", choices:["Une plume","Un livre","Un rocher"], correctChoiceIndex:2},
    {id:"fr_685", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus léger?", choices:["Un éléphant","Un cheval","Une plume"], correctChoiceIndex:2},
    {id:"fr_686", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus grand?", choices:["Un grain de sable","Une balle","Un ballon"], correctChoiceIndex:2},
    {id:"fr_687", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus petit?", choices:["Une montagne","Un bâtiment","Un grain de riz"], correctChoiceIndex:2},
    {id:"fr_688", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus long?", choices:["Une journée","Une heure","Une minute"], correctChoiceIndex:0},
    {id:"fr_689", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus court?", choices:["Une année","Un mois","Une semaine"], correctChoiceIndex:2},
    {id:"fr_690", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus lourd?", choices:["Une plume","Un livre","Un rocher"], correctChoiceIndex:2},
    {id:"fr_691", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le plus léger?", choices:["Un éléphant","Un cheval","Une plume"], correctChoiceIndex:2},
    {id:"fr_692", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de centimètres font un mètre?", choices:["100","1000","10"], correctChoiceIndex:0},
    {id:"fr_693", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de grammes font un kilogramme?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"fr_694", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de litres font un millilitre?", choices:["0.001","0.01","0.1"], correctChoiceIndex:0},
    {id:"fr_695", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de minutes font une heure?", choices:["60","30","120"], correctChoiceIndex:0},
    {id:"fr_696", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de secondes font une minute?", choices:["60","30","120"], correctChoiceIndex:0},
    {id:"fr_697", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de jours font une semaine?", choices:["7","5","10"], correctChoiceIndex:0},
    {id:"fr_698", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de semaines font un mois?", choices:["4","2","6"], correctChoiceIndex:0},
    {id:"fr_699", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de mois font une année?", choices:["12","10","15"], correctChoiceIndex:0},
    {id:"fr_700", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de centimètres font un kilomètre?", choices:["100000","10000","1000"], correctChoiceIndex:0},
    {id:"fr_701", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de millimètres font un centimètre?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"fr_702", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de millilitres font un litre?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"fr_703", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de grammes font une tonne?", choices:["1000000","100000","10000"], correctChoiceIndex:0},
    {id:"fr_704", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de minutes font un jour?", choices:["1440","1400","1500"], correctChoiceIndex:0},
    {id:"fr_705", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de secondes font une heure?", choices:["3600","3000","4000"], correctChoiceIndex:0},
    {id:"fr_706", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de jours font un an?", choices:["365","360","370"], correctChoiceIndex:0},
    {id:"fr_707", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de semaines font une année?", choices:["52","50","54"], correctChoiceIndex:0},
    {id:"fr_708", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de mois font un siècle?", choices:["1200","1000","1100"], correctChoiceIndex:0},
    {id:"fr_709", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de millimètres font un mètre?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"fr_710", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle est la plus longue?", choices:["1 mètre","1 kilomètre","1 centimètre"], correctChoiceIndex:1},
    {id:"fr_711", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle est la plus courte?", choices:["1 mètre","1 kilomètre","1 centimètre"], correctChoiceIndex:2},
    {id:"fr_712", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle est la plus lourde?", choices:["1 gramme","1 kilogramme","1 tonne"], correctChoiceIndex:2},
    {id:"fr_713", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle est la plus légère?", choices:["1 gramme","1 kilogramme","1 tonne"], correctChoiceIndex:0},
    {id:"fr_714", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle est la plus grande?", choices:["1 litre","1 millilitre","1 centilitre"], correctChoiceIndex:0},
    {id:"fr_715", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle est la plus petite?", choices:["1 litre","1 millilitre","1 centilitre"], correctChoiceIndex:1},
    {id:"fr_716", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien de centimètres dans un mètre?", choices:["100","10","1000"], correctChoiceIndex:0},
    {id:"fr_717", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien de millimètres dans un centimètre?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"fr_718", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien de grammes dans un kilogramme?", choices:["1000","100","10000"], correctChoiceIndex:0},
    {id:"fr_719", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien de millilitres dans un litre?", choices:["1000","100","10000"], correctChoiceIndex:0},
    {id:"fr_720", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle est la plus longue?", choices:["1 millimètre","1 centimètre","1 décimètre"], correctChoiceIndex:2},
    {id:"fr_721", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle est la plus courte?", choices:["1 millimètre","1 centimètre","1 décimètre"], correctChoiceIndex:0},
    {id:"fr_722", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle est la plus lourde?", choices:["1 milligramme","1 gramme","1 décagramme"], correctChoiceIndex:2},
    {id:"fr_723", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle est la plus légère?", choices:["1 milligramme","1 gramme","1 décagramme"], correctChoiceIndex:0},
    {id:"fr_724", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle est la plus grande?", choices:["1 millilitre","1 centilitre","1 décilitre"], correctChoiceIndex:2},
    {id:"fr_725", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle est la plus petite?", choices:["1 millilitre","1 centilitre","1 décilitre"], correctChoiceIndex:0},
    {id:"fr_726", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien de millimètres dans un centimètre?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"fr_727", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien de milligrammes dans un gramme?", choices:["1000","100","10000"], correctChoiceIndex:0},
    {id:"fr_728", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien de millilitres dans un centilitre?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"fr_729", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de kilomètres dans un mètre?", choices:["0.001","0.01","0.1"], correctChoiceIndex:0},
    {id:"fr_730", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de tonnes dans un kilogramme?", choices:["0.001","0.01","0.1"], correctChoiceIndex:0},
    {id:"fr_731", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de litres dans un millilitre?", choices:["0.001","0.01","0.1"], correctChoiceIndex:0},
    {id:"fr_732", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de centimètres dans un kilomètre?", choices:["100000","10000","1000"], correctChoiceIndex:0},
    {id:"fr_733", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de millimètres dans un mètre?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"fr_734", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de grammes dans une tonne?", choices:["1000000","100000","10000"], correctChoiceIndex:0},
    {id:"fr_735", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de millilitres dans un centilitre?", choices:["10","100","1"], correctChoiceIndex:0},
    {id:"fr_736", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de centilitres dans un litre?", choices:["100","10","1000"], correctChoiceIndex:0},
    {id:"fr_737", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de mètres dans un kilomètre?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"fr_738", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de kilogrammes dans une tonne?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"fr_739", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de centimètres dans un décimètre?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"fr_740", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de grammes dans un décagramme?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"fr_741", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de centilitres dans un décilitre?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"fr_742", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de décimètres dans un mètre?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"fr_743", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de décagrammes dans un kilogramme?", choices:["100","1000","10000"], correctChoiceIndex:1},
    {id:"fr_744", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de décilitres dans un litre?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"fr_745", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de mètres dans un décimètre?", choices:["0.1","1","10"], correctChoiceIndex:0},
    {id:"fr_746", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de kilogrammes dans un décagramme?", choices:["0.01","0.1","1"], correctChoiceIndex:0},
    {id:"fr_747", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de litres dans un décilitre?", choices:["0.1","1","10"], correctChoiceIndex:0},
    {id:"fr_748", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de décimètres dans un kilomètre?", choices:["10000","1000","100"], correctChoiceIndex:0},
    {id:"fr_749", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de décagrammes dans une tonne?", choices:["100000","10000","1000"], correctChoiceIndex:0},
    {id:"fr_750", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de décilitres dans un hectolitre?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"fr_751", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le nombre après 1?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"fr_752", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel est le nombre avant 3?", choices:["2","1","4"], correctChoiceIndex:0},
    {id:"fr_753", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien font 1 + 1?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"fr_754", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien font 2 + 2?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"fr_755", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien font 3 + 2?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"fr_756", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien font 4 - 1?", choices:["3","2","1"], correctChoiceIndex:0},
    {id:"fr_757", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien font 5 - 2?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"fr_758", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien font 2 + 3?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"fr_759", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien font 3 + 3?", choices:["6","7","8"], correctChoiceIndex:0},
    {id:"fr_760", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien font 4 + 4?", choices:["8","9","10"], correctChoiceIndex:0},
    {id:"fr_761", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien font 5 + 5?", choices:["10","11","12"], correctChoiceIndex:0},
    {id:"fr_762", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien font 6 - 3?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"fr_763", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien font 7 - 4?", choices:["3","2","1"], correctChoiceIndex:0},
    {id:"fr_764", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien font 8 - 5?", choices:["3","2","1"], correctChoiceIndex:0},
    {id:"fr_765", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien font 9 - 6?", choices:["3","2","1"], correctChoiceIndex:0},
    {id:"fr_766", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien font 10 - 7?", choices:["3","2","1"], correctChoiceIndex:0},
    {id:"fr_767", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 11 + 4?", choices:["15","16","17"], correctChoiceIndex:0},
    {id:"fr_768", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 12 + 5?", choices:["17","18","19"], correctChoiceIndex:0},
    {id:"fr_769", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 13 + 6?", choices:["19","20","21"], correctChoiceIndex:0},
    {id:"fr_770", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 14 + 7?", choices:["21","22","23"], correctChoiceIndex:0},
    {id:"fr_771", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 15 + 8?", choices:["23","24","25"], correctChoiceIndex:0},
    {id:"fr_772", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 16 - 9?", choices:["7","8","9"], correctChoiceIndex:0},
    {id:"fr_773", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 17 - 10?", choices:["7","8","9"], correctChoiceIndex:0},
    {id:"fr_774", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 18 - 11?", choices:["7","8","9"], correctChoiceIndex:0},
    {id:"fr_775", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 19 - 12?", choices:["7","8","9"], correctChoiceIndex:0},
    {id:"fr_776", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 20 - 13?", choices:["7","8","9"], correctChoiceIndex:0},
    {id:"fr_777", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 21 + 9?", choices:["30","31","32"], correctChoiceIndex:0},
    {id:"fr_778", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 22 + 10?", choices:["32","33","34"], correctChoiceIndex:0},
    {id:"fr_779", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 23 + 11?", choices:["34","35","36"], correctChoiceIndex:0},
    {id:"fr_780", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 24 + 12?", choices:["36","37","38"], correctChoiceIndex:0},
    {id:"fr_781", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 25 + 13?", choices:["38","39","40"], correctChoiceIndex:0},
    {id:"fr_782", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 26 - 14?", choices:["12","13","14"], correctChoiceIndex:0},
    {id:"fr_783", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 27 - 15?", choices:["12","13","14"], correctChoiceIndex:0},
    {id:"fr_784", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 28 - 16?", choices:["12","13","14"], correctChoiceIndex:0},
    {id:"fr_785", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 29 - 17?", choices:["12","13","14"], correctChoiceIndex:0},
    {id:"fr_786", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 30 - 18?", choices:["12","13","14"], correctChoiceIndex:0},
    {id:"fr_787", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 31 + 14?", choices:["45","46","47"], correctChoiceIndex:0},
    {id:"fr_788", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 32 + 15?", choices:["47","48","49"], correctChoiceIndex:0},
    {id:"fr_789", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 33 + 16?", choices:["49","50","51"], correctChoiceIndex:0},
    {id:"fr_790", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 34 + 17?", choices:["51","52","53"], correctChoiceIndex:0},
    {id:"fr_791", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 35 + 18?", choices:["53","54","55"], correctChoiceIndex:0},
    {id:"fr_792", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 36 - 19?", choices:["17","18","19"], correctChoiceIndex:0},
    {id:"fr_793", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 37 - 20?", choices:["17","18","19"], correctChoiceIndex:0},
    {id:"fr_794", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 38 - 21?", choices:["17","18","19"], correctChoiceIndex:0},
    {id:"fr_795", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 39 - 22?", choices:["17","18","19"], correctChoiceIndex:0},
    {id:"fr_796", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 40 - 23?", choices:["17","18","19"], correctChoiceIndex:0},
    {id:"fr_797", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 41 + 19?", choices:["60","61","62"], correctChoiceIndex:0},
    {id:"fr_798", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 42 + 20?", choices:["62","63","64"], correctChoiceIndex:0},
    {id:"fr_799", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 43 + 21?", choices:["64","65","66"], correctChoiceIndex:0},
    {id:"fr_800", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 44 + 22?", choices:["66","67","68"], correctChoiceIndex:0},
    {id:"fr_801", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien font 45 + 23?", choices:["68","69","70"], correctChoiceIndex:0},
    {id:"fr_802", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Si tu as 2 pommes et que tu en manges une, combien te reste-t-il de pommes?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"fr_803", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Si tu as 5 bonbons et que tu en donnes 2 à ton ami, combien te reste-t-il de bonbons?", choices:["3","2","5"], correctChoiceIndex:0},
    {id:"fr_804", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Si tu as 3 crayons et que tu en trouves 1 de plus, combien as-tu de crayons maintenant?", choices:["4","3","5"], correctChoiceIndex:0},
    {id:"fr_805", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Si tu as 4 ballons et que 1 éclate, combien te reste-t-il de ballons?", choices:["3","2","4"], correctChoiceIndex:0},
    {id:"fr_806", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Si tu as 6 jouets et que tu en perds 2, combien te reste-t-il de jouets?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"fr_807", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Si tu as 7 autocollants et que tu en colles 3, combien te reste-t-il d'autocollants?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"fr_808", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Si tu as 8 pièces de puzzle et que tu en perds 1, combien te reste-t-il de pièces?", choices:["7","8","9"], correctChoiceIndex:0},
    {id:"fr_809", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Si tu as 9 fleurs et que tu en donnes 2 à ta maman, combien te reste-t-il de fleurs?", choices:["7","8","9"], correctChoiceIndex:0},
    {id:"fr_810", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Si tu as 10 doigts et que tu caches 1, combien de doigts montres-tu?", choices:["9","10","11"], correctChoiceIndex:0},
    {id:"fr_811", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Si tu as 2 gâteaux et que tu en manges 1, combien te reste-t-il de gâteaux?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"fr_812", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 12 crayons et que tu en donnes 3 à ton ami, combien te reste-t-il de crayons?", choices:["9","10","11"], correctChoiceIndex:0},
    {id:"fr_813", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 15 bonbons et que tu en manges 5, combien te reste-t-il de bonbons?", choices:["10","11","12"], correctChoiceIndex:0},
    {id:"fr_814", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 18 jouets et que tu en perds 4, combien te reste-t-il de jouets?", choices:["14","15","16"], correctChoiceIndex:0},
    {id:"fr_815", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 20 autocollants et que tu en colles 6, combien te reste-t-il d'autocollants?", choices:["14","15","16"], correctChoiceIndex:0},
    {id:"fr_816", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 22 pièces de puzzle et que tu en perds 2, combien te reste-t-il de pièces?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_817", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 25 fleurs et que tu en donnes 5 à ta maman, combien te reste-t-il de fleurs?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_818", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 28 doigts et que tu caches 8, combien de doigts montres-tu?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_819", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 30 gâteaux et que tu en manges 10, combien te reste-t-il de gâteaux?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_820", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 32 crayons et que tu en donnes 12 à ton ami, combien te reste-t-il de crayons?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_821", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 35 bonbons et que tu en manges 15, combien te reste-t-il de bonbons?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_822", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 38 jouets et que tu en perds 18, combien te reste-t-il de jouets?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_823", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 40 autocollants et que tu en colles 20, combien te reste-t-il d'autocollants?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_824", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 42 pièces de puzzle et que tu en perds 22, combien te reste-t-il de pièces?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_825", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 45 fleurs et que tu en donnes 25 à ta maman, combien te reste-t-il de fleurs?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_826", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 48 doigts et que tu caches 28, combien de doigts montres-tu?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_827", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 50 gâteaux et que tu en manges 30, combien te reste-t-il de gâteaux?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_828", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 52 crayons et que tu en donnes 32 à ton ami, combien te reste-t-il de crayons?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_829", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 55 bonbons et que tu en manges 35, combien te reste-t-il de bonbons?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_830", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 58 jouets et que tu en perds 38, combien te reste-t-il de jouets?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_831", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 60 autocollants et que tu en colles 40, combien te reste-t-il d'autocollants?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_832", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 62 pièces de puzzle et que tu en perds 42, combien te reste-t-il de pièces?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_833", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 65 fleurs et que tu en donnes 45 à ta maman, combien te reste-t-il de fleurs?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_834", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 68 doigts et que tu caches 48, combien de doigts montres-tu?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_835", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 70 gâteaux et que tu en manges 50, combien te reste-t-il de gâteaux?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_836", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 72 crayons et que tu en donnes 52 à ton ami, combien te reste-t-il de crayons?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_837", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 75 bonbons et que tu en manges 55, combien te reste-t-il de bonbons?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_838", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 78 jouets et que tu en perds 58, combien te reste-t-il de jouets?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_839", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 80 autocollants et que tu en colles 60, combien te reste-t-il d'autocollants?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_840", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 82 pièces de puzzle et que tu en perds 62, combien te reste-t-il de pièces?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_841", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 85 fleurs et que tu en donnes 65 à ta maman, combien te reste-t-il de fleurs?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_842", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 88 doigts et que tu caches 68, combien de doigts montres-tu?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_843", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 90 gâteaux et que tu en manges 70, combien te reste-t-il de gâteaux?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_844", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 92 crayons et que tu en donnes 72 à ton ami, combien te reste-t-il de crayons?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_845", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 95 bonbons et que tu en manges 75, combien te reste-t-il de bonbons?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_846", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 98 jouets et que tu en perds 78, combien te reste-t-il de jouets?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_847", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 100 autocollants et que tu en colles 80, combien te reste-t-il d'autocollants?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_848", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 102 pièces de puzzle et que tu en perds 82, combien te reste-t-il de pièces?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_849", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 105 fleurs et que tu en donnes 85 à ta maman, combien te reste-t-il de fleurs?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_850", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 108 doigts et que tu caches 88, combien de doigts montres-tu?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_851", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Si tu as 110 gâteaux et que tu en manges 90, combien te reste-t-il de gâteaux?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"fr_852", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle forme a 3 côtés?", choices:["Carré","Triangle","Cercle"], correctChoiceIndex:1},
    {id:"fr_853", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle forme a 4 côtés égaux?", choices:["Rectangle","Cercle","Carré"], correctChoiceIndex:2},
    {id:"fr_854", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle forme est ronde?", choices:["Cercle","Carré","Triangle"], correctChoiceIndex:0},
    {id:"fr_855", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle forme a 4 côtés mais pas tous les côtés sont égaux?", choices:["Rectangle","Cercle","Triangle"], correctChoiceIndex:0},
    {id:"fr_856", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle forme ressemble à une boîte?", choices:["Cube","Cercle","Triangle"], correctChoiceIndex:0},
    {id:"fr_857", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle forme a 4 coins?", choices:["Carré","Cercle","Triangle"], correctChoiceIndex:0},
    {id:"fr_858", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle forme a 3 coins?", choices:["Carré","Triangle","Cercle"], correctChoiceIndex:1},
    {id:"fr_859", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle forme a 0 coins?", choices:["Carré","Triangle","Cercle"], correctChoiceIndex:2},
    {id:"fr_860", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle forme a des côtés droits?", choices:["Carré","Cercle","Triangle"], correctChoiceIndex:0},
    {id:"fr_861", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle forme a des côtés courbes?", choices:["Carré","Cercle","Triangle"], correctChoiceIndex:1},
    {id:"fr_862", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle forme a des côtés de même longueur?", choices:["Carré","Rectangle","Triangle"], correctChoiceIndex:0},
    {id:"fr_863", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle forme a des côtés de longueurs différentes?", choices:["Carré","Rectangle","Triangle"], correctChoiceIndex:1},
    {id:"fr_864", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle forme a des côtés droits et de même longueur?", choices:["Carré","Cercle","Triangle"], correctChoiceIndex:0},
    {id:"fr_865", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle forme a des côtés droits et de longueurs différentes?", choices:["Carré","Rectangle","Triangle"], correctChoiceIndex:1},
    {id:"fr_866", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Quelle forme a 5 côtés?", choices:["Pentagone","Cercle","Carré"], correctChoiceIndex:0},
    {id:"fr_867", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Quelle forme a 6 côtés?", choices:["Hexagone","Cercle","Carré"], correctChoiceIndex:0},
    {id:"fr_868", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Quelle forme a 8 côtés?", choices:["Octogone","Cercle","Carré"], correctChoiceIndex:0},
    {id:"fr_869", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Quelle forme a 0 côtés?", choices:["Cercle","Carré","Triangle"], correctChoiceIndex:0},
    {id:"fr_870", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Quelle forme a des côtés qui ne sont jamais droits?", choices:["Cercle","Carré","Triangle"], correctChoiceIndex:0},
    {id:"fr_871", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Quelle forme a 7 côtés?", choices:["Heptagone","Cercle","Carré"], correctChoiceIndex:0},
    {id:"fr_872", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Quelle forme a 10 côtés?", choices:["Décagone","Cercle","Carré"], correctChoiceIndex:0},
    {id:"fr_873", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Quelle forme a 12 côtés?", choices:["Dodécagone","Cercle","Carré"], correctChoiceIndex:0},
    {id:"fr_874", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Quelle forme a 9 côtés?", choices:["Ennéagone","Cercle","Carré"], correctChoiceIndex:0},
    {id:"fr_875", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Quelle forme a 11 côtés?", choices:["Hendécagone","Cercle","Carré"], correctChoiceIndex:0},
    {id:"fr_876", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Quelle forme a des côtés courbes et ne possède pas de coins?", choices:["Carré","Cercle","Triangle"], correctChoiceIndex:1},
    {id:"fr_877", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle heure est-il quand l'aiguille des minutes est sur le 12 et l'aiguille des heures est sur le 1?", choices:["1 heure","2 heures","3 heures"], correctChoiceIndex:0},
    {id:"fr_878", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien de minutes y a-t-il dans une heure?", choices:["60 minutes","30 minutes","90 minutes"], correctChoiceIndex:0},
    {id:"fr_879", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien de jours y a-t-il dans une semaine?", choices:["7 jours","5 jours","10 jours"], correctChoiceIndex:0},
    {id:"fr_880", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel mois vient après janvier?", choices:["Février","Mars","Avril"], correctChoiceIndex:0},
    {id:"fr_881", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel jour vient après lundi?", choices:["Mardi","Mercredi","Jeudi"], correctChoiceIndex:0},
    {id:"fr_882", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle heure est-il quand l'aiguille des minutes est sur le 3 et l'aiguille des heures est sur le 2?", choices:["2 heures 15","2 heures 30","2 heures 45"], correctChoiceIndex:0},
    {id:"fr_883", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien de minutes y a-t-il dans une demi-heure?", choices:["30 minutes","15 minutes","45 minutes"], correctChoiceIndex:0},
    {id:"fr_884", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien de jours y a-t-il dans un weekend?", choices:["2 jours","3 jours","4 jours"], correctChoiceIndex:0},
    {id:"fr_885", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel mois vient après mars?", choices:["Avril","Mai","Juin"], correctChoiceIndex:0},
    {id:"fr_886", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel jour vient après jeudi?", choices:["Vendredi","Samedi","Dimanche"], correctChoiceIndex:0},
    {id:"fr_887", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quelle heure est-il quand l'aiguille des minutes est sur le 6 et l'aiguille des heures est sur le 5?", choices:["5 heures 30","4 heures 30","6 heures 30"], correctChoiceIndex:0},
    {id:"fr_888", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien de minutes y a-t-il dans un quart d'heure?", choices:["15 minutes","10 minutes","20 minutes"], correctChoiceIndex:0},
    {id:"fr_889", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Combien de jours y a-t-il dans un mois de février non bissextile?", choices:["28 jours","29 jours","30 jours"], correctChoiceIndex:0},
    {id:"fr_890", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel mois vient après mai?", choices:["Juin","Juillet","Août"], correctChoiceIndex:0},
    {id:"fr_891", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Quel jour vient après vendredi?", choices:["Samedi","Dimanche","Lundi"], correctChoiceIndex:0},
    {id:"fr_892", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Quelle heure est-il quand l'aiguille des minutes est sur le 6 et l'aiguille des heures est sur le 3?", choices:["3 heures 30","2 heures 30","4 heures 30"], correctChoiceIndex:0},
    {id:"fr_893", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de secondes y a-t-il dans une minute?", choices:["60 secondes","30 secondes","90 secondes"], correctChoiceIndex:0},
    {id:"fr_894", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de mois y a-t-il dans une année?", choices:["12 mois","10 mois","15 mois"], correctChoiceIndex:0},
    {id:"fr_895", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Quel mois vient après octobre?", choices:["Novembre","Septembre","Décembre"], correctChoiceIndex:0},
    {id:"fr_896", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Quel jour vient après dimanche?", choices:["Lundi","Mardi","Mercredi"], correctChoiceIndex:0},
    {id:"fr_897", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Quelle heure est-il quand l'aiguille des minutes est sur le 9 et l'aiguille des heures est sur le 4?", choices:["4 heures 45","3 heures 45","5 heures 45"], correctChoiceIndex:0},
    {id:"fr_898", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de secondes y a-t-il dans une demi-minute?", choices:["30 secondes","15 secondes","45 secondes"], correctChoiceIndex:0},
    {id:"fr_899", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Combien de mois y a-t-il dans un semestre?", choices:["6 mois","5 mois","7 mois"], correctChoiceIndex:0},
    {id:"fr_900", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Quel mois vient après juillet?", choices:["Août","Septembre","Octobre"], correctChoiceIndex:0},
    {id:"fr_901", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Quel jour vient après mercredi?", choices:["Jeudi","Vendredi","Samedi"], correctChoiceIndex:0},
    {id:"fr_902", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel est le meilleur moyen de rester en bonne santé?", choices:["Manger des bonbons","Faire de l'exercice","Regarder la télévision"], correctChoiceIndex:1},
    {id:"fr_903", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Pourquoi devons-nous nous laver les mains?", choices:["Pour faire des bulles","Pour enlever les germes","Pour jouer avec de l'eau"], correctChoiceIndex:1},
    {id:"fr_904", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce qu'une bonne nuit de sommeil aide à faire?", choices:["Jouer à des jeux vidéo","Grandir et rester en bonne santé","Regarder plus de dessins animés"], correctChoiceIndex:1},
    {id:"fr_905", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel est le meilleur repas pour manger après l'exercice?", choices:["Glace","Fruits","Chips"], correctChoiceIndex:1},
    {id:"fr_906", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Pourquoi devrions-nous boire de l'eau?", choices:["Pour faire des bulles","Pour rester hydraté","Pour jouer avec"], correctChoiceIndex:1},
    {id:"fr_907", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce qu'une alimentation équilibrée?", choices:["Manger uniquement des bonbons","Manger différents types d'aliments","Manger uniquement du poulet"], correctChoiceIndex:1},
    {id:"fr_908", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Pourquoi est-il important de faire de l'exercice?", choices:["Pour regarder la télévision","Pour rester en forme et en bonne santé","Pour manger plus de bonbons"], correctChoiceIndex:1},
    {id:"fr_909", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce que l'hygiène personnelle?", choices:["Jouer dans la boue","Se laver et se brosser les dents régulièrement","Regarder la télévision"], correctChoiceIndex:1},
    {id:"fr_910", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Pourquoi est-il important de se brosser les dents?", choices:["Pour faire des bulles","Pour garder nos dents propres et en bonne santé","Pour manger plus de bonbons"], correctChoiceIndex:1},
    {id:"fr_911", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce qu'un mode de vie sain?", choices:["Regarder la télévision toute la journée","Faire de l'exercice, manger sainement et dormir suffisamment","Manger des bonbons toute la journée"], correctChoiceIndex:1},
    {id:"fr_912", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Pourquoi devrions-nous manger des fruits et des légumes?", choices:["Parce qu'ils sont colorés","Parce qu'ils sont bons pour notre santé","Parce qu'ils peuvent être utilisés comme jouets"], correctChoiceIndex:1},
    {id:"fr_913", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce que l'activité physique?", choices:["Regarder la télévision","Jouer à des jeux vidéo","Bouger son corps comme courir, sauter, danser"], correctChoiceIndex:2},
    {id:"fr_914", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Pourquoi est-il important de prendre une douche après l'exercice?", choices:["Pour faire des bulles","Pour se rafraîchir","Pour enlever la sueur et les germes"], correctChoiceIndex:2},
    {id:"fr_915", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce qu'une bonne hygiène?", choices:["Jouer dans la boue toute la journée","Se laver les mains avant de manger","Regarder la télévision toute la journée"], correctChoiceIndex:1},
    {id:"fr_916", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Pourquoi est-il important de dormir suffisamment?", choices:["Pour pouvoir regarder la télévision toute la nuit","Pour pouvoir jouer à des jeux vidéo toute la nuit","Pour que notre corps puisse grandir et se reposer"], correctChoiceIndex:2},
    {id:"fr_917", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel est le sport où tu nages dans l'eau?", choices:["Football","Natation","Basketball"], correctChoiceIndex:1},
    {id:"fr_918", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport se pratique sur un tapis?", choices:["Gymnastique","Tennis","Hockey"], correctChoiceIndex:0},
    {id:"fr_919", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport utilise une barre fixe?", choices:["Gymnastique","Football","Basketball"], correctChoiceIndex:0},
    {id:"fr_920", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Dans quel sport utilises-tu un maillot de bain?", choices:["Natation","Football","Tennis"], correctChoiceIndex:0},
    {id:"fr_921", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport se pratique dans une piscine?", choices:["Basketball","Football","Natation"], correctChoiceIndex:2},
    {id:"fr_922", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport utilise des mouvements comme le saut et le roulement?", choices:["Gymnastique","Football","Tennis"], correctChoiceIndex:0},
    {id:"fr_923", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport nécessite de savoir nager?", choices:["Football","Basketball","Natation"], correctChoiceIndex:2},
    {id:"fr_924", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport utilise des anneaux comme équipement?", choices:["Football","Gymnastique","Basketball"], correctChoiceIndex:1},
    {id:"fr_925", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport peut se pratiquer en solo dans l'eau?", choices:["Basketball","Football","Natation"], correctChoiceIndex:2},
    {id:"fr_926", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport nécessite de faire des flips et des sauts?", choices:["Tennis","Gymnastique","Football"], correctChoiceIndex:1},
    {id:"fr_927", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport utilise une poutre d'équilibre?", choices:["Basketball","Gymnastique","Football"], correctChoiceIndex:1},
    {id:"fr_928", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport nécessite de plonger dans l'eau?", choices:["Basketball","Football","Natation"], correctChoiceIndex:2},
    {id:"fr_929", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport utilise des mouvements comme le crawl et le papillon?", choices:["Football","Basketball","Natation"], correctChoiceIndex:2},
    {id:"fr_930", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport nécessite de faire des rotations et des sauts?", choices:["Football","Basketball","Gymnastique"], correctChoiceIndex:2},
    {id:"fr_931", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport utilise un trampoline pour faire des sauts?", choices:["Football","Basketball","Gymnastique"], correctChoiceIndex:2},
    {id:"fr_932", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce que la randonnée?", choices:["Un jeu vidéo","Marcher dans la nature","Un type de danse"], correctChoiceIndex:1},
    {id:"fr_933", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce que nous devrions toujours respecter dans la nature?", choices:["Les arbres","Les animaux","Tout ce qui est dans la nature"], correctChoiceIndex:2},
    {id:"fr_934", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce qu'un sac à dos pour la randonnée?", choices:["Un sac pour les bonbons","Un sac pour les jouets","Un sac pour transporter des choses utiles lors d'une randonnée"], correctChoiceIndex:2},
    {id:"fr_935", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Pourquoi devrions-nous ne pas jeter de déchets dans la nature?", choices:["Parce que c'est amusant","Parce que cela peut nuire à la nature","Parce que nous n'avons pas de poubelle"], correctChoiceIndex:1},
    {id:"fr_936", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce qu'un pique-nique?", choices:["Un type de danse","Un repas en plein air","Un jeu de société"], correctChoiceIndex:1},
    {id:"fr_937", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Pourquoi est-il important de rester sur les sentiers lors d'une randonnée?", choices:["Pour ne pas se perdre","Pour ne pas abîmer la nature","Les deux"], correctChoiceIndex:2},
    {id:"fr_938", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce qu'une boussole?", choices:["Un outil pour trouver le nord","Un outil pour mesurer la température","Un outil pour regarder les étoiles"], correctChoiceIndex:0},
    {id:"fr_939", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce qu'une carte topographique?", choices:["Une carte de trésor","Une carte qui montre les montagnes et les vallées","Une carte qui montre les rues d'une ville"], correctChoiceIndex:1},
    {id:"fr_940", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce qu'un jumelle?", choices:["Un outil pour voir de loin","Un outil pour écouter de loin","Un outil pour sentir de loin"], correctChoiceIndex:0},
    {id:"fr_941", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Pourquoi devrions-nous respecter les animaux dans la nature?", choices:["Parce qu'ils sont mignons","Parce qu'ils peuvent être dangereux","Parce qu'ils font partie de l'écosystème et ont le droit de vivre en paix"], correctChoiceIndex:2},
    {id:"fr_942", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce qu'un insecte?", choices:["Un type d'animal","Un type de plante","Un type de roche"], correctChoiceIndex:0},
    {id:"fr_943", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce qu'un oiseau?", choices:["Un type de poisson","Un type d'animal qui peut voler","Un type de plante"], correctChoiceIndex:1},
    {id:"fr_944", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce qu'une fleur?", choices:["Un type de roche","Un type de nuage","Un type de plante"], correctChoiceIndex:2},
    {id:"fr_945", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce qu'un arbre?", choices:["Un type de plante","Un type d'animal","Un type de roche"], correctChoiceIndex:0},
    {id:"fr_946", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Qu'est-ce qu'une forêt?", choices:["Un grand groupe d'arbres","Un grand groupe de roches","Un grand groupe d'animaux"], correctChoiceIndex:0},
    {id:"fr_947", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel est un jeu d'extérieur populaire?", choices:["Tag","Échecs","Piano"], correctChoiceIndex:0},
    {id:"fr_948", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Dans quel jeu les joueurs se cachent-ils et cherchent-ils?", choices:["Cache-cache","Tag","Football"], correctChoiceIndex:0},
    {id:"fr_949", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel jeu nécessite une équipe pour jouer?", choices:["Football","Saut à la corde","Saut en hauteur"], correctChoiceIndex:0},
    {id:"fr_950", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel jeu peut être joué avec seulement deux personnes?", choices:["Tag","Football","Basketball"], correctChoiceIndex:0},
    {id:"fr_951", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel jeu d'extérieur n'a pas besoin de ballon?", choices:["Tag","Football","Basketball"], correctChoiceIndex:0},
    {id:"fr_952", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel est le but du jeu de tag?", choices:["Toucher les autres joueurs","Cacher","Courir le plus vite possible"], correctChoiceIndex:0},
    {id:"fr_953", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel jeu d'extérieur peut être joué sans équipement?", choices:["Cache-cache","Football","Basketball"], correctChoiceIndex:0},
    {id:"fr_954", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel jeu d'extérieur nécessite le plus de joueurs?", choices:["Football","Tag","Cache-cache"], correctChoiceIndex:0},
    {id:"fr_955", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel jeu d'extérieur peut être joué en équipe?", choices:["Football","Saut à la corde","Saut en hauteur"], correctChoiceIndex:0},
    {id:"fr_956", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel jeu d'extérieur peut être joué seul?", choices:["Saut à la corde","Football","Basketball"], correctChoiceIndex:0},
    {id:"fr_957", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel jeu d'extérieur nécessite de courir?", choices:["Tag","Échecs","Piano"], correctChoiceIndex:0},
    {id:"fr_958", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel jeu d'extérieur nécessite de se cacher?", choices:["Cache-cache","Tag","Football"], correctChoiceIndex:0},
    {id:"fr_959", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel jeu d'extérieur peut être joué avec un ballon?", choices:["Football","Tag","Cache-cache"], correctChoiceIndex:0},
    {id:"fr_960", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel jeu d'extérieur nécessite de toucher les autres joueurs?", choices:["Tag","Échecs","Piano"], correctChoiceIndex:0},
    {id:"fr_961", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel jeu d'extérieur peut être joué sans courir?", choices:["Échecs","Tag","Football"], correctChoiceIndex:0},
    {id:"fr_962", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport utilise un ballon rond?", choices:["Basketball","Soccer","Tennis"], correctChoiceIndex:1},
    {id:"fr_963", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Combien de joueurs y a-t-il dans une équipe de soccer?", choices:["11","10","9"], correctChoiceIndex:0},
    {id:"fr_964", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport utilise un panier?", choices:["Soccer","Basketball","Tennis"], correctChoiceIndex:1},
    {id:"fr_965", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport utilise un ballon orange?", choices:["Soccer","Basketball","Tennis"], correctChoiceIndex:1},
    {id:"fr_966", language:QuestionLanguage.FR, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport se joue sur un terrain vert?", choices:["Basketball","Soccer","Tennis"], correctChoiceIndex:1},
    {id:"fr_967", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel est le but du soccer?", choices:["Marquer des paniers","Marquer des buts","Faire des passes"], correctChoiceIndex:1},
    {id:"fr_968", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel est le but du basketball?", choices:["Marquer des buts","Marquer des paniers","Faire des passes"], correctChoiceIndex:1},
    {id:"fr_969", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport utilise des chaussures à crampons?", choices:["Basketball","Soccer","Tennis"], correctChoiceIndex:1},
    {id:"fr_970", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport utilise un sifflet?", choices:["Basketball","Soccer","Tous les deux"], correctChoiceIndex:2},
    {id:"fr_971", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport se joue avec deux équipes de cinq joueurs?", choices:["Soccer","Basketball","Tennis"], correctChoiceIndex:1},
    {id:"fr_972", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport utilise un filet mais pas de raquette?", choices:["Basketball","Soccer","Tennis"], correctChoiceIndex:1},
    {id:"fr_973", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Dans quel sport les joueurs peuvent-ils toucher le ballon avec leurs mains?", choices:["Soccer","Basketball","Tennis"], correctChoiceIndex:1},
    {id:"fr_974", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Dans quel sport les joueurs ne peuvent-ils pas toucher le ballon avec leurs mains?", choices:["Soccer","Basketball","Tennis"], correctChoiceIndex:0},
    {id:"fr_975", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport se joue sur un terrain rectangulaire?", choices:["Basketball","Soccer","Tous les deux"], correctChoiceIndex:2},
    {id:"fr_976", language:QuestionLanguage.FR, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Quel sport utilise un ballon qui rebondit beaucoup?", choices:["Soccer","Basketball","Tennis"], correctChoiceIndex:1},
]


