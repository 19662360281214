import {QuestionAge, QuestionCategory, QuestionFilter, QuestionLanguage} from "../data/model";
import React, {useState} from "react";
import {Card} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {GenericToggleButton} from "../common/Components";
import {useTranslation} from "react-i18next";

interface QuestionFilterViewProps {
    questionFilter: QuestionFilter
    setQuestionFilter: React.Dispatch<React.SetStateAction<QuestionFilter>>
}


export function QuestionFilterView({questionFilter, setQuestionFilter}: QuestionFilterViewProps) {
    const {t} = useTranslation(['home']);
    const [tmpQuestionFilter, setTmpQuestionFilter] = useState(questionFilter)

    const onToggleCategory = (value: QuestionCategory, selected: boolean) => {
        const q = tmpQuestionFilter.copy()
        selected ? q.selectedCategories.add(value) : q.selectedCategories.delete(value)
        setTmpQuestionFilter(q)
        setQuestionFilter(q)
    }

    const onToggleAge = (value: QuestionAge, selected: boolean) => {
        const q = tmpQuestionFilter.copy()
        selected ? q.selectedAges.add(value) : q.selectedAges.delete(value)
        setTmpQuestionFilter(q)
        setQuestionFilter(q)
    }

    return <Card className="filter-container">
        <Card.Body>
            <div className="filter-container-title">{t("filter")}</div>
            <div className="filter-label">{t("age")}</div>
            <div className="filter-age-container">
                {
                    Object.values(QuestionAge).map(age =>
                        <GenericToggleButton
                            variant="outline-dark"
                            className="custom-toggle-button"
                            value={age}
                            label={age}
                            selectedItems={tmpQuestionFilter.selectedAges}
                            onToggle={onToggleAge}
                        />
                    )
                }
            </div>
            <div className="filter-label">{t("categories")}</div>
            <div className="filter-categories-container">
                {
                    Object.values(QuestionCategory).map(category =>
                        <GenericToggleButton
                            variant="outline-dark"
                            className="custom-toggle-button"
                            value={category}
                            label={category}
                            selectedItems={tmpQuestionFilter.selectedCategories}
                            onToggle={onToggleCategory}
                        />
                    )
                }
            </div>
        </Card.Body>


    </Card>
}
