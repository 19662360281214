import {QuizApp} from "./app/QuizApp";
import {Route, Routes} from "react-router-dom";
import {QuestionLanguage} from "./data/model";


function App() {
    return <>
        <Routes>
            <Route path='/' element={ <QuizApp selectedLanguage={QuestionLanguage.EN}/> } />
            <Route path='/en' element={ <QuizApp selectedLanguage={QuestionLanguage.EN}/> } />
            <Route path='/fr' element={ <QuizApp selectedLanguage={QuestionLanguage.FR}/> } />
            <Route path='/de' element={ <QuizApp selectedLanguage={QuestionLanguage.DE}/> } />
            <Route path='/sk' element={ <QuizApp selectedLanguage={QuestionLanguage.SK}/> } />
        </Routes>
    </>
}

export default App