import {Question, QuestionCategory} from "../data/model";
import React, {useState} from "react";
import Card from 'react-bootstrap/Card';
import '../App.css';
import natureCategoryIcon from "../art/natureIcon.svg";
import scienceCategoryIcon from "../art/scienceIcon.svg";
import technologyCategoryIcon from "../art/technologyIcon.svg";
import everydayLifeCategoryIcon from "../art/everydayLifeIcon.svg";
import artAndCultureCategoryIcon from "../art/cultureIcon.svg";
import mathematicsCategoryIcon from "../art/mathematicsIcon.svg";
import sportCategoryIcon from "../art/sportIcon.svg";
import {useTranslation} from "react-i18next";


interface QuestionViewProps {
    question: Question
}

const categoryColorMap = {
    [QuestionCategory.nature]: 'question-nature',
    [QuestionCategory.science]: 'question-science',
    [QuestionCategory.technology]: 'question-technology',
    [QuestionCategory.everyDayLife]: 'question-everyDayLife',
    [QuestionCategory.artAndCulture]: 'question-artAndCulture',
    [QuestionCategory.mathematics]: 'question-mathematics',
    [QuestionCategory.sportAndPhysicalEducation]: 'question-sportAndPhysicalEducation',
}

const categoryIconMap = {
    [QuestionCategory.nature]: natureCategoryIcon,
    [QuestionCategory.science]: scienceCategoryIcon,
    [QuestionCategory.technology]: technologyCategoryIcon,
    [QuestionCategory.everyDayLife]: everydayLifeCategoryIcon,
    [QuestionCategory.artAndCulture]: artAndCultureCategoryIcon,
    [QuestionCategory.mathematics]: mathematicsCategoryIcon,
    [QuestionCategory.sportAndPhysicalEducation]: sportCategoryIcon,
}

export function QuestionView({question}: QuestionViewProps) {
    const {t} = useTranslation(['home'])
    const [selectedChoice, setSelectedChoice] = useState<number | null>(null)

    const handleChoiceClick = (index: number) => {
        setSelectedChoice(index)
    }

    const getChoiceStyles = (index:number) => {
        let baseStyles = {
            indicator: 'choice-indicator', // Base class for the indicator
            text: 'choice-text', // Base class for the text
        };

        if (selectedChoice !== null) {
            if (index === selectedChoice) {
                const correct = index === question.correctChoiceIndex;
                baseStyles.indicator += correct ? ' choice-correct' : ' choice-incorrect';
                baseStyles.text += correct ? ' text-correct' : ' text-incorrect';
            } else if (index === question.correctChoiceIndex) {
                baseStyles.indicator += ' choice-correct';
                baseStyles.text += ' text-correct';
            }
        }

        return baseStyles;
    };



    const categoryIcon = categoryIconMap[question.category]
    const cardClassName = `border-0 question-card ${categoryColorMap[question.category] || 'bg-light'}`; // Default to bg-light if category is not mapped
    return (
        <Card key={question.id} className={cardClassName}>
            <div className="question-card-header">
                <div>
                    <span className="question-age">{t(question.age)}</span>
                    <img src={categoryIcon} alt="" width="30px" height="30px"/>
                </div>
            </div>
            <Card.Body>
                <Card.Text>
                    <div className="question-title">{question.question}</div>
                    {question.choices.map((choice, index) => (
                        <div key={index}
                             className={`${getChoiceStyles(index).text}`}
                             role="button"
                             onClick={() => handleChoiceClick(index)}
                             style={{display: 'flex', alignItems: 'center', marginBottom: '12px'}}
                        >
                            <span className={`${getChoiceStyles(index).indicator}`}>{String.fromCharCode(65 + index)}</span>
                            <span style={{ marginLeft: '10px' }}>{choice}</span>
                        </div>
                    ))}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}