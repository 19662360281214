import {Question, QuestionLanguage, QuestionAge, QuestionCategory} from "./model";


export const ENGLISH_QUESTIONS: Question[] = [
    {id:"en_0", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do lions eat?", choices:["Grass","Meat","Fruits"], correctChoiceIndex:1},
    {id:"en_1", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Where do fish live?", choices:["Desert","Ocean","Mountains"], correctChoiceIndex:1},
    {id:"en_2", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the home of a bird called?", choices:["Nest","Cave","Hole"], correctChoiceIndex:0},
    {id:"en_3", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do rabbits eat?", choices:["Meat","Vegetables","Fish"], correctChoiceIndex:1},
    {id:"en_4", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Where do penguins live?", choices:["Desert","Jungle","Antarctica"], correctChoiceIndex:2},
    {id:"en_5", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do bees make?", choices:["Honey","Milk","Juice"], correctChoiceIndex:0},
    {id:"en_6", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Where do bears live?", choices:["Forest","Desert","Ocean"], correctChoiceIndex:0},
    {id:"en_7", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do elephants eat?", choices:["Meat","Fruits","Fish"], correctChoiceIndex:1},
    {id:"en_8", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Where do kangaroos live?", choices:["Africa","Australia","Antarctica"], correctChoiceIndex:1},
    {id:"en_9", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do cows give us?", choices:["Milk","Juice","Honey"], correctChoiceIndex:0},
    {id:"en_10", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do lions eat?", choices:["Grass","Meat","Fruits"], correctChoiceIndex:1},
    {id:"en_11", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Where do fish live?", choices:["Desert","Ocean","Mountains"], correctChoiceIndex:1},
    {id:"en_12", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the home of a bird called?", choices:["Nest","Cave","Hole"], correctChoiceIndex:0},
    {id:"en_13", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do rabbits eat?", choices:["Meat","Carrots","Fish"], correctChoiceIndex:1},
    {id:"en_14", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Where do bears live?", choices:["Desert","Ocean","Forest"], correctChoiceIndex:2},
    {id:"en_15", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do bees make?", choices:["Honey","Milk","Juice"], correctChoiceIndex:0},
    {id:"en_16", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Where do kangaroos live?", choices:["Africa","Australia","Asia"], correctChoiceIndex:1},
    {id:"en_17", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do elephants eat?", choices:["Meat","Plants","Fish"], correctChoiceIndex:1},
    {id:"en_18", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do cows give us?", choices:["Milk","Honey","Juice"], correctChoiceIndex:0},
    {id:"en_19", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Where do monkeys live?", choices:["Forest","Desert","Ocean"], correctChoiceIndex:0},
    {id:"en_20", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the largest animal in the world?", choices:["Elephant","Blue Whale","Giraffe"], correctChoiceIndex:1},
    {id:"en_21", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is a group of lions called?", choices:["Herd","Pride","Flock"], correctChoiceIndex:1},
    {id:"en_22", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the fastest land animal?", choices:["Cheetah","Lion","Elephant"], correctChoiceIndex:0},
    {id:"en_23", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What do carnivores eat?", choices:["Plants","Meat","Fruits"], correctChoiceIndex:1},
    {id:"en_24", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the main food of a panda?", choices:["Bamboo","Meat","Fruits"], correctChoiceIndex:0},
    {id:"en_25", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the lifespan of a butterfly?", choices:["1 week","1 month","1 year"], correctChoiceIndex:1},
    {id:"en_26", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the largest land animal?", choices:["Elephant","Lion","Giraffe"], correctChoiceIndex:0},
    {id:"en_27", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the smallest bird in the world?", choices:["Sparrow","Hummingbird","Eagle"], correctChoiceIndex:1},
    {id:"en_28", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the main food of a koala?", choices:["Eucalyptus leaves","Meat","Fruits"], correctChoiceIndex:0},
    {id:"en_29", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the lifespan of a housefly?", choices:["24 hours","1 week","1 month"], correctChoiceIndex:1},
    {id:"en_30", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the largest bird in the world?", choices:["Sparrow","Ostrich","Eagle"], correctChoiceIndex:1},
    {id:"en_31", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the main food of a kangaroo?", choices:["Grass","Meat","Fruits"], correctChoiceIndex:0},
    {id:"en_32", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the lifespan of a turtle?", choices:["1 year","10 years","100 years"], correctChoiceIndex:2},
    {id:"en_33", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the smallest fish in the world?", choices:["Shark","Goldfish","Paedocypris"], correctChoiceIndex:2},
    {id:"en_34", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the main food of a giraffe?", choices:["Leaves","Meat","Fruits"], correctChoiceIndex:0},
    {id:"en_35", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the lifespan of a elephant?", choices:["10 years","70 years","100 years"], correctChoiceIndex:1},
    {id:"en_36", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the largest fish in the world?", choices:["Goldfish","Shark","Whale Shark"], correctChoiceIndex:2},
    {id:"en_37", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the main food of a monkey?", choices:["Leaves","Meat","Fruits"], correctChoiceIndex:2},
    {id:"en_38", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the lifespan of a rabbit?", choices:["1 year","10 years","8 years"], correctChoiceIndex:2},
    {id:"en_39", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the smallest animal in the world?", choices:["Elephant","Ant","Whale"], correctChoiceIndex:1},
    {id:"en_40", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the largest animal in the world?", choices:["Elephant","Blue Whale","Lion"], correctChoiceIndex:1},
    {id:"en_41", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What do carnivores eat?", choices:["Plants","Meat","Both"], correctChoiceIndex:1},
    {id:"en_42", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Where do penguins live?", choices:["Arctic","Antarctica","Desert"], correctChoiceIndex:1},
    {id:"en_43", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is a group of lions called?", choices:["Flock","Pride","School"], correctChoiceIndex:1},
    {id:"en_44", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What do herbivores eat?", choices:["Plants","Meat","Both"], correctChoiceIndex:0},
    {id:"en_45", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the fastest land animal?", choices:["Cheetah","Lion","Elephant"], correctChoiceIndex:0},
    {id:"en_46", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What do omnivores eat?", choices:["Plants","Meat","Both"], correctChoiceIndex:2},
    {id:"en_47", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Where do camels live?", choices:["Desert","Forest","Ocean"], correctChoiceIndex:0},
    {id:"en_48", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is a baby kangaroo called?", choices:["Cub","Joey","Kid"], correctChoiceIndex:1},
    {id:"en_49", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the tallest animal in the world?", choices:["Elephant","Giraffe","Lion"], correctChoiceIndex:1},
    {id:"en_50", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the biggest ocean on Earth?", choices:["Atlantic Ocean","Indian Ocean","Pacific Ocean"], correctChoiceIndex:2},
    {id:"en_51", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the hottest place on Earth?", choices:["Antarctica","Sahara Desert","Death Valley"], correctChoiceIndex:2},
    {id:"en_52", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the coldest place on Earth?", choices:["Antarctica","Sahara Desert","Death Valley"], correctChoiceIndex:0},
    {id:"en_53", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the tallest mountain on Earth?", choices:["Mount Everest","Kilimanjaro","K2"], correctChoiceIndex:0},
    {id:"en_54", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the longest river on Earth?", choices:["Amazon River","Nile River","Yangtze River"], correctChoiceIndex:1},
    {id:"en_55", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is a volcano?", choices:["A mountain with fire inside","A type of cloud","A deep hole in the ocean"], correctChoiceIndex:0},
    {id:"en_56", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is a rainbow?", choices:["A colorful arc in the sky","A type of bird","A type of flower"], correctChoiceIndex:0},
    {id:"en_57", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is a desert?", choices:["A place with lots of sand and little rain","A place with lots of trees","A place with lots of water"], correctChoiceIndex:0},
    {id:"en_58", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is a forest?", choices:["A place with lots of trees","A place with lots of sand","A place with lots of water"], correctChoiceIndex:0},
    {id:"en_59", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is a lake?", choices:["A large body of water surrounded by land","A large body of land surrounded by water","A large body of sand surrounded by water"], correctChoiceIndex:0},
    {id:"en_60", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What causes a rainbow?", choices:["Sunlight and rain","Moonlight and snow","Wind and clouds"], correctChoiceIndex:0},
    {id:"en_61", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the Earth's largest continent?", choices:["Asia","Africa","Europe"], correctChoiceIndex:0},
    {id:"en_62", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the Earth's smallest continent?", choices:["Australia","Africa","Europe"], correctChoiceIndex:0},
    {id:"en_63", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the Earth's largest desert?", choices:["Sahara Desert","Gobi Desert","Arctic Desert"], correctChoiceIndex:0},
    {id:"en_64", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the Earth's largest rainforest?", choices:["Amazon Rainforest","Congo Rainforest","Borneo Rainforest"], correctChoiceIndex:0},
    {id:"en_65", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the Earth's largest island?", choices:["Greenland","Australia","New Guinea"], correctChoiceIndex:0},
    {id:"en_66", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the Earth's deepest ocean trench?", choices:["Mariana Trench","Tonga Trench","Kuril-Kamchatka Trench"], correctChoiceIndex:0},
    {id:"en_67", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the Earth's highest waterfall?", choices:["Angel Falls","Niagara Falls","Victoria Falls"], correctChoiceIndex:0},
    {id:"en_68", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the Earth's largest lake?", choices:["Caspian Sea","Lake Superior","Lake Victoria"], correctChoiceIndex:0},
    {id:"en_69", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the Earth's longest mountain range?", choices:["Andes","Himalayas","Rocky Mountains"], correctChoiceIndex:0},
    {id:"en_70", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What causes an earthquake?", choices:["Movement of tectonic plates","Rainfall","Wind"], correctChoiceIndex:0},
    {id:"en_71", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is a tornado?", choices:["A powerful windstorm in the shape of a funnel","A type of rain","A type of cloud"], correctChoiceIndex:0},
    {id:"en_72", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is a hurricane?", choices:["A large storm with strong winds and rain","A type of cloud","A type of rainbow"], correctChoiceIndex:0},
    {id:"en_73", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is a tsunami?", choices:["A large ocean wave caused by an underwater earthquake","A type of cloud","A type of rainbow"], correctChoiceIndex:0},
    {id:"en_74", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is a glacier?", choices:["A large mass of ice that moves slowly","A type of cloud","A type of rainbow"], correctChoiceIndex:0},
    {id:"en_75", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is a geyser?", choices:["A spring that shoots water and steam into the air","A type of cloud","A type of rainbow"], correctChoiceIndex:0},
    {id:"en_76", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is a marsh?", choices:["A type of wetland with grasses and reeds","A type of desert","A type of mountain"], correctChoiceIndex:0},
    {id:"en_77", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is a savanna?", choices:["A grassy plain with few trees","A type of desert","A type of mountain"], correctChoiceIndex:0},
    {id:"en_78", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is a tundra?", choices:["A cold, treeless area","A type of desert","A type of mountain"], correctChoiceIndex:0},
    {id:"en_79", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is a prairie?", choices:["A large open area of grassland","A type of desert","A type of mountain"], correctChoiceIndex:0},
    {id:"en_80", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the biggest organ in your body?", choices:["Heart","Brain","Skin"], correctChoiceIndex:2},
    {id:"en_81", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do we use to smell?", choices:["Nose","Ears","Eyes"], correctChoiceIndex:0},
    {id:"en_82", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"How many eyes do humans have?", choices:["One","Two","Three"], correctChoiceIndex:1},
    {id:"en_83", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do we use to hear?", choices:["Mouth","Ears","Hands"], correctChoiceIndex:1},
    {id:"en_84", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What helps you chew your food?", choices:["Teeth","Fingers","Toes"], correctChoiceIndex:0},
    {id:"en_85", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do we use to taste?", choices:["Tongue","Nose","Eyes"], correctChoiceIndex:0},
    {id:"en_86", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the organ that pumps blood around your body?", choices:["Lungs","Heart","Stomach"], correctChoiceIndex:1},
    {id:"en_87", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do we use to touch and feel?", choices:["Skin","Eyes","Ears"], correctChoiceIndex:0},
    {id:"en_88", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do we use to see?", choices:["Eyes","Ears","Nose"], correctChoiceIndex:0},
    {id:"en_89", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the organ that helps you breathe?", choices:["Heart","Lungs","Liver"], correctChoiceIndex:1},
    {id:"en_90", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you think?", choices:["Brain","Heart","Lungs"], correctChoiceIndex:0},
    {id:"en_91", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that filters harmful substances from your blood?", choices:["Heart","Lungs","Liver"], correctChoiceIndex:2},
    {id:"en_92", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you digest food?", choices:["Stomach","Heart","Lungs"], correctChoiceIndex:0},
    {id:"en_93", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you absorb nutrients from food?", choices:["Stomach","Intestines","Heart"], correctChoiceIndex:1},
    {id:"en_94", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that produces insulin?", choices:["Pancreas","Heart","Lungs"], correctChoiceIndex:0},
    {id:"en_95", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you balance?", choices:["Ears","Eyes","Nose"], correctChoiceIndex:0},
    {id:"en_96", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you remove waste from your body?", choices:["Kidneys","Heart","Lungs"], correctChoiceIndex:0},
    {id:"en_97", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you fight infections?", choices:["Spleen","Heart","Lungs"], correctChoiceIndex:0},
    {id:"en_98", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you produce red blood cells?", choices:["Bone marrow","Heart","Lungs"], correctChoiceIndex:0},
    {id:"en_99", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you store vitamins?", choices:["Liver","Heart","Lungs"], correctChoiceIndex:0},
    {id:"en_100", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you produce hormones?", choices:["Endocrine glands","Heart","Lungs"], correctChoiceIndex:0},
    {id:"en_101", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you produce bile?", choices:["Liver","Heart","Lungs"], correctChoiceIndex:0},
    {id:"en_102", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you absorb water?", choices:["Large intestine","Heart","Lungs"], correctChoiceIndex:0},
    {id:"en_103", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you produce urine?", choices:["Kidneys","Heart","Lungs"], correctChoiceIndex:0},
    {id:"en_104", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you produce sperm?", choices:["Testes","Heart","Lungs"], correctChoiceIndex:0},
    {id:"en_105", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you produce eggs?", choices:["Ovaries","Heart","Lungs"], correctChoiceIndex:0},
    {id:"en_106", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you produce milk?", choices:["Mammary glands","Heart","Lungs"], correctChoiceIndex:0},
    {id:"en_107", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you produce sweat?", choices:["Sweat glands","Heart","Lungs"], correctChoiceIndex:0},
    {id:"en_108", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you produce tears?", choices:["Tear glands","Heart","Lungs"], correctChoiceIndex:0},
    {id:"en_109", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the organ that helps you produce saliva?", choices:["Salivary glands","Heart","Lungs"], correctChoiceIndex:0},
    {id:"en_110", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What color are most plants?", choices:["Red","Green","Blue"], correctChoiceIndex:1},
    {id:"en_111", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do plants need to grow?", choices:["Water","Candy","Soda"], correctChoiceIndex:0},
    {id:"en_112", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Where do plants grow?", choices:["In the sky","In the ground","In the sea"], correctChoiceIndex:1},
    {id:"en_113", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What part of the plant is underground?", choices:["Roots","Leaves","Flowers"], correctChoiceIndex:0},
    {id:"en_114", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What part of the plant do we see above the ground?", choices:["Roots","Stem","Seeds"], correctChoiceIndex:1},
    {id:"en_115", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What part of the plant makes seeds?", choices:["Flower","Leaf","Root"], correctChoiceIndex:0},
    {id:"en_116", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do plants breathe in?", choices:["Oxygen","Carbon Dioxide","Nitrogen"], correctChoiceIndex:1},
    {id:"en_117", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do plants breathe out?", choices:["Oxygen","Carbon Dioxide","Nitrogen"], correctChoiceIndex:0},
    {id:"en_118", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do plants need from the sun to grow?", choices:["Heat","Light","Both"], correctChoiceIndex:2},
    {id:"en_119", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the process called when plants make food using sunlight?", choices:["Photosynthesis","Respiration","Digestion"], correctChoiceIndex:0},
    {id:"en_120", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the largest plant on earth?", choices:["Rose","Oak Tree","Redwood Tree"], correctChoiceIndex:2},
    {id:"en_121", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the smallest plant on earth?", choices:["Duckweed","Rose","Oak Tree"], correctChoiceIndex:0},
    {id:"en_122", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the fastest growing plant on earth?", choices:["Bamboo","Rose","Oak Tree"], correctChoiceIndex:0},
    {id:"en_123", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What part of the plant carries water from the roots to the leaves?", choices:["Stem","Flower","Leaf"], correctChoiceIndex:0},
    {id:"en_124", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What part of the plant absorbs water and nutrients from the soil?", choices:["Roots","Leaves","Flowers"], correctChoiceIndex:0},
    {id:"en_125", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What part of the plant catches sunlight for photosynthesis?", choices:["Roots","Leaves","Flowers"], correctChoiceIndex:1},
    {id:"en_126", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the main function of a plant's leaves?", choices:["To provide shade","To make food","To attract insects"], correctChoiceIndex:1},
    {id:"en_127", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the main function of a plant's flowers?", choices:["To make food","To provide shade","To attract insects"], correctChoiceIndex:2},
    {id:"en_128", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the main function of a plant's roots?", choices:["To make food","To absorb water and nutrients","To provide shade"], correctChoiceIndex:1},
    {id:"en_129", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the main function of a plant's stem?", choices:["To provide shade","To make food","To support the plant and transport nutrients"], correctChoiceIndex:2},
    {id:"en_130", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the process called when a seed starts to grow?", choices:["Germination","Pollination","Fertilization"], correctChoiceIndex:0},
    {id:"en_131", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the process called when a flower is fertilized and produces seeds?", choices:["Germination","Pollination","Fertilization"], correctChoiceIndex:2},
    {id:"en_132", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the process called when pollen is transferred from the male part of a flower to the female part?", choices:["Germination","Pollination","Fertilization"], correctChoiceIndex:1},
    {id:"en_133", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the green pigment in plants that absorbs sunlight?", choices:["Chlorophyll","Hemoglobin","Melanin"], correctChoiceIndex:0},
    {id:"en_134", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the process where plants lose water through their leaves?", choices:["Transpiration","Respiration","Perspiration"], correctChoiceIndex:0},
    {id:"en_135", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the tiny holes on the underside of leaves where gas exchange occurs?", choices:["Stomata","Pores","Vents"], correctChoiceIndex:0},
    {id:"en_136", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the part of the flower that produces pollen?", choices:["Stamen","Petal","Sepal"], correctChoiceIndex:0},
    {id:"en_137", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the part of the flower that receives pollen?", choices:["Pistil","Petal","Sepal"], correctChoiceIndex:0},
    {id:"en_138", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the part of the plant where photosynthesis mainly occurs?", choices:["Leaves","Roots","Stem"], correctChoiceIndex:0},
    {id:"en_139", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the part of the plant where new growth occurs?", choices:["Bud","Root","Stem"], correctChoiceIndex:0},
    {id:"en_140", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the name of the planet we live on?", choices:["Mars","Earth","Venus"], correctChoiceIndex:1},
    {id:"en_141", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the biggest planet in our solar system?", choices:["Jupiter","Earth","Mars"], correctChoiceIndex:0},
    {id:"en_142", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the name of the star that our planet orbits around?", choices:["Moon","Sun","Venus"], correctChoiceIndex:1},
    {id:"en_143", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the name of the natural satellite of the Earth?", choices:["Star","Moon","Sun"], correctChoiceIndex:1},
    {id:"en_144", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"How many planets are there in our solar system?", choices:["Eight","Nine","Ten"], correctChoiceIndex:0},
    {id:"en_145", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the color of the sun?", choices:["Yellow","Blue","Red"], correctChoiceIndex:0},
    {id:"en_146", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do we call a shooting star?", choices:["Meteor","Comet","Asteroid"], correctChoiceIndex:0},
    {id:"en_147", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What do we call a group of stars?", choices:["Galaxy","Universe","Constellation"], correctChoiceIndex:2},
    {id:"en_148", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the name of the planet known as the 'Ringed Planet'?", choices:["Saturn","Jupiter","Mars"], correctChoiceIndex:0},
    {id:"en_149", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"What is the name of the planet that is closest to the sun?", choices:["Mercury","Venus","Earth"], correctChoiceIndex:0},
    {id:"en_150", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the smallest planet in our solar system?", choices:["Mercury","Venus","Mars"], correctChoiceIndex:0},
    {id:"en_151", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the second planet from the sun?", choices:["Earth","Venus","Mars"], correctChoiceIndex:1},
    {id:"en_152", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What planet is known as the Red Planet?", choices:["Mars","Jupiter","Saturn"], correctChoiceIndex:0},
    {id:"en_153", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the hottest planet in our solar system?", choices:["Venus","Mercury","Mars"], correctChoiceIndex:0},
    {id:"en_154", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What planet is known for its beautiful rings?", choices:["Jupiter","Saturn","Uranus"], correctChoiceIndex:1},
    {id:"en_155", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the galaxy we live in?", choices:["Andromeda","Milky Way","Whirlpool"], correctChoiceIndex:1},
    {id:"en_156", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is a group of stars that forms a pattern called?", choices:["Constellation","Galaxy","Universe"], correctChoiceIndex:0},
    {id:"en_157", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the closest star to Earth?", choices:["Sun","Polaris","Sirius"], correctChoiceIndex:0},
    {id:"en_158", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the first man who walked on the moon?", choices:["Neil Armstrong","Buzz Aldrin","Michael Collins"], correctChoiceIndex:0},
    {id:"en_159", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the dwarf planet that was once considered the ninth planet in our solar system?", choices:["Pluto","Mars","Jupiter"], correctChoiceIndex:0},
    {id:"en_160", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the planet that is farthest from the sun?", choices:["Neptune","Uranus","Saturn"], correctChoiceIndex:0},
    {id:"en_161", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the planet that is second farthest from the sun?", choices:["Uranus","Neptune","Saturn"], correctChoiceIndex:0},
    {id:"en_162", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the largest moon in our solar system?", choices:["Titan","Ganymede","Callisto"], correctChoiceIndex:1},
    {id:"en_163", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the second largest moon in our solar system?", choices:["Titan","Ganymede","Callisto"], correctChoiceIndex:0},
    {id:"en_164", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the third largest moon in our solar system?", choices:["Titan","Ganymede","Callisto"], correctChoiceIndex:2},
    {id:"en_165", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the fourth largest moon in our solar system?", choices:["Titan","Ganymede","Callisto"], correctChoiceIndex:0},
    {id:"en_166", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the fifth largest moon in our solar system?", choices:["Titan","Ganymede","Callisto"], correctChoiceIndex:2},
    {id:"en_167", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the sixth largest moon in our solar system?", choices:["Titan","Ganymede","Callisto"], correctChoiceIndex:1},
    {id:"en_168", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the seventh largest moon in our solar system?", choices:["Titan","Ganymede","Callisto"], correctChoiceIndex:0},
    {id:"en_169", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"What is the name of the eighth largest moon in our solar system?", choices:["Titan","Ganymede","Callisto"], correctChoiceIndex:2},
    {id:"en_170", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is the smallest unit of life?", choices:["Cell","Animal","Plant"], correctChoiceIndex:0},
    {id:"en_171", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What do plants need to grow?", choices:["Water, Sunlight, and Soil","Candy and Soda","Toys and Games"], correctChoiceIndex:0},
    {id:"en_172", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What do we call the green part of the plant?", choices:["Leaf","Root","Stem"], correctChoiceIndex:0},
    {id:"en_173", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is the name of the process by which plants make food?", choices:["Photosynthesis","Cooking","Eating"], correctChoiceIndex:0},
    {id:"en_174", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What do animals eat?", choices:["Food","Books","Shoes"], correctChoiceIndex:0},
    {id:"en_175", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the powerhouse of the cell?", choices:["Mitochondria","Nucleus","Cell wall"], correctChoiceIndex:0},
    {id:"en_176", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the function of the cell membrane?", choices:["Protects the cell","Makes food for the cell","Gives color to the cell"], correctChoiceIndex:0},
    {id:"en_177", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the function of the roots in a plant?", choices:["Absorb water and nutrients","Make food","Give color to the plant"], correctChoiceIndex:0},
    {id:"en_178", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the function of the stem in a plant?", choices:["Supports the plant","Absorbs water and nutrients","Makes food"], correctChoiceIndex:0},
    {id:"en_179", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the function of the leaves in a plant?", choices:["Make food","Supports the plant","Absorbs water and nutrients"], correctChoiceIndex:0},
    {id:"en_180", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is an ecosystem?", choices:["A community of living organisms and their environment","A type of cell","A type of plant"], correctChoiceIndex:0},
    {id:"en_181", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is a predator?", choices:["An animal that hunts other animals","An animal that is hunted by other animals","A type of plant"], correctChoiceIndex:0},
    {id:"en_182", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is a prey?", choices:["An animal that is hunted by other animals","An animal that hunts other animals","A type of plant"], correctChoiceIndex:0},
    {id:"en_183", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is a herbivore?", choices:["An animal that eats plants","An animal that eats meat","An animal that eats both plants and meat"], correctChoiceIndex:0},
    {id:"en_184", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is a carnivore?", choices:["An animal that eats meat","An animal that eats plants","An animal that eats both plants and meat"], correctChoiceIndex:0},
    {id:"en_185", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is an omnivore?", choices:["An animal that eats both plants and meat","An animal that eats meat","An animal that eats plants"], correctChoiceIndex:0},
    {id:"en_186", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is a food chain?", choices:["A series showing how each living thing gets food","A chain made of food","A type of plant"], correctChoiceIndex:0},
    {id:"en_187", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is a producer in a food chain?", choices:["An organism that makes its own food","An organism that eats other organisms","An organism that is eaten by other organisms"], correctChoiceIndex:0},
    {id:"en_188", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is a consumer in a food chain?", choices:["An organism that eats other organisms","An organism that makes its own food","An organism that is eaten by other organisms"], correctChoiceIndex:0},
    {id:"en_189", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is a decomposer in a food chain?", choices:["An organism that breaks down dead organisms","An organism that makes its own food","An organism that eats other organisms"], correctChoiceIndex:0},
    {id:"en_190", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the function of the nucleus in a cell?", choices:["Controls the cell's activities","Protects the cell","Makes food for the cell"], correctChoiceIndex:0},
    {id:"en_191", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the function of the mitochondria in a cell?", choices:["Produces energy","Controls the cell's activities","Protects the cell"], correctChoiceIndex:0},
    {id:"en_192", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the function of the cell wall in a plant cell?", choices:["Provides support and protection","Produces energy","Controls the cell's activities"], correctChoiceIndex:0},
    {id:"en_193", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the function of the chloroplasts in a plant cell?", choices:["Carry out photosynthesis","Provides support and protection","Produces energy"], correctChoiceIndex:0},
    {id:"en_194", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the function of the vacuole in a plant cell?", choices:["Stores water and nutrients","Carry out photosynthesis","Provides support and protection"], correctChoiceIndex:0},
    {id:"en_195", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is the state of water when it is frozen?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:0},
    {id:"en_196", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What happens when you mix baking soda and vinegar?", choices:["It explodes","It disappears","It fizzes"], correctChoiceIndex:2},
    {id:"en_197", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is the state of water when it is boiling?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:2},
    {id:"en_198", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is the state of water when it is in a glass?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:1},
    {id:"en_199", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is the state of ice cream when it melts?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:1},
    {id:"en_200", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for a cloud?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:2},
    {id:"en_201", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for a rock?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:0},
    {id:"en_202", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for juice?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:1},
    {id:"en_203", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for a balloon filled with air?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:2},
    {id:"en_204", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for a wooden table?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:0},
    {id:"en_205", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What happens when you mix baking soda and vinegar in a closed container?", choices:["It explodes","It disappears","It fizzes and the container may pop open"], correctChoiceIndex:2},
    {id:"en_206", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for a snowflake?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:0},
    {id:"en_207", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for a bubble?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:2},
    {id:"en_208", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for a glass of milk?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:1},
    {id:"en_209", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for a block of cheese?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:0},
    {id:"en_210", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What happens when you mix baking soda and vinegar in a volcano model?", choices:["It explodes","It disappears","It fizzes and looks like a volcano erupting"], correctChoiceIndex:2},
    {id:"en_211", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for a steam from a kettle?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:2},
    {id:"en_212", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for a piece of chocolate?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:0},
    {id:"en_213", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for a cup of tea?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:1},
    {id:"en_214", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for a breath of air?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:2},
    {id:"en_215", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for a piece of ice?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:0},
    {id:"en_216", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for a glass of water?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:1},
    {id:"en_217", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for a puff of smoke?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:2},
    {id:"en_218", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for a piece of bread?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:0},
    {id:"en_219", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the state of matter for a bowl of soup?", choices:["Solid","Liquid","Gas"], correctChoiceIndex:1},
    {id:"en_220", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What can we recycle?", choices:["Food","Plastic","Clothes"], correctChoiceIndex:1},
    {id:"en_221", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is pollution?", choices:["Clean air","Dirty air","Fresh water"], correctChoiceIndex:1},
    {id:"en_222", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Where should we throw our trash?", choices:["In the river","In the bin","On the ground"], correctChoiceIndex:1},
    {id:"en_223", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What does a tree give us?", choices:["Ice cream","Oxygen","Toys"], correctChoiceIndex:1},
    {id:"en_224", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Why should we save water?", choices:["Because it's fun","Because it's blue","Because it's limited"], correctChoiceIndex:2},
    {id:"en_225", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is a renewable resource?", choices:["Candy","Sunlight","Toys"], correctChoiceIndex:1},
    {id:"en_226", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is a non-renewable resource?", choices:["Wind","Water","Fossil fuels"], correctChoiceIndex:2},
    {id:"en_227", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is composting?", choices:["A type of dance","A way to recycle organic waste","A type of drawing"], correctChoiceIndex:1},
    {id:"en_228", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is global warming?", choices:["When the earth gets a fever","When the earth spins","When the earth gets a cold"], correctChoiceIndex:0},
    {id:"en_229", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is solar energy?", choices:["Energy from the sun","Energy from the moon","Energy from the stars"], correctChoiceIndex:0},
    {id:"en_230", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is wind energy?", choices:["Energy from breathing","Energy from moving","Energy from the wind"], correctChoiceIndex:2},
    {id:"en_231", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is water conservation?", choices:["Saving water","Drinking water","Swimming in water"], correctChoiceIndex:0},
    {id:"en_232", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is soil erosion?", choices:["When soil stays in place","When soil moves away","When soil changes color"], correctChoiceIndex:1},
    {id:"en_233", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is deforestation?", choices:["Planting trees","Cutting down trees","Watering plants"], correctChoiceIndex:1},
    {id:"en_234", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the main cause of air pollution?", choices:["Factories","Flowers","Rainbows"], correctChoiceIndex:0},
    {id:"en_235", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What can we do to save energy?", choices:["Leave lights on","Use less electricity","Play more video games"], correctChoiceIndex:1},
    {id:"en_236", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What happens when we litter?", choices:["It helps plants grow","It makes places dirty","It attracts butterflies"], correctChoiceIndex:1},
    {id:"en_237", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Why is it important to recycle?", choices:["To make things look pretty","To reduce waste","To have fun"], correctChoiceIndex:1},
    {id:"en_238", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is an ecosystem?", choices:["A type of car","A community of living things","A type of food"], correctChoiceIndex:1},
    {id:"en_239", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is biodiversity?", choices:["A type of candy","Variety of life on earth","A type of game"], correctChoiceIndex:1},
    {id:"en_240", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is a habitat?", choices:["A type of hat","A home for animals","A type of bag"], correctChoiceIndex:1},
    {id:"en_241", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is conservation?", choices:["A type of conversation","Protection of natural resources","A type of decoration"], correctChoiceIndex:1},
    {id:"en_242", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is a carbon footprint?", choices:["A type of shoe print","Amount of carbon dioxide released","A type of hand print"], correctChoiceIndex:1},
    {id:"en_243", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is a natural resource?", choices:["Something found in nature that people can use","Something people make","Something people buy"], correctChoiceIndex:0},
    {id:"en_244", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is a fossil fuel?", choices:["A type of dinosaur","A type of rock","A natural fuel such as coal or gas"], correctChoiceIndex:2},
    {id:"en_245", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What does a lever help us do?", choices:["Jump higher","Lift heavy things","Run faster"], correctChoiceIndex:1},
    {id:"en_246", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What force pulls things down?", choices:["Wind","Gravity","Friction"], correctChoiceIndex:1},
    {id:"en_247", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What machine has a pointy end and a wide end?", choices:["Wedge","Wheel","Lever"], correctChoiceIndex:0},
    {id:"en_248", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What force slows things down when they slide?", choices:["Gravity","Friction","Magnetism"], correctChoiceIndex:1},
    {id:"en_249", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What machine helps us move things in a circle?", choices:["Wheel and axle","Lever","Pulley"], correctChoiceIndex:0},
    {id:"en_250", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What force makes a ball roll down a hill?", choices:["Gravity","Friction","Magnetism"], correctChoiceIndex:0},
    {id:"en_251", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What simple machine helps us cut things?", choices:["Wedge","Wheel and axle","Lever"], correctChoiceIndex:0},
    {id:"en_252", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What force makes it hard to slide a box on the floor?", choices:["Gravity","Friction","Magnetism"], correctChoiceIndex:1},
    {id:"en_253", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What simple machine helps us move things up and down?", choices:["Pulley","Wheel and axle","Lever"], correctChoiceIndex:0},
    {id:"en_254", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What force makes a magnet stick to the fridge?", choices:["Gravity","Friction","Magnetism"], correctChoiceIndex:2},
    {id:"en_255", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What simple machine is used to split things apart?", choices:["Wedge","Wheel and axle","Lever"], correctChoiceIndex:0},
    {id:"en_256", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What force makes a ball stop rolling after a while?", choices:["Gravity","Friction","Magnetism"], correctChoiceIndex:1},
    {id:"en_257", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What simple machine helps us move things from one place to another?", choices:["Wheel and axle","Lever","Pulley"], correctChoiceIndex:0},
    {id:"en_258", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What does a lever help us do?", choices:["Jump higher","Lift heavy things","Run faster"], correctChoiceIndex:1},
    {id:"en_259", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What force pulls things down?", choices:["Friction","Gravity","Magnetism"], correctChoiceIndex:1},
    {id:"en_260", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What machine has a wheel and a rope to lift things?", choices:["Pulley","Lever","Inclined Plane"], correctChoiceIndex:0},
    {id:"en_261", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What force slows things down when they slide or roll?", choices:["Gravity","Friction","Magnetism"], correctChoiceIndex:1},
    {id:"en_262", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What simple machine is a slide a good example of?", choices:["Screw","Inclined Plane","Lever"], correctChoiceIndex:1},
    {id:"en_263", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the force that opposes motion between two surfaces?", choices:["Gravity","Friction","Magnetism"], correctChoiceIndex:1},
    {id:"en_264", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What simple machine is used to lift or move loads?", choices:["Pulley","Screw","Wedge"], correctChoiceIndex:0},
    {id:"en_265", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What force keeps us on the ground?", choices:["Gravity","Friction","Magnetism"], correctChoiceIndex:0},
    {id:"en_266", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What simple machine is made up of a wheel with a rod through its center?", choices:["Wheel and axle","Lever","Pulley"], correctChoiceIndex:0},
    {id:"en_267", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What simple machine is used to hold things together or to lift things?", choices:["Screw","Lever","Pulley"], correctChoiceIndex:0},
    {id:"en_268", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What simple machine is used to raise and lower things?", choices:["Pulley","Screw","Wedge"], correctChoiceIndex:0},
    {id:"en_269", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What force pulls everything towards the center of the Earth?", choices:["Gravity","Friction","Magnetism"], correctChoiceIndex:0},
    {id:"en_270", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What simple machine is made up of an inclined plane wrapped around a cylinder?", choices:["Screw","Lever","Pulley"], correctChoiceIndex:0},
    {id:"en_271", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What force acts between two objects in contact that are moving relative to each other?", choices:["Gravity","Friction","Magnetism"], correctChoiceIndex:1},
    {id:"en_272", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What simple machine is used to lift heavy weights with less effort?", choices:["Lever","Screw","Wedge"], correctChoiceIndex:0},
    {id:"en_273", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What force is responsible for the weight of objects?", choices:["Gravity","Friction","Magnetism"], correctChoiceIndex:0},
    {id:"en_274", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What simple machine is used to turn things?", choices:["Wheel and axle","Lever","Pulley"], correctChoiceIndex:0},
    {id:"en_275", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What force keeps us on the ground?", choices:["Gravity","Friction","Magnetism"], correctChoiceIndex:0},
    {id:"en_276", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What simple machine would you use to hold a door open?", choices:["Wedge","Screw","Pulley"], correctChoiceIndex:0},
    {id:"en_277", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What simple machine is a seesaw an example of?", choices:["Lever","Screw","Wedge"], correctChoiceIndex:0},
    {id:"en_278", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What force makes it hard to slide a heavy box across the floor?", choices:["Gravity","Friction","Magnetism"], correctChoiceIndex:1},
    {id:"en_279", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What simple machine is a jar lid an example of?", choices:["Screw","Wedge","Lever"], correctChoiceIndex:0},
    {id:"en_280", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is the white fluffy cloud called?", choices:["Cumulus","Stratus","Cirrus"], correctChoiceIndex:0},
    {id:"en_281", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What falls from the sky when it rains?", choices:["Snow","Water","Leaves"], correctChoiceIndex:1},
    {id:"en_282", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"How many seasons are there in a year?", choices:["2","4","6"], correctChoiceIndex:1},
    {id:"en_283", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What season comes after winter?", choices:["Spring","Summer","Autumn"], correctChoiceIndex:0},
    {id:"en_284", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is the season when leaves fall from the trees?", choices:["Winter","Spring","Autumn"], correctChoiceIndex:2},
    {id:"en_285", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is the season when we can go to the beach?", choices:["Winter","Spring","Summer"], correctChoiceIndex:2},
    {id:"en_286", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is the season when we can build a snowman?", choices:["Winter","Spring","Summer"], correctChoiceIndex:0},
    {id:"en_287", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is the season when we can see baby animals?", choices:["Winter","Spring","Summer"], correctChoiceIndex:1},
    {id:"en_288", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is the season when we can see a lot of fruits?", choices:["Winter","Spring","Autumn"], correctChoiceIndex:2},
    {id:"en_289", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"What is the season when we can see a lot of flowers?", choices:["Winter","Spring","Summer"], correctChoiceIndex:1},
    {id:"en_290", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the thin, wispy cloud called?", choices:["Cumulus","Cirrus","Stratus"], correctChoiceIndex:1},
    {id:"en_291", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the cloud that covers the sky like a blanket called?", choices:["Stratus","Cumulus","Cirrus"], correctChoiceIndex:0},
    {id:"en_292", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the season when flowers bloom?", choices:["Winter","Spring","Summer"], correctChoiceIndex:1},
    {id:"en_293", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the season when it is very hot?", choices:["Winter","Spring","Summer"], correctChoiceIndex:2},
    {id:"en_294", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the season when it is very cold?", choices:["Winter","Spring","Summer"], correctChoiceIndex:0},
    {id:"en_295", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What falls from the sky when it's cold and snowy?", choices:["Rain","Snow","Leaves"], correctChoiceIndex:1},
    {id:"en_296", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the season when we can see a lot of green leaves?", choices:["Winter","Spring","Summer"], correctChoiceIndex:2},
    {id:"en_297", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the cloud that can bring thunderstorms?", choices:["Cumulus","Cirrus","Stratus"], correctChoiceIndex:0},
    {id:"en_298", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the season when we can see a lot of colorful leaves?", choices:["Winter","Spring","Autumn"], correctChoiceIndex:2},
    {id:"en_299", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the season when we can see a lot of snow?", choices:["Winter","Spring","Autumn"], correctChoiceIndex:0},
    {id:"en_300", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the season when we can see a lot of rain?", choices:["Winter","Spring","Autumn"], correctChoiceIndex:1},
    {id:"en_301", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the season when we can see a lot of snowflakes?", choices:["Winter","Spring","Autumn"], correctChoiceIndex:0},
    {id:"en_302", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the season when we can see a lot of pumpkins?", choices:["Winter","Spring","Autumn"], correctChoiceIndex:2},
    {id:"en_303", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the season when we can see a lot of butterflies?", choices:["Winter","Spring","Summer"], correctChoiceIndex:1},
    {id:"en_304", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"What is the season when we can see a lot of birds?", choices:["Winter","Spring","Summer"], correctChoiceIndex:2},
    {id:"en_305", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What is a telephone used for?", choices:["Playing games","Calling people","Cooking"], correctChoiceIndex:1},
    {id:"en_306", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What should you not tell strangers online?", choices:["Your favorite color","Your name and address","Your favorite food"], correctChoiceIndex:1},
    {id:"en_307", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What is a computer used for?", choices:["Swimming","Doing homework","Eating"], correctChoiceIndex:1},
    {id:"en_308", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What is a television used for?", choices:["Watching shows","Driving","Sleeping"], correctChoiceIndex:0},
    {id:"en_309", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What is a radio used for?", choices:["Listening to music","Cooking","Running"], correctChoiceIndex:0},
    {id:"en_310", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is an email used for?", choices:["Sending messages","Cooking","Swimming"], correctChoiceIndex:0},
    {id:"en_311", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a mobile phone used for?", choices:["Calling people","Eating","Sleeping"], correctChoiceIndex:0},
    {id:"en_312", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a tablet used for?", choices:["Playing games","Cooking","Running"], correctChoiceIndex:0},
    {id:"en_313", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a website?", choices:["A place on the internet","A type of food","A type of animal"], correctChoiceIndex:0},
    {id:"en_314", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a password used for?", choices:["To protect your information","To play games","To cook food"], correctChoiceIndex:0},
    {id:"en_315", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a video call?", choices:["A way to see and talk to people online","A type of food","A type of animal"], correctChoiceIndex:0},
    {id:"en_316", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a microphone used for?", choices:["To make your voice louder","To cook food","To play games"], correctChoiceIndex:0},
    {id:"en_317", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a speaker used for?", choices:["To make sound louder","To cook food","To play games"], correctChoiceIndex:0},
    {id:"en_318", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a camera used for?", choices:["To take pictures","To cook food","To play games"], correctChoiceIndex:0},
    {id:"en_319", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a keyboard used for?", choices:["To type on a computer","To cook food","To play games"], correctChoiceIndex:0},
    {id:"en_320", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a mouse used for?", choices:["To control a computer","To cook food","To play games"], correctChoiceIndex:0},
    {id:"en_321", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a printer used for?", choices:["To print out papers","To cook food","To play games"], correctChoiceIndex:0},
    {id:"en_322", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a scanner used for?", choices:["To copy papers","To cook food","To play games"], correctChoiceIndex:0},
    {id:"en_323", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a modem used for?", choices:["To connect to the internet","To cook food","To play games"], correctChoiceIndex:0},
    {id:"en_324", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a router used for?", choices:["To share internet","To cook food","To play games"], correctChoiceIndex:0},
    {id:"en_325", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What is the main part of a computer called?", choices:["Monitor","Mouse","CPU"], correctChoiceIndex:2},
    {id:"en_326", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to click on things on the computer?", choices:["Keyboard","Mouse","Screen"], correctChoiceIndex:1},
    {id:"en_327", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What part of the computer helps us see things?", choices:["Monitor","Keyboard","CPU"], correctChoiceIndex:0},
    {id:"en_328", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to type on a computer?", choices:["Mouse","Keyboard","Screen"], correctChoiceIndex:1},
    {id:"en_329", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What is the brain of the computer called?", choices:["Monitor","CPU","Keyboard"], correctChoiceIndex:1},
    {id:"en_330", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is the device that stores information on a computer called?", choices:["Hard Drive","Monitor","Keyboard"], correctChoiceIndex:0},
    {id:"en_331", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is the name of the device that we use to move the cursor on the screen?", choices:["Keyboard","Mouse","Monitor"], correctChoiceIndex:1},
    {id:"en_332", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is the name of the device that we use to hear sounds from the computer?", choices:["Monitor","Keyboard","Speakers"], correctChoiceIndex:2},
    {id:"en_333", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is the name of the device that we use to print out information from the computer?", choices:["Printer","Monitor","Keyboard"], correctChoiceIndex:0},
    {id:"en_334", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is the name of the device that we use to scan images into the computer?", choices:["Monitor","Scanner","Keyboard"], correctChoiceIndex:1},
    {id:"en_335", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is the name of the device that we use to save our work on the computer?", choices:["Hard Drive","Monitor","Keyboard"], correctChoiceIndex:0},
    {id:"en_336", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is the name of the device that we use to connect to the internet?", choices:["Monitor","Keyboard","Modem"], correctChoiceIndex:2},
    {id:"en_337", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is the name of the device that we use to play games on the computer?", choices:["Joystick","Monitor","Keyboard"], correctChoiceIndex:0},
    {id:"en_338", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is the name of the device that we use to draw on the computer?", choices:["Monitor","Keyboard","Graphics Tablet"], correctChoiceIndex:2},
    {id:"en_339", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is the name of the device that we use to take pictures with the computer?", choices:["Monitor","Webcam","Keyboard"], correctChoiceIndex:1},
    {id:"en_340", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is the name of the device that we use to watch movies on the computer?", choices:["Monitor","Keyboard","DVD Drive"], correctChoiceIndex:2},
    {id:"en_341", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is the name of the device that we use to listen to music on the computer?", choices:["Monitor","Keyboard","Speakers"], correctChoiceIndex:2},
    {id:"en_342", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is the name of the device that we use to record sounds on the computer?", choices:["Monitor","Microphone","Keyboard"], correctChoiceIndex:1},
    {id:"en_343", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is the name of the device that we use to connect other devices to the computer?", choices:["USB Port","Monitor","Keyboard"], correctChoiceIndex:0},
    {id:"en_344", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is the name of the device that we use to charge the computer?", choices:["Monitor","Keyboard","Power Adapter"], correctChoiceIndex:2},
    {id:"en_345", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to keep our food cold?", choices:["Oven","Fridge","Washing Machine"], correctChoiceIndex:1},
    {id:"en_346", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to wash our clothes?", choices:["Dishwasher","Washing Machine","Microwave"], correctChoiceIndex:1},
    {id:"en_347", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to cook food quickly?", choices:["Microwave","Toaster","Fridge"], correctChoiceIndex:0},
    {id:"en_348", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to toast bread?", choices:["Microwave","Toaster","Washing Machine"], correctChoiceIndex:1},
    {id:"en_349", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to clean dishes?", choices:["Dishwasher","Toaster","Fridge"], correctChoiceIndex:0},
    {id:"en_350", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to heat up water?", choices:["Kettle","Fridge","Washing Machine"], correctChoiceIndex:0},
    {id:"en_351", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to blend food?", choices:["Blender","Fridge","Washing Machine"], correctChoiceIndex:0},
    {id:"en_352", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to keep our room cool?", choices:["Air Conditioner","Fridge","Washing Machine"], correctChoiceIndex:0},
    {id:"en_353", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to light up our room?", choices:["Light Bulb","Fridge","Washing Machine"], correctChoiceIndex:0},
    {id:"en_354", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to boil water?", choices:["Kettle","Fridge","Washing Machine"], correctChoiceIndex:0},
    {id:"en_355", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to make juice?", choices:["Juicer","Fridge","Washing Machine"], correctChoiceIndex:0},
    {id:"en_356", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to keep our food cold?", choices:["Oven","Fridge","Washing Machine"], correctChoiceIndex:1},
    {id:"en_357", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to wash our clothes?", choices:["Dishwasher","Washing Machine","Microwave"], correctChoiceIndex:1},
    {id:"en_358", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to cook food quickly?", choices:["Microwave","Toaster","Fridge"], correctChoiceIndex:0},
    {id:"en_359", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to toast bread?", choices:["Toaster","Fridge","Washing Machine"], correctChoiceIndex:0},
    {id:"en_360", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to clean the dishes?", choices:["Dishwasher","Microwave","Toaster"], correctChoiceIndex:0},
    {id:"en_361", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What do we use to vacuum the floor?", choices:["Vacuum Cleaner","Toaster","Fridge"], correctChoiceIndex:0},
    {id:"en_362", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What do we use to dry our hair?", choices:["Hair Dryer","Toaster","Fridge"], correctChoiceIndex:0},
    {id:"en_363", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What do we use to iron our clothes?", choices:["Washing Machine","Iron","Fridge"], correctChoiceIndex:1},
    {id:"en_364", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What do we use to watch movies at home?", choices:["Toaster","Fridge","Television"], correctChoiceIndex:2},
    {id:"en_365", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What do we use to listen to music at home?", choices:["Radio","Fridge","Washing Machine"], correctChoiceIndex:0},
    {id:"en_366", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What do we use to make coffee?", choices:["Coffee Maker","Fridge","Washing Machine"], correctChoiceIndex:0},
    {id:"en_367", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What do we use to bake cakes?", choices:["Oven","Fridge","Washing Machine"], correctChoiceIndex:0},
    {id:"en_368", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What do we use to charge our phone?", choices:["Charger","Fridge","Washing Machine"], correctChoiceIndex:0},
    {id:"en_369", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What do we use to print documents?", choices:["Printer","Fridge","Washing Machine"], correctChoiceIndex:0},
    {id:"en_370", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What appliance uses a remote control?", choices:["Washing Machine","Television","Fridge"], correctChoiceIndex:1},
    {id:"en_371", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What do we use to dry our clothes after washing?", choices:["Dishwasher","Dryer","Microwave"], correctChoiceIndex:1},
    {id:"en_372", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What do we use to vacuum the floor?", choices:["Toaster","Vacuum Cleaner","Fridge"], correctChoiceIndex:1},
    {id:"en_373", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What do we use to make a smoothie?", choices:["Blender","Washing Machine","Dryer"], correctChoiceIndex:0},
    {id:"en_374", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What do we use to boil water?", choices:["Microwave","Kettle","Toaster"], correctChoiceIndex:1},
    {id:"en_375", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What is a robot?", choices:["A toy","A machine that can do tasks","A type of food"], correctChoiceIndex:1},
    {id:"en_376", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What can robots do?", choices:["Eat food","Play games","Do tasks"], correctChoiceIndex:2},
    {id:"en_377", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Can robots help people?", choices:["No","Yes","Maybe"], correctChoiceIndex:1},
    {id:"en_378", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Are robots real or make-believe?", choices:["Make-believe","Real","Both"], correctChoiceIndex:1},
    {id:"en_379", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Can robots talk?", choices:["No","Yes","Only to other robots"], correctChoiceIndex:1},
    {id:"en_380", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a robot made of?", choices:["Plastic and metal","Wood","Candy"], correctChoiceIndex:0},
    {id:"en_381", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Can robots think like humans?", choices:["Yes","No","Sometimes"], correctChoiceIndex:1},
    {id:"en_382", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Can robots feel emotions?", choices:["Yes","No","Sometimes"], correctChoiceIndex:1},
    {id:"en_383", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What powers a robot?", choices:["Batteries or electricity","Water","Sunlight"], correctChoiceIndex:0},
    {id:"en_384", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Can robots learn new things?", choices:["Yes","No","Only if they go to school"], correctChoiceIndex:0},
    {id:"en_385", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Can robots make mistakes?", choices:["Yes","No","Only on Tuesdays"], correctChoiceIndex:0},
    {id:"en_386", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Can robots be used in medicine?", choices:["Yes","No","Only on weekends"], correctChoiceIndex:0},
    {id:"en_387", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Can robots be used in space?", choices:["Yes","No","Only on Earth"], correctChoiceIndex:0},
    {id:"en_388", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Can robots be used in construction?", choices:["Yes","No","Only in the kitchen"], correctChoiceIndex:0},
    {id:"en_389", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Can robots be used in farming?", choices:["Yes","No","Only in the city"], correctChoiceIndex:0},
    {id:"en_390", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Can robots be used in the military?", choices:["Yes","No","Only in movies"], correctChoiceIndex:0},
    {id:"en_391", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Can robots be used in the kitchen?", choices:["Yes","No","Only in restaurants"], correctChoiceIndex:0},
    {id:"en_392", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Can robots be used in the ocean?", choices:["Yes","No","Only on land"], correctChoiceIndex:0},
    {id:"en_393", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Can robots be used in the desert?", choices:["Yes","No","Only in the jungle"], correctChoiceIndex:0},
    {id:"en_394", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Can robots be used in the jungle?", choices:["Yes","No","Only in the desert"], correctChoiceIndex:0},
    {id:"en_395", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What color is a stop sign?", choices:["Blue","Red","Green"], correctChoiceIndex:1},
    {id:"en_396", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What does a green traffic light mean?", choices:["Stop","Go","Slow down"], correctChoiceIndex:1},
    {id:"en_397", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What is a bicycle?", choices:["A type of food","A type of animal","A type of vehicle"], correctChoiceIndex:2},
    {id:"en_398", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we use to walk?", choices:["Feet","Hands","Eyes"], correctChoiceIndex:0},
    {id:"en_399", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What is a car?", choices:["A type of food","A type of vehicle","A type of animal"], correctChoiceIndex:1},
    {id:"en_400", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What is a truck?", choices:["A type of animal","A type of food","A type of vehicle"], correctChoiceIndex:2},
    {id:"en_401", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What is a scooter?", choices:["A type of food","A type of vehicle","A type of animal"], correctChoiceIndex:1},
    {id:"en_402", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What is a boat?", choices:["A type of animal","A type of food","A type of vehicle"], correctChoiceIndex:2},
    {id:"en_403", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What is a skateboard?", choices:["A type of food","A type of vehicle","A type of animal"], correctChoiceIndex:1},
    {id:"en_404", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What is a roller skate?", choices:["A type of food","A type of vehicle","A type of animal"], correctChoiceIndex:1},
    {id:"en_405", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What is a tricycle?", choices:["A type of food","A type of vehicle","A type of animal"], correctChoiceIndex:1},
    {id:"en_406", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What color is a school bus?", choices:["Red","Blue","Yellow"], correctChoiceIndex:2},
    {id:"en_407", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What does a boat travel on?", choices:["Road","Water","Railway"], correctChoiceIndex:1},
    {id:"en_408", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What has wings and can fly?", choices:["Car","Airplane","Train"], correctChoiceIndex:1},
    {id:"en_409", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What do we ride on to go to space?", choices:["Rocket","Car","Bicycle"], correctChoiceIndex:0},
    {id:"en_410", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"What has wheels and can be ridden on a sidewalk?", choices:["Bicycle","Boat","Airplane"], correctChoiceIndex:0},
    {id:"en_411", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a train?", choices:["A type of vehicle","A type of food","A type of animal"], correctChoiceIndex:0},
    {id:"en_412", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a ship?", choices:["A type of animal","A type of food","A type of vehicle"], correctChoiceIndex:2},
    {id:"en_413", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a plane?", choices:["A type of food","A type of animal","A type of vehicle"], correctChoiceIndex:2},
    {id:"en_414", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a bus?", choices:["A type of animal","A type of vehicle","A type of food"], correctChoiceIndex:1},
    {id:"en_415", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a motorcycle?", choices:["A type of food","A type of vehicle","A type of animal"], correctChoiceIndex:1},
    {id:"en_416", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a helicopter?", choices:["A type of food","A type of animal","A type of vehicle"], correctChoiceIndex:2},
    {id:"en_417", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a subway?", choices:["A type of food","A type of vehicle","A type of animal"], correctChoiceIndex:1},
    {id:"en_418", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a taxi?", choices:["A type of animal","A type of vehicle","A type of food"], correctChoiceIndex:1},
    {id:"en_419", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What is a hoverboard?", choices:["A type of food","A type of animal","A type of vehicle"], correctChoiceIndex:2},
    {id:"en_420", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What type of transportation uses tracks?", choices:["Car","Train","Airplane"], correctChoiceIndex:1},
    {id:"en_421", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What type of vehicle is used for long sea journeys?", choices:["Car","Bicycle","Ship"], correctChoiceIndex:2},
    {id:"en_422", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What type of vehicle can travel on land and water?", choices:["Amphibious vehicle","Airplane","Bicycle"], correctChoiceIndex:0},
    {id:"en_423", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What type of transportation does a pilot fly?", choices:["Train","Airplane","Boat"], correctChoiceIndex:1},
    {id:"en_424", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What type of vehicle uses a propeller?", choices:["Helicopter","Car","Bicycle"], correctChoiceIndex:0},
    {id:"en_425", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What type of vehicle is used to transport goods over long distances?", choices:["Truck","Bicycle","Boat"], correctChoiceIndex:0},
    {id:"en_426", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What type of vehicle is used in a race?", choices:["Race car","Boat","Airplane"], correctChoiceIndex:0},
    {id:"en_427", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What type of vehicle is used to transport people in a city?", choices:["Bus","Airplane","Boat"], correctChoiceIndex:0},
    {id:"en_428", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What type of vehicle is used to transport people between floors in a building?", choices:["Elevator","Car","Bicycle"], correctChoiceIndex:0},
    {id:"en_429", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What type of vehicle is used to transport people over snow?", choices:["Snowmobile","Car","Boat"], correctChoiceIndex:0},
    {id:"en_430", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What type of vehicle is used to transport people in the desert?", choices:["Camel","Car","Boat"], correctChoiceIndex:0},
    {id:"en_431", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What type of vehicle is used to transport people in the mountains?", choices:["Cable car","Car","Boat"], correctChoiceIndex:0},
    {id:"en_432", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What type of vehicle is used to transport people in the water?", choices:["Boat","Car","Bicycle"], correctChoiceIndex:0},
    {id:"en_433", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What type of vehicle is used to transport people in the air?", choices:["Airplane","Car","Boat"], correctChoiceIndex:0},
    {id:"en_434", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"What type of vehicle is used to transport people on the road?", choices:["Car","Boat","Airplane"], correctChoiceIndex:0},
    {id:"en_435", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Who puts out fires?", choices:["Firefighter","Doctor","Teacher"], correctChoiceIndex:0},
    {id:"en_436", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Who helps us when we are sick?", choices:["Teacher","Doctor","Firefighter"], correctChoiceIndex:1},
    {id:"en_437", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Who teaches us at school?", choices:["Doctor","Firefighter","Teacher"], correctChoiceIndex:2},
    {id:"en_438", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What does a firefighter wear?", choices:["Uniform","Swimsuit","Pajamas"], correctChoiceIndex:0},
    {id:"en_439", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Where does a doctor work?", choices:["School","Fire station","Hospital"], correctChoiceIndex:2},
    {id:"en_440", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What vehicle does a firefighter drive?", choices:["Car","Fire truck","Bicycle"], correctChoiceIndex:1},
    {id:"en_441", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What does a teacher use to write on the board?", choices:["Stethoscope","Chalk","Hose"], correctChoiceIndex:1},
    {id:"en_442", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What does a doctor wear on their hands?", choices:["Gloves","Mittens","Rings"], correctChoiceIndex:0},
    {id:"en_443", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What does a firefighter use to climb tall buildings?", choices:["Ladder","Stairs","Elevator"], correctChoiceIndex:0},
    {id:"en_444", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What does a doctor use to see inside your body?", choices:["X-ray machine","Telescope","Microscope"], correctChoiceIndex:0},
    {id:"en_445", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Who puts out fires in our community?", choices:["Teachers","Firefighters","Doctors"], correctChoiceIndex:1},
    {id:"en_446", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Who helps us when we are sick?", choices:["Firefighters","Teachers","Doctors"], correctChoiceIndex:2},
    {id:"en_447", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool does a firefighter use to put out fires?", choices:["Stethoscope","Hose","Chalkboard"], correctChoiceIndex:1},
    {id:"en_448", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What does a doctor use to check your heartbeat?", choices:["Hose","Stethoscope","Chalkboard"], correctChoiceIndex:1},
    {id:"en_449", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What subject does a teacher teach?", choices:["Firefighting","Medicine","Many subjects"], correctChoiceIndex:2},
    {id:"en_450", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Why does a firefighter need a helmet?", choices:["To protect their head","To look nice","To keep their hair neat"], correctChoiceIndex:0},
    {id:"en_451", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What does a doctor do when someone is sick?", choices:["Teaches them","Puts out fires","Examines and treats them"], correctChoiceIndex:2},
    {id:"en_452", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What does a firefighter do when there is a fire?", choices:["Calls a doctor","Puts out the fire","Goes to school"], correctChoiceIndex:1},
    {id:"en_453", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What does a teacher do when a student doesn't understand?", choices:["Calls a firefighter","Teaches them again","Gives them medicine"], correctChoiceIndex:1},
    {id:"en_454", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What does a doctor do when a patient is hurt?", choices:["Teaches them","Puts out fires","Helps them"], correctChoiceIndex:2},
    {id:"en_455", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What does a teacher do at school?", choices:["Puts out fires","Teaches students","Cures patients"], correctChoiceIndex:1},
    {id:"en_456", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What does a doctor do with a stethoscope?", choices:["Writes on a board","Listens to your heart and lungs","Puts out fires"], correctChoiceIndex:1},
    {id:"en_457", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What does a teacher do?", choices:["Puts out fires","Helps when we are sick","Helps us learn"], correctChoiceIndex:2},
    {id:"en_458", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What does a doctor do?", choices:["Helps us learn","Helps when we are sick","Puts out fires"], correctChoiceIndex:1},
    {id:"en_459", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What does a firefighter do?", choices:["Helps when we are sick","Puts out fires","Helps us learn"], correctChoiceIndex:1},
    {id:"en_460", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Which one is a fruit?", choices:["Apple","Bread","Cheese"], correctChoiceIndex:0},
    {id:"en_461", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What should we drink a lot every day?", choices:["Soda","Water","Coffee"], correctChoiceIndex:1},
    {id:"en_462", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Which one is a vegetable?", choices:["Banana","Carrot","Milk"], correctChoiceIndex:1},
    {id:"en_463", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Which food group does bread belong to?", choices:["Fruits","Grains","Dairy"], correctChoiceIndex:1},
    {id:"en_464", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Which one is not good for our teeth?", choices:["Candy","Apple","Carrot"], correctChoiceIndex:0},
    {id:"en_465", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Which one is a healthy snack?", choices:["Candy","Fruit","Ice cream"], correctChoiceIndex:1},
    {id:"en_466", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What is the main nutrient in bread?", choices:["Protein","Carbohydrate","Vitamin"], correctChoiceIndex:1},
    {id:"en_467", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Which one is not a food group?", choices:["Sweets","Vegetables","Grains"], correctChoiceIndex:0},
    {id:"en_468", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Which one is not a fruit?", choices:["Apple","Carrot","Banana"], correctChoiceIndex:1},
    {id:"en_469", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What is the main nutrient in milk?", choices:["Protein","Carbohydrate","Calcium"], correctChoiceIndex:2},
    {id:"en_470", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Which food group does cheese belong to?", choices:["Dairy","Fruits","Vegetables"], correctChoiceIndex:0},
    {id:"en_471", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Why do we need to eat vegetables?", choices:["For energy","For strong bones","Both"], correctChoiceIndex:2},
    {id:"en_472", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Which one is a protein food?", choices:["Chicken","Apple","Bread"], correctChoiceIndex:0},
    {id:"en_473", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What does vitamin C help with?", choices:["Seeing in the dark","Fighting off germs","Growing hair"], correctChoiceIndex:1},
    {id:"en_474", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Which food group does an egg belong to?", choices:["Protein","Dairy","Fruits"], correctChoiceIndex:0},
    {id:"en_475", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What is the main nutrient in meat?", choices:["Protein","Carbohydrate","Fat"], correctChoiceIndex:0},
    {id:"en_476", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What does calcium help with?", choices:["Seeing in the dark","Growing hair","Strong bones and teeth"], correctChoiceIndex:2},
    {id:"en_477", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Which one is a dairy product?", choices:["Milk","Apple","Bread"], correctChoiceIndex:0},
    {id:"en_478", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What is the main nutrient in vegetables?", choices:["Vitamin","Protein","Carbohydrate"], correctChoiceIndex:0},
    {id:"en_479", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What does fiber help with?", choices:["Digestion","Seeing in the dark","Growing hair"], correctChoiceIndex:0},
    {id:"en_480", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What is the value of a penny?", choices:["1 cent","5 cents","10 cents"], correctChoiceIndex:0},
    {id:"en_481", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"If you have 2 pennies, how much money do you have?", choices:["1 cent","2 cents","3 cents"], correctChoiceIndex:1},
    {id:"en_482", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What is the value of a nickel?", choices:["1 cent","5 cents","10 cents"], correctChoiceIndex:1},
    {id:"en_483", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"If you have 2 nickels, how much money do you have?", choices:["5 cents","10 cents","15 cents"], correctChoiceIndex:1},
    {id:"en_484", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What is the value of a dime?", choices:["5 cents","10 cents","25 cents"], correctChoiceIndex:1},
    {id:"en_485", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"If you have 2 dimes, how much money do you have?", choices:["10 cents","20 cents","30 cents"], correctChoiceIndex:1},
    {id:"en_486", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What is the value of a quarter?", choices:["5 cents","10 cents","25 cents"], correctChoiceIndex:2},
    {id:"en_487", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"If you have 2 quarters, how much money do you have?", choices:["25 cents","50 cents","75 cents"], correctChoiceIndex:1},
    {id:"en_488", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If you have 3 dimes and 2 nickels, how much money do you have?", choices:["25 cents","30 cents","35 cents"], correctChoiceIndex:2},
    {id:"en_489", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If you have 4 quarters, how much money do you have?", choices:["50 cents","75 cents","1 dollar"], correctChoiceIndex:2},
    {id:"en_490", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If you have 5 pennies and 2 dimes, how much money do you have?", choices:["15 cents","20 cents","25 cents"], correctChoiceIndex:2},
    {id:"en_491", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If you have 3 quarters and 2 dimes, how much money do you have?", choices:["85 cents","95 cents","1 dollar"], correctChoiceIndex:1},
    {id:"en_492", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If you have 2 dollars and 3 quarters, how much money do you have?", choices:["2.50 dollars","2.75 dollars","3 dollars"], correctChoiceIndex:1},
    {id:"en_493", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If you have 5 dollars and 2 quarters, how much money do you have?", choices:["5.25 dollars","5.50 dollars","5.75 dollars"], correctChoiceIndex:2},
    {id:"en_494", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If you have 10 dollars and 5 dimes, how much money do you have?", choices:["10.25 dollars","10.50 dollars","10.75 dollars"], correctChoiceIndex:1},
    {id:"en_495", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If you have 20 dollars and 4 quarters, how much money do you have?", choices:["21 dollars","22 dollars","23 dollars"], correctChoiceIndex:0},
    {id:"en_496", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If you have 50 dollars and 10 dimes, how much money do you have?", choices:["50.50 dollars","51 dollars","52 dollars"], correctChoiceIndex:1},
    {id:"en_497", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If you have 100 dollars and 20 nickels, how much money do you have?", choices:["101 dollars","102 dollars","103 dollars"], correctChoiceIndex:0},
    {id:"en_498", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If you have 200 dollars and 50 pennies, how much money do you have?", choices:["200.25 dollars","200.50 dollars","200.75 dollars"], correctChoiceIndex:1},
    {id:"en_499", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If you have 500 dollars and 100 pennies, how much money do you have?", choices:["501 dollars","502 dollars","503 dollars"], correctChoiceIndex:0},
    {id:"en_500", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What should you do before crossing the street?", choices:["Run quickly","Look both ways","Close your eyes"], correctChoiceIndex:1},
    {id:"en_501", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What number do you call in case of an emergency?", choices:["123","911","789"], correctChoiceIndex:1},
    {id:"en_502", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What should you do if a stranger offers you candy?", choices:["Take it","Say thank you","Say no and walk away"], correctChoiceIndex:2},
    {id:"en_503", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Where should you play with your toys?", choices:["In the middle of the road","On the sidewalk","In a safe area like your home or a park"], correctChoiceIndex:2},
    {id:"en_504", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What should you do if you see a fire?", choices:["Try to put it out yourself","Call for an adult or dial 911","Hide"], correctChoiceIndex:1},
    {id:"en_505", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What should you do if you find a sharp object like a knife or a needle?", choices:["Play with it","Give it to your younger sibling","Tell an adult"], correctChoiceIndex:2},
    {id:"en_506", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What should you do if you see a dog you don't know?", choices:["Run towards it","Pet it","Stay away and tell an adult"], correctChoiceIndex:2},
    {id:"en_507", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What should you do if you're in a car and the driver is not wearing a seatbelt?", choices:["Say nothing","Ask them to put their seatbelt on","Take off your own seatbelt"], correctChoiceIndex:1},
    {id:"en_508", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What should you do if you see a car coming while you're crossing the street?", choices:["Run faster","Stop and wait for the car to pass","Wave at the car"], correctChoiceIndex:1},
    {id:"en_509", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What should you do if you find medicine?", choices:["Eat it","Play with it","Give it to an adult"], correctChoiceIndex:2},
    {id:"en_510", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What should you do if you're riding a bike and you see a stop sign?", choices:["Ignore it","Stop and look both ways","Ride faster"], correctChoiceIndex:1},
    {id:"en_511", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What should you do if you get lost in a public place?", choices:["Cry loudly","Look for a police officer or a trusted adult","Run around looking for your parents"], correctChoiceIndex:1},
    {id:"en_512", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What should you do if someone you don't know knocks at the door when you're home alone?", choices:["Open the door","Ask who it is without opening the door","Invite them in"], correctChoiceIndex:1},
    {id:"en_513", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What should you do if you smell gas in your house?", choices:["Ignore it","Open all windows and doors, then tell an adult or call 911","Light a match to see better"], correctChoiceIndex:1},
    {id:"en_514", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What should you do if your clothes catch on fire?", choices:["Run around","Stop, drop and roll","Scream for help"], correctChoiceIndex:1},
    {id:"en_515", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What should you do if you see a broken glass on the floor?", choices:["Pick it up with your hands","Tell an adult","Play with it"], correctChoiceIndex:1},
    {id:"en_516", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What should you do if you see someone getting hurt?", choices:["Laugh","Join in","Tell an adult or call 911"], correctChoiceIndex:2},
    {id:"en_517", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What should you do if you see a gun?", choices:["Play with it","Tell an adult","Hide it"], correctChoiceIndex:1},
    {id:"en_518", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What should you do if you're home alone and there's a power outage?", choices:["Play with the fuse box","Light candles all over the house","Use a flashlight and call a trusted adult"], correctChoiceIndex:2},
    {id:"en_519", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What should you do if you see someone fall into a pool and they can't swim?", choices:["Jump in to save them","Laugh","Call for help or find an adult"], correctChoiceIndex:2},
    {id:"en_520", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What is the first day of the week?", choices:["Monday","Sunday","Saturday"], correctChoiceIndex:1},
    {id:"en_521", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What is the last day of the week?", choices:["Friday","Saturday","Sunday"], correctChoiceIndex:2},
    {id:"en_522", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"How many days are in a week?", choices:["5","7","10"], correctChoiceIndex:1},
    {id:"en_523", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What day comes after Monday?", choices:["Tuesday","Wednesday","Thursday"], correctChoiceIndex:0},
    {id:"en_524", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What day comes before Friday?", choices:["Thursday","Wednesday","Tuesday"], correctChoiceIndex:0},
    {id:"en_525", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What is the 7th month of the year?", choices:["July","June","August"], correctChoiceIndex:0},
    {id:"en_526", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"How many months are in a year?", choices:["10","12","15"], correctChoiceIndex:1},
    {id:"en_527", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What month comes after April?", choices:["May","June","July"], correctChoiceIndex:0},
    {id:"en_528", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What month comes before December?", choices:["November","October","September"], correctChoiceIndex:0},
    {id:"en_529", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What is the 1st month of the year?", choices:["January","February","March"], correctChoiceIndex:0},
    {id:"en_530", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If the clock shows 12:00, what time is it?", choices:["Noon","Midnight","Afternoon"], correctChoiceIndex:0},
    {id:"en_531", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If the clock shows 00:00, what time is it?", choices:["Noon","Midnight","Morning"], correctChoiceIndex:1},
    {id:"en_532", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"How many hours are in a day?", choices:["24","12","48"], correctChoiceIndex:0},
    {id:"en_533", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If the clock shows 15:00, what time is it?", choices:["3 PM","5 PM","1 PM"], correctChoiceIndex:0},
    {id:"en_534", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If the clock shows 18:00, what time is it?", choices:["6 PM","8 PM","4 PM"], correctChoiceIndex:0},
    {id:"en_535", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"How many minutes are in an hour?", choices:["60","30","120"], correctChoiceIndex:0},
    {id:"en_536", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If the clock shows 12:30, what time is it?", choices:["Half past twelve","Half past one","Half past two"], correctChoiceIndex:0},
    {id:"en_537", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If the clock shows 09:15, what time is it?", choices:["Quarter past nine","Quarter to nine","Quarter past ten"], correctChoiceIndex:0},
    {id:"en_538", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If the clock shows 10:45, what time is it?", choices:["Quarter to eleven","Quarter past ten","Quarter to ten"], correctChoiceIndex:0},
    {id:"en_539", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"If the clock shows 08:00, what time is it?", choices:["8 AM","8 PM","9 AM"], correctChoiceIndex:0},
    {id:"en_540", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What color is a standard hammer?", choices:["Red","Blue","Yellow"], correctChoiceIndex:0},
    {id:"en_541", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What tool do we use to cut paper?", choices:["Scissors","Spoon","Fork"], correctChoiceIndex:0},
    {id:"en_542", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What tool do we use to stick things together?", choices:["Glue","Hammer","Screwdriver"], correctChoiceIndex:0},
    {id:"en_543", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What tool do we use to draw a circle?", choices:["Compass","Ruler","Eraser"], correctChoiceIndex:0},
    {id:"en_544", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What tool do we use to measure length?", choices:["Ruler","Scissors","Pencil"], correctChoiceIndex:0},
    {id:"en_545", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What tool do we use to erase pencil marks?", choices:["Eraser","Pencil","Ruler"], correctChoiceIndex:0},
    {id:"en_546", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What tool do we use to color a picture?", choices:["Crayons","Hammer","Screwdriver"], correctChoiceIndex:0},
    {id:"en_547", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What tool do we use to cut wood?", choices:["Saw","Scissors","Pencil"], correctChoiceIndex:0},
    {id:"en_548", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What tool do we use to tighten screws?", choices:["Screwdriver","Hammer","Saw"], correctChoiceIndex:0},
    {id:"en_549", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"What tool do we use to hammer nails?", choices:["Hammer","Screwdriver","Saw"], correctChoiceIndex:0},
    {id:"en_550", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to make holes in wood?", choices:["Drill","Screwdriver","Hammer"], correctChoiceIndex:0},
    {id:"en_551", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to measure angles?", choices:["Protractor","Ruler","Compass"], correctChoiceIndex:0},
    {id:"en_552", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to draw straight lines?", choices:["Ruler","Compass","Protractor"], correctChoiceIndex:0},
    {id:"en_553", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to cut metal?", choices:["Hacksaw","Scissors","Knife"], correctChoiceIndex:0},
    {id:"en_554", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to hold things together while they dry?", choices:["Clamp","Hammer","Screwdriver"], correctChoiceIndex:0},
    {id:"en_555", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to smooth wood?", choices:["Sandpaper","Scissors","Ruler"], correctChoiceIndex:0},
    {id:"en_556", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to paint walls?", choices:["Paintbrush","Hammer","Screwdriver"], correctChoiceIndex:0},
    {id:"en_557", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to mix paint?", choices:["Paint stirrer","Screwdriver","Hammer"], correctChoiceIndex:0},
    {id:"en_558", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to remove nails?", choices:["Pliers","Hammer","Screwdriver"], correctChoiceIndex:0},
    {id:"en_559", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to cut wires?", choices:["Wire cutters","Scissors","Knife"], correctChoiceIndex:0},
    {id:"en_560", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to bend wires?", choices:["Pliers","Hammer","Screwdriver"], correctChoiceIndex:0},
    {id:"en_561", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to measure the size of nuts and bolts?", choices:["Caliper","Ruler","Protractor"], correctChoiceIndex:0},
    {id:"en_562", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to tighten bolts?", choices:["Wrench","Hammer","Screwdriver"], correctChoiceIndex:0},
    {id:"en_563", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to cut threads in metal?", choices:["Tap and die set","Scissors","Knife"], correctChoiceIndex:0},
    {id:"en_564", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to make a hole in leather?", choices:["Leather punch","Scissors","Knife"], correctChoiceIndex:0},
    {id:"en_565", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to carve wood?", choices:["Chisel","Scissors","Knife"], correctChoiceIndex:0},
    {id:"en_566", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to shape metal?", choices:["File","Scissors","Knife"], correctChoiceIndex:0},
    {id:"en_567", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to measure depth?", choices:["Depth gauge","Ruler","Protractor"], correctChoiceIndex:0},
    {id:"en_568", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to cut glass?", choices:["Glass cutter","Scissors","Knife"], correctChoiceIndex:0},
    {id:"en_569", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"What tool do we use to strip wires?", choices:["Wire stripper","Scissors","Knife"], correctChoiceIndex:0},
    {id:"en_570", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What color do you get when you mix yellow and blue?", choices:["Red","Green","Purple"], correctChoiceIndex:1},
    {id:"en_571", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What is the primary color?", choices:["Green","Blue","Brown"], correctChoiceIndex:1},
    {id:"en_572", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What color do you get when you mix red and blue?", choices:["Purple","Orange","Green"], correctChoiceIndex:0},
    {id:"en_573", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What is a sculpture?", choices:["A type of dance","A type of painting","A three-dimensional work of art"], correctChoiceIndex:2},
    {id:"en_574", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What do we use to draw a picture?", choices:["Pencil","Spoon","Shoe"], correctChoiceIndex:0},
    {id:"en_575", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What color do you get when you mix blue and red?", choices:["Purple","Yellow","Black"], correctChoiceIndex:0},
    {id:"en_576", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is a self-portrait?", choices:["A picture of someone else","A picture you draw of yourself","A picture of a pet"], correctChoiceIndex:1},
    {id:"en_577", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is a landscape painting?", choices:["A painting of a city","A painting of nature","A painting of a person"], correctChoiceIndex:1},
    {id:"en_578", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is a still life painting?", choices:["A painting of moving objects","A painting of inanimate objects","A painting of animals"], correctChoiceIndex:1},
    {id:"en_579", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What color do you get when you mix all the primary colors together?", choices:["White","Black","Brown"], correctChoiceIndex:2},
    {id:"en_580", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is an abstract painting?", choices:["A painting that looks exactly like the subject","A painting that does not look like the subject","A painting of a person"], correctChoiceIndex:1},
    {id:"en_581", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is a mural?", choices:["A small painting","A painting on a wall","A sculpture"], correctChoiceIndex:1},
    {id:"en_582", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is a collage?", choices:["A type of dance","A picture made by sticking pieces of different materials together","A type of music"], correctChoiceIndex:1},
    {id:"en_583", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is a portrait?", choices:["A painting of a person","A painting of a landscape","A painting of an animal"], correctChoiceIndex:0},
    {id:"en_584", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is a sketch?", choices:["A quick, rough drawing","A detailed painting","A sculpture"], correctChoiceIndex:0},
    {id:"en_585", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is a palette?", choices:["A tool used to mix colors","A type of paintbrush","A type of canvas"], correctChoiceIndex:0},
    {id:"en_586", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is a canvas?", choices:["A type of paint","A surface used for painting","A type of paintbrush"], correctChoiceIndex:1},
    {id:"en_587", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is a fresco?", choices:["A type of sculpture","A type of painting done on wet plaster","A type of dance"], correctChoiceIndex:1},
    {id:"en_588", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is a stencil?", choices:["A tool used to draw perfect shapes","A type of paintbrush","A type of canvas"], correctChoiceIndex:0},
    {id:"en_589", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is a sculpture made from?", choices:["Clay, wood, metal, stone","Paper, cloth, plastic","Water, air, fire"], correctChoiceIndex:0},
    {id:"en_590", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What part of your body do you use to dance?", choices:["Hands","Feet","Both hands and feet"], correctChoiceIndex:2},
    {id:"en_591", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What is ballet?", choices:["A type of food","A type of dance","A type of animal"], correctChoiceIndex:1},
    {id:"en_592", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What do we wear on our feet for tap dance?", choices:["Sandals","Tap Shoes","Sneakers"], correctChoiceIndex:1},
    {id:"en_593", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What is a pirouette?", choices:["A type of food","A spinning dance move","A type of instrument"], correctChoiceIndex:1},
    {id:"en_594", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What is a leap in dance?", choices:["Jumping over something","Running fast","Sitting down"], correctChoiceIndex:0},
    {id:"en_595", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the dance form 'Bharatanatyam' originated from?", choices:["China","India","Russia"], correctChoiceIndex:1},
    {id:"en_596", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the dance form 'Flamenco' originated from?", choices:["Spain","France","Germany"], correctChoiceIndex:0},
    {id:"en_597", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the dance form 'Tango' originated from?", choices:["Argentina","Australia","Austria"], correctChoiceIndex:0},
    {id:"en_598", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the dance form 'Salsa' originated from?", choices:["Cuba","Canada","Czech Republic"], correctChoiceIndex:0},
    {id:"en_599", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the dance form 'Hip Hop' originated from?", choices:["United States","United Kingdom","Ukraine"], correctChoiceIndex:0},
    {id:"en_600", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the dance form 'Ballet' originated from?", choices:["France","Finland","Fiji"], correctChoiceIndex:0},
    {id:"en_601", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the dance form 'Samba' originated from?", choices:["Brazil","Belgium","Bulgaria"], correctChoiceIndex:0},
    {id:"en_602", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the dance form 'Kathak' originated from?", choices:["India","Iceland","Indonesia"], correctChoiceIndex:0},
    {id:"en_603", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the dance form 'Belly Dance' originated from?", choices:["Egypt","Estonia","Ecuador"], correctChoiceIndex:0},
    {id:"en_604", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the dance form 'Break Dance' originated from?", choices:["United States","Uganda","Uzbekistan"], correctChoiceIndex:0},
    {id:"en_605", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the dance form 'Jazz Dance' originated from?", choices:["United States","Uruguay","Ukraine"], correctChoiceIndex:0},
    {id:"en_606", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the dance form 'Tap Dance' originated from?", choices:["United States","Uganda","Uzbekistan"], correctChoiceIndex:0},
    {id:"en_607", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the dance form 'Folk Dance' originated from?", choices:["Different countries","Denmark","Dominican Republic"], correctChoiceIndex:0},
    {id:"en_608", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the dance form 'Ballroom Dance' originated from?", choices:["England","Ecuador","Egypt"], correctChoiceIndex:0},
    {id:"en_609", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the dance form 'Contemporary Dance' originated from?", choices:["United States","Uganda","Uzbekistan"], correctChoiceIndex:0},
    {id:"en_610", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Who is the main character in the fairy tale 'Little Red Riding Hood'?", choices:["The Big Bad Wolf","Little Red Riding Hood","The Three Little Pigs"], correctChoiceIndex:1},
    {id:"en_611", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What does Cinderella lose at the ball?", choices:["Her shoe","Her dress","Her necklace"], correctChoiceIndex:0},
    {id:"en_612", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Who are the three characters in 'The Three Little Pigs'?", choices:["Three pigs and a wolf","Three pigs and a fox","Three pigs and a bear"], correctChoiceIndex:0},
    {id:"en_613", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What does the Gingerbread Man run away from in the story?", choices:["A fox","A wolf","A bear"], correctChoiceIndex:0},
    {id:"en_614", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Who is the villain in 'Snow White'?", choices:["The Evil Queen","The Seven Dwarfs","Snow White"], correctChoiceIndex:0},
    {id:"en_615", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Who wrote the fairy tale 'The Ugly Duckling'?", choices:["Hans Christian Andersen","Brothers Grimm","Charles Perrault"], correctChoiceIndex:0},
    {id:"en_616", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the princess in 'The Princess and the Pea'?", choices:["Cinderella","Sleeping Beauty","It is not mentioned"], correctChoiceIndex:2},
    {id:"en_617", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What does Jack trade for magic beans in 'Jack and the Beanstalk'?", choices:["His cow","His horse","His dog"], correctChoiceIndex:0},
    {id:"en_618", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Who helps Pinocchio throughout his journey?", choices:["The Blue Fairy","The Red Fairy","The Green Fairy"], correctChoiceIndex:0},
    {id:"en_619", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What does the Little Mermaid give up to become human?", choices:["Her voice","Her tail","Her family"], correctChoiceIndex:0},
    {id:"en_620", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Who is the villain in 'Hansel and Gretel'?", choices:["The witch","The stepmother","The father"], correctChoiceIndex:0},
    {id:"en_621", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What does Aladdin use to summon the Genie?", choices:["A lamp","A ring","A bottle"], correctChoiceIndex:0},
    {id:"en_622", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Who is the main character in 'Alice in Wonderland'?", choices:["Alice","The Mad Hatter","The Queen of Hearts"], correctChoiceIndex:0},
    {id:"en_623", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the boy who never grows up in J.M. Barrie's novel?", choices:["Peter Pan","Captain Hook","Wendy"], correctChoiceIndex:0},
    {id:"en_624", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Who is the author of 'The Jungle Book'?", choices:["Rudyard Kipling","Mark Twain","Charles Dickens"], correctChoiceIndex:0},
    {id:"en_625", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the girl in 'The Secret Garden'?", choices:["Mary Lennox","Sara Crewe","Anne Shirley"], correctChoiceIndex:0},
    {id:"en_626", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Who is the author of 'Peter Rabbit'?", choices:["Beatrix Potter","Lewis Carroll","Roald Dahl"], correctChoiceIndex:0},
    {id:"en_627", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the lion in 'The Lion, the Witch and the Wardrobe'?", choices:["Aslan","Simba","Mufasa"], correctChoiceIndex:0},
    {id:"en_628", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Who is the author of 'Matilda'?", choices:["Roald Dahl","J.K. Rowling","Dr. Seuss"], correctChoiceIndex:0},
    {id:"en_629", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the boy wizard in J.K. Rowling's series?", choices:["Harry Potter","Ron Weasley","Hermione Granger"], correctChoiceIndex:0},
    {id:"en_630", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What is the name of the big drum that you hit with a stick?", choices:["Guitar","Piano","Drum"], correctChoiceIndex:2},
    {id:"en_631", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What instrument has black and white keys that you press to make sound?", choices:["Piano","Violin","Flute"], correctChoiceIndex:0},
    {id:"en_632", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What instrument do you strum with your fingers?", choices:["Guitar","Drum","Trumpet"], correctChoiceIndex:0},
    {id:"en_633", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What instrument do you blow into and press buttons on the side?", choices:["Flute","Drum","Piano"], correctChoiceIndex:0},
    {id:"en_634", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What instrument has four strings and is played with a bow?", choices:["Violin","Piano","Drum"], correctChoiceIndex:0},
    {id:"en_635", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the instrument that Beethoven famously played?", choices:["Piano","Guitar","Drum"], correctChoiceIndex:0},
    {id:"en_636", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the instrument that has 88 keys?", choices:["Piano","Guitar","Violin"], correctChoiceIndex:0},
    {id:"en_637", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the instrument that you have to blow into and press valves?", choices:["Trumpet","Drum","Piano"], correctChoiceIndex:0},
    {id:"en_638", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the instrument that is made up of a series of pipes?", choices:["Organ","Guitar","Violin"], correctChoiceIndex:0},
    {id:"en_639", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the instrument that is often used in a marching band?", choices:["Drum","Piano","Violin"], correctChoiceIndex:0},
    {id:"en_640", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the instrument that is often used in jazz music?", choices:["Saxophone","Piano","Drum"], correctChoiceIndex:0},
    {id:"en_641", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the instrument that is often used in orchestras and has a bow?", choices:["Violin","Piano","Drum"], correctChoiceIndex:0},
    {id:"en_642", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the instrument that is often used in rock music?", choices:["Electric Guitar","Piano","Drum"], correctChoiceIndex:0},
    {id:"en_643", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the instrument that is often used in country music?", choices:["Banjo","Piano","Drum"], correctChoiceIndex:0},
    {id:"en_644", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the instrument that is often used in classical Indian music?", choices:["Sitar","Piano","Drum"], correctChoiceIndex:0},
    {id:"en_645", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the instrument that is often used in reggae music?", choices:["Steel Drum","Piano","Drum"], correctChoiceIndex:0},
    {id:"en_646", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the instrument that is often used in flamenco music?", choices:["Spanish Guitar","Piano","Drum"], correctChoiceIndex:0},
    {id:"en_647", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the instrument that is often used in blues music?", choices:["Harmonica","Piano","Drum"], correctChoiceIndex:0},
    {id:"en_648", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the instrument that is often used in mariachi bands?", choices:["Trumpet","Piano","Drum"], correctChoiceIndex:0},
    {id:"en_649", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the name of the instrument that is often used in Scottish music?", choices:["Bagpipes","Piano","Drum"], correctChoiceIndex:0},
    {id:"en_650", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What language do people in Spain speak?", choices:["Spanish","English","French"], correctChoiceIndex:0},
    {id:"en_651", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What is the traditional dress of Japan called?", choices:["Kimono","Sari","Jeans"], correctChoiceIndex:0},
    {id:"en_652", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What is the color of the flag of China?", choices:["Red","Blue","Green"], correctChoiceIndex:0},
    {id:"en_653", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What is the main ingredient in Italian pizza?", choices:["Cheese","Rice","Potato"], correctChoiceIndex:0},
    {id:"en_654", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"What is the famous dance of Ireland?", choices:["Jig","Ballet","Salsa"], correctChoiceIndex:0},
    {id:"en_655", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the capital of Australia?", choices:["Sydney","Melbourne","Canberra"], correctChoiceIndex:2},
    {id:"en_656", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the traditional music of Jamaica?", choices:["Reggae","Pop","Rock"], correctChoiceIndex:0},
    {id:"en_657", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the famous festival of Spain?", choices:["Tomatina","Holi","Christmas"], correctChoiceIndex:0},
    {id:"en_658", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the famous monument of India?", choices:["Taj Mahal","Eiffel Tower","Statue of Liberty"], correctChoiceIndex:0},
    {id:"en_659", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the traditional food of Mexico?", choices:["Tacos","Pizza","Burger"], correctChoiceIndex:0},
    {id:"en_660", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the official language of Brazil?", choices:["Portuguese","Spanish","English"], correctChoiceIndex:0},
    {id:"en_661", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the famous dance of Argentina?", choices:["Tango","Ballet","Hip Hop"], correctChoiceIndex:0},
    {id:"en_662", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the famous festival of China?", choices:["Chinese New Year","Easter","Thanksgiving"], correctChoiceIndex:0},
    {id:"en_663", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the famous monument of Egypt?", choices:["Pyramids","Statue of Liberty","Eiffel Tower"], correctChoiceIndex:0},
    {id:"en_664", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the traditional food of Italy?", choices:["Pasta","Burger","Sushi"], correctChoiceIndex:0},
    {id:"en_665", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the official language of Canada?", choices:["English and French","Spanish","German"], correctChoiceIndex:0},
    {id:"en_666", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the famous dance of Spain?", choices:["Flamenco","Ballet","Hip Hop"], correctChoiceIndex:0},
    {id:"en_667", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the famous festival of India?", choices:["Diwali","Easter","Thanksgiving"], correctChoiceIndex:0},
    {id:"en_668", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the famous monument of France?", choices:["Eiffel Tower","Statue of Liberty","Taj Mahal"], correctChoiceIndex:0},
    {id:"en_669", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"What is the traditional food of Japan?", choices:["Sushi","Burger","Pasta"], correctChoiceIndex:0},
    {id:"en_670", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Which is longer, a foot or a meter?", choices:["A foot","A meter","They are the same"], correctChoiceIndex:1},
    {id:"en_671", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Which is heavier, a pound or a kilogram?", choices:["A pound","A kilogram","They are the same"], correctChoiceIndex:1},
    {id:"en_672", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Which is bigger, a liter or a gallon?", choices:["A liter","A gallon","They are the same"], correctChoiceIndex:1},
    {id:"en_673", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Which is longer, an inch or a centimeter?", choices:["An inch","A centimeter","They are the same"], correctChoiceIndex:0},
    {id:"en_674", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Which is heavier, a gram or an ounce?", choices:["A gram","An ounce","They are the same"], correctChoiceIndex:1},
    {id:"en_675", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Which is longer, 1 foot or 1 inch?", choices:["1 foot","1 inch","They are the same"], correctChoiceIndex:0},
    {id:"en_676", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Which is heavier, 1 pound or 1 ounce?", choices:["1 pound","1 ounce","They are the same"], correctChoiceIndex:0},
    {id:"en_677", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Which is more, 1 liter or 1 milliliter?", choices:["1 liter","1 milliliter","They are the same"], correctChoiceIndex:0},
    {id:"en_678", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Which is longer, 1 meter or 1 centimeter?", choices:["1 meter","1 centimeter","They are the same"], correctChoiceIndex:0},
    {id:"en_679", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Which is heavier, 1 kilogram or 1 gram?", choices:["1 kilogram","1 gram","They are the same"], correctChoiceIndex:0},
    {id:"en_680", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many inches are in a foot?", choices:["10","12","14"], correctChoiceIndex:1},
    {id:"en_681", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many centimeters are in a meter?", choices:["100","1000","10"], correctChoiceIndex:0},
    {id:"en_682", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many grams are in a kilogram?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"en_683", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many liters are in a gallon?", choices:["3.78","4.54","2.2"], correctChoiceIndex:0},
    {id:"en_684", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many ounces are in a pound?", choices:["16","12","20"], correctChoiceIndex:0},
    {id:"en_685", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many feet are in a yard?", choices:["3","2","4"], correctChoiceIndex:0},
    {id:"en_686", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many meters are in a kilometer?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"en_687", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many millimeters are in a centimeter?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"en_688", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many pounds are in a ton?", choices:["2000","1000","500"], correctChoiceIndex:0},
    {id:"en_689", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many inches are in a yard?", choices:["36","32","40"], correctChoiceIndex:0},
    {id:"en_690", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many feet are in a mile?", choices:["5280","5000","6000"], correctChoiceIndex:0},
    {id:"en_691", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many yards are in a mile?", choices:["1760","1600","2000"], correctChoiceIndex:0},
    {id:"en_692", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many centimeters are in a kilometer?", choices:["100000","10000","1000"], correctChoiceIndex:0},
    {id:"en_693", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many millimeters are in a meter?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"en_694", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many grams are in a ton?", choices:["1000000","100000","10000"], correctChoiceIndex:0},
    {id:"en_695", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many liters are in a cubic meter?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"en_696", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many ounces are in a gallon?", choices:["128","120","100"], correctChoiceIndex:0},
    {id:"en_697", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many pounds are in a kilogram?", choices:["2.2","2","1.5"], correctChoiceIndex:0},
    {id:"en_698", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many feet are in a kilometer?", choices:["3281","3000","3500"], correctChoiceIndex:0},
    {id:"en_699", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many yards are in a kilometer?", choices:["1094","1000","1200"], correctChoiceIndex:0},
    {id:"en_700", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"If you have 2 cups of water, how many milliliters is that approximately?", choices:["500 milliliters","250 milliliters","1000 milliliters"], correctChoiceIndex:0},
    {id:"en_701", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many inches are there in a foot?", choices:["12 inches","10 inches","15 inches"], correctChoiceIndex:0},
    {id:"en_702", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many centimeters are there in a meter?", choices:["100 centimeters","10 centimeters","1000 centimeters"], correctChoiceIndex:0},
    {id:"en_703", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many grams are there in a kilogram?", choices:["1000 grams","100 grams","10,000 grams"], correctChoiceIndex:0},
    {id:"en_704", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many milliliters are there in a liter?", choices:["1000 milliliters","100 milliliters","10,000 milliliters"], correctChoiceIndex:0},
    {id:"en_705", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many ounces are there in a pound?", choices:["16 ounces","10 ounces","20 ounces"], correctChoiceIndex:0},
    {id:"en_706", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many feet are there in a yard?", choices:["3 feet","2 feet","4 feet"], correctChoiceIndex:0},
    {id:"en_707", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many yards are there in a mile?", choices:["1760 yards","1000 yards","5280 yards"], correctChoiceIndex:0},
    {id:"en_708", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many pounds are there in a ton?", choices:["2000 pounds","1000 pounds","5000 pounds"], correctChoiceIndex:0},
    {id:"en_709", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many liters are there in a gallon?", choices:["3.785 liters","2 liters","4 liters"], correctChoiceIndex:0},
    {id:"en_710", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Which is longer, 1 meter or 100 centimeters?", choices:["1 meter","100 centimeters","Both are the same"], correctChoiceIndex:2},
    {id:"en_711", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Which is heavier, 1 kilogram or 1000 grams?", choices:["1 kilogram","1000 grams","Both are the same"], correctChoiceIndex:2},
    {id:"en_712", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Which is more, 1 liter or 1000 milliliters?", choices:["1 liter","1000 milliliters","Both are the same"], correctChoiceIndex:2},
    {id:"en_713", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Which is shorter, 1 meter or 100 centimeters?", choices:["1 meter","100 centimeters","Both are the same"], correctChoiceIndex:2},
    {id:"en_714", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Which is lighter, 1 kilogram or 1000 grams?", choices:["1 kilogram","1000 grams","Both are the same"], correctChoiceIndex:2},
    {id:"en_715", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Which is less, 1 liter or 1000 milliliters?", choices:["1 liter","1000 milliliters","Both are the same"], correctChoiceIndex:2},
    {id:"en_716", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many centimeters are in 2 meters?", choices:["200 centimeters","20 centimeters","2 centimeters"], correctChoiceIndex:0},
    {id:"en_717", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many grams are in 2 kilograms?", choices:["2000 grams","200 grams","2 grams"], correctChoiceIndex:0},
    {id:"en_718", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many milliliters are in 2 liters?", choices:["2000 milliliters","200 milliliters","2 milliliters"], correctChoiceIndex:0},
    {id:"en_719", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many meters are in 200 centimeters?", choices:["2 meters","20 meters","200 meters"], correctChoiceIndex:0},
    {id:"en_720", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many kilograms are in 2000 grams?", choices:["2 kilograms","20 kilograms","200 kilograms"], correctChoiceIndex:0},
    {id:"en_721", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many liters are in 2000 milliliters?", choices:["2 liters","20 liters","200 liters"], correctChoiceIndex:0},
    {id:"en_722", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many centimeters are in 3 meters?", choices:["300 centimeters","30 centimeters","3 centimeters"], correctChoiceIndex:0},
    {id:"en_723", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many grams are in 3 kilograms?", choices:["3000 grams","300 grams","3 grams"], correctChoiceIndex:0},
    {id:"en_724", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many milliliters are in 3 liters?", choices:["3000 milliliters","300 milliliters","3 milliliters"], correctChoiceIndex:0},
    {id:"en_725", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many meters are in 300 centimeters?", choices:["3 meters","30 meters","300 meters"], correctChoiceIndex:0},
    {id:"en_726", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many kilograms are in 3000 grams?", choices:["3 kilograms","30 kilograms","300 kilograms"], correctChoiceIndex:0},
    {id:"en_727", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many liters are in 3000 milliliters?", choices:["3 liters","30 liters","300 liters"], correctChoiceIndex:0},
    {id:"en_728", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many centimeters are in 4 meters?", choices:["400 centimeters","40 centimeters","4 centimeters"], correctChoiceIndex:0},
    {id:"en_729", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many grams are in 4 kilograms?", choices:["4000 grams","400 grams","4 grams"], correctChoiceIndex:0},
    {id:"en_730", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many milliliters are in 4 liters?", choices:["4000 milliliters","400 milliliters","4 milliliters"], correctChoiceIndex:0},
    {id:"en_731", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many meters are in 400 centimeters?", choices:["4 meters","40 meters","400 meters"], correctChoiceIndex:0},
    {id:"en_732", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many kilograms are in 4000 grams?", choices:["4 kilograms","40 kilograms","400 kilograms"], correctChoiceIndex:0},
    {id:"en_733", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many liters are in 4000 milliliters?", choices:["4 liters","40 liters","400 liters"], correctChoiceIndex:0},
    {id:"en_734", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many centimeters are in 5 meters?", choices:["500 centimeters","50 centimeters","5 centimeters"], correctChoiceIndex:0},
    {id:"en_735", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many grams are in 5 kilograms?", choices:["5000 grams","500 grams","5 grams"], correctChoiceIndex:0},
    {id:"en_736", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many milliliters are in 5 liters?", choices:["5000 milliliters","500 milliliters","5 milliliters"], correctChoiceIndex:0},
    {id:"en_737", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many meters are in 500 centimeters?", choices:["5 meters","50 meters","500 meters"], correctChoiceIndex:0},
    {id:"en_738", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many kilograms are in 5000 grams?", choices:["5 kilograms","50 kilograms","500 kilograms"], correctChoiceIndex:0},
    {id:"en_739", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many liters are in 5000 milliliters?", choices:["5 liters","50 liters","500 liters"], correctChoiceIndex:0},
    {id:"en_740", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many centimeters are in 6 meters?", choices:["600 centimeters","60 centimeters","6 centimeters"], correctChoiceIndex:0},
    {id:"en_741", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many grams are in 6 kilograms?", choices:["6000 grams","600 grams","6 grams"], correctChoiceIndex:0},
    {id:"en_742", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many milliliters are in 6 liters?", choices:["6000 milliliters","600 milliliters","6 milliliters"], correctChoiceIndex:0},
    {id:"en_743", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many meters are in 600 centimeters?", choices:["6 meters","60 meters","600 meters"], correctChoiceIndex:0},
    {id:"en_744", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many kilograms are in 6000 grams?", choices:["6 kilograms","60 kilograms","600 kilograms"], correctChoiceIndex:0},
    {id:"en_745", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many liters are in 6000 milliliters?", choices:["6 liters","60 liters","600 liters"], correctChoiceIndex:0},
    {id:"en_746", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many centimeters are in 7 meters?", choices:["700 centimeters","70 centimeters","7 centimeters"], correctChoiceIndex:0},
    {id:"en_747", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many grams are in 7 kilograms?", choices:["7000 grams","700 grams","7 grams"], correctChoiceIndex:0},
    {id:"en_748", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many milliliters are in 7 liters?", choices:["7000 milliliters","700 milliliters","7 milliliters"], correctChoiceIndex:0},
    {id:"en_749", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many meters are in 700 centimeters?", choices:["7 meters","70 meters","700 meters"], correctChoiceIndex:0},
    {id:"en_750", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many kilograms are in 7000 grams?", choices:["7 kilograms","70 kilograms","700 kilograms"], correctChoiceIndex:0},
    {id:"en_751", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many liters are in 7000 milliliters?", choices:["7 liters","70 liters","700 liters"], correctChoiceIndex:0},
    {id:"en_752", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 1+1?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"en_753", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 2+2?", choices:["3","4","5"], correctChoiceIndex:1},
    {id:"en_754", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 3+3?", choices:["5","6","7"], correctChoiceIndex:1},
    {id:"en_755", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 4+4?", choices:["7","8","9"], correctChoiceIndex:1},
    {id:"en_756", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 5+5?", choices:["9","10","11"], correctChoiceIndex:1},
    {id:"en_757", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 2-1?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"en_758", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 3-2?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"en_759", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 4-3?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"en_760", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 5-4?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"en_761", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 6-5?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"en_762", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 1+1?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"en_763", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 2+2?", choices:["3","4","5"], correctChoiceIndex:1},
    {id:"en_764", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 3+3?", choices:["5","6","7"], correctChoiceIndex:1},
    {id:"en_765", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 4+4?", choices:["7","8","9"], correctChoiceIndex:1},
    {id:"en_766", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 5+5?", choices:["9","10","11"], correctChoiceIndex:1},
    {id:"en_767", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 1+2?", choices:["2","3","4"], correctChoiceIndex:1},
    {id:"en_768", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 2+3?", choices:["4","5","6"], correctChoiceIndex:1},
    {id:"en_769", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 3+4?", choices:["6","7","8"], correctChoiceIndex:1},
    {id:"en_770", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 4+5?", choices:["8","9","10"], correctChoiceIndex:1},
    {id:"en_771", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is 5+6?", choices:["10","11","12"], correctChoiceIndex:1},
    {id:"en_772", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 6+6?", choices:["11","12","13"], correctChoiceIndex:1},
    {id:"en_773", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 7+7?", choices:["13","14","15"], correctChoiceIndex:1},
    {id:"en_774", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 8+8?", choices:["15","16","17"], correctChoiceIndex:1},
    {id:"en_775", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 9+9?", choices:["17","18","19"], correctChoiceIndex:1},
    {id:"en_776", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 10+10?", choices:["19","20","21"], correctChoiceIndex:1},
    {id:"en_777", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 7-6?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"en_778", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 8-7?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"en_779", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 9-8?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"en_780", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 10-9?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"en_781", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 11-10?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"en_782", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 6+7?", choices:["12","13","14"], correctChoiceIndex:0},
    {id:"en_783", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 7+8?", choices:["14","15","16"], correctChoiceIndex:0},
    {id:"en_784", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 8+9?", choices:["16","17","18"], correctChoiceIndex:0},
    {id:"en_785", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 9+10?", choices:["18","19","20"], correctChoiceIndex:0},
    {id:"en_786", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 10+11?", choices:["20","21","22"], correctChoiceIndex:0},
    {id:"en_787", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 10-1?", choices:["8","9","10"], correctChoiceIndex:1},
    {id:"en_788", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 9-2?", choices:["6","7","8"], correctChoiceIndex:1},
    {id:"en_789", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 8-3?", choices:["4","5","6"], correctChoiceIndex:1},
    {id:"en_790", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 7-4?", choices:["2","3","4"], correctChoiceIndex:1},
    {id:"en_791", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 6-5?", choices:["0","1","2"], correctChoiceIndex:0},
    {id:"en_792", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 10-2?", choices:["7","8","9"], correctChoiceIndex:0},
    {id:"en_793", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 9-3?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"en_794", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 8-4?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"en_795", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 7-5?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"en_796", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 6-6?", choices:["0","1","2"], correctChoiceIndex:0},
    {id:"en_797", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 10+10?", choices:["19","20","21"], correctChoiceIndex:1},
    {id:"en_798", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 9+9?", choices:["17","18","19"], correctChoiceIndex:1},
    {id:"en_799", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 8+8?", choices:["15","16","17"], correctChoiceIndex:1},
    {id:"en_800", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 7+7?", choices:["13","14","15"], correctChoiceIndex:1},
    {id:"en_801", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is 6+6?", choices:["11","12","13"], correctChoiceIndex:1},
    {id:"en_802", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"If you have 2 apples and you eat 1, how many are left?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"en_803", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"You have 3 candies and your friend gives you 2 more. How many candies do you have now?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"en_804", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"If there are 4 birds on a tree and 1 flies away, how many birds are left on the tree?", choices:["3","2","1"], correctChoiceIndex:0},
    {id:"en_805", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"You have 5 cookies and you give 2 to your friend. How many cookies do you have left?", choices:["3","2","1"], correctChoiceIndex:0},
    {id:"en_806", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"If you have 3 balloons and you pop 1, how many balloons are left?", choices:["2","1","0"], correctChoiceIndex:0},
    {id:"en_807", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"You have 4 pencils and you lose 1. How many pencils do you have left?", choices:["3","2","1"], correctChoiceIndex:0},
    {id:"en_808", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"If you have 3 teddy bears and your friend gives you 1 more, how many teddy bears do you have now?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"en_809", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"There are 5 dogs in a park. 1 dog leaves. How many dogs are left in the park?", choices:["4","3","2"], correctChoiceIndex:0},
    {id:"en_810", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"You have 6 candies and you eat 2. How many candies do you have left?", choices:["4","3","2"], correctChoiceIndex:0},
    {id:"en_811", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"If you have 2 balls and you find 1 more, how many balls do you have now?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"en_812", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"You have 3 cupcakes and you eat 1. How many cupcakes do you have left?", choices:["2","1","0"], correctChoiceIndex:0},
    {id:"en_813", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"If you have 2 dolls and your friend gives you 2 more, how many dolls do you have now?", choices:["4","3","2"], correctChoiceIndex:0},
    {id:"en_814", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"There are 6 butterflies. 1 butterfly flies away. How many butterflies are left?", choices:["5","4","3"], correctChoiceIndex:0},
    {id:"en_815", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"You have 7 crayons and you lose 2. How many crayons do you have left?", choices:["5","4","3"], correctChoiceIndex:0},
    {id:"en_816", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"If you have 4 blocks and you add 1 more, how many blocks do you have now?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"en_817", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"If you have 2 apples and you eat 1, how many are left?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"en_818", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"You have 5 candies and your friend gives you 2 more. How many candies do you have now?", choices:["7","8","9"], correctChoiceIndex:0},
    {id:"en_819", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"If there are 3 birds on a tree and 1 flies away, how many birds are left?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"en_820", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"You have 4 balloons and you lose 1. How many balloons do you have left?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"en_821", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"If you have 3 cookies and your friend gives you 1 more, how many cookies do you have?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"en_822", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"If you have 3 teddy bears and your friend gives you 2 more, how many teddy bears do you have?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"en_823", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"You have 4 toy cars and your friend gives you 1 more. How many toy cars do you have now?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"en_824", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"If there are 5 ducks in a pond and 2 swim away, how many ducks are left?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"en_825", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"You have 6 crayons and you lose 1. How many crayons do you have left?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"en_826", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"If you have 2 ice creams and your friend gives you 1 more, how many ice creams do you have?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"en_827", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"If you have 4 lollipops and you eat 1, how many lollipops are left?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"en_828", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"You have 3 toy trains and your friend gives you 2 more. How many toy trains do you have now?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"en_829", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"If there are 6 frogs and 2 jump away, how many frogs are left?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"en_830", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"You have 5 toy blocks and you lose 1. How many toy blocks do you have left?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"en_831", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"If you have 2 toy planes and your friend gives you 2 more, how many toy planes do you have?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"en_832", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"If you have 7 marbles and your friend gives you 3 more, how many marbles do you have now?", choices:["10","9","8"], correctChoiceIndex:0},
    {id:"en_833", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"There are 8 ducks in a pond. 3 ducks swim away. How many ducks are left in the pond?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"en_834", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"You have 10 stickers. You give 4 to your friend. How many stickers do you have left?", choices:["6","5","4"], correctChoiceIndex:0},
    {id:"en_835", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"If you have 6 books and you buy 2 more, how many books do you have now?", choices:["8","7","6"], correctChoiceIndex:0},
    {id:"en_836", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"There are 9 cats. 2 cats run away. How many cats are left?", choices:["7","6","5"], correctChoiceIndex:0},
    {id:"en_837", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"You have 8 toys. You give 3 to your friend. How many toys do you have left?", choices:["5","4","3"], correctChoiceIndex:0},
    {id:"en_838", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"There are 7 rabbits in a field. 2 rabbits hop away. How many rabbits are left in the field?", choices:["5","4","3"], correctChoiceIndex:0},
    {id:"en_839", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"If you have 5 cars and you buy 3 more, how many cars do you have now?", choices:["8","7","6"], correctChoiceIndex:0},
    {id:"en_840", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"You have 9 coins. You spend 4. How many coins do you have left?", choices:["5","4","3"], correctChoiceIndex:0},
    {id:"en_841", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"There are 10 fish in a tank. 3 fish swim away. How many fish are left in the tank?", choices:["7","6","5"], correctChoiceIndex:0},
    {id:"en_842", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"If you have 7 pencils and you give 2 to your friend, how many pencils do you have left?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"en_843", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"You have 8 books and your friend gives you 3 more. How many books do you have now?", choices:["11","12","13"], correctChoiceIndex:0},
    {id:"en_844", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"If there are 10 cats and 3 run away, how many cats are left?", choices:["7","8","9"], correctChoiceIndex:0},
    {id:"en_845", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"You have 6 marbles and you lose 2. How many marbles do you have left?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"en_846", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"If you have 9 stickers and your friend gives you 2 more, how many stickers do you have?", choices:["11","12","13"], correctChoiceIndex:0},
    {id:"en_847", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"If you have 8 candies and you eat 3, how many candies do you have left?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"en_848", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"You have 7 dolls and your friend gives you 2 more. How many dolls do you have now?", choices:["9","10","11"], correctChoiceIndex:0},
    {id:"en_849", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"If there are 9 dogs and 4 run away, how many dogs are left?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"en_850", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"You have 10 coins and you lose 3. How many coins do you have left?", choices:["7","8","9"], correctChoiceIndex:0},
    {id:"en_851", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"If you have 6 cupcakes and your friend gives you 3 more, how many cupcakes do you have?", choices:["9","10","11"], correctChoiceIndex:0},
    {id:"en_852", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What shape is a stop sign?", choices:["Circle","Octagon","Triangle"], correctChoiceIndex:1},
    {id:"en_853", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What shape is a football?", choices:["Circle","Rectangle","Oval"], correctChoiceIndex:2},
    {id:"en_854", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What shape is a slice of pizza?", choices:["Triangle","Circle","Square"], correctChoiceIndex:0},
    {id:"en_855", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What shape is a wheel?", choices:["Circle","Square","Rectangle"], correctChoiceIndex:0},
    {id:"en_856", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What shape is a book?", choices:["Rectangle","Circle","Triangle"], correctChoiceIndex:0},
    {id:"en_857", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What shape is a dice?", choices:["Cube","Sphere","Cylinder"], correctChoiceIndex:0},
    {id:"en_858", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What shape is a pyramid?", choices:["Triangle","Square","Circle"], correctChoiceIndex:0},
    {id:"en_859", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What shape is a door?", choices:["Rectangle","Circle","Triangle"], correctChoiceIndex:0},
    {id:"en_860", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What shape is a coin?", choices:["Circle","Square","Rectangle"], correctChoiceIndex:0},
    {id:"en_861", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What shape is a box?", choices:["Cube","Sphere","Cylinder"], correctChoiceIndex:0},
    {id:"en_862", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many sides does a triangle have?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"en_863", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many sides does a square have?", choices:["3","4","5"], correctChoiceIndex:1},
    {id:"en_864", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many sides does a pentagon have?", choices:["3","5","6"], correctChoiceIndex:1},
    {id:"en_865", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many sides does a hexagon have?", choices:["4","6","8"], correctChoiceIndex:1},
    {id:"en_866", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many sides does an octagon have?", choices:["6","8","10"], correctChoiceIndex:1},
    {id:"en_867", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many corners does a rectangle have?", choices:["2","4","6"], correctChoiceIndex:1},
    {id:"en_868", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many corners does a triangle have?", choices:["2","3","4"], correctChoiceIndex:1},
    {id:"en_869", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many corners does a pentagon have?", choices:["3","5","7"], correctChoiceIndex:1},
    {id:"en_870", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many corners does a hexagon have?", choices:["4","6","8"], correctChoiceIndex:1},
    {id:"en_871", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many corners does an octagon have?", choices:["6","8","10"], correctChoiceIndex:1},
    {id:"en_872", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many faces does a cube have?", choices:["4","6","8"], correctChoiceIndex:1},
    {id:"en_873", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many faces does a pyramid have?", choices:["3","4","5"], correctChoiceIndex:1},
    {id:"en_874", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many edges does a cube have?", choices:["8","12","16"], correctChoiceIndex:1},
    {id:"en_875", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many edges does a pyramid have?", choices:["4","8","12"], correctChoiceIndex:1},
    {id:"en_876", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many vertices does a cube have?", choices:["4","8","12"], correctChoiceIndex:1},
    {id:"en_877", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What time does the clock show when both hands point to 12?", choices:["12:00","1:00","3:00"], correctChoiceIndex:0},
    {id:"en_878", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"How many hours are there in a day?", choices:["24","12","48"], correctChoiceIndex:0},
    {id:"en_879", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"How many minutes are there in an hour?", choices:["60","30","120"], correctChoiceIndex:0},
    {id:"en_880", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What comes after Monday?", choices:["Tuesday","Wednesday","Sunday"], correctChoiceIndex:0},
    {id:"en_881", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is the first month of the year?", choices:["January","February","March"], correctChoiceIndex:0},
    {id:"en_882", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is the last month of the year?", choices:["December","November","October"], correctChoiceIndex:0},
    {id:"en_883", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"How many days are there in a week?", choices:["7","5","10"], correctChoiceIndex:0},
    {id:"en_884", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is the 7th month of the year?", choices:["July","June","August"], correctChoiceIndex:0},
    {id:"en_885", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is the 12th month of the year?", choices:["December","November","October"], correctChoiceIndex:0},
    {id:"en_886", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"What is the 3rd day of the week?", choices:["Tuesday","Wednesday","Thursday"], correctChoiceIndex:1},
    {id:"en_887", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"If it is 2:00 now, what time will it be in 3 hours?", choices:["5:00","3:00","6:00"], correctChoiceIndex:0},
    {id:"en_888", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"If it is 10:00 now, what time was it 2 hours ago?", choices:["8:00","12:00","9:00"], correctChoiceIndex:0},
    {id:"en_889", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many seconds are there in a minute?", choices:["60","30","120"], correctChoiceIndex:0},
    {id:"en_890", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is the 4th month of the year?", choices:["April","March","May"], correctChoiceIndex:0},
    {id:"en_891", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is the 9th month of the year?", choices:["September","October","August"], correctChoiceIndex:0},
    {id:"en_892", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is the 5th day of the week?", choices:["Friday","Thursday","Saturday"], correctChoiceIndex:0},
    {id:"en_893", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"If it is 7:00 now, what time will it be in 4 hours?", choices:["11:00","8:00","12:00"], correctChoiceIndex:0},
    {id:"en_894", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"If it is 5:00 now, what time was it 3 hours ago?", choices:["2:00","3:00","4:00"], correctChoiceIndex:0},
    {id:"en_895", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many days are there in February in a leap year?", choices:["29","28","30"], correctChoiceIndex:0},
    {id:"en_896", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is the 6th month of the year?", choices:["June","July","May"], correctChoiceIndex:0},
    {id:"en_897", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is the 10th month of the year?", choices:["October","September","November"], correctChoiceIndex:0},
    {id:"en_898", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"What is the 2nd day of the week?", choices:["Tuesday","Monday","Wednesday"], correctChoiceIndex:0},
    {id:"en_899", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"If it is 9:00 now, what time will it be in 5 hours?", choices:["2:00","3:00","1:00"], correctChoiceIndex:0},
    {id:"en_900", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"If it is 6:00 now, what time was it 4 hours ago?", choices:["2:00","3:00","1:00"], correctChoiceIndex:0},
    {id:"en_901", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"How many days are there in April?", choices:["30","31","28"], correctChoiceIndex:0},
    {id:"en_902", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What should we do every day to stay healthy?", choices:["Eat candies","Play video games","Exercise"], correctChoiceIndex:2},
    {id:"en_903", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Why do we need to wash our hands?", choices:["To make bubbles","To keep them clean and germ-free","Because mom said so"], correctChoiceIndex:1},
    {id:"en_904", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What happens when we exercise?", choices:["We get tired","Our heart beats faster","We get hungry"], correctChoiceIndex:1},
    {id:"en_905", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What should we do after playing outside?", choices:["Eat a snack","Wash our hands","Watch TV"], correctChoiceIndex:1},
    {id:"en_906", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Why is sleep important?", choices:["So we can dream","So we can rest and grow","So we can stay up late"], correctChoiceIndex:1},
    {id:"en_907", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"How many hours of sleep should a child get each night?", choices:["5 hours","8-10 hours","12 hours"], correctChoiceIndex:1},
    {id:"en_908", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Why is drinking water important?", choices:["It keeps us hydrated","It makes us full","It makes us tall"], correctChoiceIndex:0},
    {id:"en_909", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is a healthy snack?", choices:["Chips","Candy","Fruits"], correctChoiceIndex:2},
    {id:"en_910", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What does exercise do to our bodies?", choices:["Makes us tired","Makes us strong and healthy","Makes us sleepy"], correctChoiceIndex:1},
    {id:"en_911", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Why should we brush our teeth every day?", choices:["To make them shiny","To prevent cavities and keep them healthy","To make them white"], correctChoiceIndex:1},
    {id:"en_912", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the best thing to do when you sneeze or cough?", choices:["Cover your mouth and nose with a tissue or your elbow","Sneeze or cough into your hands","Sneeze or cough into the air"], correctChoiceIndex:0},
    {id:"en_913", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What should you do before eating?", choices:["Play a game","Wash your hands","Watch TV"], correctChoiceIndex:1},
    {id:"en_914", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Why is it important to eat vegetables?", choices:["Because they taste good","Because they make you strong and healthy","Because they are colorful"], correctChoiceIndex:1},
    {id:"en_915", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What should you do after using the bathroom?", choices:["Wash your hands","Play a game","Watch TV"], correctChoiceIndex:0},
    {id:"en_916", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is a good habit to have?", choices:["Eating junk food","Watching TV all day","Exercising regularly"], correctChoiceIndex:2},
    {id:"en_917", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What do we use to swim in the water?", choices:["Hands and Feet","Books","Pencils"], correctChoiceIndex:0},
    {id:"en_918", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What do we wear on our feet when we do gymnastics?", choices:["Shoes","Socks","Barefoot"], correctChoiceIndex:2},
    {id:"en_919", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What do we need to breathe under water while swimming?", choices:["Goggles","Snorkel","Flippers"], correctChoiceIndex:1},
    {id:"en_920", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What do we wear on our eyes in the swimming pool?", choices:["Goggles","Gloves","Boots"], correctChoiceIndex:0},
    {id:"en_921", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What do we do before and after swimming?", choices:["Eat","Sleep","Stretch"], correctChoiceIndex:2},
    {id:"en_922", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is a type of jump in gymnastics called?", choices:["Somersault","Hopscotch","Jumping Jack"], correctChoiceIndex:0},
    {id:"en_923", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the long piece of equipment used in gymnastics called?", choices:["Beam","Ball","Net"], correctChoiceIndex:0},
    {id:"en_924", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the name of the stroke where you lie on your back in swimming?", choices:["Backstroke","Butterfly","Breaststroke"], correctChoiceIndex:0},
    {id:"en_925", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the name of the stroke where you move like a frog in swimming?", choices:["Backstroke","Butterfly","Breaststroke"], correctChoiceIndex:2},
    {id:"en_926", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the name of the stroke where you move your arms like a butterfly in swimming?", choices:["Backstroke","Butterfly","Breaststroke"], correctChoiceIndex:1},
    {id:"en_927", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the name of the gymnastics move where you spin around a bar?", choices:["Cartwheel","Flip","Swing"], correctChoiceIndex:2},
    {id:"en_928", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the name of the gymnastics move where you stand on your hands?", choices:["Handstand","Headstand","Cartwheel"], correctChoiceIndex:0},
    {id:"en_929", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the name of the gymnastics move where you roll forward on the ground?", choices:["Somersault","Cartwheel","Flip"], correctChoiceIndex:0},
    {id:"en_930", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the name of the gymnastics move where you spin around on one foot?", choices:["Pirouette","Flip","Cartwheel"], correctChoiceIndex:0},
    {id:"en_931", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the name of the gymnastics move where you jump over a vault?", choices:["Vault","Flip","Cartwheel"], correctChoiceIndex:0},
    {id:"en_932", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What should you do if you see trash while hiking?", choices:["Pick it up and throw it away","Leave it there","Play with it"], correctChoiceIndex:0},
    {id:"en_933", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What is hiking?", choices:["Walking in nature","Running in a race","Swimming in a pool"], correctChoiceIndex:0},
    {id:"en_934", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What should you wear for a hike?", choices:["Flip flops","Hiking boots","Ice skates"], correctChoiceIndex:1},
    {id:"en_935", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What should you bring when you go hiking?", choices:["A television","A snack and water","A pillow"], correctChoiceIndex:1},
    {id:"en_936", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Why should we respect the environment?", choices:["Because it's fun","Because it's our home","Because it's boring"], correctChoiceIndex:1},
    {id:"en_937", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is one way to respect the environment while hiking?", choices:["By leaving no trace","By carving your name into a tree","By leaving your trash behind"], correctChoiceIndex:0},
    {id:"en_938", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Why is it important to stay on the trail while hiking?", choices:["To protect plants and animals","To make the hike more difficult","To get lost"], correctChoiceIndex:0},
    {id:"en_939", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What can you use to observe nature closely?", choices:["A telescope","A microscope","Binoculars"], correctChoiceIndex:2},
    {id:"en_940", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What should you do if you encounter a wild animal while hiking?", choices:["Try to pet it","Run away","Observe it from a distance"], correctChoiceIndex:2},
    {id:"en_941", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the best way to observe birds in nature?", choices:["Chase them","Use binoculars","Shout at them"], correctChoiceIndex:1},
    {id:"en_942", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Why is it important to carry a map while hiking?", choices:["To use as a fan","To know the route","To draw on it"], correctChoiceIndex:1},
    {id:"en_943", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What should you do if you get lost while hiking?", choices:["Panic","Wait for someone to find you","Stay where you are and call for help"], correctChoiceIndex:2},
    {id:"en_944", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the Leave No Trace principle?", choices:["Leave nothing but footprints, take nothing but pictures","Leave all your trash behind","Take all the flowers you can find"], correctChoiceIndex:0},
    {id:"en_945", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What should you do if you find an injured animal while hiking?", choices:["Try to help it yourself","Leave it alone","Inform a park ranger or adult"], correctChoiceIndex:2},
    {id:"en_946", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Why should we not feed wild animals?", choices:["Because they might bite","Because it can harm their health and change their behavior","Because they don't like human food"], correctChoiceIndex:1},
    {id:"en_947", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What do you need to play tag?", choices:["A ball","A tag","Friends"], correctChoiceIndex:2},
    {id:"en_948", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the main rule in hide-and-seek?", choices:["You have to run fast","You have to hide","You have to swim"], correctChoiceIndex:1},
    {id:"en_949", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What does teamwork mean?", choices:["Playing alone","Playing with a team","Playing with a toy"], correctChoiceIndex:1},
    {id:"en_950", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What should you do if you're 'it' in tag?", choices:["Hide","Chase others","Stand still"], correctChoiceIndex:1},
    {id:"en_951", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the goal in hide-and-seek?", choices:["To find others","To run fast","To jump high"], correctChoiceIndex:0},
    {id:"en_952", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Why is teamwork important in games?", choices:["To win alone","To make the game more fun","To play with toys"], correctChoiceIndex:1},
    {id:"en_953", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What happens if you're tagged in tag?", choices:["You're 'it'","You win","You lose"], correctChoiceIndex:0},
    {id:"en_954", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What should you do if you're found in hide-and-seek?", choices:["Run away","Tag the seeker","Hide again"], correctChoiceIndex:0},
    {id:"en_955", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is a good strategy in hide-and-seek?", choices:["Hide in a visible place","Hide in a hard-to-find place","Don't hide"], correctChoiceIndex:1},
    {id:"en_956", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What does 'it' mean in tag?", choices:["You have to chase others","You have to hide","You have to stand still"], correctChoiceIndex:0},
    {id:"en_957", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the best way to avoid being 'it' in tag?", choices:["Run fast","Hide","Stand still"], correctChoiceIndex:0},
    {id:"en_958", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What should you do if you're not 'it' in tag?", choices:["Chase others","Hide","Avoid being tagged"], correctChoiceIndex:2},
    {id:"en_959", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the main goal in tag?", choices:["To tag others","To hide","To run fast"], correctChoiceIndex:0},
    {id:"en_960", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the best strategy in tag?", choices:["To run slow","To stand still","To run fast and avoid being tagged"], correctChoiceIndex:2},
    {id:"en_961", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the main rule in tag?", choices:["To hide","To tag others if you're 'it'","To stand still"], correctChoiceIndex:1},
    {id:"en_962", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What shape is a soccer ball?", choices:["Square","Circle","Triangle"], correctChoiceIndex:1},
    {id:"en_963", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"How many players are on a basketball team?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"en_964", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What color is a basketball?", choices:["Blue","Orange","Green"], correctChoiceIndex:1},
    {id:"en_965", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What do we use to play soccer?", choices:["Bat","Ball","Racket"], correctChoiceIndex:1},
    {id:"en_966", language:QuestionLanguage.EN, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"What do we wear on our feet to play soccer?", choices:["Gloves","Boots","Hats"], correctChoiceIndex:1},
    {id:"en_967", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"How many players are on a soccer team?", choices:["11","10","12"], correctChoiceIndex:0},
    {id:"en_968", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the purpose of a goalkeeper in soccer?", choices:["To score goals","To prevent goals","To cheer the team"], correctChoiceIndex:1},
    {id:"en_969", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is a dribble in basketball?", choices:["Kicking the ball","Running with the ball while bouncing it","Throwing the ball"], correctChoiceIndex:1},
    {id:"en_970", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is a foul in soccer?", choices:["A good play","A mistake that breaks the rules","A type of goal"], correctChoiceIndex:1},
    {id:"en_971", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the name of the area where the goalkeeper protects the goal in soccer?", choices:["Penalty area","Dribbling area","Shooting area"], correctChoiceIndex:0},
    {id:"en_972", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is a free throw in basketball?", choices:["A type of dribble","A shot taken from the free-throw line","A type of pass"], correctChoiceIndex:1},
    {id:"en_973", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is a penalty in soccer?", choices:["A reward for good play","A punishment for a foul in the penalty area","A type of goal"], correctChoiceIndex:1},
    {id:"en_974", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the name of the line that runs across the middle of a soccer field?", choices:["Halfway line","End line","Side line"], correctChoiceIndex:0},
    {id:"en_975", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the name of the circle in the center of a basketball court?", choices:["Center circle","Dribbling circle","Shooting circle"], correctChoiceIndex:0},
    {id:"en_976", language:QuestionLanguage.EN, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"What is the name of the area where players sit when they are not playing in a basketball game?", choices:["Bench","Stand","Locker room"], correctChoiceIndex:0},
]


