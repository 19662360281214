import Dropdown from "react-bootstrap/Dropdown";
import React, {useState} from "react";
import {ToggleButton} from "react-bootstrap";
import {useTranslation} from "react-i18next";


interface GenericDropdownProps<T> {
    options: T[]
    selectedOption: T
    onSelect: (selected: T) => void
    labelKey: (option: T) => string
    variant:string
    className:string
}

export function GenericDropdown<T>({variant, className, options, selectedOption, onSelect, labelKey}: GenericDropdownProps<T>) {
    const handleSelect = (value: any) => {
        const selected = options.find(option => labelKey(option) === value)
        if (selected) {
            onSelect(selected)
        }
    }

    return (
        <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle variant={variant} className={className} id="genericDropdown">
                {labelKey(selectedOption)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {options.map(option => (
                    <Dropdown.Item key={labelKey(option)} eventKey={labelKey(option)}>
                        {labelKey(option)}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}


interface ToggleButtonProps<T> {
    value: T
    label: string
    selectedItems: Set<T>
    onToggle: (value: T, selected: boolean) => void
    variant:string
    className:string
}

export function GenericToggleButton<T>({variant, className, value, label, selectedItems, onToggle}: ToggleButtonProps<T>) {
    const { t } = useTranslation(['home']);
    const [selected, setSelected] = useState(selectedItems.has(value))

    const handleClick = () => {
        const newSelected = !selected
        onToggle(value, newSelected)
        setSelected(newSelected)
    }

    return (
        <ToggleButton
            type="checkbox"
            variant={variant}
            className={selected ? className+"-checked" : className}
            style={{margin: "5px"}}
            id={String(value)}
            value={String(value)}
            onClick={handleClick}
            checked={selected}
        >
            {t(label)}
        </ToggleButton>
    )
}