import React, {useEffect, useState} from "react";
import {Question, QuestionAge, QuestionCategory, QuestionFilter, QuestionLanguage} from "../data/model";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import {QuestionFilterView} from "./QuestionFilterView";
import {QuestionListView} from "./QuestionListView";
import {GenericDropdown} from "../common/Components";
import {useTranslation} from "react-i18next";
import {shuffle} from "../data/utils";
import {FRENCH_QUESTIONS} from "../data/questions_fr";
import {DEUTSCH_QUESTIONS} from "../data/questions_de";
import {SLOVAK_QUESTIONS} from "../data/questions_sk";
import {ENGLISH_QUESTIONS} from "../data/questions_en";
import {Helmet} from "react-helmet";
import kidsquizioLogo from "../art/kidsquizio.png";
import arrowDownSvg from "../art/arrowDown.svg";

const QUESTION_FILTER_STORAGE_KEY = 'questionFilter';

const DEFAULT_QUESTION_FILTER = new QuestionFilter(
    QuestionLanguage.EN,
    new Set<QuestionAge>([QuestionAge.age_4_6, QuestionAge.age_6_8]),
    new Set<QuestionCategory>([
        QuestionCategory.nature,
        QuestionCategory.science,
        QuestionCategory.mathematics,
        QuestionCategory.everyDayLife,
        QuestionCategory.technology,
        QuestionCategory.artAndCulture,
        QuestionCategory.sportAndPhysicalEducation
    ])
)

interface QuizAppProps{
    selectedLanguage:QuestionLanguage
}

export function QuizApp({selectedLanguage}:QuizAppProps) {
    // init translation
    const { t, i18n } = useTranslation(['home']);
    if(i18n.language !== selectedLanguage){
        i18n.changeLanguage(selectedLanguage)
    }

    const initialQuestionFilter = loadQuestionFilter(selectedLanguage)
    const [questionFilter, setQuestionFilter] = useState(initialQuestionFilter)
    useEffect(() => {
        persistQuestionFilter(questionFilter)
    }, [questionFilter]);

    const allQuestions = selectAllQuestionsForLanguage(selectedLanguage)
    const questionPool = shuffle(allQuestions.filter(q => questionFilter.matches(q)))

    const onToggleLanguage = (selectedLanguage: QuestionLanguage) => {
        window.location.href = `/${selectedLanguage}`
    }

    const labelLanguageKey = (key: QuestionLanguage): string => {
        if(key === QuestionLanguage.FR) return "Francais"
        if(key === QuestionLanguage.EN) return "English"
        if(key === QuestionLanguage.DE) return "Deutsch"
        if(key === QuestionLanguage.SK) return "Slovak"
        else return key
    }
    const pageCanonicalUrl = "https://www.kidsquiz.io/" + selectedLanguage
    return <>
        <Helmet>
            <html lang={selectedLanguage}/>
            <meta name="description" content={t("head.meta.description")} />
            <title>{t("head.title")}</title>
            <meta name="og:title" content={t("head.title")} />
            <meta name="og:description" content={t("head.meta.description")} />
            <meta name="og:url" content="https://www.kidsquiz.io" />
            <meta name="og:type" content="website" />
            <meta name="og:image" content="https://kidsquiz.io/path-to-your-image.jpg" />
            <meta name="og:image:alt" content="A colorful and engaging banner representing a variety of trivia categories for kids" />
            <meta name="og:site_name" content="KidsQuiz.io" />
            <meta name="og:locale" content="en_US" />
            <meta name="og:locale:alternate" content="de_DE" />
            <meta name="og:locale:alternate" content="fr_FR" />
            <meta name="og:locale:alternate" content="sk_SK" />
            <link rel="alternate" href="https://www.kidsquiz.io" hrefLang="en" />
            <link rel="alternate" href="https://www.kidsquiz.io/en" hrefLang="en" />
            <link rel="alternate" href="https://www.kidsquiz.io/de" hrefLang="de" />
            <link rel="alternate" href="https://www.kidsquiz.io/fr" hrefLang="fr" />
            <link rel="alternate" href="https://www.kidsquiz.io/sk" hrefLang="sk" />
            <link rel="canonical" href={pageCanonicalUrl} />
        </Helmet>
        <Container>
            <Navbar className="custom-navbar">
                <Navbar.Brand href="/">
                    <img src={kidsquizioLogo} alt="logo" width="237" height="63"/>
                </Navbar.Brand>
                <GenericDropdown
                    variant="outline-dark"
                    className="custom-button"
                    options={Object.values(QuestionLanguage)}
                    selectedOption={selectedLanguage}
                    onSelect={onToggleLanguage}
                    labelKey={labelLanguageKey}
                />
            </Navbar>
            <br/>

            <div className="px-4 py-5 my-5 text-center">
                    <h1 className="display-5 fw-bold text-body-emphasis">{t("hero.title")}</h1>
                    <div className="col-lg-8 mx-auto">
                        <p className="lead mb-4">{t("hero.description")}</p>
                        <p><b>{t("hero.facts")}</b></p>

                    </div>
                    <img src={arrowDownSvg} alt="logo" width="240" height="64"/>
            </div>

            <QuestionFilterView questionFilter={questionFilter} setQuestionFilter={setQuestionFilter}/>
            <br/>
            <QuestionListView questionPool={questionPool}/>
            <br/>
            <footer className="py-3 my-4">
                <p className="text-center">&copy; 2024 kidsquiz.io</p>
            </footer>
        </Container>
    </>
}


function loadQuestionFilter(language:QuestionLanguage): QuestionFilter {
    const storedQuestionFilter = localStorage.getItem(QUESTION_FILTER_STORAGE_KEY);
    let questionFilter =  storedQuestionFilter ? QuestionFilter.fromJSON(storedQuestionFilter) : DEFAULT_QUESTION_FILTER
    questionFilter.selectedLanguage = language
    return questionFilter
}

function persistQuestionFilter(questionFilter: QuestionFilter) {
    localStorage.setItem(QUESTION_FILTER_STORAGE_KEY, QuestionFilter.toJSON(questionFilter));
}

function selectAllQuestionsForLanguage(language: QuestionLanguage): Question[] {
    if(language == QuestionLanguage.EN) return ENGLISH_QUESTIONS
    if(language == QuestionLanguage.FR) return FRENCH_QUESTIONS
    if(language == QuestionLanguage.DE) return DEUTSCH_QUESTIONS
    if(language == QuestionLanguage.SK) return SLOVAK_QUESTIONS
    return []
}