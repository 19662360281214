import {Question, QuestionLanguage, QuestionAge, QuestionCategory} from "./model";


export const DEUTSCH_QUESTIONS: Question[] = [
    {id:"de_0", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Wo lebt ein Löwe?", choices:["In der Wüste","Im Dschungel","In der Savanne"], correctChoiceIndex:2},
    {id:"de_1", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was frisst ein Kaninchen?", choices:["Fleisch","Gemüse","Fisch"], correctChoiceIndex:1},
    {id:"de_2", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Wo lebt ein Pinguin?", choices:["In der Wüste","In der Antarktis","Im Dschungel"], correctChoiceIndex:1},
    {id:"de_3", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was frisst ein Bär?", choices:["Fisch","Gemüse","Beides"], correctChoiceIndex:2},
    {id:"de_4", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Wo lebt ein Kamel?", choices:["In der Wüste","Im Dschungel","In der Savanne"], correctChoiceIndex:0},
    {id:"de_5", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was frisst ein Frosch?", choices:["Insekten","Gemüse","Fisch"], correctChoiceIndex:0},
    {id:"de_6", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Wo lebt ein Elefant?", choices:["In der Wüste","Im Dschungel","In der Savanne"], correctChoiceIndex:2},
    {id:"de_7", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was frisst ein Vogel?", choices:["Insekten","Gemüse","Fisch"], correctChoiceIndex:0},
    {id:"de_8", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Wo lebt ein Affe?", choices:["In der Wüste","Im Dschungel","In der Savanne"], correctChoiceIndex:1},
    {id:"de_9", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was frisst ein Hund?", choices:["Fleisch","Gemüse","Beides"], correctChoiceIndex:2},
    {id:"de_10", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Hai?", choices:["Fisch","Gemüse","Beides"], correctChoiceIndex:0},
    {id:"de_11", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Wal?", choices:["In der Wüste","Im Ozean","Im Dschungel"], correctChoiceIndex:1},
    {id:"de_12", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Pferd?", choices:["Fleisch","Gemüse","Fisch"], correctChoiceIndex:1},
    {id:"de_13", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Känguru?", choices:["In der Wüste","Im Dschungel","In Australien"], correctChoiceIndex:2},
    {id:"de_14", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Schmetterling?", choices:["Nektar","Gemüse","Fisch"], correctChoiceIndex:0},
    {id:"de_15", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Eisbär?", choices:["In der Wüste","In der Antarktis","Im Dschungel"], correctChoiceIndex:1},
    {id:"de_16", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Panda?", choices:["Fleisch","Bambus","Fisch"], correctChoiceIndex:1},
    {id:"de_17", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Koala?", choices:["In der Wüste","Im Dschungel","In Australien"], correctChoiceIndex:2},
    {id:"de_18", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Krokodil?", choices:["Fleisch","Gemüse","Fisch"], correctChoiceIndex:0},
    {id:"de_19", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Wolf?", choices:["In der Wüste","Im Wald","Im Dschungel"], correctChoiceIndex:1},
    {id:"de_20", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Fuchs?", choices:["Fleisch","Gemüse","Beides"], correctChoiceIndex:2},
    {id:"de_21", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Delfin?", choices:["In der Wüste","Im Ozean","Im Dschungel"], correctChoiceIndex:1},
    {id:"de_22", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Schaf?", choices:["Fleisch","Gras","Fisch"], correctChoiceIndex:1},
    {id:"de_23", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Tiger?", choices:["In der Wüste","Im Dschungel","In der Savanne"], correctChoiceIndex:1},
    {id:"de_24", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Löwe?", choices:["Fleisch","Gemüse","Fisch"], correctChoiceIndex:0},
    {id:"de_25", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Hase?", choices:["In der Wüste","Im Wald","Im Dschungel"], correctChoiceIndex:1},
    {id:"de_26", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Huhn?", choices:["Fleisch","Körner","Fisch"], correctChoiceIndex:1},
    {id:"de_27", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Schwan?", choices:["In der Wüste","Im See","Im Dschungel"], correctChoiceIndex:1},
    {id:"de_28", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Reh?", choices:["Fleisch","Gras","Fisch"], correctChoiceIndex:1},
    {id:"de_29", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Frosch?", choices:["In der Wüste","Im Teich","Im Dschungel"], correctChoiceIndex:1},
    {id:"de_30", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Eichhörnchen?", choices:["Fleisch","Nüsse","Fisch"], correctChoiceIndex:1},
    {id:"de_31", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Biber?", choices:["In der Wüste","Im Fluss","Im Dschungel"], correctChoiceIndex:1},
    {id:"de_32", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Esel?", choices:["Fleisch","Gras","Fisch"], correctChoiceIndex:1},
    {id:"de_33", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Seehund?", choices:["In der Wüste","Im Ozean","Im Dschungel"], correctChoiceIndex:1},
    {id:"de_34", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Pinguin?", choices:["Fleisch","Gemüse","Fisch"], correctChoiceIndex:0},
    {id:"de_35", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Nashorn?", choices:["In der Wüste","Im Dschungel","In der Savanne"], correctChoiceIndex:2},
    {id:"de_36", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Zebra?", choices:["Fleisch","Gras","Fisch"], correctChoiceIndex:1},
    {id:"de_37", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Otter?", choices:["In der Wüste","Im Fluss","Im Dschungel"], correctChoiceIndex:1},
    {id:"de_38", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Igel?", choices:["Insekten","Gemüse","Fisch"], correctChoiceIndex:0},
    {id:"de_39", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Schnecke?", choices:["In der Wüste","Im Garten","Im Dschungel"], correctChoiceIndex:1},
    {id:"de_40", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Kuh?", choices:["Fleisch","Gras","Fisch"], correctChoiceIndex:1},
    {id:"de_41", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Schildkröte?", choices:["In der Wüste","Im Meer","Im Dschungel"], correctChoiceIndex:1},
    {id:"de_42", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Ente?", choices:["Fleisch","Brot","Fisch"], correctChoiceIndex:1},
    {id:"de_43", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Adler?", choices:["In der Wüste","Im Gebirge","Im Dschungel"], correctChoiceIndex:1},
    {id:"de_44", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Katze?", choices:["Fleisch","Gemüse","Fisch"], correctChoiceIndex:0},
    {id:"de_45", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Schlange?", choices:["In der Wüste","Im Wald","Im Dschungel"], correctChoiceIndex:1},
    {id:"de_46", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Maus?", choices:["Fleisch","Käse","Fisch"], correctChoiceIndex:1},
    {id:"de_47", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Spinne?", choices:["In der Wüste","Im Haus","Im Dschungel"], correctChoiceIndex:1},
    {id:"de_48", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Vogel?", choices:["Insekten","Gemüse","Fisch"], correctChoiceIndex:0},
    {id:"de_49", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wo lebt ein Fisch?", choices:["In der Wüste","Im Meer","Im Dschungel"], correctChoiceIndex:1},
    {id:"de_50", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was frisst ein Elefant?", choices:["Fleisch","Gemüse","Beides"], correctChoiceIndex:2},
    {id:"de_51", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was ist der größte Ozean auf der Erde?", choices:["Atlantischer Ozean","Indischer Ozean","Pazifischer Ozean"], correctChoiceIndex:2},
    {id:"de_52", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Wie viele Kontinente gibt es auf der Erde?", choices:["5","7","9"], correctChoiceIndex:1},
    {id:"de_53", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was ist der längste Fluss der Welt?", choices:["Der Nil","Der Amazonas","Der Jangtse"], correctChoiceIndex:1},
    {id:"de_54", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was ist der höchste Berg der Welt?", choices:["Mount Everest","K2","Kilimandscharo"], correctChoiceIndex:0},
    {id:"de_55", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was ist der größte Wüste der Welt?", choices:["Sahara","Gobi","Arabische Wüste"], correctChoiceIndex:0},
    {id:"de_56", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was ist der größte See der Welt?", choices:["Kaspisches Meer","Große Seen","Baikalsee"], correctChoiceIndex:0},
    {id:"de_57", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was ist der tiefste Punkt der Erde?", choices:["Marianengraben","Totes Meer","Mount Everest"], correctChoiceIndex:0},
    {id:"de_58", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was ist der größte Wald der Welt?", choices:["Amazonas-Regenwald","Sibirischer Wald","Kongo-Regenwald"], correctChoiceIndex:0},
    {id:"de_59", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was ist der größte Kontinent der Welt?", choices:["Asien","Afrika","Nordamerika"], correctChoiceIndex:0},
    {id:"de_60", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was ist der kleinste Kontinent der Welt?", choices:["Australien","Europa","Antarktis"], correctChoiceIndex:0},
    {id:"de_61", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte Vulkan der Welt?", choices:["Mauna Loa","Mount St. Helens","Krakatau"], correctChoiceIndex:0},
    {id:"de_62", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte Gletscher der Welt?", choices:["Lambert-Gletscher","Perito Moreno","Vatnajökull"], correctChoiceIndex:0},
    {id:"de_63", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte Insel der Welt?", choices:["Grönland","Neuguinea","Borneo"], correctChoiceIndex:0},
    {id:"de_64", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte Wasserfall der Welt?", choices:["Niagarafälle","Victoriafälle","Angel Falls"], correctChoiceIndex:2},
    {id:"de_65", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte Korallenriff der Welt?", choices:["Great Barrier Reef","Rotes Meer","Karibisches Meer"], correctChoiceIndex:0},
    {id:"de_66", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte Salzsee der Welt?", choices:["Kaspisches Meer","Totes Meer","Salar de Uyuni"], correctChoiceIndex:2},
    {id:"de_67", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte Canyon der Welt?", choices:["Grand Canyon","Fish River Canyon","Yarlung Tsangpo Grand Canyon"], correctChoiceIndex:2},
    {id:"de_68", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte Sumpf der Welt?", choices:["Pantanal","Everglades","Okefenokee-Sumpf"], correctChoiceIndex:0},
    {id:"de_69", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte Flussdelta der Welt?", choices:["Gangesdelta","Amazonasdelta","Nildelta"], correctChoiceIndex:0},
    {id:"de_70", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte Sandstrand der Welt?", choices:["Praia do Cassino","Bondi Beach","Copacabana"], correctChoiceIndex:0},
    {id:"de_71", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte Geysir der Welt?", choices:["Old Faithful","Strokkur","Steamboat Geysir"], correctChoiceIndex:2},
    {id:"de_72", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte Meteoritenkrater der Welt?", choices:["Vredefort-Krater","Chicxulub-Krater","Barringer-Krater"], correctChoiceIndex:0},
    {id:"de_73", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte Salzbergwerk der Welt?", choices:["Khewra Salzmine","Wieliczka-Salzmine","Sifto Salt Mine"], correctChoiceIndex:0},
    {id:"de_74", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte Nationalpark der Welt?", choices:["Northeast Greenland National Park","Yellowstone National Park","Kruger National Park"], correctChoiceIndex:0},
    {id:"de_75", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte Zoo der Welt?", choices:["San Diego Zoo","Berlin Zoologischer Garten","Beijing Zoo"], correctChoiceIndex:0},
    {id:"de_76", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte botanische Garten der Welt?", choices:["Royal Botanic Gardens, Kew","Missouri Botanical Garden","Singapore Botanic Gardens"], correctChoiceIndex:0},
    {id:"de_77", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte Stadtpark der Welt?", choices:["Englischer Garten, München","Central Park, New York","Kings Park, Perth"], correctChoiceIndex:0},
    {id:"de_78", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte See in Europa?", choices:["Bodensee","Ladogasee","Kaspisches Meer"], correctChoiceIndex:2},
    {id:"de_79", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der größte Fluss in Europa?", choices:["Donau","Rhein","Wolga"], correctChoiceIndex:2},
    {id:"de_80", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der höchste Berg in Europa?", choices:["Mont Blanc","Elbrus","Matterhorn"], correctChoiceIndex:1},
    {id:"de_81", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was ist das größte Organ in deinem Körper?", choices:["Herz","Haut","Gehirn"], correctChoiceIndex:1},
    {id:"de_82", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Wie viele Augen haben wir?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"de_83", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was hilft uns zu riechen?", choices:["Nase","Ohren","Hände"], correctChoiceIndex:0},
    {id:"de_84", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was hilft uns zu hören?", choices:["Augen","Ohren","Mund"], correctChoiceIndex:1},
    {id:"de_85", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Wie viele Beine haben wir?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"de_86", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was benutzen wir zum Atmen?", choices:["Lunge","Herz","Magen"], correctChoiceIndex:0},
    {id:"de_87", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was schlägt in unserer Brust?", choices:["Herz","Lunge","Magen"], correctChoiceIndex:0},
    {id:"de_88", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was benutzen wir zum Essen und Sprechen?", choices:["Nase","Ohren","Mund"], correctChoiceIndex:2},
    {id:"de_89", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Wie viele Arme haben wir?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"de_90", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was benutzen wir zum Greifen und Halten?", choices:["Hände","Füße","Ohren"], correctChoiceIndex:0},
    {id:"de_91", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist das stärkste Muskel in unserem Körper?", choices:["Herz","Zunge","Bizeps"], correctChoiceIndex:1},
    {id:"de_92", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wie viele Knochen hat ein erwachsener Mensch?", choices:["206","300","500"], correctChoiceIndex:0},
    {id:"de_93", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist das kleinste Knochen in unserem Körper?", choices:["Stirnbein","Stapes","Femur"], correctChoiceIndex:1},
    {id:"de_94", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist das größte Knochen in unserem Körper?", choices:["Femur","Schädel","Wirbelsäule"], correctChoiceIndex:0},
    {id:"de_95", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wie viele Zähne hat ein erwachsener Mensch normalerweise?", choices:["32","30","28"], correctChoiceIndex:0},
    {id:"de_96", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist das größte Muskel in unserem Körper?", choices:["Gluteus Maximus","Bizeps","Bauchmuskeln"], correctChoiceIndex:0},
    {id:"de_97", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist das schnellste Muskel in unserem Körper?", choices:["Augenlid","Herz","Zunge"], correctChoiceIndex:0},
    {id:"de_98", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wie viele Lungen haben wir?", choices:["2","1","3"], correctChoiceIndex:0},
    {id:"de_99", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist das größte Gelenk in unserem Körper?", choices:["Knie","Ellbogen","Schulter"], correctChoiceIndex:0},
    {id:"de_100", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist das kleinste Organ in unserem Körper?", choices:["Zirbeldrüse","Herz","Leber"], correctChoiceIndex:0},
    {id:"de_101", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wie viele Nieren haben wir normalerweise?", choices:["2","1","3"], correctChoiceIndex:0},
    {id:"de_102", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist das härteste Material in unserem Körper?", choices:["Zähne","Knochen","Haut"], correctChoiceIndex:0},
    {id:"de_103", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist das weichste Material in unserem Körper?", choices:["Gehirn","Haut","Muskeln"], correctChoiceIndex:0},
    {id:"de_104", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wie viele Ohren haben wir?", choices:["2","1","3"], correctChoiceIndex:0},
    {id:"de_105", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist das größte Sinnesorgan in unserem Körper?", choices:["Haut","Augen","Ohren"], correctChoiceIndex:0},
    {id:"de_106", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wie viele Finger haben wir an einer Hand?", choices:["5","4","6"], correctChoiceIndex:0},
    {id:"de_107", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wie viele Zehen haben wir an einem Fuß?", choices:["5","4","6"], correctChoiceIndex:0},
    {id:"de_108", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist das größte Drüsenorgan in unserem Körper?", choices:["Leber","Niere","Herz"], correctChoiceIndex:0},
    {id:"de_109", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist das kleinste Drüsenorgan in unserem Körper?", choices:["Zirbeldrüse","Leber","Niere"], correctChoiceIndex:0},
    {id:"de_110", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Wie viele Gehirne haben wir?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"de_111", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was ist der Hauptteil einer Pflanze, der sie unterstützt und Wasser und Nährstoffe transportiert?", choices:["Blätter","Stamm","Wurzeln"], correctChoiceIndex:1},
    {id:"de_112", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Welcher Teil der Pflanze produziert Nahrung durch Photosynthese?", choices:["Blätter","Stamm","Wurzeln"], correctChoiceIndex:0},
    {id:"de_113", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Welcher Teil der Pflanze nimmt Wasser und Nährstoffe aus dem Boden auf?", choices:["Blätter","Stamm","Wurzeln"], correctChoiceIndex:2},
    {id:"de_114", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Welche Art von Pflanze wächst und stirbt in einer Saison?", choices:["Einjährige Pflanze","Mehrjährige Pflanze","Stauden"], correctChoiceIndex:0},
    {id:"de_115", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Welche Art von Pflanze lebt für mehrere Jahre?", choices:["Einjährige Pflanze","Mehrjährige Pflanze","Stauden"], correctChoiceIndex:1},
    {id:"de_116", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Welche Art von Pflanze lebt Jahr für Jahr?", choices:["Einjährige Pflanze","Mehrjährige Pflanze","Stauden"], correctChoiceIndex:2},
    {id:"de_117", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was ist der Prozess, durch den Pflanzen Nahrung herstellen?", choices:["Photosynthese","Respiration","Transpiration"], correctChoiceIndex:0},
    {id:"de_118", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was ist der Prozess, durch den Pflanzen Wasser verlieren?", choices:["Photosynthese","Respiration","Transpiration"], correctChoiceIndex:2},
    {id:"de_119", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was ist der Prozess, durch den Pflanzen Sauerstoff verbrauchen und Kohlendioxid freisetzen?", choices:["Photosynthese","Respiration","Transpiration"], correctChoiceIndex:1},
    {id:"de_120", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Welche Art von Pflanze verliert im Winter ihre Blätter?", choices:["Laubbaum","Nadelbaum","Stauden"], correctChoiceIndex:0},
    {id:"de_121", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Welche Art von Pflanze behält das ganze Jahr über ihre Blätter?", choices:["Laubbaum","Nadelbaum","Stauden"], correctChoiceIndex:1},
    {id:"de_122", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der Hauptzweck von Blumen in einer Pflanze?", choices:["Nahrungsherstellung","Unterstützung","Fortpflanzung"], correctChoiceIndex:2},
    {id:"de_123", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der Hauptzweck von Früchten in einer Pflanze?", choices:["Nahrungsherstellung","Unterstützung","Samenverteilung"], correctChoiceIndex:2},
    {id:"de_124", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der Hauptzweck von Samen in einer Pflanze?", choices:["Nahrungsherstellung","Fortpflanzung","Wachstum"], correctChoiceIndex:1},
    {id:"de_125", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der Hauptzweck von Wurzeln in einer Pflanze?", choices:["Nahrungsherstellung","Wasseraufnahme und Nährstoffaufnahme","Unterstützung"], correctChoiceIndex:1},
    {id:"de_126", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der Hauptzweck von Stielen in einer Pflanze?", choices:["Nahrungsherstellung","Unterstützung","Wasseraufnahme und Nährstoffaufnahme"], correctChoiceIndex:1},
    {id:"de_127", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der Hauptzweck von Blättern in einer Pflanze?", choices:["Nahrungsherstellung","Unterstützung","Wasseraufnahme und Nährstoffaufnahme"], correctChoiceIndex:0},
    {id:"de_128", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Welche Art von Pflanze produziert Samen ohne Blüten?", choices:["Farn","Rose","Tulpe"], correctChoiceIndex:0},
    {id:"de_129", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Welche Art von Pflanze produziert Samen in Kegeln?", choices:["Farn","Kiefer","Rose"], correctChoiceIndex:1},
    {id:"de_130", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Welche Art von Pflanze produziert Samen in Früchten?", choices:["Farn","Kiefer","Apfelbaum"], correctChoiceIndex:2},
    {id:"de_131", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der Prozess, durch den Samen zu neuen Pflanzen werden?", choices:["Photosynthese","Keimung","Transpiration"], correctChoiceIndex:1},
    {id:"de_132", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der Prozess, durch den Pflanzen Wasser, Kohlendioxid und Sonnenlicht in Nahrung umwandeln?", choices:["Photosynthese","Keimung","Transpiration"], correctChoiceIndex:0},
    {id:"de_133", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist der Prozess, durch den Pflanzen Wasser durch ihre Blätter verlieren?", choices:["Photosynthese","Keimung","Transpiration"], correctChoiceIndex:2},
    {id:"de_134", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Welche Art von Pflanze wächst in der Wüste?", choices:["Kaktus","Rose","Tulpe"], correctChoiceIndex:0},
    {id:"de_135", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Welche Art von Pflanze wächst in kalten, schneebedeckten Gebieten?", choices:["Kaktus","Rose","Tanne"], correctChoiceIndex:2},
    {id:"de_136", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Welche Art von Pflanze wächst in feuchten, tropischen Gebieten?", choices:["Kaktus","Orchidee","Tanne"], correctChoiceIndex:1},
    {id:"de_137", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Welche Art von Pflanze wächst in trockenen, sandigen Gebieten?", choices:["Kaktus","Orchidee","Tanne"], correctChoiceIndex:0},
    {id:"de_138", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Welche Art von Pflanze wächst in nassen, sumpfigen Gebieten?", choices:["Kaktus","Orchidee","Schilf"], correctChoiceIndex:2},
    {id:"de_139", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Welche Art von Pflanze wächst in gemäßigten, gemäßigten Gebieten?", choices:["Kaktus","Rose","Schilf"], correctChoiceIndex:1},
    {id:"de_140", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Welche Art von Pflanze wächst in heißen, sonnigen Gebieten?", choices:["Kaktus","Rose","Schilf"], correctChoiceIndex:0},
    {id:"de_141", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Welcher Planet ist der größte in unserem Sonnensystem?", choices:["Mars","Jupiter","Erde"], correctChoiceIndex:1},
    {id:"de_142", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Wie viele Planeten gibt es in unserem Sonnensystem?", choices:["8","9","10"], correctChoiceIndex:0},
    {id:"de_143", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Welcher Planet ist der nächste zur Sonne?", choices:["Merkur","Venus","Erde"], correctChoiceIndex:0},
    {id:"de_144", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Welcher Planet ist bekannt als der rote Planet?", choices:["Mars","Jupiter","Venus"], correctChoiceIndex:0},
    {id:"de_145", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Welcher Planet hat Ringe um sich herum?", choices:["Saturn","Mars","Erde"], correctChoiceIndex:0},
    {id:"de_146", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Was ist die Sonne?", choices:["Ein Planet","Ein Stern","Ein Mond"], correctChoiceIndex:1},
    {id:"de_147", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Welcher Planet ist der kleinste in unserem Sonnensystem?", choices:["Merkur","Venus","Mars"], correctChoiceIndex:0},
    {id:"de_148", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Welcher Planet ist der weiteste von der Sonne entfernt?", choices:["Neptun","Uranus","Saturn"], correctChoiceIndex:0},
    {id:"de_149", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Welcher Planet ist bekannt für seine Flecken?", choices:["Jupiter","Mars","Venus"], correctChoiceIndex:0},
    {id:"de_150", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Welcher Planet hat die meisten Monde?", choices:["Jupiter","Mars","Erde"], correctChoiceIndex:0},
    {id:"de_151", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist ein Komet?", choices:["Ein Stern","Ein Planet","Ein Eis- und Steinbrocken im Weltraum"], correctChoiceIndex:2},
    {id:"de_152", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist eine Galaxie?", choices:["Eine Gruppe von Sternen, Gas und Staub","Ein Planet","Ein Mond"], correctChoiceIndex:0},
    {id:"de_153", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist ein Schwarzes Loch?", choices:["Ein Ort im Weltraum, wo die Schwerkraft so stark ist, dass nichts entkommen kann","Ein Planet","Ein Stern"], correctChoiceIndex:0},
    {id:"de_154", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist ein Meteorit?", choices:["Ein Stück Stein oder Metall, das auf die Erde gefallen ist","Ein Stern","Ein Planet"], correctChoiceIndex:0},
    {id:"de_155", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist ein Astronaut?", choices:["Eine Person, die ins All reist","Ein Stern","Ein Planet"], correctChoiceIndex:0},
    {id:"de_156", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist ein Teleskop?", choices:["Ein Gerät zum Betrachten von Objekten im Weltraum","Ein Stern","Ein Planet"], correctChoiceIndex:0},
    {id:"de_157", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist die Milchstraße?", choices:["Die Galaxie, in der wir leben","Ein Stern","Ein Planet"], correctChoiceIndex:0},
    {id:"de_158", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist ein Satellit?", choices:["Ein Objekt, das um einen Planeten kreist","Ein Stern","Ein Planet"], correctChoiceIndex:0},
    {id:"de_159", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist die Internationale Raumstation?", choices:["Ein großer Raumfahrzeug, das um die Erde kreist","Ein Stern","Ein Planet"], correctChoiceIndex:0},
    {id:"de_160", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist ein Nebel?", choices:["Eine große Wolke aus Gas und Staub im Weltraum","Ein Stern","Ein Planet"], correctChoiceIndex:0},
    {id:"de_161", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist ein Lichtjahr?", choices:["Die Entfernung, die das Licht in einem Jahr zurücklegt","Ein Stern","Ein Planet"], correctChoiceIndex:0},
    {id:"de_162", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist ein Planetarium?", choices:["Ein Ort, an dem man den Nachthimmel und die Sterne studieren kann","Ein Stern","Ein Planet"], correctChoiceIndex:0},
    {id:"de_163", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist ein Astronom?", choices:["Eine Person, die das Universum studiert","Ein Stern","Ein Planet"], correctChoiceIndex:0},
    {id:"de_164", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist ein Sonnensystem?", choices:["Eine Gruppe von Planeten, die um eine Sonne kreisen","Ein Stern","Ein Planet"], correctChoiceIndex:0},
    {id:"de_165", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist ein Mond?", choices:["Ein Objekt, das um einen Planeten kreist","Ein Stern","Ein Planet"], correctChoiceIndex:0},
    {id:"de_166", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist ein Sternbild?", choices:["Eine Gruppe von Sternen, die ein Bild im Himmel bilden","Ein Stern","Ein Planet"], correctChoiceIndex:0},
    {id:"de_167", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist ein Zwergplanet?", choices:["Ein kleiner Planet, der nicht die Kriterien für einen vollen Planeten erfüllt","Ein Stern","Ein Planet"], correctChoiceIndex:0},
    {id:"de_168", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist ein Gasriese?", choices:["Ein großer Planet, der hauptsächlich aus Gas besteht","Ein Stern","Ein Planet"], correctChoiceIndex:0},
    {id:"de_169", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist ein Asteroid?", choices:["Ein kleiner Felsbrocken, der um die Sonne kreist","Ein Stern","Ein Planet"], correctChoiceIndex:0},
    {id:"de_170", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Was ist ein Supernova?", choices:["Die Explosion eines sterbenden Sterns","Ein Stern","Ein Planet"], correctChoiceIndex:0},
    {id:"de_171", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist die kleinste Einheit des Lebens?", choices:["Ein Hund","Eine Zelle","Ein Auto"], correctChoiceIndex:1},
    {id:"de_172", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Welche Zelle hat keine Kern?", choices:["Pflanzenzelle","Tierzelle","Bakterienzelle"], correctChoiceIndex:2},
    {id:"de_173", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist ein Ökosystem?", choices:["Ein Spielzeug","Ein Ort, an dem Pflanzen und Tiere leben","Ein Buch"], correctChoiceIndex:1},
    {id:"de_174", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist die Hauptfunktion der Zellwand?", choices:["Schutz und Unterstützung","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_175", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist die Hauptfunktion des Zellkerns?", choices:["Steuerung der Zellaktivität","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_176", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion der Mitochondrien in der Zelle?", choices:["Energieproduktion","Schutz","Bewegung"], correctChoiceIndex:0},
    {id:"de_177", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion des Chloroplasten in der Zelle?", choices:["Fotosynthese","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_178", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion des Endoplasmatischen Retikulums in der Zelle?", choices:["Proteinproduktion","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_179", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion des Golgi-Apparats in der Zelle?", choices:["Proteinmodifikation und -transport","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_180", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion der Lysosomen in der Zelle?", choices:["Verdauung und Abfallentsorgung","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_181", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion der Vakuole in der Zelle?", choices:["Lagerung","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_182", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion der Ribosomen in der Zelle?", choices:["Proteinproduktion","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_183", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion der Zellmembran?", choices:["Kontrolle des Ein- und Ausgangs von Stoffen","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_184", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion des Zytoplasmas in der Zelle?", choices:["Ort, an dem die meisten Zellaktivitäten stattfinden","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_185", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion des Nukleolus in der Zelle?", choices:["Produktion von Ribosomen","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_186", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion der Zentriolen in der Zelle?", choices:["Hilfe bei der Zellteilung","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_187", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion der Peroxisomen in der Zelle?", choices:["Abbau von Fettsäuren und Entgiftung","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_188", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion der Plastiden in der Zelle?", choices:["Speicherung von Lebensmitteln und Pigmenten","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_189", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion der Mikrotubuli in der Zelle?", choices:["Unterstützung und Formgebung","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_190", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion der Mikrofilamente in der Zelle?", choices:["Unterstützung und Bewegung","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_191", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion der Intermediärfilamente in der Zelle?", choices:["Unterstützung und Formgebung","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_192", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion der Kernmembran in der Zelle?", choices:["Schutz des Zellkerns","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_193", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion der Kernporen in der Zelle?", choices:["Transport von Molekülen in und aus dem Zellkern","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_194", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion der Chromoplasten in der Zelle?", choices:["Speicherung von Pigmenten","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_195", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Funktion der Leukoplasten in der Zelle?", choices:["Speicherung von Stärke, Öl und Protein","Atmung","Bewegung"], correctChoiceIndex:0},
    {id:"de_196", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist der feste Zustand der Materie?", choices:["Wasser","Eis","Dampf"], correctChoiceIndex:1},
    {id:"de_197", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist der flüssige Zustand der Materie?", choices:["Wasser","Eis","Dampf"], correctChoiceIndex:0},
    {id:"de_198", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist der gasförmige Zustand der Materie?", choices:["Wasser","Eis","Dampf"], correctChoiceIndex:2},
    {id:"de_199", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was passiert, wenn man Backpulver und Essig mischt?", choices:["Es explodiert","Es wird zu Wasser","Es schäumt"], correctChoiceIndex:2},
    {id:"de_200", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist kein Zustand der Materie?", choices:["Flüssigkeit","Feststoff","Regenbogen"], correctChoiceIndex:2},
    {id:"de_201", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Salz in Wasser auflöst?", choices:["Es wird zu Zucker","Es löst sich auf","Es wird zu Eis"], correctChoiceIndex:1},
    {id:"de_202", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Wasser einfriert?", choices:["Es wird zu Dampf","Es wird zu Eis","Es wird zu Salz"], correctChoiceIndex:1},
    {id:"de_203", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Eis erhitzt?", choices:["Es wird zu Dampf","Es wird zu Wasser","Es wird zu Salz"], correctChoiceIndex:1},
    {id:"de_204", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Wasser kocht?", choices:["Es wird zu Eis","Es wird zu Dampf","Es wird zu Salz"], correctChoiceIndex:1},
    {id:"de_205", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Eis schmilzt?", choices:["Es wird zu Dampf","Es wird zu Wasser","Es wird zu Salz"], correctChoiceIndex:1},
    {id:"de_206", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Wasser verdampft?", choices:["Es wird zu Eis","Es wird zu Dampf","Es wird zu Salz"], correctChoiceIndex:1},
    {id:"de_207", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Dampf kondensiert?", choices:["Es wird zu Eis","Es wird zu Wasser","Es wird zu Salz"], correctChoiceIndex:1},
    {id:"de_208", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Eis sublimiert?", choices:["Es wird zu Dampf","Es wird zu Wasser","Es wird zu Salz"], correctChoiceIndex:0},
    {id:"de_209", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Dampf deponiert?", choices:["Es wird zu Eis","Es wird zu Wasser","Es wird zu Salz"], correctChoiceIndex:0},
    {id:"de_210", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Wasser einfriert und dann schmilzt?", choices:["Es wird zu Dampf","Es wird zu Wasser","Es wird zu Salz"], correctChoiceIndex:1},
    {id:"de_211", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Eis erhitzt und dann abkühlt?", choices:["Es wird zu Dampf","Es wird zu Wasser","Es wird zu Salz"], correctChoiceIndex:1},
    {id:"de_212", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Wasser kocht und dann abkühlt?", choices:["Es wird zu Eis","Es wird zu Dampf","Es wird zu Wasser"], correctChoiceIndex:2},
    {id:"de_213", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Eis schmilzt und dann einfriert?", choices:["Es wird zu Dampf","Es wird zu Wasser","Es wird zu Eis"], correctChoiceIndex:2},
    {id:"de_214", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Wasser verdampft und dann kondensiert?", choices:["Es wird zu Eis","Es wird zu Wasser","Es wird zu Dampf"], correctChoiceIndex:1},
    {id:"de_215", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Dampf kondensiert und dann verdampft?", choices:["Es wird zu Eis","Es wird zu Wasser","Es wird zu Dampf"], correctChoiceIndex:2},
    {id:"de_216", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Eis sublimiert und dann deponiert?", choices:["Es wird zu Dampf","Es wird zu Wasser","Es wird zu Eis"], correctChoiceIndex:2},
    {id:"de_217", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Dampf deponiert und dann sublimiert?", choices:["Es wird zu Eis","Es wird zu Wasser","Es wird zu Dampf"], correctChoiceIndex:0},
    {id:"de_218", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Wasser einfriert, dann schmilzt und dann verdampft?", choices:["Es wird zu Eis","Es wird zu Wasser","Es wird zu Dampf"], correctChoiceIndex:2},
    {id:"de_219", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Eis erhitzt, dann abkühlt und dann wieder erhitzt?", choices:["Es wird zu Dampf","Es wird zu Wasser","Es wird zu Eis"], correctChoiceIndex:0},
    {id:"de_220", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn man Wasser kocht, dann abkühlt und dann wieder kocht?", choices:["Es wird zu Eis","Es wird zu Dampf","Es wird zu Wasser"], correctChoiceIndex:1},
    {id:"de_221", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist Recycling?", choices:["Wiederverwendung von Materialien","Fahren eines Autos","Spielen mit Spielzeug"], correctChoiceIndex:0},
    {id:"de_222", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was können wir recyceln?", choices:["Papier und Plastik","Kekse","Spielzeugautos"], correctChoiceIndex:0},
    {id:"de_223", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist Umweltverschmutzung?", choices:["Schmutz in der Luft und im Wasser","Ein sauberer Park","Ein sauberes Haus"], correctChoiceIndex:0},
    {id:"de_224", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was können wir tun, um die Umwelt zu schützen?", choices:["Müll recyceln","Mehr Plastik kaufen","Mehr Auto fahren"], correctChoiceIndex:0},
    {id:"de_225", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Warum ist es wichtig, Bäume zu pflanzen?", choices:["Sie helfen, die Luft zu reinigen","Sie sind grün","Sie sind groß"], correctChoiceIndex:0},
    {id:"de_226", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn wir zu viel Müll produzieren?", choices:["Es kann die Umwelt verschmutzen","Es macht uns glücklich","Es macht uns traurig"], correctChoiceIndex:0},
    {id:"de_227", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist eine erneuerbare Energiequelle?", choices:["Sonnenenergie","Benzin","Kohle"], correctChoiceIndex:0},
    {id:"de_228", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist der Treibhauseffekt?", choices:["Erwärmung der Erde durch Gase in der Atmosphäre","Ein Haus mit vielen Pflanzen","Ein grünes Haus"], correctChoiceIndex:0},
    {id:"de_229", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist eine nicht erneuerbare Energiequelle?", choices:["Erdöl","Windenergie","Sonnenenergie"], correctChoiceIndex:0},
    {id:"de_230", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist der Unterschied zwischen Wetter und Klima?", choices:["Wetter ist kurzfristig, Klima ist langfristig","Es gibt keinen Unterschied","Klima ist kurzfristig, Wetter ist langfristig"], correctChoiceIndex:0},
    {id:"de_231", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein Ökosystem?", choices:["Eine Gemeinschaft von Lebewesen und ihrer Umgebung","Ein großer Park","Ein großer Wald"], correctChoiceIndex:0},
    {id:"de_232", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist Biodiversität?", choices:["Vielfalt von Leben in einem Gebiet","Ein großer Wald","Ein großer Park"], correctChoiceIndex:0},
    {id:"de_233", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein Lebensraum?", choices:["Ein Ort, an dem eine Art lebt","Ein großer Park","Ein großer Wald"], correctChoiceIndex:0},
    {id:"de_234", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein fossiler Brennstoff?", choices:["Eine Energiequelle aus alten Pflanzen und Tieren","Ein alter Stein","Ein alter Baum"], correctChoiceIndex:0},
    {id:"de_235", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist Wasserverschmutzung?", choices:["Schadstoffe im Wasser","Sauberes Wasser","Ein sauberer Fluss"], correctChoiceIndex:0},
    {id:"de_236", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist Luftverschmutzung?", choices:["Schadstoffe in der Luft","Frische Luft","Ein sauberer Park"], correctChoiceIndex:0},
    {id:"de_237", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist Bodenverschmutzung?", choices:["Schadstoffe im Boden","Saubere Erde","Ein sauberer Park"], correctChoiceIndex:0},
    {id:"de_238", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist Lärmbelästigung?", choices:["Zu viel Lärm","Stille","Ruhige Musik"], correctChoiceIndex:0},
    {id:"de_239", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist Lichtverschmutzung?", choices:["Zu viel künstliches Licht","Dunkelheit","Ruhige Musik"], correctChoiceIndex:0},
    {id:"de_240", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein Naturschutzgebiet?", choices:["Ein geschützter Bereich für Tiere und Pflanzen","Ein großer Park","Ein großer Wald"], correctChoiceIndex:0},
    {id:"de_241", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein Aussterben?", choices:["Wenn eine Art für immer verschwindet","Wenn eine Art in den Urlaub geht","Wenn eine Art schläft"], correctChoiceIndex:0},
    {id:"de_242", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein Endemit?", choices:["Eine Art, die nur in einem bestimmten Gebiet vorkommt","Ein großer Baum","Ein großer Fisch"], correctChoiceIndex:0},
    {id:"de_243", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein invasiver Arten?", choices:["Eine Art, die in ein neues Gebiet eintritt und Schaden anrichtet","Ein großer Baum","Ein großer Fisch"], correctChoiceIndex:0},
    {id:"de_244", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein Parasit?", choices:["Ein Organismus, der von einem anderen lebt und ihm schadet","Ein großer Baum","Ein großer Fisch"], correctChoiceIndex:0},
    {id:"de_245", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist Symbiose?", choices:["Eine Beziehung, in der zwei Arten zusammenleben und sich gegenseitig helfen","Ein großer Baum","Ein großer Fisch"], correctChoiceIndex:0},
    {id:"de_246", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist Schwerkraft?", choices:["Es ist eine Kraft, die uns nach unten zieht","Es ist eine Kraft, die uns nach oben zieht","Es ist eine Kraft, die uns seitwärts zieht"], correctChoiceIndex:0},
    {id:"de_247", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist Reibung?", choices:["Es ist eine Kraft, die Dinge stoppt","Es ist eine Kraft, die Dinge schneller macht","Es ist eine Kraft, die Dinge fliegen lässt"], correctChoiceIndex:0},
    {id:"de_248", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist eine einfache Maschine?", choices:["Ein Spielzeugauto","Ein Computer","Ein Hebel"], correctChoiceIndex:2},
    {id:"de_249", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was macht ein Hebel?", choices:["Er hilft uns, schwere Dinge zu heben","Er hilft uns, schneller zu laufen","Er hilft uns, höher zu springen"], correctChoiceIndex:0},
    {id:"de_250", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist eine Rampe?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_251", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist ein Rad und Achse?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_252", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist ein geneigter Flug?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_253", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist ein Hammer?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_254", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist eine Zange?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_255", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist ein Flaschenzug?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_256", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist ein Keil?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_257", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist ein geneigter Flug?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_258", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist ein Keil?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_259", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist eine Zange?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_260", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist eine Schere?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_261", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist ein Keil?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_262", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist eine Schraube?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_263", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was ist ein Keil?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_264", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist die Schwerkraft auf dem Mond im Vergleich zur Erde?", choices:["Stärker","Schwächer","Gleich"], correctChoiceIndex:1},
    {id:"de_265", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was passiert, wenn die Reibung erhöht wird?", choices:["Dinge bewegen sich schneller","Dinge bewegen sich langsamer","Dinge bewegen sich in der gleichen Geschwindigkeit"], correctChoiceIndex:1},
    {id:"de_266", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein Flaschenzug?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_267", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was macht ein Keil?", choices:["Er hilft uns, Dinge zu teilen oder zu heben","Er hilft uns, schneller zu laufen","Er hilft uns, höher zu springen"], correctChoiceIndex:0},
    {id:"de_268", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist eine Schraube?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_269", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein Keil?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_270", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein Schraubenzieher?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_271", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist eine Schere?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_272", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein Wagenheber?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_273", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist eine Schraube?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_274", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein Rad und Achse?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_275", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein Schraubenzieher?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_276", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein Hammer?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_277", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein Wagenheber?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_278", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein Flaschenzug?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_279", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein Rad und Achse?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_280", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist ein geneigter Flug?", choices:["Eine Art von einfacher Maschine","Ein Typ von Auto","Ein Typ von Flugzeug"], correctChoiceIndex:0},
    {id:"de_281", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Welche Art von Wolken sind flach und bedecken oft den ganzen Himmel?", choices:["Cumulus","Stratus","Cirrus"], correctChoiceIndex:1},
    {id:"de_282", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Welche Jahreszeit kommt nach dem Winter?", choices:["Frühling","Sommer","Herbst"], correctChoiceIndex:0},
    {id:"de_283", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was fällt vom Himmel, wenn es regnet?", choices:["Schnee","Regen","Blätter"], correctChoiceIndex:1},
    {id:"de_284", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Welche Art von Wetter haben wir normalerweise im Sommer?", choices:["Kalt","Warm","Schnee"], correctChoiceIndex:1},
    {id:"de_285", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Welche Art von Wolken sind dünn und fedrig?", choices:["Cumulus","Stratus","Cirrus"], correctChoiceIndex:2},
    {id:"de_286", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Welche Art von Wolken können Gewitter verursachen?", choices:["Cumulus","Stratus","Cirrus"], correctChoiceIndex:0},
    {id:"de_287", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Welche Jahreszeit kommt nach dem Sommer?", choices:["Herbst","Winter","Frühling"], correctChoiceIndex:0},
    {id:"de_288", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was fällt vom Himmel, wenn es schneit?", choices:["Regen","Blätter","Schnee"], correctChoiceIndex:2},
    {id:"de_289", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Welche Art von Wetter haben wir normalerweise im Herbst?", choices:["Warm","Kalt","Regen"], correctChoiceIndex:1},
    {id:"de_290", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Welche Art von Wolken sind oft hoch und dünn?", choices:["Cumulus","Stratus","Cirrus"], correctChoiceIndex:2},
    {id:"de_291", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Welche Art von Wolken können einen klaren Himmel bedecken?", choices:["Cumulus","Stratus","Cirrus"], correctChoiceIndex:1},
    {id:"de_292", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Welche Jahreszeit kommt nach dem Herbst?", choices:["Winter","Frühling","Sommer"], correctChoiceIndex:0},
    {id:"de_293", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Was fällt vom Himmel, wenn es hagelt?", choices:["Regen","Hagel","Schnee"], correctChoiceIndex:1},
    {id:"de_294", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Welche Art von Wetter haben wir normalerweise im Winter?", choices:["Warm","Kalt","Schnee"], correctChoiceIndex:2},
    {id:"de_295", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Welche Art von Wolken sind oft weiß und flauschig?", choices:["Cumulus","Stratus","Cirrus"], correctChoiceIndex:0},
    {id:"de_296", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Welche Art von Niederschlag ist gefrorenes Wasser?", choices:["Regen","Schnee","Nebel"], correctChoiceIndex:1},
    {id:"de_297", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Welche Jahreszeit kommt nach dem Herbst?", choices:["Winter","Frühling","Sommer"], correctChoiceIndex:0},
    {id:"de_298", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Welche Art von Wolken sind dick und flauschig?", choices:["Cumulus","Stratus","Cirrus"], correctChoiceIndex:0},
    {id:"de_299", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist der wissenschaftliche Name für den Wechsel der Jahreszeiten?", choices:["Rotation","Revolution","Neigung"], correctChoiceIndex:2},
    {id:"de_300", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Welche Art von Wetter haben wir normalerweise im Winter?", choices:["Warm","Kalt","Regen"], correctChoiceIndex:1},
    {id:"de_301", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Welche Art von Niederschlag ist flüssiges Wasser?", choices:["Regen","Schnee","Nebel"], correctChoiceIndex:0},
    {id:"de_302", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Welche Jahreszeit kommt nach dem Frühling?", choices:["Sommer","Herbst","Winter"], correctChoiceIndex:0},
    {id:"de_303", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Welche Art von Wolken sind oft niedrig und grau?", choices:["Cumulus","Stratus","Cirrus"], correctChoiceIndex:1},
    {id:"de_304", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Was ist der wissenschaftliche Name für den Wechsel von Tag und Nacht?", choices:["Rotation","Revolution","Neigung"], correctChoiceIndex:0},
    {id:"de_305", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Welche Art von Wetter haben wir normalerweise im Frühling?", choices:["Warm","Kalt","Regen"], correctChoiceIndex:0},
    {id:"de_306", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist ein Handy?", choices:["Ein Spielzeug","Ein Kommunikationsgerät","Ein Essen"], correctChoiceIndex:1},
    {id:"de_307", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist ein Computer?", choices:["Ein Tier","Ein Spielzeug","Ein Gerät zum Spielen und Lernen"], correctChoiceIndex:2},
    {id:"de_308", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist ein Tablet?", choices:["Ein Kissen","Ein Gerät zum Spielen und Lernen","Ein Getränk"], correctChoiceIndex:1},
    {id:"de_309", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist das Internet?", choices:["Ein Ort zum Spielen","Ein Ort zum Lernen und Spielen","Ein Ort zum Schlafen"], correctChoiceIndex:1},
    {id:"de_310", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist ein Telefon?", choices:["Ein Spielzeug","Ein Gerät zum Sprechen mit anderen","Ein Essen"], correctChoiceIndex:1},
    {id:"de_311", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist eine E-Mail?", choices:["Eine Art zu essen","Eine Art zu spielen","Eine Art zu kommunizieren"], correctChoiceIndex:2},
    {id:"de_312", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Passwort?", choices:["Etwas zum Essen","Etwas zum Spielen","Etwas zum Schutz deiner Informationen"], correctChoiceIndex:2},
    {id:"de_313", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Benutzername?", choices:["Etwas zum Essen","Etwas zum Spielen","Etwas, das dich auf Websites identifiziert"], correctChoiceIndex:2},
    {id:"de_314", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Virus?", choices:["Eine Krankheit","Ein böses Programm auf dem Computer","Ein Spielzeug"], correctChoiceIndex:1},
    {id:"de_315", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein soziales Netzwerk?", choices:["Ein Spielplatz","Eine Website zum Teilen und Kommunizieren","Ein Buch"], correctChoiceIndex:1},
    {id:"de_316", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Chat?", choices:["Ein Tier","Ein Spiel","Eine Möglichkeit, online zu kommunizieren"], correctChoiceIndex:2},
    {id:"de_317", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Emoji?", choices:["Ein Spielzeug","Ein Symbol in Textnachrichten","Ein Essen"], correctChoiceIndex:1},
    {id:"de_318", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Selfie?", choices:["Ein Spiel","Ein Foto von dir selbst","Ein Buch"], correctChoiceIndex:1},
    {id:"de_319", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Blog?", choices:["Ein Spielzeug","Ein Ort zum Schlafen","Eine Website, auf der du deine Gedanken teilen kannst"], correctChoiceIndex:2},
    {id:"de_320", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Podcast?", choices:["Ein Spielzeug","Eine Art von Essen","Eine Aufnahme, die du online anhören kannst"], correctChoiceIndex:2},
    {id:"de_321", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Hashtag?", choices:["Ein Spielzeug","Ein Symbol in sozialen Medien","Ein Essen"], correctChoiceIndex:1},
    {id:"de_322", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Link?", choices:["Ein Spielzeug","Eine Verbindung zu einer anderen Website","Ein Essen"], correctChoiceIndex:1},
    {id:"de_323", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Browser?", choices:["Ein Tier","Ein Spielzeug","Ein Programm zum Anzeigen von Websites"], correctChoiceIndex:2},
    {id:"de_324", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Server?", choices:["Ein Spielzeug","Ein Gerät, das Websites speichert","Ein Essen"], correctChoiceIndex:1},
    {id:"de_325", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Router?", choices:["Ein Spielzeug","Ein Gerät, das Internet in deinem Haus verteilt","Ein Essen"], correctChoiceIndex:1},
    {id:"de_326", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist ein Computer?", choices:["Ein Tier","Ein Spielzeug","Eine Maschine"], correctChoiceIndex:2},
    {id:"de_327", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Welche Farbe hat normalerweise eine Tastatur?", choices:["Rot","Schwarz","Blau"], correctChoiceIndex:1},
    {id:"de_328", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist eine Maus in Bezug auf einen Computer?", choices:["Ein Tier","Ein Gerät","Ein Spiel"], correctChoiceIndex:1},
    {id:"de_329", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was zeigt der Monitor eines Computers an?", choices:["Bilder und Texte","Nur Texte","Nichts"], correctChoiceIndex:0},
    {id:"de_330", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist das Internet?", choices:["Ein Spiel","Ein Ort","Ein Netzwerk"], correctChoiceIndex:2},
    {id:"de_331", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Betriebssystem auf einem Computer?", choices:["Ein Programm","Ein Spiel","Ein Gerät"], correctChoiceIndex:0},
    {id:"de_332", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Browser?", choices:["Ein Programm zum Surfen im Internet","Ein Gerät zum Hören von Musik","Ein Spiel"], correctChoiceIndex:0},
    {id:"de_333", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Virus in Bezug auf Computer?", choices:["Eine Krankheit","Ein schädliches Programm","Ein Tier"], correctChoiceIndex:1},
    {id:"de_334", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Passwort?", choices:["Ein Geheimcode","Ein Spiel","Ein Tier"], correctChoiceIndex:0},
    {id:"de_335", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Drucker?", choices:["Ein Gerät zum Erstellen von Papierkopien","Ein Gerät zum Spielen von Spielen","Ein Gerät zum Ansehen von Filmen"], correctChoiceIndex:0},
    {id:"de_336", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein USB-Stick?", choices:["Ein Gerät zum Speichern von Daten","Ein Gerät zum Spielen von Spielen","Ein Gerät zum Ansehen von Filmen"], correctChoiceIndex:0},
    {id:"de_337", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist eine Webcam?", choices:["Ein Gerät zum Aufnehmen von Videos","Ein Gerät zum Spielen von Spielen","Ein Gerät zum Ansehen von Filmen"], correctChoiceIndex:0},
    {id:"de_338", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Mikrofon?", choices:["Ein Gerät zum Aufnehmen von Tönen","Ein Gerät zum Spielen von Spielen","Ein Gerät zum Ansehen von Filmen"], correctChoiceIndex:0},
    {id:"de_339", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Lautsprecher?", choices:["Ein Gerät zum Erzeugen von Tönen","Ein Gerät zum Spielen von Spielen","Ein Gerät zum Ansehen von Filmen"], correctChoiceIndex:0},
    {id:"de_340", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Headset?", choices:["Ein Gerät zum Hören und Sprechen","Ein Gerät zum Spielen von Spielen","Ein Gerät zum Ansehen von Filmen"], correctChoiceIndex:0},
    {id:"de_341", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Laptop?", choices:["Ein tragbarer Computer","Ein Gerät zum Spielen von Spielen","Ein Gerät zum Ansehen von Filmen"], correctChoiceIndex:0},
    {id:"de_342", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Tablet?", choices:["Ein tragbarer Computer mit Touchscreen","Ein Gerät zum Spielen von Spielen","Ein Gerät zum Ansehen von Filmen"], correctChoiceIndex:0},
    {id:"de_343", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Smartphone?", choices:["Ein tragbarer Computer mit Telefonfunktion","Ein Gerät zum Spielen von Spielen","Ein Gerät zum Ansehen von Filmen"], correctChoiceIndex:0},
    {id:"de_344", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Server?", choices:["Ein Computer, der Dienste für andere Computer bereitstellt","Ein Gerät zum Spielen von Spielen","Ein Gerät zum Ansehen von Filmen"], correctChoiceIndex:0},
    {id:"de_345", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Netzwerk?", choices:["Eine Verbindung zwischen Computern","Ein Gerät zum Spielen von Spielen","Ein Gerät zum Ansehen von Filmen"], correctChoiceIndex:0},
    {id:"de_346", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist ein Kühlschrank?", choices:["Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln","Ein Gerät zum Waschen von Kleidung"], correctChoiceIndex:1},
    {id:"de_347", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was macht eine Waschmaschine?", choices:["Kocht Essen","Spielt Musik","Wäscht Kleidung"], correctChoiceIndex:2},
    {id:"de_348", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Wofür benutzen wir einen Toaster?", choices:["Um Brot zu toasten","Um Kleidung zu waschen","Um Essen zu kühlen"], correctChoiceIndex:0},
    {id:"de_349", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist ein Fernseher?", choices:["Ein Gerät zum Ansehen von Shows und Filmen","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_350", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was macht ein Staubsauger?", choices:["Kocht Essen","Saugt Schmutz und Staub auf","Spielt Musik"], correctChoiceIndex:1},
    {id:"de_351", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist ein Föhn?", choices:["Ein Gerät zum Trocknen der Haare","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_352", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist ein Bügeleisen?", choices:["Ein Gerät zum Glätten der Kleidung","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_353", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist ein Elektroherd?", choices:["Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln","Ein Gerät zum Waschen von Kleidung"], correctChoiceIndex:0},
    {id:"de_354", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist ein Ventilator?", choices:["Ein Gerät zum Erzeugen von Wind","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_355", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist eine Klimaanlage?", choices:["Ein Gerät zum Kühlen der Luft","Ein Gerät zum Kochen","Ein Gerät zum Waschen von Kleidung"], correctChoiceIndex:0},
    {id:"de_356", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist ein Heizgerät?", choices:["Ein Gerät zum Erwärmen der Luft","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_357", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist eine Glühbirne?", choices:["Ein Gerät zum Erzeugen von Licht","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_358", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist ein Telefon?", choices:["Ein Gerät zum Anrufen und Empfangen von Anrufen","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_359", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist ein DVD-Player?", choices:["Ein Gerät zum Abspielen von DVDs","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_360", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Mikrowellenherd?", choices:["Ein Gerät zum Erhitzen von Lebensmitteln","Ein Gerät zum Kühlen von Lebensmitteln","Ein Gerät zum Waschen von Kleidung"], correctChoiceIndex:0},
    {id:"de_361", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was macht ein Geschirrspüler?", choices:["Wäscht Geschirr","Kocht Essen","Spielt Musik"], correctChoiceIndex:0},
    {id:"de_362", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Computer?", choices:["Ein Gerät zum Spielen von Spielen und Surfen im Internet","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_363", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Handy?", choices:["Ein Gerät zum Anrufen und Senden von Nachrichten","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_364", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Mixer?", choices:["Ein Gerät zum Mischen von Lebensmitteln","Ein Gerät zum Waschen von Kleidung","Ein Gerät zum Ansehen von Shows und Filmen"], correctChoiceIndex:0},
    {id:"de_365", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Kaffeemaschine?", choices:["Ein Gerät zum Zubereiten von Kaffee","Ein Gerät zum Waschen von Kleidung","Ein Gerät zum Ansehen von Shows und Filmen"], correctChoiceIndex:0},
    {id:"de_366", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Wasserkocher?", choices:["Ein Gerät zum Erhitzen von Wasser","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_367", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Radio?", choices:["Ein Gerät zum Hören von Musik und Nachrichten","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_368", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Wecker?", choices:["Ein Gerät zum Wecken von Menschen","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_369", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Drucker?", choices:["Ein Gerät zum Drucken von Dokumenten","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_370", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Scanner?", choices:["Ein Gerät zum Digitalisieren von Dokumenten","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_371", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Router?", choices:["Ein Gerät zum Bereitstellen von Internetzugang","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_372", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Modem?", choices:["Ein Gerät zum Verbinden mit dem Internet","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_373", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Blu-ray-Player?", choices:["Ein Gerät zum Abspielen von Blu-ray-Discs","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_374", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein CD-Player?", choices:["Ein Gerät zum Abspielen von CDs","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_375", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein MP3-Player?", choices:["Ein Gerät zum Abspielen von MP3-Musik","Ein Gerät zum Kochen","Ein Gerät zum Kühlen von Lebensmitteln"], correctChoiceIndex:0},
    {id:"de_376", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was ist ein Roboter?", choices:["Ein Tier","Ein Auto","Eine Maschine"], correctChoiceIndex:2},
    {id:"de_377", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Was kann ein Roboter tun?", choices:["Laufen und springen","Essen und trinken","Aufgaben erledigen"], correctChoiceIndex:2},
    {id:"de_378", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Wo werden Roboter verwendet?", choices:["In der Schule","In Fabriken","Im Wald"], correctChoiceIndex:1},
    {id:"de_379", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Können Roboter sprechen?", choices:["Ja, immer","Nein, niemals","Manchmal, wenn sie programmiert sind"], correctChoiceIndex:2},
    {id:"de_380", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Können Roboter Menschen helfen?", choices:["Ja","Nein","Vielleicht"], correctChoiceIndex:0},
    {id:"de_381", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Können Roboter sehen?", choices:["Ja, sie haben Augen","Nein, sie haben keine Augen","Sie können Sensoren verwenden, um ihre Umgebung zu erkennen"], correctChoiceIndex:2},
    {id:"de_382", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Können Roboter fühlen?", choices:["Ja, sie haben Gefühle","Nein, sie haben keine Gefühle","Sie können Sensoren verwenden, um Berührungen zu erkennen"], correctChoiceIndex:2},
    {id:"de_383", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Können Roboter tanzen?", choices:["Ja","Nein","Vielleicht"], correctChoiceIndex:0},
    {id:"de_384", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Können Roboter malen?", choices:["Ja","Nein","Vielleicht"], correctChoiceIndex:0},
    {id:"de_385", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Können Roboter singen?", choices:["Ja","Nein","Vielleicht"], correctChoiceIndex:0},
    {id:"de_386", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Wie funktioniert ein Roboter?", choices:["Mit Batterien","Mit Wasser","Mit Luft"], correctChoiceIndex:0},
    {id:"de_387", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein Roboterarm?", choices:["Ein Arm, der Dinge aufheben kann","Ein Arm, der Dinge malen kann","Ein Arm, der Dinge essen kann"], correctChoiceIndex:0},
    {id:"de_388", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Können Roboter schwere Dinge heben?", choices:["Ja","Nein","Vielleicht"], correctChoiceIndex:0},
    {id:"de_389", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Können Roboter gefährlich sein?", choices:["Ja","Nein","Vielleicht"], correctChoiceIndex:0},
    {id:"de_390", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Können Roboter im Weltraum arbeiten?", choices:["Ja","Nein","Vielleicht"], correctChoiceIndex:0},
    {id:"de_391", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist künstliche Intelligenz?", choices:["Eine Art von Spiel","Eine Art von Roboter","Eine Art von Technologie, die Maschinen hilft, wie Menschen zu denken"], correctChoiceIndex:2},
    {id:"de_392", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Können Roboter lernen?", choices:["Ja, sie können lernen","Nein, sie können nicht lernen","Sie können programmiert werden, um neue Dinge zu tun"], correctChoiceIndex:0},
    {id:"de_393", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist ein autonomer Roboter?", choices:["Ein Roboter, der alleine arbeiten kann","Ein Roboter, der nur mit Menschen arbeiten kann","Ein Roboter, der nur mit anderen Robotern arbeiten kann"], correctChoiceIndex:0},
    {id:"de_394", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Können Roboter Autos bauen?", choices:["Ja","Nein","Vielleicht"], correctChoiceIndex:0},
    {id:"de_395", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Können Roboter kochen?", choices:["Ja","Nein","Vielleicht"], correctChoiceIndex:0},
    {id:"de_396", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Welches Fahrzeug hat Räder?", choices:["Auto","Boot","Flugzeug"], correctChoiceIndex:0},
    {id:"de_397", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Welches Fahrzeug kann fliegen?", choices:["Auto","Boot","Flugzeug"], correctChoiceIndex:2},
    {id:"de_398", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Welches Fahrzeug kann schwimmen?", choices:["Auto","Boot","Flugzeug"], correctChoiceIndex:1},
    {id:"de_399", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Welches Fahrzeug fährt auf Schienen?", choices:["Auto","Zug","Flugzeug"], correctChoiceIndex:1},
    {id:"de_400", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Welches Fahrzeug hat Flügel?", choices:["Auto","Boot","Flugzeug"], correctChoiceIndex:2},
    {id:"de_401", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Welches Fahrzeug kann man treten?", choices:["Fahrrad","Auto","Flugzeug"], correctChoiceIndex:0},
    {id:"de_402", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Welches Fahrzeug hat einen Propeller?", choices:["Auto","Boot","Hubschrauber"], correctChoiceIndex:2},
    {id:"de_403", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Welches Fahrzeug kann man schieben?", choices:["Roller","Auto","Flugzeug"], correctChoiceIndex:0},
    {id:"de_404", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Welches Fahrzeug hat einen Motor?", choices:["Fahrrad","Auto","Roller"], correctChoiceIndex:1},
    {id:"de_405", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Welches Fahrzeug kann man ziehen?", choices:["Wagen","Auto","Flugzeug"], correctChoiceIndex:0},
    {id:"de_406", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist das schnellste Landfahrzeug?", choices:["Auto","Zug","Rennauto"], correctChoiceIndex:2},
    {id:"de_407", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist das schnellste Wasserfahrzeug?", choices:["Segelboot","Motorboot","Ruderboot"], correctChoiceIndex:1},
    {id:"de_408", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist das schnellste Luftfahrzeug?", choices:["Hubschrauber","Flugzeug","Düsenjet"], correctChoiceIndex:2},
    {id:"de_409", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist das langsamste Fahrzeug?", choices:["Rennauto","Fahrrad","Schnecke"], correctChoiceIndex:2},
    {id:"de_410", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist das größte Fahrzeug?", choices:["Auto","Schiff","Fahrrad"], correctChoiceIndex:1},
    {id:"de_411", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist das kleinste Fahrzeug?", choices:["Auto","Fahrrad","Roller"], correctChoiceIndex:2},
    {id:"de_412", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist das schwerste Fahrzeug?", choices:["Auto","Zug","Fahrrad"], correctChoiceIndex:1},
    {id:"de_413", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Was ist das leichteste Fahrzeug?", choices:["Fahrrad","Auto","Flugzeug"], correctChoiceIndex:0},
    {id:"de_414", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat die meisten Räder?", choices:["Auto","Zug","Fahrrad"], correctChoiceIndex:1},
    {id:"de_415", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat die wenigsten Räder?", choices:["Auto","Fahrrad","Flugzeug"], correctChoiceIndex:1},
    {id:"de_416", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat die größten Räder?", choices:["Auto","Monstertruck","Fahrrad"], correctChoiceIndex:1},
    {id:"de_417", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat die kleinsten Räder?", choices:["Monstertruck","Auto","Roller"], correctChoiceIndex:2},
    {id:"de_418", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat die meisten Sitze?", choices:["Auto","Bus","Fahrrad"], correctChoiceIndex:1},
    {id:"de_419", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat die wenigsten Sitze?", choices:["Bus","Auto","Fahrrad"], correctChoiceIndex:2},
    {id:"de_420", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat die größten Fenster?", choices:["Auto","Bus","Fahrrad"], correctChoiceIndex:1},
    {id:"de_421", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat die kleinsten Fenster?", choices:["Bus","Auto","U-Boot"], correctChoiceIndex:2},
    {id:"de_422", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat die lauteste Hupe?", choices:["Auto","Zug","Fahrrad"], correctChoiceIndex:1},
    {id:"de_423", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat die leiseste Hupe?", choices:["Zug","Auto","Fahrrad"], correctChoiceIndex:2},
    {id:"de_424", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat die hellsten Scheinwerfer?", choices:["Auto","Zug","Fahrrad"], correctChoiceIndex:0},
    {id:"de_425", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat die dunkelsten Scheinwerfer?", choices:["Zug","Auto","Fahrrad"], correctChoiceIndex:2},
    {id:"de_426", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat den größten Motor?", choices:["Auto","Zug","Fahrrad"], correctChoiceIndex:1},
    {id:"de_427", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat den kleinsten Motor?", choices:["Zug","Auto","Fahrrad"], correctChoiceIndex:2},
    {id:"de_428", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat den stärksten Motor?", choices:["Auto","Zug","Rennauto"], correctChoiceIndex:2},
    {id:"de_429", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat den schwächsten Motor?", choices:["Rennauto","Auto","Fahrrad"], correctChoiceIndex:2},
    {id:"de_430", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat den größten Tank?", choices:["Auto","Zug","Fahrrad"], correctChoiceIndex:1},
    {id:"de_431", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat den kleinsten Tank?", choices:["Zug","Auto","Fahrrad"], correctChoiceIndex:2},
    {id:"de_432", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat den größten Kofferraum?", choices:["Auto","LKW","Fahrrad"], correctChoiceIndex:1},
    {id:"de_433", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat den kleinsten Kofferraum?", choices:["LKW","Auto","Fahrrad"], correctChoiceIndex:2},
    {id:"de_434", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat die größte Reichweite?", choices:["Auto","Flugzeug","Fahrrad"], correctChoiceIndex:1},
    {id:"de_435", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Welches Fahrzeug hat die kleinste Reichweite?", choices:["Flugzeug","Auto","Fahrrad"], correctChoiceIndex:2},
    {id:"de_436", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Was macht ein Feuerwehrmann?", choices:["Er löscht Feuer","Er verkauft Eis","Er fährt Busse"], correctChoiceIndex:0},
    {id:"de_437", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Was macht ein Arzt?", choices:["Er repariert Autos","Er heilt Menschen","Er kocht Essen"], correctChoiceIndex:1},
    {id:"de_438", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Was macht ein Lehrer?", choices:["Er baut Häuser","Er fährt Züge","Er lehrt Kinder"], correctChoiceIndex:2},
    {id:"de_439", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Wo arbeitet ein Feuerwehrmann?", choices:["In der Schule","In der Feuerwache","Im Supermarkt"], correctChoiceIndex:1},
    {id:"de_440", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Wo arbeitet ein Arzt?", choices:["Im Krankenhaus","In der Bäckerei","Auf dem Bauernhof"], correctChoiceIndex:0},
    {id:"de_441", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Wo arbeitet ein Lehrer?", choices:["In der Schule","Im Zoo","In der Bank"], correctChoiceIndex:0},
    {id:"de_442", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Wer hilft uns, wenn wir krank sind?", choices:["Der Bäcker","Der Arzt","Der Postbote"], correctChoiceIndex:1},
    {id:"de_443", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Wer lehrt uns in der Schule?", choices:["Der Koch","Der Gärtner","Der Lehrer"], correctChoiceIndex:2},
    {id:"de_444", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Wer hilft uns, wenn es brennt?", choices:["Der Feuerwehrmann","Der Zahnarzt","Der Friseur"], correctChoiceIndex:0},
    {id:"de_445", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was ist die Hauptaufgabe eines Feuerwehrmanns?", choices:["Feuer löschen","Bücher lesen","Kuchen backen"], correctChoiceIndex:0},
    {id:"de_446", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was ist die Hauptaufgabe eines Arztes?", choices:["Menschen heilen","Autos reparieren","Häuser malen"], correctChoiceIndex:0},
    {id:"de_447", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was ist die Hauptaufgabe eines Lehrers?", choices:["Kinder unterrichten","Blumen pflanzen","Züge fahren"], correctChoiceIndex:0},
    {id:"de_448", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wo würde man einen Feuerwehrmann finden, wenn es kein Feuer gibt?", choices:["In der Feuerwache","Im Kino","Im Schwimmbad"], correctChoiceIndex:0},
    {id:"de_449", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wo würde man einen Arzt finden, wenn niemand krank ist?", choices:["Im Krankenhaus","Im Park","In der Bibliothek"], correctChoiceIndex:0},
    {id:"de_450", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wo würde man einen Lehrer finden, wenn keine Schule ist?", choices:["In der Schule","Auf dem Fußballplatz","Im Supermarkt"], correctChoiceIndex:0},
    {id:"de_451", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wer hilft uns, wenn wir uns verletzen?", choices:["Der Arzt","Der Bäcker","Der Busfahrer"], correctChoiceIndex:0},
    {id:"de_452", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wer hilft uns, wenn wir etwas Neues lernen wollen?", choices:["Der Lehrer","Der Metzger","Der Elektriker"], correctChoiceIndex:0},
    {id:"de_453", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wer hilft uns, wenn unser Haus brennt?", choices:["Der Feuerwehrmann","Der Gärtner","Der Maler"], correctChoiceIndex:0},
    {id:"de_454", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was trägt ein Feuerwehrmann zur Arbeit?", choices:["Eine Uniform","Eine Badehose","Ein Anzug"], correctChoiceIndex:0},
    {id:"de_455", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was trägt ein Arzt zur Arbeit?", choices:["Einen Kittel","Einen Pyjama","Einen Regenmantel"], correctChoiceIndex:0},
    {id:"de_456", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was trägt ein Lehrer zur Arbeit?", choices:["Kleidung","Einen Taucheranzug","Einen Raumanzug"], correctChoiceIndex:0},
    {id:"de_457", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was benutzt ein Feuerwehrmann, um ein Feuer zu löschen?", choices:["Einen Wasserschlauch","Einen Staubsauger","Einen Fön"], correctChoiceIndex:0},
    {id:"de_458", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was benutzt ein Arzt, um einen Patienten zu untersuchen?", choices:["Ein Stethoskop","Ein Mikroskop","Ein Teleskop"], correctChoiceIndex:0},
    {id:"de_459", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was benutzt ein Lehrer, um zu unterrichten?", choices:["Ein Buch","Ein Hammer","Ein Kochlöffel"], correctChoiceIndex:0},
    {id:"de_460", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wer hilft uns, wenn wir uns verlaufen haben?", choices:["Ein Polizist","Ein Bäcker","Ein Maler"], correctChoiceIndex:0},
    {id:"de_461", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welche Farbe hat eine Banane?", choices:["Blau","Gelb","Rot"], correctChoiceIndex:1},
    {id:"de_462", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welche Frucht ist rot und rund?", choices:["Banane","Apfel","Orange"], correctChoiceIndex:1},
    {id:"de_463", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Was ist das Hauptgetränk zum Frühstück?", choices:["Saft","Milch","Soda"], correctChoiceIndex:1},
    {id:"de_464", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Gemüse ist orange?", choices:["Karotte","Tomate","Gurke"], correctChoiceIndex:0},
    {id:"de_465", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Essen ist gut für die Augen?", choices:["Karotten","Schokolade","Chips"], correctChoiceIndex:0},
    {id:"de_466", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welche Farbe hat eine Erdbeere?", choices:["Gelb","Grün","Rot"], correctChoiceIndex:2},
    {id:"de_467", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welche Frucht ist gelb und krumm?", choices:["Banane","Apfel","Orange"], correctChoiceIndex:0},
    {id:"de_468", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Was ist das Hauptgetränk zum Abendessen?", choices:["Saft","Milch","Wasser"], correctChoiceIndex:2},
    {id:"de_469", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Gemüse ist grün?", choices:["Karotte","Tomate","Gurke"], correctChoiceIndex:2},
    {id:"de_470", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Essen ist gut für die Knochen?", choices:["Karotten","Milch","Chips"], correctChoiceIndex:1},
    {id:"de_471", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welche Lebensmittelgruppe enthält Brot und Nudeln?", choices:["Obst und Gemüse","Milchprodukte","Getreide"], correctChoiceIndex:2},
    {id:"de_472", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welche Lebensmittelgruppe enthält Fleisch und Fisch?", choices:["Proteine","Milchprodukte","Getreide"], correctChoiceIndex:0},
    {id:"de_473", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welche Lebensmittelgruppe enthält Milch und Käse?", choices:["Obst und Gemüse","Milchprodukte","Getreide"], correctChoiceIndex:1},
    {id:"de_474", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was ist das Hauptzutat in einem Omelett?", choices:["Eier","Brot","Käse"], correctChoiceIndex:0},
    {id:"de_475", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welches Gemüse ist grün und sieht aus wie ein kleiner Baum?", choices:["Brokkoli","Karotte","Tomate"], correctChoiceIndex:0},
    {id:"de_476", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welche Lebensmittelgruppe enthält Äpfel und Bananen?", choices:["Obst und Gemüse","Milchprodukte","Getreide"], correctChoiceIndex:0},
    {id:"de_477", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welche Lebensmittelgruppe enthält Bohnen und Nüsse?", choices:["Proteine","Milchprodukte","Getreide"], correctChoiceIndex:0},
    {id:"de_478", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welche Lebensmittelgruppe enthält Joghurt und Butter?", choices:["Obst und Gemüse","Milchprodukte","Getreide"], correctChoiceIndex:1},
    {id:"de_479", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was ist das Hauptzutat in einem Pfannkuchen?", choices:["Eier","Mehl","Käse"], correctChoiceIndex:1},
    {id:"de_480", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welches Gemüse ist rot und rund?", choices:["Brokkoli","Karotte","Tomate"], correctChoiceIndex:2},
    {id:"de_481", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Was ist die Währung in Deutschland?", choices:["Dollar","Euro","Pfund"], correctChoiceIndex:1},
    {id:"de_482", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Wenn du 2 Euro hast und du gibst 1 Euro aus, wie viel Geld hast du noch übrig?", choices:["1 Euro","2 Euro","3 Euro"], correctChoiceIndex:0},
    {id:"de_483", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Wenn du 5 Euro hast und du gibst 2 Euro aus, wie viel Geld hast du noch übrig?", choices:["3 Euro","2 Euro","4 Euro"], correctChoiceIndex:0},
    {id:"de_484", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Wenn du 3 Euro hast und du gibst 1 Euro aus, wie viel Geld hast du noch übrig?", choices:["2 Euro","1 Euro","3 Euro"], correctChoiceIndex:0},
    {id:"de_485", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Wenn du 4 Euro hast und du gibst 2 Euro aus, wie viel Geld hast du noch übrig?", choices:["2 Euro","1 Euro","3 Euro"], correctChoiceIndex:0},
    {id:"de_486", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Wenn du 1 Euro hast und du gibst 1 Euro aus, wie viel Geld hast du noch übrig?", choices:["0 Euro","1 Euro","2 Euro"], correctChoiceIndex:0},
    {id:"de_487", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Wenn du 2 Euro hast und du gibst 1 Euro aus, wie viel Geld hast du noch übrig?", choices:["1 Euro","2 Euro","3 Euro"], correctChoiceIndex:0},
    {id:"de_488", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Wenn du 3 Euro hast und du gibst 2 Euro aus, wie viel Geld hast du noch übrig?", choices:["1 Euro","2 Euro","3 Euro"], correctChoiceIndex:0},
    {id:"de_489", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Wenn du 4 Euro hast und du gibst 2 Euro aus, wie viel Geld hast du noch übrig?", choices:["2 Euro","3 Euro","4 Euro"], correctChoiceIndex:0},
    {id:"de_490", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wenn du 10 Euro hast und du gibst 5 Euro aus, wie viel Geld hast du noch übrig?", choices:["5 Euro","4 Euro","6 Euro"], correctChoiceIndex:0},
    {id:"de_491", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wenn du 7 Euro hast und du gibst 3 Euro aus, wie viel Geld hast du noch übrig?", choices:["4 Euro","3 Euro","5 Euro"], correctChoiceIndex:0},
    {id:"de_492", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wenn du 6 Euro hast und du gibst 2 Euro aus, wie viel Geld hast du noch übrig?", choices:["4 Euro","3 Euro","5 Euro"], correctChoiceIndex:0},
    {id:"de_493", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wenn du 8 Euro hast und du gibst 3 Euro aus, wie viel Geld hast du noch übrig?", choices:["5 Euro","4 Euro","6 Euro"], correctChoiceIndex:0},
    {id:"de_494", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wenn du 9 Euro hast und du gibst 4 Euro aus, wie viel Geld hast du noch übrig?", choices:["5 Euro","4 Euro","6 Euro"], correctChoiceIndex:0},
    {id:"de_495", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wenn du 5 Euro hast und du gibst 2 Euro aus, wie viel Geld hast du noch übrig?", choices:["3 Euro","4 Euro","5 Euro"], correctChoiceIndex:0},
    {id:"de_496", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wenn du 6 Euro hast und du gibst 3 Euro aus, wie viel Geld hast du noch übrig?", choices:["3 Euro","4 Euro","5 Euro"], correctChoiceIndex:0},
    {id:"de_497", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wenn du 7 Euro hast und du gibst 3 Euro aus, wie viel Geld hast du noch übrig?", choices:["4 Euro","5 Euro","6 Euro"], correctChoiceIndex:0},
    {id:"de_498", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wenn du 8 Euro hast und du gibst 4 Euro aus, wie viel Geld hast du noch übrig?", choices:["4 Euro","5 Euro","6 Euro"], correctChoiceIndex:0},
    {id:"de_499", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wenn du 9 Euro hast und du gibst 4 Euro aus, wie viel Geld hast du noch übrig?", choices:["5 Euro","6 Euro","7 Euro"], correctChoiceIndex:0},
    {id:"de_500", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wenn du 10 Euro hast und du gibst 5 Euro aus, wie viel Geld hast du noch übrig?", choices:["5 Euro","6 Euro","7 Euro"], correctChoiceIndex:0},
    {id:"de_501", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Was solltest du tun, bevor du die Straße überquerst?", choices:["Laufen","Schauen, hören und dann gehen","Tanzen"], correctChoiceIndex:1},
    {id:"de_502", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Was solltest du tragen, um nachts sicherer zu sein?", choices:["Ein Hut","Helle Kleidung","Eine Sonnenbrille"], correctChoiceIndex:1},
    {id:"de_503", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Was ist die Nummer, die du im Notfall anrufen solltest?", choices:["123","112","999"], correctChoiceIndex:1},
    {id:"de_504", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Wo ist der sicherste Ort zum Spielen?", choices:["Auf der Straße","Im Haus","Auf der Autobahn"], correctChoiceIndex:1},
    {id:"de_505", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Was solltest du tun, wenn ein Fremder dir Süßigkeiten anbietet?", choices:["Nehmen und essen","Nein sagen und weggehen","Fragen, ob er mehr hat"], correctChoiceIndex:1},
    {id:"de_506", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Was solltest du tun, wenn du einen Unfall siehst?", choices:["Lachen","Hilfe rufen","Weglaufen"], correctChoiceIndex:1},
    {id:"de_507", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Was solltest du tun, wenn du dich verletzt hast?", choices:["Weinen","Einen Erwachsenen um Hilfe bitten","Alleine bleiben"], correctChoiceIndex:1},
    {id:"de_508", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Was solltest du tun, wenn du einen Hund ohne Besitzer siehst?", choices:["Ihn streicheln","Weglaufen","Einen Erwachsenen informieren"], correctChoiceIndex:2},
    {id:"de_509", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Was solltest du tun, wenn du ein Messer auf dem Boden siehst?", choices:["Es aufheben","Darauf treten","Einen Erwachsenen informieren"], correctChoiceIndex:2},
    {id:"de_510", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Was solltest du tun, wenn du ein kaputtes Glas auf dem Boden siehst?", choices:["Es aufheben","Darauf treten","Einen Erwachsenen informieren"], correctChoiceIndex:2},
    {id:"de_511", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Was solltest du tun, wenn du einen großen Hund siehst?", choices:["Ihn streicheln","Weglaufen","Einen Erwachsenen informieren"], correctChoiceIndex:2},
    {id:"de_512", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was bedeutet ein rotes Licht an einer Ampel?", choices:["Gehen","Laufen","Stoppen"], correctChoiceIndex:2},
    {id:"de_513", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was solltest du tun, wenn du dich verlaufen hast?", choices:["Weinen","Einen Erwachsenen um Hilfe bitten","Alleine nach Hause gehen"], correctChoiceIndex:1},
    {id:"de_514", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was solltest du tun, wenn es zu Hause brennt?", choices:["Verstecken","Aus dem Haus rennen und Hilfe rufen","Im Haus bleiben"], correctChoiceIndex:1},
    {id:"de_515", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was bedeutet ein grünes Licht an einer Ampel?", choices:["Stoppen","Gehen","Laufen"], correctChoiceIndex:1},
    {id:"de_516", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was solltest du tun, wenn du siehst, dass jemand in Gefahr ist?", choices:["Weglaufen","Hilfe rufen","Zuschauen"], correctChoiceIndex:1},
    {id:"de_517", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was solltest du tun, wenn du ein Feuer siehst?", choices:["Es berühren","Hilfe rufen","Es ignorieren"], correctChoiceIndex:1},
    {id:"de_518", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was solltest du tun, wenn du einen Fremden vor deinem Haus siehst?", choices:["Mit ihm spielen","Einen Erwachsenen informieren","Ihn hereinlassen"], correctChoiceIndex:1},
    {id:"de_519", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was solltest du tun, wenn du dich in einem Geschäft verlaufen hast?", choices:["Etwas kaufen","Weinen","Einen Mitarbeiter um Hilfe bitten"], correctChoiceIndex:2},
    {id:"de_520", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Was solltest du tun, wenn du einen Fremden in der Schule siehst?", choices:["Mit ihm spielen","Einen Lehrer informieren","Ihn ignorieren"], correctChoiceIndex:1},
    {id:"de_521", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Wie viele Tage hat eine Woche?", choices:["5","7","10"], correctChoiceIndex:1},
    {id:"de_522", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welcher Tag kommt nach Montag?", choices:["Dienstag","Mittwoch","Donnerstag"], correctChoiceIndex:0},
    {id:"de_523", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welcher Tag kommt vor Freitag?", choices:["Donnerstag","Mittwoch","Dienstag"], correctChoiceIndex:0},
    {id:"de_524", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Wie viele Monate hat ein Jahr?", choices:["10","12","15"], correctChoiceIndex:1},
    {id:"de_525", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welcher Monat kommt nach Januar?", choices:["Februar","März","April"], correctChoiceIndex:0},
    {id:"de_526", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welcher Monat kommt vor Dezember?", choices:["November","Oktober","September"], correctChoiceIndex:0},
    {id:"de_527", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wie viele Stunden hat ein Tag?", choices:["24","12","48"], correctChoiceIndex:0},
    {id:"de_528", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wie viele Minuten hat eine Stunde?", choices:["60","30","120"], correctChoiceIndex:0},
    {id:"de_529", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wenn es 3 Uhr ist, welcher Zeiger ist länger?", choices:["Stundenzeiger","Minutenzeiger","Sekundenzeiger"], correctChoiceIndex:1},
    {id:"de_530", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wenn es 15 Uhr ist, was zeigt die Uhr im 12-Stunden-Format an?", choices:["3 Uhr","5 Uhr","7 Uhr"], correctChoiceIndex:0},
    {id:"de_531", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wie viele Sekunden hat eine Minute?", choices:["60","30","120"], correctChoiceIndex:0},
    {id:"de_532", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welcher Tag kommt nach Sonntag?", choices:["Montag","Dienstag","Mittwoch"], correctChoiceIndex:0},
    {id:"de_533", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welcher Monat kommt nach Juni?", choices:["Juli","August","September"], correctChoiceIndex:0},
    {id:"de_534", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wenn es 12 Uhr mittags ist, was zeigt die Uhr im 24-Stunden-Format an?", choices:["12 Uhr","24 Uhr","0 Uhr"], correctChoiceIndex:0},
    {id:"de_535", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wenn es 12 Uhr nachts ist, was zeigt die Uhr im 24-Stunden-Format an?", choices:["0 Uhr","12 Uhr","24 Uhr"], correctChoiceIndex:0},
    {id:"de_536", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wie viele Tage hat der Februar in einem Schaltjahr?", choices:["28","29","30"], correctChoiceIndex:1},
    {id:"de_537", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welcher Monat hat 31 Tage?", choices:["Januar","Februar","April"], correctChoiceIndex:0},
    {id:"de_538", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welcher Tag kommt vor Samstag?", choices:["Freitag","Donnerstag","Mittwoch"], correctChoiceIndex:0},
    {id:"de_539", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welcher Monat kommt vor Mai?", choices:["April","März","Februar"], correctChoiceIndex:0},
    {id:"de_540", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Wie viele Minuten hat eine halbe Stunde?", choices:["15","30","45"], correctChoiceIndex:1},
    {id:"de_541", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Malen?", choices:["Pinsel","Hammer","Schraubenzieher"], correctChoiceIndex:0},
    {id:"de_542", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Schneiden von Papier?", choices:["Schere","Zange","Schraubenschlüssel"], correctChoiceIndex:0},
    {id:"de_543", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Kleben?", choices:["Klebestift","Säge","Bohrmaschine"], correctChoiceIndex:0},
    {id:"de_544", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Zeichnen?", choices:["Bleistift","Schraubenzieher","Zange"], correctChoiceIndex:0},
    {id:"de_545", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Ausmalen?", choices:["Buntstifte","Hammer","Schraubenschlüssel"], correctChoiceIndex:0},
    {id:"de_546", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Schneiden von Stoff?", choices:["Stoffschere","Hammer","Bohrmaschine"], correctChoiceIndex:0},
    {id:"de_547", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Nähen?", choices:["Nadel","Zange","Säge"], correctChoiceIndex:0},
    {id:"de_548", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Lochen von Papier?", choices:["Locher","Schraubenschlüssel","Bohrmaschine"], correctChoiceIndex:0},
    {id:"de_549", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Radieren?", choices:["Radiergummi","Hammer","Zange"], correctChoiceIndex:0},
    {id:"de_550", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Schneiden von Faden?", choices:["Schere","Schraubenzieher","Bohrmaschine"], correctChoiceIndex:0},
    {id:"de_551", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Formen von Ton?", choices:["Töpferwerkzeuge","Zange","Hammer"], correctChoiceIndex:0},
    {id:"de_552", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Mischen von Farben?", choices:["Pinsel","Schraubenschlüssel","Bohrmaschine"], correctChoiceIndex:0},
    {id:"de_553", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Schneiden von Klebeband?", choices:["Schere","Zange","Hammer"], correctChoiceIndex:0},
    {id:"de_554", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Zeichnen von Linien?", choices:["Lineal","Säge","Bohrmaschine"], correctChoiceIndex:0},
    {id:"de_555", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Schneiden von Brot?", choices:["Brotmesser","Schraubenzieher","Zange"], correctChoiceIndex:0},
    {id:"de_556", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Basteln?", choices:["Bastelschere","Bohrmaschine","Schraubenschlüssel"], correctChoiceIndex:0},
    {id:"de_557", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Messen?", choices:["Lineal","Hammer","Zange"], correctChoiceIndex:0},
    {id:"de_558", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Schreiben?", choices:["Stift","Säge","Bohrmaschine"], correctChoiceIndex:0},
    {id:"de_559", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Falten von Papier?", choices:["Hände","Schraubenzieher","Schraubenschlüssel"], correctChoiceIndex:0},
    {id:"de_560", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Bauen mit Legosteinen?", choices:["Hände","Zange","Hammer"], correctChoiceIndex:0},
    {id:"de_561", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Bauen von Modellen?", choices:["Modellbauwerkzeuge","Hammer","Zange"], correctChoiceIndex:0},
    {id:"de_562", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Zeichnen von Kreisen?", choices:["Zirkel","Säge","Schraubenschlüssel"], correctChoiceIndex:0},
    {id:"de_563", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Schneiden von Karton?", choices:["Schere","Bohrmaschine","Zange"], correctChoiceIndex:0},
    {id:"de_564", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Befestigen von Papier?", choices:["Heftgerät","Hammer","Säge"], correctChoiceIndex:0},
    {id:"de_565", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Schneiden von Draht?", choices:["Drahtschneider","Schraubenzieher","Bohrmaschine"], correctChoiceIndex:0},
    {id:"de_566", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Bauen von Sandburgen?", choices:["Schaufel","Hammer","Schraubenschlüssel"], correctChoiceIndex:0},
    {id:"de_567", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Schneiden von Obst?", choices:["Obstmesser","Bohrmaschine","Zange"], correctChoiceIndex:0},
    {id:"de_568", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Schneiden von Gemüse?", choices:["Gemüsemesser","Hammer","Säge"], correctChoiceIndex:0},
    {id:"de_569", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Schneiden von Fleisch?", choices:["Fleischmesser","Schraubenzieher","Bohrmaschine"], correctChoiceIndex:0},
    {id:"de_570", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Welches Werkzeug benutzt du zum Schneiden von Käse?", choices:["Käsemesser","Zange","Hammer"], correctChoiceIndex:0},
    {id:"de_571", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welche Farbe erhältst du, wenn du Rot und Blau mischst?", choices:["Grün","Lila","Orange"], correctChoiceIndex:1},
    {id:"de_572", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welche Farbe erhältst du, wenn du Gelb und Blau mischst?", choices:["Grün","Lila","Orange"], correctChoiceIndex:0},
    {id:"de_573", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welche Farbe erhältst du, wenn du Rot und Gelb mischst?", choices:["Grün","Lila","Orange"], correctChoiceIndex:2},
    {id:"de_574", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Was ist eine Skulptur?", choices:["Ein Lied","Ein Bild","Ein dreidimensionales Kunstwerk"], correctChoiceIndex:2},
    {id:"de_575", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Was ist ein Gemälde?", choices:["Ein Lied","Ein Bild","Ein dreidimensionales Kunstwerk"], correctChoiceIndex:1},
    {id:"de_576", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Wer hat das Bild Mona Lisa gemalt?", choices:["Vincent Van Gogh","Leonardo da Vinci","Pablo Picasso"], correctChoiceIndex:1},
    {id:"de_577", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Wer hat das Bild Sternennacht gemalt?", choices:["Vincent Van Gogh","Leonardo da Vinci","Pablo Picasso"], correctChoiceIndex:0},
    {id:"de_578", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Wer hat das Bild Guernica gemalt?", choices:["Vincent Van Gogh","Leonardo da Vinci","Pablo Picasso"], correctChoiceIndex:2},
    {id:"de_579", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Was ist ein Selbstporträt?", choices:["Ein Bild von jemand anderem","Ein Bild von dir selbst","Ein Bild von einem Tier"], correctChoiceIndex:1},
    {id:"de_580", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Was ist ein Stillleben?", choices:["Ein Bild von beweglichen Objekten","Ein Bild von stillen (unbeweglichen) Objekten","Ein Bild von Tieren"], correctChoiceIndex:1},
    {id:"de_581", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Was ist ein Landschaftsbild?", choices:["Ein Bild von Gebäuden","Ein Bild von Menschen","Ein Bild von Natur"], correctChoiceIndex:2},
    {id:"de_582", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Was ist ein Porträt?", choices:["Ein Bild von einem Tier","Ein Bild von einer Person","Ein Bild von einem Gebäude"], correctChoiceIndex:1},
    {id:"de_583", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Was ist ein Mosaik?", choices:["Ein Bild aus kleinen Stücken von Glas, Stein oder Keramik","Ein Bild aus Farbe","Ein Bild aus Ton"], correctChoiceIndex:0},
    {id:"de_584", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Was ist eine Collage?", choices:["Ein Bild aus geschnittenen oder gerissenen Stücken von Papier oder Stoff","Ein Bild aus Farbe","Ein Bild aus Ton"], correctChoiceIndex:0},
    {id:"de_585", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Was ist ein Aquarell?", choices:["Ein Bild aus Wasser und Farbe","Ein Bild aus Öl und Farbe","Ein Bild aus Ton und Farbe"], correctChoiceIndex:0},
    {id:"de_586", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Was ist ein Ölgemälde?", choices:["Ein Bild aus Wasser und Farbe","Ein Bild aus Öl und Farbe","Ein Bild aus Ton und Farbe"], correctChoiceIndex:1},
    {id:"de_587", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Was ist ein Pastellbild?", choices:["Ein Bild aus weichen Kreiden","Ein Bild aus harten Kreiden","Ein Bild aus Öl und Farbe"], correctChoiceIndex:0},
    {id:"de_588", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Was ist ein Acrylbild?", choices:["Ein Bild aus Wasser und Farbe","Ein Bild aus Öl und Farbe","Ein Bild aus Acryl und Farbe"], correctChoiceIndex:2},
    {id:"de_589", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Was ist ein Fresko?", choices:["Ein Bild auf nasser Kalkputz","Ein Bild auf trockener Wand","Ein Bild auf Papier"], correctChoiceIndex:0},
    {id:"de_590", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Was ist ein Graffiti?", choices:["Ein Bild auf Papier","Ein Bild auf einer Wand oder Oberfläche im öffentlichen Raum","Ein Bild auf Leinwand"], correctChoiceIndex:1},
    {id:"de_591", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in Spanien getanzt?", choices:["Ballett","Flamenco","Hip-Hop"], correctChoiceIndex:1},
    {id:"de_592", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in Irland getanzt?", choices:["Tango","Irischer Stepptanz","Salsa"], correctChoiceIndex:1},
    {id:"de_593", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in Indien getanzt?", choices:["Bollywood-Tanz","Jazz","Ballett"], correctChoiceIndex:0},
    {id:"de_594", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in Argentinien getanzt?", choices:["Tango","Hip-Hop","Bollywood-Tanz"], correctChoiceIndex:0},
    {id:"de_595", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in Amerika getanzt?", choices:["Hip-Hop","Tango","Flamenco"], correctChoiceIndex:0},
    {id:"de_596", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in China getanzt?", choices:["Drachentanz","Samba","Tango"], correctChoiceIndex:0},
    {id:"de_597", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in Hawaii getanzt?", choices:["Hula","Tango","Hip-Hop"], correctChoiceIndex:0},
    {id:"de_598", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in Japan getanzt?", choices:["Bon Odori","Salsa","Ballett"], correctChoiceIndex:0},
    {id:"de_599", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in Griechenland getanzt?", choices:["Sirtaki","Hip-Hop","Salsa"], correctChoiceIndex:0},
    {id:"de_600", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in Australien getanzt?", choices:["Aboriginal-Tanz","Salsa","Ballett"], correctChoiceIndex:0},
    {id:"de_601", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in Russland getanzt?", choices:["Ballett","Salsa","Bollywood-Tanz"], correctChoiceIndex:0},
    {id:"de_602", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in Kuba getanzt?", choices:["Salsa","Ballett","Hip-Hop"], correctChoiceIndex:0},
    {id:"de_603", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in Brasilien getanzt?", choices:["Samba","Ballett","Hip-Hop"], correctChoiceIndex:0},
    {id:"de_604", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in Ägypten getanzt?", choices:["Bauchtanz","Salsa","Ballett"], correctChoiceIndex:0},
    {id:"de_605", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in Frankreich getanzt?", choices:["Can-Can","Samba","Hip-Hop"], correctChoiceIndex:0},
    {id:"de_606", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in Afrika getanzt?", choices:["Afrikanischer Tanz","Salsa","Ballett"], correctChoiceIndex:0},
    {id:"de_607", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in der Karibik getanzt?", choices:["Reggae","Salsa","Ballett"], correctChoiceIndex:0},
    {id:"de_608", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in Neuseeland getanzt?", choices:["Haka","Salsa","Ballett"], correctChoiceIndex:0},
    {id:"de_609", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in der Türkei getanzt?", choices:["Türkischer Volkstanz","Salsa","Ballett"], correctChoiceIndex:0},
    {id:"de_610", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welcher Tanz wird oft in Schottland getanzt?", choices:["Schottischer Volkstanz","Salsa","Ballett"], correctChoiceIndex:0},
    {id:"de_611", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welches Märchen hat die Brüder Grimm geschrieben?", choices:["Aschenputtel","Moby Dick","Der alte Mann und das Meer"], correctChoiceIndex:0},
    {id:"de_612", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Wer ist der Hauptcharakter in Rotkäppchen?", choices:["Die böse Hexe","Der große böse Wolf","Rotkäppchen"], correctChoiceIndex:2},
    {id:"de_613", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Wie endet das Märchen Die drei kleinen Schweinchen?", choices:["Sie bauen ein Haus aus Ziegelsteinen","Sie fliegen zum Mond","Sie werden zu Superhelden"], correctChoiceIndex:0},
    {id:"de_614", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Wer hilft Pinocchio auf seinem Abenteuer?", choices:["Die blaue Fee","Der König der Löwen","Der Zauberer von Oz"], correctChoiceIndex:0},
    {id:"de_615", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Was passiert mit Schneewittchen im Märchen?", choices:["Sie schläft ein","Sie wird zur Königin","Sie fliegt auf einem Besen"], correctChoiceIndex:0},
    {id:"de_616", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Wer schrieb das Buch Der kleine Prinz?", choices:["Antoine de Saint-Exupéry","J.K. Rowling","Roald Dahl"], correctChoiceIndex:0},
    {id:"de_617", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"In welchem Märchen gibt es eine goldene Gans?", choices:["Die goldene Gans","Hänsel und Gretel","Der Froschkönig"], correctChoiceIndex:0},
    {id:"de_618", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Wer sind die Hauptfiguren in Hänsel und Gretel?", choices:["Hänsel und Gretel","Alice und der weiße Kaninchen","Peter Pan und Wendy"], correctChoiceIndex:0},
    {id:"de_619", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Was passiert mit der bösen Hexe am Ende des Märchens Hänsel und Gretel?", choices:["Sie wird in ihrem eigenen Ofen gebacken","Sie wird zur Königin","Sie fliegt auf einem Besen"], correctChoiceIndex:0},
    {id:"de_620", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Wer ist der Autor von Alice im Wunderland?", choices:["Lewis Carroll","J.K. Rowling","Roald Dahl"], correctChoiceIndex:0},
    {id:"de_621", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"In welchem Märchen gibt es einen sprechenden Spiegel?", choices:["Schneewittchen","Aschenputtel","Die kleine Meerjungfrau"], correctChoiceIndex:0},
    {id:"de_622", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Wer ist der Hauptcharakter in Peter Pan?", choices:["Peter Pan","Der kleine Prinz","Harry Potter"], correctChoiceIndex:0},
    {id:"de_623", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Wer schrieb das Buch Charlie und die Schokoladenfabrik?", choices:["Roald Dahl","J.K. Rowling","Antoine de Saint-Exupéry"], correctChoiceIndex:0},
    {id:"de_624", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"In welchem Märchen gibt es eine Prinzessin, die auf einer Erbse schläft?", choices:["Die Prinzessin auf der Erbse","Aschenputtel","Die kleine Meerjungfrau"], correctChoiceIndex:0},
    {id:"de_625", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Wer sind die Hauptfiguren in Die Bremer Stadtmusikanten?", choices:["Ein Esel, ein Hund, eine Katze und ein Hahn","Hänsel und Gretel","Alice und der weiße Kaninchen"], correctChoiceIndex:0},
    {id:"de_626", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Wer ist der Autor von Der Zauberer von Oz?", choices:["L. Frank Baum","Roald Dahl","J.K. Rowling"], correctChoiceIndex:0},
    {id:"de_627", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"In welchem Märchen gibt es einen Wolf, der die Großmutter frisst?", choices:["Rotkäppchen","Die drei kleinen Schweinchen","Der Froschkönig"], correctChoiceIndex:0},
    {id:"de_628", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Wer ist der Hauptcharakter in Der kleine Prinz?", choices:["Der kleine Prinz","Peter Pan","Harry Potter"], correctChoiceIndex:0},
    {id:"de_629", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Wer schrieb das Buch Matilda?", choices:["Roald Dahl","J.K. Rowling","Antoine de Saint-Exupéry"], correctChoiceIndex:0},
    {id:"de_630", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"In welchem Märchen gibt es eine Hexe, die ein Lebkuchenhaus besitzt?", choices:["Hänsel und Gretel","Die drei kleinen Schweinchen","Der Froschkönig"], correctChoiceIndex:0},
    {id:"de_631", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welches Instrument hat schwarze und weiße Tasten?", choices:["Gitarre","Klavier","Trommel"], correctChoiceIndex:1},
    {id:"de_632", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welches Instrument benutzt man einen Bogen um zu spielen?", choices:["Geige","Flöte","Trompete"], correctChoiceIndex:0},
    {id:"de_633", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welches Instrument hat sechs Saiten?", choices:["Klavier","Gitarre","Trommel"], correctChoiceIndex:1},
    {id:"de_634", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welches Instrument macht ein lautes Geräusch, wenn man es schlägt?", choices:["Flöte","Geige","Trommel"], correctChoiceIndex:2},
    {id:"de_635", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welches Instrument bläst man hinein, um Töne zu erzeugen?", choices:["Flöte","Gitarre","Geige"], correctChoiceIndex:0},
    {id:"de_636", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welches Instrument hat 88 Tasten?", choices:["Klavier","Gitarre","Trommel"], correctChoiceIndex:0},
    {id:"de_637", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welches Instrument hat vier Saiten und wird mit einem Bogen gespielt?", choices:["Geige","Flöte","Trompete"], correctChoiceIndex:0},
    {id:"de_638", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welches Instrument hat eine große, runde Oberfläche, die man schlägt?", choices:["Flöte","Geige","Trommel"], correctChoiceIndex:2},
    {id:"de_639", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welches Instrument hat Tasten und wird oft in Jazzmusik verwendet?", choices:["Saxophon","Trompete","Klavier"], correctChoiceIndex:2},
    {id:"de_640", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welches Instrument hat man normalerweise zwischen den Knien, wenn man es spielt?", choices:["Cello","Flöte","Trompete"], correctChoiceIndex:0},
    {id:"de_641", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welches Instrument hat man normalerweise auf dem Schoß, wenn man es spielt?", choices:["Gitarre","Flöte","Trompete"], correctChoiceIndex:0},
    {id:"de_642", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welches Instrument hat man normalerweise unter dem Kinn, wenn man es spielt?", choices:["Geige","Flöte","Trompete"], correctChoiceIndex:0},
    {id:"de_643", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welches Instrument hat man normalerweise an den Lippen, wenn man es spielt?", choices:["Flöte","Gitarre","Geige"], correctChoiceIndex:0},
    {id:"de_644", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welches Instrument hat man normalerweise in den Händen, wenn man es spielt?", choices:["Trommel","Flöte","Trompete"], correctChoiceIndex:0},
    {id:"de_645", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welches Instrument hat man normalerweise auf den Schultern, wenn man es spielt?", choices:["Geige","Flöte","Trompete"], correctChoiceIndex:0},
    {id:"de_646", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welches Instrument hat man normalerweise zwischen den Beinen, wenn man es spielt?", choices:["Cello","Flöte","Trompete"], correctChoiceIndex:0},
    {id:"de_647", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welches Instrument hat man normalerweise auf dem Rücken, wenn man es spielt?", choices:["Gitarre","Flöte","Trompete"], correctChoiceIndex:0},
    {id:"de_648", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welches Instrument hat man normalerweise auf dem Bauch, wenn man es spielt?", choices:["Gitarre","Flöte","Trompete"], correctChoiceIndex:0},
    {id:"de_649", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welches Instrument hat man normalerweise auf dem Kopf, wenn man es spielt?", choices:["Trommel","Flöte","Trompete"], correctChoiceIndex:0},
    {id:"de_650", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welches Instrument hat man normalerweise in der Hand, wenn man es spielt?", choices:["Trommel","Flöte","Trompete"], correctChoiceIndex:0},
    {id:"de_651", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welche Sprache sprechen sie in Spanien?", choices:["Englisch","Spanisch","Französisch"], correctChoiceIndex:1},
    {id:"de_652", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welches Land feiert das Fest Diwali?", choices:["Indien","Australien","Kanada"], correctChoiceIndex:0},
    {id:"de_653", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welche Sprache sprechen sie in Frankreich?", choices:["Deutsch","Spanisch","Französisch"], correctChoiceIndex:2},
    {id:"de_654", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welches Land feiert das Fest Hanukkah?", choices:["Israel","Russland","Brasilien"], correctChoiceIndex:0},
    {id:"de_655", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welche Sprache sprechen sie in Italien?", choices:["Italienisch","Englisch","Spanisch"], correctChoiceIndex:0},
    {id:"de_656", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welche Sprache sprechen sie in Russland?", choices:["Russisch","Englisch","Spanisch"], correctChoiceIndex:0},
    {id:"de_657", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"In welchem Land feiern sie das Fest Cinco de Mayo?", choices:["Mexiko","USA","Kanada"], correctChoiceIndex:0},
    {id:"de_658", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welche Sprache sprechen sie in Deutschland?", choices:["Deutsch","Englisch","Spanisch"], correctChoiceIndex:0},
    {id:"de_659", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"In welchem Land feiern sie das Fest Chinese New Year?", choices:["China","USA","Kanada"], correctChoiceIndex:0},
    {id:"de_660", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Welche Sprache sprechen sie in Kanada?", choices:["Englisch","Spanisch","Französisch"], correctChoiceIndex:0},
    {id:"de_661", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"In welchem Land feiern sie das Fest Day of the Dead?", choices:["Mexiko","USA","Kanada"], correctChoiceIndex:0},
    {id:"de_662", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"In welchem Land feiern sie das Fest Karneval?", choices:["Brasilien","Kanada","Australien"], correctChoiceIndex:0},
    {id:"de_663", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welche Sprache sprechen sie in Japan?", choices:["Japanisch","Englisch","Spanisch"], correctChoiceIndex:0},
    {id:"de_664", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"In welchem Land feiern sie das Fest Oktoberfest?", choices:["Deutschland","Frankreich","Italien"], correctChoiceIndex:0},
    {id:"de_665", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welche Sprache sprechen sie in China?", choices:["Chinesisch","Englisch","Spanisch"], correctChoiceIndex:0},
    {id:"de_666", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"In welchem Land feiern sie das Fest Mardi Gras?", choices:["USA","Kanada","Australien"], correctChoiceIndex:0},
    {id:"de_667", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welche Sprache sprechen sie in Brasilien?", choices:["Portugiesisch","Englisch","Spanisch"], correctChoiceIndex:0},
    {id:"de_668", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"In welchem Land feiern sie das Fest St. Patrick's Day?", choices:["Irland","USA","Kanada"], correctChoiceIndex:0},
    {id:"de_669", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Welche Sprache sprechen sie in Australien?", choices:["Englisch","Spanisch","Französisch"], correctChoiceIndex:0},
    {id:"de_670", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"In welchem Land feiern sie das Fest Bastille Day?", choices:["Frankreich","USA","Kanada"], correctChoiceIndex:0},
    {id:"de_671", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Welche Einheit wird verwendet, um die Länge zu messen?", choices:["Meter","Liter","Gramm"], correctChoiceIndex:0},
    {id:"de_672", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Welche Einheit wird verwendet, um das Gewicht zu messen?", choices:["Meter","Liter","Gramm"], correctChoiceIndex:2},
    {id:"de_673", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Welche Einheit wird verwendet, um das Volumen zu messen?", choices:["Meter","Liter","Gramm"], correctChoiceIndex:1},
    {id:"de_674", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Was ist länger, 1 Meter oder 1 Kilometer?", choices:["1 Meter","1 Kilometer","Sie sind gleich lang"], correctChoiceIndex:1},
    {id:"de_675", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Was ist schwerer, 1 Gramm oder 1 Kilogramm?", choices:["1 Gramm","1 Kilogramm","Sie sind gleich schwer"], correctChoiceIndex:1},
    {id:"de_676", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Was hat mehr Volumen, 1 Liter oder 1 Milliliter?", choices:["1 Liter","1 Milliliter","Sie haben das gleiche Volumen"], correctChoiceIndex:0},
    {id:"de_677", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Meter sind in einem Kilometer?", choices:["100","1000","10000"], correctChoiceIndex:1},
    {id:"de_678", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Gramm sind in einem Kilogramm?", choices:["100","1000","10000"], correctChoiceIndex:1},
    {id:"de_679", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Milliliter sind in einem Liter?", choices:["10","100","1000"], correctChoiceIndex:2},
    {id:"de_680", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Was ist länger, 1 Meter oder 100 Zentimeter?", choices:["1 Meter","100 Zentimeter","Sie sind gleich lang"], correctChoiceIndex:2},
    {id:"de_681", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Zentimeter sind in einem Meter?", choices:["100","1000","10000"], correctChoiceIndex:0},
    {id:"de_682", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Milligramm sind in einem Gramm?", choices:["100","1000","10000"], correctChoiceIndex:1},
    {id:"de_683", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Zentiliter sind in einem Liter?", choices:["100","1000","10000"], correctChoiceIndex:0},
    {id:"de_684", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist länger, 1 Kilometer oder 1000 Meter?", choices:["1 Kilometer","1000 Meter","Sie sind gleich lang"], correctChoiceIndex:2},
    {id:"de_685", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist schwerer, 1 Kilogramm oder 1000 Gramm?", choices:["1 Kilogramm","1000 Gramm","Sie sind gleich schwer"], correctChoiceIndex:2},
    {id:"de_686", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was hat mehr Volumen, 1 Liter oder 1000 Milliliter?", choices:["1 Liter","1000 Milliliter","Sie haben das gleiche Volumen"], correctChoiceIndex:2},
    {id:"de_687", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Millimeter sind in einem Zentimeter?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"de_688", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Zentigramm sind in einem Gramm?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"de_689", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Milliliter sind in einem Zentiliter?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"de_690", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist länger, 1 Zentimeter oder 10 Millimeter?", choices:["1 Zentimeter","10 Millimeter","Sie sind gleich lang"], correctChoiceIndex:2},
    {id:"de_691", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist schwerer, 1 Gramm oder 10 Zentigramm?", choices:["1 Gramm","10 Zentigramm","Sie sind gleich schwer"], correctChoiceIndex:2},
    {id:"de_692", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was hat mehr Volumen, 1 Zentiliter oder 10 Milliliter?", choices:["1 Zentiliter","10 Milliliter","Sie haben das gleiche Volumen"], correctChoiceIndex:2},
    {id:"de_693", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Meter sind in 2 Kilometern?", choices:["200","2000","20000"], correctChoiceIndex:1},
    {id:"de_694", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Gramm sind in 2 Kilogramm?", choices:["200","2000","20000"], correctChoiceIndex:1},
    {id:"de_695", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Liter sind in 2 Hektolitern?", choices:["200","2000","20000"], correctChoiceIndex:1},
    {id:"de_696", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Zentimeter sind in 2 Metern?", choices:["200","2000","20000"], correctChoiceIndex:0},
    {id:"de_697", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Milligramm sind in 2 Gramm?", choices:["200","2000","20000"], correctChoiceIndex:1},
    {id:"de_698", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Zentiliter sind in 2 Litern?", choices:["200","2000","20000"], correctChoiceIndex:0},
    {id:"de_699", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Millimeter sind in 2 Zentimetern?", choices:["20","200","2000"], correctChoiceIndex:0},
    {id:"de_700", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Zentigramm sind in 2 Gramm?", choices:["20","200","2000"], correctChoiceIndex:0},
    {id:"de_701", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Milliliter sind in 2 Zentilitern?", choices:["20","200","2000"], correctChoiceIndex:0},
    {id:"de_702", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist länger, 2 Meter oder 200 Zentimeter?", choices:["2 Meter","200 Zentimeter","Sie sind gleich lang"], correctChoiceIndex:2},
    {id:"de_703", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist schwerer, 2 Gramm oder 200 Zentigramm?", choices:["2 Gramm","200 Zentigramm","Sie sind gleich schwer"], correctChoiceIndex:2},
    {id:"de_704", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was hat mehr Volumen, 2 Liter oder 200 Zentiliter?", choices:["2 Liter","200 Zentiliter","Sie haben das gleiche Volumen"], correctChoiceIndex:2},
    {id:"de_705", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Kilometer sind in 3000 Metern?", choices:["3","30","300"], correctChoiceIndex:0},
    {id:"de_706", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Kilogramm sind in 3000 Gramm?", choices:["3","30","300"], correctChoiceIndex:0},
    {id:"de_707", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Hektoliter sind in 3000 Litern?", choices:["3","30","300"], correctChoiceIndex:0},
    {id:"de_708", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Meter sind in 300 Zentimeter?", choices:["3","30","300"], correctChoiceIndex:0},
    {id:"de_709", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Gramm sind in 300 Milligramm?", choices:["0.3","3","30"], correctChoiceIndex:0},
    {id:"de_710", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Liter sind in 300 Zentiliter?", choices:["3","30","300"], correctChoiceIndex:0},
    {id:"de_711", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Zentimeter sind in 30 Millimeter?", choices:["3","30","300"], correctChoiceIndex:0},
    {id:"de_712", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Welche Einheit wird verwendet, um die Länge zu messen?", choices:["Meter","Liter","Gramm"], correctChoiceIndex:0},
    {id:"de_713", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Welche Einheit wird verwendet, um das Gewicht zu messen?", choices:["Meter","Liter","Gramm"], correctChoiceIndex:2},
    {id:"de_714", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Welche Einheit wird verwendet, um das Volumen zu messen?", choices:["Meter","Liter","Gramm"], correctChoiceIndex:1},
    {id:"de_715", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Was ist länger, 1 Meter oder 1 Kilometer?", choices:["1 Meter","1 Kilometer","Beide sind gleich"], correctChoiceIndex:1},
    {id:"de_716", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Was ist schwerer, 1 Gramm oder 1 Kilogramm?", choices:["1 Gramm","1 Kilogramm","Beide sind gleich"], correctChoiceIndex:1},
    {id:"de_717", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Was ist mehr, 1 Liter oder 1 Milliliter?", choices:["1 Liter","1 Milliliter","Beide sind gleich"], correctChoiceIndex:0},
    {id:"de_718", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Meter sind in einem Kilometer?", choices:["100 Meter","1000 Meter","10 Meter"], correctChoiceIndex:1},
    {id:"de_719", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Gramm sind in einem Kilogramm?", choices:["100 Gramm","1000 Gramm","10 Gramm"], correctChoiceIndex:1},
    {id:"de_720", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Milliliter sind in einem Liter?", choices:["100 Milliliter","1000 Milliliter","10 Milliliter"], correctChoiceIndex:1},
    {id:"de_721", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Was ist länger, 1 Meter oder 100 Zentimeter?", choices:["1 Meter","100 Zentimeter","Beide sind gleich"], correctChoiceIndex:2},
    {id:"de_722", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Millimeter sind in 1 Meter?", choices:["1000 Millimeter","100 Millimeter","10 Millimeter"], correctChoiceIndex:0},
    {id:"de_723", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Milliliter sind in 1 Liter?", choices:["1000 Milliliter","100 Milliliter","10 Milliliter"], correctChoiceIndex:0},
    {id:"de_724", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Milligramm sind in 1 Gramm?", choices:["1000 Milligramm","100 Milligramm","10 Milligramm"], correctChoiceIndex:0},
    {id:"de_725", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Zentimeter sind in 1 Meter?", choices:["100 Zentimeter","10 Zentimeter","1000 Zentimeter"], correctChoiceIndex:0},
    {id:"de_726", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Dezimeter sind in 1 Meter?", choices:["10 Dezimeter","1 Dezimeter","100 Dezimeter"], correctChoiceIndex:0},
    {id:"de_727", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Zentimeter sind in 1 Dezimeter?", choices:["10 Zentimeter","1 Zentimeter","100 Zentimeter"], correctChoiceIndex:0},
    {id:"de_728", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Meter sind in 1 Kilometer?", choices:["1000 Meter","100 Meter","10 Meter"], correctChoiceIndex:0},
    {id:"de_729", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Liter sind in 1 Kubikmeter?", choices:["1000 Liter","100 Liter","10 Liter"], correctChoiceIndex:0},
    {id:"de_730", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Gramm sind in 1 Kilogramm?", choices:["1000 Gramm","100 Gramm","10 Gramm"], correctChoiceIndex:0},
    {id:"de_731", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Kilometer sind in 1 Meter?", choices:["0.001 Kilometer","0.01 Kilometer","0.1 Kilometer"], correctChoiceIndex:0},
    {id:"de_732", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Meter sind in 1 Zentimeter?", choices:["0.01 Meter","0.1 Meter","1 Meter"], correctChoiceIndex:0},
    {id:"de_733", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Zentimeter sind in einem Meter?", choices:["100 Zentimeter","10 Zentimeter","1000 Zentimeter"], correctChoiceIndex:0},
    {id:"de_734", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Milligramm sind in einem Gramm?", choices:["1000 Milligramm","100 Milligramm","10 Milligramm"], correctChoiceIndex:0},
    {id:"de_735", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Kilogramm sind in einer Tonne?", choices:["1000 Kilogramm","100 Kilogramm","10 Kilogramm"], correctChoiceIndex:0},
    {id:"de_736", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Liter sind in einem Kubikmeter?", choices:["1000 Liter","100 Liter","10 Liter"], correctChoiceIndex:0},
    {id:"de_737", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Millimeter sind in einem Zentimeter?", choices:["10 Millimeter","100 Millimeter","1000 Millimeter"], correctChoiceIndex:0},
    {id:"de_738", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Zentimeter sind in einem Dezimeter?", choices:["10 Zentimeter","100 Zentimeter","1000 Zentimeter"], correctChoiceIndex:0},
    {id:"de_739", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Dezimeter sind in einem Meter?", choices:["10 Dezimeter","100 Dezimeter","1000 Dezimeter"], correctChoiceIndex:0},
    {id:"de_740", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Meter sind in einem Dezimeter?", choices:["0.1 Meter","1 Meter","10 Meter"], correctChoiceIndex:0},
    {id:"de_741", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Gramm sind in einem Dezigramm?", choices:["0.1 Gramm","1 Gramm","10 Gramm"], correctChoiceIndex:0},
    {id:"de_742", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Liter sind in einem Deziliter?", choices:["0.1 Liter","1 Liter","10 Liter"], correctChoiceIndex:0},
    {id:"de_743", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Millimeter sind in 1 Zentimeter?", choices:["10 Millimeter","1 Millimeter","100 Millimeter"], correctChoiceIndex:0},
    {id:"de_744", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Milliliter sind in 1 Deziliter?", choices:["100 Milliliter","10 Milliliter","1000 Milliliter"], correctChoiceIndex:0},
    {id:"de_745", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Milligramm sind in 1 Dezigramm?", choices:["10 Milligramm","1 Milligramm","100 Milligramm"], correctChoiceIndex:0},
    {id:"de_746", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Zentimeter sind in 1 Millimeter?", choices:["0.1 Zentimeter","1 Zentimeter","10 Zentimeter"], correctChoiceIndex:0},
    {id:"de_747", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Deziliter sind in 1 Milliliter?", choices:["0.01 Deziliter","0.1 Deziliter","1 Deziliter"], correctChoiceIndex:0},
    {id:"de_748", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Dezigramm sind in 1 Milligramm?", choices:["0.01 Dezigramm","0.1 Dezigramm","1 Dezigramm"], correctChoiceIndex:0},
    {id:"de_749", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Kubikmeter sind in 1 Liter?", choices:["0.001 Kubikmeter","0.01 Kubikmeter","0.1 Kubikmeter"], correctChoiceIndex:0},
    {id:"de_750", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Kilogramm sind in 1 Gramm?", choices:["0.001 Kilogramm","0.01 Kilogramm","0.1 Kilogramm"], correctChoiceIndex:0},
    {id:"de_751", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Liter sind in 1 Milliliter?", choices:["0.001 Liter","0.01 Liter","0.1 Liter"], correctChoiceIndex:0},
    {id:"de_752", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Äpfel siehst du?", choices:["1","2","3"], correctChoiceIndex:1},
    {id:"de_753", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 2 Bonbons hast und ich dir noch 1 gebe, wie viele hast du dann?", choices:["2","3","4"], correctChoiceIndex:2},
    {id:"de_754", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Finger hat eine Hand?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"de_755", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 3 Kekse hast und 1 isst, wie viele hast du dann?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"de_756", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Zehen hat ein Fuß?", choices:["5","10","15"], correctChoiceIndex:1},
    {id:"de_757", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 4 Stifte hast und 2 verlierst, wie viele hast du dann?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"de_758", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Augen hat eine Person?", choices:["1","2","3"], correctChoiceIndex:1},
    {id:"de_759", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 5 Münzen hast und 1 verlierst, wie viele hast du dann?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"de_760", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Ohren hat ein Hund?", choices:["1","2","3"], correctChoiceIndex:1},
    {id:"de_761", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 1 Ball hast und ich dir noch 2 gebe, wie viele hast du dann?", choices:["2","3","4"], correctChoiceIndex:2},
    {id:"de_762", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Beine hat ein Stuhl?", choices:["2","3","4"], correctChoiceIndex:2},
    {id:"de_763", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 3 Bücher hast und 1 verlierst, wie viele hast du dann?", choices:["1","2","3"], correctChoiceIndex:1},
    {id:"de_764", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Räder hat ein Auto?", choices:["2","3","4"], correctChoiceIndex:2},
    {id:"de_765", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 4 Bälle hast und 2 verlierst, wie viele hast du dann?", choices:["1","2","3"], correctChoiceIndex:1},
    {id:"de_766", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Flügel hat ein Vogel?", choices:["1","2","3"], correctChoiceIndex:1},
    {id:"de_767", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 5 Sterne hast und 1 verlierst, wie viele hast du dann?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"de_768", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Beine hat ein Hund?", choices:["2","3","4"], correctChoiceIndex:2},
    {id:"de_769", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 1 Teddybär hast und ich dir noch 2 gebe, wie viele hast du dann?", choices:["2","3","4"], correctChoiceIndex:2},
    {id:"de_770", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Beine hat eine Katze?", choices:["2","3","4"], correctChoiceIndex:2},
    {id:"de_771", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 2 Puppen hast und ich dir noch 1 gebe, wie viele hast du dann?", choices:["2","3","4"], correctChoiceIndex:2},
    {id:"de_772", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 5 + 3?", choices:["7","8","9"], correctChoiceIndex:1},
    {id:"de_773", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 7 - 2?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"de_774", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 6 + 4?", choices:["9","10","11"], correctChoiceIndex:1},
    {id:"de_775", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 9 - 3?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"de_776", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 8 + 2?", choices:["9","10","11"], correctChoiceIndex:1},
    {id:"de_777", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 10 - 5?", choices:["4","5","6"], correctChoiceIndex:1},
    {id:"de_778", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 7 + 3?", choices:["9","10","11"], correctChoiceIndex:1},
    {id:"de_779", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 8 - 4?", choices:["3","4","5"], correctChoiceIndex:1},
    {id:"de_780", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 6 + 5?", choices:["10","11","12"], correctChoiceIndex:1},
    {id:"de_781", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 10 - 6?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"de_782", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 9 + 2?", choices:["10","11","12"], correctChoiceIndex:1},
    {id:"de_783", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 7 - 3?", choices:["3","4","5"], correctChoiceIndex:1},
    {id:"de_784", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 8 + 4?", choices:["11","12","13"], correctChoiceIndex:1},
    {id:"de_785", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 10 - 7?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"de_786", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 6 + 6?", choices:["11","12","13"], correctChoiceIndex:1},
    {id:"de_787", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 9 - 4?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"de_788", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 7 + 5?", choices:["11","12","13"], correctChoiceIndex:1},
    {id:"de_789", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 8 - 5?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"de_790", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 6 + 7?", choices:["12","13","14"], correctChoiceIndex:1},
    {id:"de_791", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 10 - 8?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"de_792", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 5 + 4?", choices:["8","9","10"], correctChoiceIndex:1},
    {id:"de_793", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 7 - 3?", choices:["3","4","5"], correctChoiceIndex:1},
    {id:"de_794", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 6 + 5?", choices:["10","11","12"], correctChoiceIndex:1},
    {id:"de_795", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 9 - 4?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"de_796", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 8 + 3?", choices:["10","11","12"], correctChoiceIndex:1},
    {id:"de_797", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 10 - 6?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"de_798", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 7 + 4?", choices:["10","11","12"], correctChoiceIndex:1},
    {id:"de_799", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 8 - 5?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"de_800", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 6 + 6?", choices:["11","12","13"], correctChoiceIndex:1},
    {id:"de_801", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 10 - 7?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"de_802", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was ist 7 + 5?", choices:["11","12","13"], correctChoiceIndex:1},
    {id:"de_803", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 2 Äpfel hast und ich dir noch 2 gebe, wie viele Äpfel hast du dann?", choices:["2","4","6"], correctChoiceIndex:1},
    {id:"de_804", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 5 Bonbons hast und 2 isst, wie viele Bonbons hast du dann?", choices:["3","2","5"], correctChoiceIndex:0},
    {id:"de_805", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 3 Kekse hast und ich dir noch 1 gebe, wie viele Kekse hast du dann?", choices:["4","3","2"], correctChoiceIndex:0},
    {id:"de_806", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 4 Bälle hast und 1 verlierst, wie viele Bälle hast du dann?", choices:["3","4","2"], correctChoiceIndex:0},
    {id:"de_807", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 2 Katzen hast und noch 1 bekommst, wie viele Katzen hast du dann?", choices:["3","2","1"], correctChoiceIndex:0},
    {id:"de_808", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 3 Teddybären hast und noch 2 bekommst, wie viele Teddybären hast du dann?", choices:["5","3","2"], correctChoiceIndex:0},
    {id:"de_809", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 2 Puppen hast und 1 verlierst, wie viele Puppen hast du dann?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"de_810", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 4 Autos hast und noch 1 bekommst, wie viele Autos hast du dann?", choices:["5","4","3"], correctChoiceIndex:0},
    {id:"de_811", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 5 Enten hast und 2 wegfliegen, wie viele Enten hast du dann?", choices:["3","5","2"], correctChoiceIndex:0},
    {id:"de_812", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 3 Fische hast und noch 1 bekommst, wie viele Fische hast du dann?", choices:["4","3","2"], correctChoiceIndex:0},
    {id:"de_813", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 3 Kaninchen hast und noch 2 bekommst, wie viele Kaninchen hast du dann?", choices:["5","3","2"], correctChoiceIndex:0},
    {id:"de_814", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 2 Schafe hast und 1 verliert, wie viele Schafe hast du dann?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"de_815", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 4 Pferde hast und noch 1 bekommst, wie viele Pferde hast du dann?", choices:["5","4","3"], correctChoiceIndex:0},
    {id:"de_816", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 5 Kühe hast und 2 weglaufen, wie viele Kühe hast du dann?", choices:["3","5","2"], correctChoiceIndex:0},
    {id:"de_817", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 3 Schweine hast und noch 1 bekommst, wie viele Schweine hast du dann?", choices:["4","3","2"], correctChoiceIndex:0},
    {id:"de_818", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 3 Mäuse hast und noch 2 bekommst, wie viele Mäuse hast du dann?", choices:["5","3","2"], correctChoiceIndex:0},
    {id:"de_819", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 2 Hunde hast und 1 verliert, wie viele Hunde hast du dann?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"de_820", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 4 Katzen hast und noch 1 bekommst, wie viele Katzen hast du dann?", choices:["5","4","3"], correctChoiceIndex:0},
    {id:"de_821", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 5 Vögel hast und 2 wegfliegen, wie viele Vögel hast du dann?", choices:["3","5","2"], correctChoiceIndex:0},
    {id:"de_822", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 3 Fische hast und noch 1 bekommst, wie viele Fische hast du dann?", choices:["4","3","2"], correctChoiceIndex:0},
    {id:"de_823", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 3 Schmetterlinge hast und noch 2 bekommst, wie viele Schmetterlinge hast du dann?", choices:["5","3","2"], correctChoiceIndex:0},
    {id:"de_824", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 2 Bienen hast und 1 verliert, wie viele Bienen hast du dann?", choices:["1","2","3"], correctChoiceIndex:0},
    {id:"de_825", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 4 Käfer hast und noch 1 bekommst, wie viele Käfer hast du dann?", choices:["5","4","3"], correctChoiceIndex:0},
    {id:"de_826", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 5 Ameisen hast und 2 weglaufen, wie viele Ameisen hast du dann?", choices:["3","5","2"], correctChoiceIndex:0},
    {id:"de_827", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wenn du 3 Marienkäfer hast und noch 1 bekommst, wie viele Marienkäfer hast du dann?", choices:["4","3","2"], correctChoiceIndex:0},
    {id:"de_828", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 7 Stifte hast und 2 verlierst, wie viele Stifte hast du dann?", choices:["5","7","6"], correctChoiceIndex:0},
    {id:"de_829", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 6 Bücher hast und noch 3 bekommst, wie viele Bücher hast du dann?", choices:["9","6","3"], correctChoiceIndex:0},
    {id:"de_830", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 8 Blumen hast und 3 pflückst, wie viele Blumen hast du dann?", choices:["5","8","3"], correctChoiceIndex:0},
    {id:"de_831", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 5 Hunde hast und noch 2 bekommst, wie viele Hunde hast du dann?", choices:["7","5","2"], correctChoiceIndex:0},
    {id:"de_832", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 4 Vögel hast und 1 fliegt weg, wie viele Vögel hast du dann?", choices:["3","4","1"], correctChoiceIndex:0},
    {id:"de_833", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 7 Sterne siehst und 2 verschwinden, wie viele Sterne siehst du dann?", choices:["5","7","6"], correctChoiceIndex:0},
    {id:"de_834", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 6 Münzen hast und noch 3 bekommst, wie viele Münzen hast du dann?", choices:["9","6","3"], correctChoiceIndex:0},
    {id:"de_835", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 8 Schmetterlinge siehst und 3 wegfliegen, wie viele Schmetterlinge siehst du dann?", choices:["5","8","3"], correctChoiceIndex:0},
    {id:"de_836", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 5 Bären hast und noch 2 bekommst, wie viele Bären hast du dann?", choices:["7","5","2"], correctChoiceIndex:0},
    {id:"de_837", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 4 Drachen hast und 1 fliegt weg, wie viele Drachen hast du dann?", choices:["3","4","1"], correctChoiceIndex:0},
    {id:"de_838", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 7 Ziegen hast und 2 weglaufen, wie viele Ziegen hast du dann?", choices:["5","7","6"], correctChoiceIndex:0},
    {id:"de_839", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 6 Hühner hast und noch 3 bekommst, wie viele Hühner hast du dann?", choices:["9","6","3"], correctChoiceIndex:0},
    {id:"de_840", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 8 Enten hast und 3 wegfliegen, wie viele Enten hast du dann?", choices:["5","8","3"], correctChoiceIndex:0},
    {id:"de_841", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 5 Gänse hast und noch 2 bekommst, wie viele Gänse hast du dann?", choices:["7","5","2"], correctChoiceIndex:0},
    {id:"de_842", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 4 Truthähne hast und 1 wegfliegt, wie viele Truthähne hast du dann?", choices:["3","4","1"], correctChoiceIndex:0},
    {id:"de_843", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 7 Schnecken hast und 2 wegkriechen, wie viele Schnecken hast du dann?", choices:["5","7","6"], correctChoiceIndex:0},
    {id:"de_844", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 6 Frösche hast und noch 3 bekommst, wie viele Frösche hast du dann?", choices:["9","6","3"], correctChoiceIndex:0},
    {id:"de_845", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 8 Spinnen hast und 3 weglaufen, wie viele Spinnen hast du dann?", choices:["5","8","3"], correctChoiceIndex:0},
    {id:"de_846", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 5 Schlangen hast und noch 2 bekommst, wie viele Schlangen hast du dann?", choices:["7","5","2"], correctChoiceIndex:0},
    {id:"de_847", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 4 Schildkröten hast und 1 wegkriecht, wie viele Schildkröten hast du dann?", choices:["3","4","1"], correctChoiceIndex:0},
    {id:"de_848", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 7 Wespen hast und 2 wegfliegen, wie viele Wespen hast du dann?", choices:["5","7","6"], correctChoiceIndex:0},
    {id:"de_849", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 6 Fliegen hast und noch 3 bekommst, wie viele Fliegen hast du dann?", choices:["9","6","3"], correctChoiceIndex:0},
    {id:"de_850", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 8 Käfer hast und 3 weglaufen, wie viele Käfer hast du dann?", choices:["5","8","3"], correctChoiceIndex:0},
    {id:"de_851", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 5 Schnecken hast und noch 2 bekommst, wie viele Schnecken hast du dann?", choices:["7","5","2"], correctChoiceIndex:0},
    {id:"de_852", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wenn du 4 Frösche hast und 1 wegspringt, wie viele Frösche hast du dann?", choices:["3","4","1"], correctChoiceIndex:0},
    {id:"de_853", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Welche Form hat 3 Seiten?", choices:["Kreis","Dreieck","Quadrat"], correctChoiceIndex:1},
    {id:"de_854", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Welche Form hat 4 gleiche Seiten?", choices:["Rechteck","Quadrat","Dreieck"], correctChoiceIndex:1},
    {id:"de_855", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Welche Form ist rund?", choices:["Kreis","Quadrat","Dreieck"], correctChoiceIndex:0},
    {id:"de_856", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Welche Form hat keine Ecken?", choices:["Kreis","Quadrat","Dreieck"], correctChoiceIndex:0},
    {id:"de_857", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Welche Form hat 4 Seiten, aber nicht alle Seiten sind gleich lang?", choices:["Rechteck","Quadrat","Dreieck"], correctChoiceIndex:0},
    {id:"de_858", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Ecken hat ein Quadrat?", choices:["3","4","5"], correctChoiceIndex:1},
    {id:"de_859", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Ecken hat ein Dreieck?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"de_860", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Seiten hat ein Hexagon?", choices:["5","6","7"], correctChoiceIndex:1},
    {id:"de_861", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Ecken hat ein Pentagon?", choices:["4","5","6"], correctChoiceIndex:1},
    {id:"de_862", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Seiten hat ein Oktogon?", choices:["7","8","9"], correctChoiceIndex:1},
    {id:"de_863", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Ecken hat ein Oktogon?", choices:["7","8","9"], correctChoiceIndex:1},
    {id:"de_864", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Seiten hat ein Quadrat?", choices:["3","4","5"], correctChoiceIndex:1},
    {id:"de_865", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Ecken hat ein Rechteck?", choices:["3","4","5"], correctChoiceIndex:1},
    {id:"de_866", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Seiten hat ein Dreieck?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"de_867", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Ecken hat ein Hexagon?", choices:["5","6","7"], correctChoiceIndex:1},
    {id:"de_868", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Seiten hat ein Pentagon?", choices:["4","5","6"], correctChoiceIndex:1},
    {id:"de_869", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Ecken hat ein Oktogon?", choices:["7","8","9"], correctChoiceIndex:1},
    {id:"de_870", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Seiten hat ein Quadrat?", choices:["3","4","5"], correctChoiceIndex:1},
    {id:"de_871", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Ecken hat ein Rechteck?", choices:["3","4","5"], correctChoiceIndex:1},
    {id:"de_872", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Seiten hat ein Dreieck?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"de_873", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Ecken hat ein Hexagon?", choices:["5","6","7"], correctChoiceIndex:1},
    {id:"de_874", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Seiten hat ein Pentagon?", choices:["4","5","6"], correctChoiceIndex:1},
    {id:"de_875", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Ecken hat ein Oktogon?", choices:["7","8","9"], correctChoiceIndex:1},
    {id:"de_876", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Seiten hat ein Quadrat?", choices:["3","4","5"], correctChoiceIndex:1},
    {id:"de_877", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Ecken hat ein Rechteck?", choices:["3","4","5"], correctChoiceIndex:1},
    {id:"de_878", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Stunden hat ein Tag?", choices:["24 Stunden","12 Stunden","30 Stunden"], correctChoiceIndex:0},
    {id:"de_879", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Minuten hat eine Stunde?", choices:["60 Minuten","30 Minuten","120 Minuten"], correctChoiceIndex:0},
    {id:"de_880", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Sekunden hat eine Minute?", choices:["60 Sekunden","30 Sekunden","120 Sekunden"], correctChoiceIndex:0},
    {id:"de_881", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Tage hat eine Woche?", choices:["7 Tage","5 Tage","10 Tage"], correctChoiceIndex:0},
    {id:"de_882", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Wochen hat ein Monat?", choices:["4 Wochen","2 Wochen","6 Wochen"], correctChoiceIndex:0},
    {id:"de_883", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Wie viele Monate hat ein Jahr?", choices:["12 Monate","10 Monate","15 Monate"], correctChoiceIndex:0},
    {id:"de_884", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Was kommt nach Montag?", choices:["Dienstag","Mittwoch","Freitag"], correctChoiceIndex:0},
    {id:"de_885", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Was kommt vor Sonntag?", choices:["Samstag","Freitag","Montag"], correctChoiceIndex:0},
    {id:"de_886", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Welcher Monat kommt nach Januar?", choices:["Februar","März","April"], correctChoiceIndex:0},
    {id:"de_887", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Welcher Monat kommt vor Dezember?", choices:["November","Oktober","September"], correctChoiceIndex:0},
    {id:"de_888", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Tage hat der Februar in einem Schaltjahr?", choices:["29 Tage","28 Tage","30 Tage"], correctChoiceIndex:0},
    {id:"de_889", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Stunden sind in zwei Tagen?", choices:["48 Stunden","24 Stunden","72 Stunden"], correctChoiceIndex:0},
    {id:"de_890", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Minuten sind in einer halben Stunde?", choices:["30 Minuten","15 Minuten","60 Minuten"], correctChoiceIndex:0},
    {id:"de_891", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Sekunden sind in zwei Minuten?", choices:["120 Sekunden","60 Sekunden","240 Sekunden"], correctChoiceIndex:0},
    {id:"de_892", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Wochen sind in einem Jahr?", choices:["52 Wochen","48 Wochen","60 Wochen"], correctChoiceIndex:0},
    {id:"de_893", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was kommt nach Donnerstag?", choices:["Freitag","Samstag","Sonntag"], correctChoiceIndex:0},
    {id:"de_894", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Was kommt vor Mittwoch?", choices:["Dienstag","Montag","Donnerstag"], correctChoiceIndex:0},
    {id:"de_895", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Welcher Monat kommt nach Juni?", choices:["Juli","August","September"], correctChoiceIndex:0},
    {id:"de_896", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Welcher Monat kommt vor Mai?", choices:["April","März","Februar"], correctChoiceIndex:0},
    {id:"de_897", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Tage hat der November?", choices:["30 Tage","31 Tage","28 Tage"], correctChoiceIndex:0},
    {id:"de_898", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Stunden sind in drei Tagen?", choices:["72 Stunden","48 Stunden","96 Stunden"], correctChoiceIndex:0},
    {id:"de_899", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Minuten sind in zwei Stunden?", choices:["120 Minuten","60 Minuten","180 Minuten"], correctChoiceIndex:0},
    {id:"de_900", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Sekunden sind in drei Minuten?", choices:["180 Sekunden","120 Sekunden","240 Sekunden"], correctChoiceIndex:0},
    {id:"de_901", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Wochen sind in sechs Monaten?", choices:["26 Wochen","24 Wochen","30 Wochen"], correctChoiceIndex:0},
    {id:"de_902", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Wie viele Monate sind in zwei Jahren?", choices:["24 Monate","12 Monate","36 Monate"], correctChoiceIndex:0},
    {id:"de_903", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Warum sollten wir uns bewegen?", choices:["Es macht Spaß","Es hält uns gesund","Es ist langweilig"], correctChoiceIndex:1},
    {id:"de_904", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Was sollten wir nach dem Spielen im Freien tun?", choices:["Hände waschen","Fernsehen","Schlafen"], correctChoiceIndex:0},
    {id:"de_905", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist eine gesunde Gewohnheit?", choices:["Zähne putzen","Videospiele spielen","Süßigkeiten essen"], correctChoiceIndex:0},
    {id:"de_906", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Warum ist Schlaf wichtig?", choices:["Es hilft uns zu wachsen","Es ist langweilig","Es macht uns müde"], correctChoiceIndex:0},
    {id:"de_907", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Was sollten wir jeden Tag tun?", choices:["Sport treiben","Videospiele spielen","Fernsehen"], correctChoiceIndex:0},
    {id:"de_908", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Warum ist es wichtig, Wasser zu trinken?", choices:["Es hält uns hydratisiert","Es macht uns müde","Es macht uns hungrig"], correctChoiceIndex:0},
    {id:"de_909", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was passiert, wenn wir uns nicht bewegen?", choices:["Wir können ungesund werden","Wir werden stärker","Wir werden schneller"], correctChoiceIndex:0},
    {id:"de_910", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Warum sollten wir Obst und Gemüse essen?", choices:["Es ist gesund","Es ist lecker","Es ist ungesund"], correctChoiceIndex:0},
    {id:"de_911", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist eine gute Gewohnheit vor dem Schlafengehen?", choices:["Zähne putzen","Videospiele spielen","Fernsehen"], correctChoiceIndex:0},
    {id:"de_912", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was sollten wir tun, wenn wir uns krank fühlen?", choices:["Zum Arzt gehen","Zur Schule gehen","Videospiele spielen"], correctChoiceIndex:0},
    {id:"de_913", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Warum ist es wichtig, regelmäßig zu baden?", choices:["Es hält uns sauber","Es macht uns müde","Es macht uns hungrig"], correctChoiceIndex:0},
    {id:"de_914", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was sollten wir tun, um gesund zu bleiben?", choices:["Gesund essen und Sport treiben","Videospiele spielen und fernsehen","Süßigkeiten essen und schlafen"], correctChoiceIndex:0},
    {id:"de_915", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Warum sollten wir unsere Hände waschen?", choices:["Um Keime loszuwerden","Um schneller zu werden","Um stärker zu werden"], correctChoiceIndex:0},
    {id:"de_916", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist eine gute Gewohnheit nach dem Essen?", choices:["Zähne putzen","Videospiele spielen","Fernsehen"], correctChoiceIndex:0},
    {id:"de_917", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was passiert, wenn wir nicht genug schlafen?", choices:["Wir können müde und krank werden","Wir werden stärker","Wir werden schneller"], correctChoiceIndex:0},
    {id:"de_918", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Welche Sportart schwimmt man im Wasser?", choices:["Fußball","Schwimmen","Basketball"], correctChoiceIndex:1},
    {id:"de_919", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Welche Ausrüstung brauchst du zum Schwimmen?", choices:["Schwimmbrille","Fußball","Fahrradhelm"], correctChoiceIndex:0},
    {id:"de_920", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Welche Sportart machst du auf einer Matte?", choices:["Gymnastik","Basketball","Fußball"], correctChoiceIndex:0},
    {id:"de_921", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Welche Sportart machst du auf einem Trampolin?", choices:["Basketball","Fußball","Gymnastik"], correctChoiceIndex:2},
    {id:"de_922", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Welche Sportart machst du mit einem Ball?", choices:["Schwimmen","Gymnastik","Fußball"], correctChoiceIndex:2},
    {id:"de_923", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist ein wichtiger Teil der Gymnastik?", choices:["Springen","Laufen","Schlafen"], correctChoiceIndex:0},
    {id:"de_924", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist ein wichtiger Teil des Schwimmens?", choices:["Atmen","Essen","Schlafen"], correctChoiceIndex:0},
    {id:"de_925", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Welche Sportart machst du in einem Pool?", choices:["Schwimmen","Basketball","Fußball"], correctChoiceIndex:0},
    {id:"de_926", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Welche Sportart machst du mit einem Reifen?", choices:["Basketball","Gymnastik","Fußball"], correctChoiceIndex:1},
    {id:"de_927", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Welche Sportart machst du mit einem Pferd?", choices:["Gymnastik","Fußball","Reiten"], correctChoiceIndex:2},
    {id:"de_928", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Welche Sportart machst du mit einem Schläger?", choices:["Tennis","Schwimmen","Gymnastik"], correctChoiceIndex:0},
    {id:"de_929", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Welche Sportart machst du auf Eis?", choices:["Eiskunstlauf","Basketball","Fußball"], correctChoiceIndex:0},
    {id:"de_930", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Welche Sportart machst du mit einem Seil?", choices:["Seilspringen","Fußball","Schwimmen"], correctChoiceIndex:0},
    {id:"de_931", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Welche Sportart machst du auf einem Fahrrad?", choices:["Radfahren","Schwimmen","Gymnastik"], correctChoiceIndex:0},
    {id:"de_932", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Welche Sportart machst du auf einem Skateboard?", choices:["Skateboarden","Schwimmen","Gymnastik"], correctChoiceIndex:0},
    {id:"de_933", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Welche Farbe hat das Gras?", choices:["Blau","Grün","Rot"], correctChoiceIndex:1},
    {id:"de_934", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist Wandern?", choices:["Ein Spiel","Eine Art zu laufen","Ein Lied"], correctChoiceIndex:1},
    {id:"de_935", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Was sollten wir nicht in den Wald werfen?", choices:["Müll","Blumen","Steine"], correctChoiceIndex:0},
    {id:"de_936", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Welches Tier kann fliegen?", choices:["Fisch","Vogel","Kuh"], correctChoiceIndex:1},
    {id:"de_937", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist ein Baum?", choices:["Ein Tier","Eine Pflanze","Ein Auto"], correctChoiceIndex:1},
    {id:"de_938", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Warum ist es wichtig, die Natur zu respektieren?", choices:["Um Tiere zu schützen","Um Fernsehen zu schauen","Um Eis zu essen"], correctChoiceIndex:0},
    {id:"de_939", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist eine mögliche Gefahr beim Wandern?", choices:["Einen Berg hinaufzufallen","Einen Kuchen zu essen","Ein Buch zu lesen"], correctChoiceIndex:0},
    {id:"de_940", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist eine gute Aktivität, um die Natur zu beobachten?", choices:["Videospiele spielen","Fernsehen","Vogelbeobachtung"], correctChoiceIndex:2},
    {id:"de_941", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist eine gute Sache, um beim Wandern zu tragen?", choices:["Flip-Flops","Wanderschuhe","Einen Hut"], correctChoiceIndex:1},
    {id:"de_942", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist eine gute Sache, um in der Natur zu tun?", choices:["Müll hinterlassen","Tiere füttern","Die Natur respektieren"], correctChoiceIndex:2},
    {id:"de_943", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist eine gute Sache, um beim Wandern zu tragen?", choices:["Einen schweren Rucksack","Leichte Kleidung","Eine Winterjacke"], correctChoiceIndex:1},
    {id:"de_944", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist eine gute Sache, um beim Wandern zu tragen?", choices:["Einen schweren Rucksack","Leichte Kleidung","Eine Winterjacke"], correctChoiceIndex:1},
    {id:"de_945", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist eine gute Sache, um beim Wandern zu tragen?", choices:["Einen schweren Rucksack","Leichte Kleidung","Eine Winterjacke"], correctChoiceIndex:1},
    {id:"de_946", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist eine gute Sache, um beim Wandern zu tragen?", choices:["Einen schweren Rucksack","Leichte Kleidung","Eine Winterjacke"], correctChoiceIndex:1},
    {id:"de_947", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist eine gute Sache, um beim Wandern zu tragen?", choices:["Einen schweren Rucksack","Leichte Kleidung","Eine Winterjacke"], correctChoiceIndex:1},
    {id:"de_948", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Welches Spiel wird oft draußen gespielt?", choices:["Verstecken","Schach","Kochen"], correctChoiceIndex:0},
    {id:"de_949", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist das Ziel beim Fangen?", choices:["Jemanden zu fangen","Ein Tor zu schießen","Ein Rennen zu gewinnen"], correctChoiceIndex:0},
    {id:"de_950", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Was bedeutet Teamarbeit?", choices:["Alleine arbeiten","Mit Freunden spielen","Mit deinen Eltern spielen"], correctChoiceIndex:1},
    {id:"de_951", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist das Ziel beim Verstecken?", choices:["Gefunden zu werden","Nicht gefunden zu werden","Ein Tor zu schießen"], correctChoiceIndex:1},
    {id:"de_952", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Welches Spiel kann man im Team spielen?", choices:["Fangen","Schach","Beide"], correctChoiceIndex:0},
    {id:"de_953", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was sind die Regeln beim Fangen?", choices:["Jemanden zu fangen","Nicht gefangen zu werden","Beide"], correctChoiceIndex:2},
    {id:"de_954", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was sind die Regeln beim Verstecken?", choices:["Sich zu verstecken","Gefunden zu werden","Beide"], correctChoiceIndex:0},
    {id:"de_955", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist das Ziel beim Teamspiel?", choices:["Gewinnen","Verlieren","Spaß haben"], correctChoiceIndex:0},
    {id:"de_956", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist das Ziel beim Einzelspiel?", choices:["Gewinnen","Verlieren","Spaß haben"], correctChoiceIndex:0},
    {id:"de_957", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist das Ziel beim Verstecken?", choices:["Gefunden zu werden","Nicht gefunden zu werden","Ein Tor zu schießen"], correctChoiceIndex:1},
    {id:"de_958", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist das Ziel beim Fangen?", choices:["Jemanden zu fangen","Ein Tor zu schießen","Ein Rennen zu gewinnen"], correctChoiceIndex:0},
    {id:"de_959", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was bedeutet Teamarbeit?", choices:["Alleine arbeiten","Mit Freunden spielen","Mit deinen Eltern spielen"], correctChoiceIndex:1},
    {id:"de_960", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Welches Spiel kann man im Team spielen?", choices:["Fangen","Schach","Beide"], correctChoiceIndex:0},
    {id:"de_961", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was sind die Regeln beim Fangen?", choices:["Jemanden zu fangen","Nicht gefangen zu werden","Beide"], correctChoiceIndex:2},
    {id:"de_962", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was sind die Regeln beim Verstecken?", choices:["Sich zu verstecken","Gefunden zu werden","Beide"], correctChoiceIndex:0},
    {id:"de_963", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Wie viele Spieler sind in einer Fußballmannschaft?", choices:["11","10","12"], correctChoiceIndex:0},
    {id:"de_964", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Was trägt ein Fußballspieler an den Füßen?", choices:["Schuhe","Handschuhe","Hut"], correctChoiceIndex:0},
    {id:"de_965", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist das Ziel im Fußball?", choices:["Ein Tor zu erzielen","Auf dem Feld zu rennen","Den Ball zu fangen"], correctChoiceIndex:0},
    {id:"de_966", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Wie viele Punkte erhältst du für einen Korb im Basketball?", choices:["2","3","1"], correctChoiceIndex:0},
    {id:"de_967", language:QuestionLanguage.DE, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Was benutzt man im Basketballspiel?", choices:["Einen Ball","Einen Schläger","Einen Würfel"], correctChoiceIndex:0},
    {id:"de_968", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Wie viele Spieler sind auf dem Feld in einem Basketballspiel?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"de_969", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist das Ziel im Basketball?", choices:["Einen Korb zu erzielen","Den Ball zu fangen","Auf dem Feld zu rennen"], correctChoiceIndex:0},
    {id:"de_970", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Wie viele Halbzeiten gibt es in einem Fußballspiel?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"de_971", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist ein Foul im Fußball?", choices:["Wenn ein Spieler den Ball mit der Hand berührt","Wenn ein Spieler ein Tor erzielt","Wenn ein Spieler den Ball mit dem Kopf trifft"], correctChoiceIndex:0},
    {id:"de_972", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist ein Dribbling im Basketball?", choices:["Wenn ein Spieler den Ball auf den Boden schlägt","Wenn ein Spieler den Ball wirft","Wenn ein Spieler den Ball fängt"], correctChoiceIndex:0},
    {id:"de_973", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist ein Freiwurf im Basketball?", choices:["Ein Wurf von der Freiwurflinie","Ein Wurf von der Mittellinie","Ein Wurf von der Dreipunktelinie"], correctChoiceIndex:0},
    {id:"de_974", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist ein Eckstoß im Fußball?", choices:["Wenn der Ball über die Seitenlinie geht","Wenn der Ball über die Torlinie geht","Wenn der Ball in der Luft ist"], correctChoiceIndex:1},
    {id:"de_975", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist ein Abseits im Fußball?", choices:["Wenn ein Spieler näher zur gegnerischen Torlinie ist als der Ball und der vorletzte Abwehrspieler","Wenn ein Spieler den Ball mit der Hand berührt","Wenn ein Spieler ein Tor erzielt"], correctChoiceIndex:0},
    {id:"de_976", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist ein Elfmeter im Fußball?", choices:["Ein Schuss vom Elfmeterpunkt","Ein Schuss von der Mittellinie","Ein Schuss von der Eckfahne"], correctChoiceIndex:0},
    {id:"de_977", language:QuestionLanguage.DE, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Was ist ein Slam Dunk im Basketball?", choices:["Wenn ein Spieler den Ball in den Korb wirft","Wenn ein Spieler den Ball auf den Boden schlägt","Wenn ein Spieler den Ball fängt"], correctChoiceIndex:0},
]


