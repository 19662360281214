import {Question} from "../data/model";
import React, {useState} from "react";
import {QuestionView} from "./QuestionView";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import {Col, Row} from "react-bootstrap";


interface QuestionListViewProps {
    questionPool: Question[]
}

const QUESTION_BATCH_SIZE = 10

export function QuestionListView({questionPool}: QuestionListViewProps) {
    const {t} = useTranslation(['home'])
    const [displayedQuestions, setDisplayedQuestions] = useState(QUESTION_BATCH_SIZE);
    const questions = questionPool.slice(0, displayedQuestions);

    const loadMoreQuestions = () => {
        setDisplayedQuestions(prev => prev + QUESTION_BATCH_SIZE);
    };

    return <>
        <Row xs={1} md={3} className="g-2">
            {questions.map((q) => (
                <Col key={q.id}>
                    <QuestionView question={q} />
                </Col>
            ))}
            {displayedQuestions < questionPool.length && (
                <Col xs={12} md={4} className="d-flex justify-content-center align-items-center">
                    <Button variant="primary"  className="custom-button" onClick={loadMoreQuestions}>{t("moreQuestions!")}</Button>
                </Col>
            )}

        </Row>
    </>
}