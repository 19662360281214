export enum QuestionLanguage {
    EN = "en",
    FR = "fr",
    DE = "de",
    SK = "sk"
}

export enum QuestionAge {
    age_4_6 = "age_4-6",
    age_6_8 = "age_6-8",
}

export enum QuestionCategory {
    nature = "category_nature",
    science = "category_science",
    technology = "category_technology",
    everyDayLife = "category_everyDayLife",
    artAndCulture = "category_artAndCulture",
    mathematics = "category_mathematics",
    sportAndPhysicalEducation = "category_sport"
}

export interface Question {
    id: string
    age: QuestionAge
    language: QuestionLanguage
    category: QuestionCategory
    question: string
    choices: string[]
    correctChoiceIndex: number
}


export class QuestionFilter {

    selectedLanguage: QuestionLanguage
    selectedAges: Set<QuestionAge>
    selectedCategories: Set<QuestionCategory>

    constructor(
        selectedLanguage: QuestionLanguage,
        selectedAges: Set<QuestionAge>,
        selectedCategories: Set<QuestionCategory>
    ) {
        this.selectedLanguage = selectedLanguage
        this.selectedAges = selectedAges
        this.selectedCategories = selectedCategories
    }

    matches(question: Question): Boolean {
        return this.selectedAges.has(question.age)
            && this.selectedCategories.has(question.category)
            && this.selectedLanguage === question.language
    }

    print(): String {
        return "Language: " + this.selectedLanguage
            + " / Ages: " + Array.from(this.selectedAges).join(",")
            + " / Categories:" + Array.from(this.selectedCategories).join(",")
    }

    copy(): QuestionFilter {
        return new QuestionFilter(
            this.selectedLanguage,
            new Set(Array.from(this.selectedAges)),
            new Set(Array.from(this.selectedCategories))
        )
    }

    static fromJSON(json: string): QuestionFilter {
        const parsedData = JSON.parse(json)
        return new QuestionFilter(
            parsedData.selectedLanguage,
            new Set(parsedData.selectedAges),
            new Set(parsedData.selectedCategories)
        )
    }

    static toJSON(questionFilter: QuestionFilter): string {
        const {selectedLanguage, selectedAges, selectedCategories} = questionFilter
        return JSON.stringify({
            selectedLanguage,
            selectedAges: Array.from(selectedAges),
            selectedCategories: Array.from(selectedCategories)
        })
    }
}