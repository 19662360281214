import {Question, QuestionLanguage, QuestionAge, QuestionCategory} from "./model";


export const SLOVAK_QUESTIONS: Question[] = [
    {id:"sk_0", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Kde žije lev?", choices:["V mori","V džungli","V púšti"], correctChoiceIndex:1},
    {id:"sk_1", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo jedá panda?", choices:["Mäso","Bambus","Ryby"], correctChoiceIndex:1},
    {id:"sk_2", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Kde žije tučniak?", choices:["Na póle","V džungli","V púšti"], correctChoiceIndex:0},
    {id:"sk_3", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo jedá žirafa?", choices:["Listy","Mäso","Ryby"], correctChoiceIndex:0},
    {id:"sk_4", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Kde žije medveď?", choices:["V džungli","V púšti","V lese"], correctChoiceIndex:2},
    {id:"sk_5", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo jedá slon?", choices:["Mäso","Rastliny","Ryby"], correctChoiceIndex:1},
    {id:"sk_6", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Kde žije krokodíl?", choices:["V mori","V rieke","V púšti"], correctChoiceIndex:1},
    {id:"sk_7", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo jedá krokodíl?", choices:["Rastliny","Ryby","Mäso"], correctChoiceIndex:2},
    {id:"sk_8", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Kde žije tiger?", choices:["V lese","V púšti","V mori"], correctChoiceIndex:0},
    {id:"sk_9", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo jedá tiger?", choices:["Rastliny","Ryby","Mäso"], correctChoiceIndex:2},
    {id:"sk_10", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije korytnačka?", choices:["V mori","V púšti","V lese"], correctChoiceIndex:0},
    {id:"sk_11", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá korytnačka?", choices:["Rastliny","Ryby","Mäso"], correctChoiceIndex:0},
    {id:"sk_12", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije kenguru?", choices:["V lese","V púšti","V Austrálii"], correctChoiceIndex:2},
    {id:"sk_13", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá kenguru?", choices:["Rastliny","Ryby","Mäso"], correctChoiceIndex:0},
    {id:"sk_14", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije vlk?", choices:["V lese","V púšti","V mori"], correctChoiceIndex:0},
    {id:"sk_15", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá vlk?", choices:["Rastliny","Ryby","Mäso"], correctChoiceIndex:2},
    {id:"sk_16", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije zebra?", choices:["V lese","V púšti","V Afrike"], correctChoiceIndex:2},
    {id:"sk_17", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá zebra?", choices:["Rastliny","Ryby","Mäso"], correctChoiceIndex:0},
    {id:"sk_18", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije opica?", choices:["V lese","V púšti","V mori"], correctChoiceIndex:0},
    {id:"sk_19", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá opica?", choices:["Rastliny","Ryby","Mäso"], correctChoiceIndex:0},
    {id:"sk_20", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije nosorožec?", choices:["V lese","V púšti","V Afrike"], correctChoiceIndex:2},
    {id:"sk_21", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá nosorožec?", choices:["Rastliny","Ryby","Mäso"], correctChoiceIndex:0},
    {id:"sk_22", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije slon?", choices:["V lese","V púšti","V Afrike"], correctChoiceIndex:2},
    {id:"sk_23", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá slon?", choices:["Rastliny","Ryby","Mäso"], correctChoiceIndex:0},
    {id:"sk_24", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije kôň?", choices:["V lese","Na farme","V mori"], correctChoiceIndex:1},
    {id:"sk_25", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá kôň?", choices:["Rastliny","Ryby","Mäso"], correctChoiceIndex:0},
    {id:"sk_26", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije krava?", choices:["V lese","Na farme","V mori"], correctChoiceIndex:1},
    {id:"sk_27", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá krava?", choices:["Rastliny","Ryby","Mäso"], correctChoiceIndex:0},
    {id:"sk_28", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije ovca?", choices:["V lese","Na farme","V mori"], correctChoiceIndex:1},
    {id:"sk_29", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá ovca?", choices:["Rastliny","Ryby","Mäso"], correctChoiceIndex:0},
    {id:"sk_30", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije koza?", choices:["V lese","Na farme","V mori"], correctChoiceIndex:1},
    {id:"sk_31", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá koza?", choices:["Rastliny","Ryby","Mäso"], correctChoiceIndex:0},
    {id:"sk_32", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije mačka?", choices:["V lese","Na farme","V dome"], correctChoiceIndex:2},
    {id:"sk_33", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá mačka?", choices:["Rastliny","Ryby","Mäso"], correctChoiceIndex:2},
    {id:"sk_34", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije pes?", choices:["V lese","Na farme","V dome"], correctChoiceIndex:2},
    {id:"sk_35", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá pes?", choices:["Rastliny","Ryby","Mäso"], correctChoiceIndex:2},
    {id:"sk_36", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije myš?", choices:["V lese","V dome","V mori"], correctChoiceIndex:1},
    {id:"sk_37", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá myš?", choices:["Rastliny","Ryby","Mäso"], correctChoiceIndex:0},
    {id:"sk_38", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije vták?", choices:["V lese","V dome","V mori"], correctChoiceIndex:0},
    {id:"sk_39", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá vták?", choices:["Rastliny","Ryby","Hmyz"], correctChoiceIndex:2},
    {id:"sk_40", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije žaba?", choices:["V lese","V rieke","V mori"], correctChoiceIndex:1},
    {id:"sk_41", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá žaba?", choices:["Rastliny","Ryby","Hmyz"], correctChoiceIndex:2},
    {id:"sk_42", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije had?", choices:["V lese","V púšti","V mori"], correctChoiceIndex:0},
    {id:"sk_43", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá had?", choices:["Rastliny","Ryby","Mäso"], correctChoiceIndex:2},
    {id:"sk_44", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije ryba?", choices:["V lese","V rieke","V mori"], correctChoiceIndex:2},
    {id:"sk_45", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá ryba?", choices:["Rastliny","Hmyz","Mäso"], correctChoiceIndex:2},
    {id:"sk_46", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije kachna?", choices:["V lese","V rieke","V mori"], correctChoiceIndex:1},
    {id:"sk_47", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá kachna?", choices:["Rastliny","Hmyz","Mäso"], correctChoiceIndex:0},
    {id:"sk_48", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije krab?", choices:["V lese","V rieke","V mori"], correctChoiceIndex:2},
    {id:"sk_49", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá krab?", choices:["Rastliny","Hmyz","Mäso"], correctChoiceIndex:2},
    {id:"sk_50", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Kde žije chobotnica?", choices:["V lese","V rieke","V mori"], correctChoiceIndex:2},
    {id:"sk_51", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo jedá chobotnica?", choices:["Rastliny","Hmyz","Mäso"], correctChoiceIndex:2},
    {id:"sk_52", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Aký je najväčší oceán na Zemi?", choices:["Indický oceán","Tichý oceán","Atlantický oceán"], correctChoiceIndex:1},
    {id:"sk_53", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Ktorý kontinent je najväčší?", choices:["Afrika","Ázia","Austrália"], correctChoiceIndex:1},
    {id:"sk_54", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Koľko kontinentov je na Zemi?", choices:["5","6","7"], correctChoiceIndex:2},
    {id:"sk_55", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Aké je najvyššie pohorie na Zemi?", choices:["Himaláje","Alpy","Andy"], correctChoiceIndex:0},
    {id:"sk_56", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Ktorá rieka je najdlhšia na svete?", choices:["Amazonka","Nil","Mississippi"], correctChoiceIndex:1},
    {id:"sk_57", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Ktorý je najväčší prales na Zemi?", choices:["Amazonský prales","Kongo","Borneo"], correctChoiceIndex:0},
    {id:"sk_58", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Koľko ročných období máme?", choices:["2","3","4"], correctChoiceIndex:2},
    {id:"sk_59", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Ktorý je najchladnejší kontinent na Zemi?", choices:["Antarktída","Európa","Severná Amerika"], correctChoiceIndex:0},
    {id:"sk_60", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Ktorý je najteplejší kontinent na Zemi?", choices:["Afrika","Austrália","Ázia"], correctChoiceIndex:0},
    {id:"sk_61", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Ktorý je najmenší kontinent na Zemi?", choices:["Austrália","Európa","Antarktída"], correctChoiceIndex:0},
    {id:"sk_62", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší púštny systém na Zemi?", choices:["Sahara","Gobi","Antarktída"], correctChoiceIndex:0},
    {id:"sk_63", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší ostrov na Zemi?", choices:["Grónsko","Nová Guinea","Madagaskar"], correctChoiceIndex:0},
    {id:"sk_64", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší vodopád na Zemi?", choices:["Niagarské vodopády","Victoria Falls","Angel Falls"], correctChoiceIndex:2},
    {id:"sk_65", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najhlbší oceán na Zemi?", choices:["Indický oceán","Tichý oceán","Atlantický oceán"], correctChoiceIndex:1},
    {id:"sk_66", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší jazero na Zemi?", choices:["Kaspické more","Jazero Superior","Jazero Baikal"], correctChoiceIndex:0},
    {id:"sk_67", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší ľadovec na Zemi?", choices:["Lambert","Perito Moreno","Vatnajökull"], correctChoiceIndex:0},
    {id:"sk_68", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší kráter na Zemi?", choices:["Vredefort","Chicxulub","Sudbury"], correctChoiceIndex:0},
    {id:"sk_69", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší vulkán na Zemi?", choices:["Mauna Loa","Mount St. Helens","Krakatoa"], correctChoiceIndex:0},
    {id:"sk_70", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší korálový útes na Zemi?", choices:["Veľký bariérový útes","Belize Barrier Reef","New Caledonia Barrier Reef"], correctChoiceIndex:0},
    {id:"sk_71", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší prírodný most na Zemi?", choices:["Rainbow Bridge","Natural Bridge","Arch of Cabo San Lucas"], correctChoiceIndex:0},
    {id:"sk_72", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší prírodný tunel na Zemi?", choices:["Natural Tunnel","Box Canyon","Tunnel of Love"], correctChoiceIndex:0},
    {id:"sk_73", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší prírodný amfiteáter na Zemi?", choices:["Bryce Canyon","Grand Canyon","Yosemite Valley"], correctChoiceIndex:0},
    {id:"sk_74", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší prírodný park na Zemi?", choices:["Yellowstone","Yosemite","Kruger"], correctChoiceIndex:0},
    {id:"sk_75", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší prírodný rezervát na Zemi?", choices:["Great Barrier Reef","Serengeti","Galapagos"], correctChoiceIndex:0},
    {id:"sk_76", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší prírodný prístav na Zemi?", choices:["Sydney","New York","Tokyo"], correctChoiceIndex:0},
    {id:"sk_77", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší prírodný kanál na Zemi?", choices:["Panama","Suez","English Channel"], correctChoiceIndex:0},
    {id:"sk_78", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší prírodný záliv na Zemi?", choices:["Hudson Bay","Bay of Bengal","Gulf of Mexico"], correctChoiceIndex:1},
    {id:"sk_79", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší prírodný ostrov na Zemi?", choices:["Greenland","New Guinea","Borneo"], correctChoiceIndex:0},
    {id:"sk_80", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší prírodný les na Zemi?", choices:["Amazon Rainforest","Congo Rainforest","Borneo Rainforest"], correctChoiceIndex:0},
    {id:"sk_81", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý je najväčší prírodný púštny systém na Zemi?", choices:["Sahara","Gobi","Antarctica"], correctChoiceIndex:0},
    {id:"sk_82", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Koľko očí má človek?", choices:["1","2","3"], correctChoiceIndex:1},
    {id:"sk_83", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Koľko uší má človek?", choices:["1","2","3"], correctChoiceIndex:1},
    {id:"sk_84", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Koľko nôh má človek?", choices:["1","2","3"], correctChoiceIndex:1},
    {id:"sk_85", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Koľko rúk má človek?", choices:["1","2","3"], correctChoiceIndex:1},
    {id:"sk_86", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Koľko prstov má človek na jednej ruke?", choices:["3","5","6"], correctChoiceIndex:1},
    {id:"sk_87", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Koľko prstov má človek na jednej nohe?", choices:["3","5","6"], correctChoiceIndex:1},
    {id:"sk_88", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Kde je tvoje srdce?", choices:["V hlave","V bruchu","V hrudi"], correctChoiceIndex:2},
    {id:"sk_89", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo nám pomáha dýchať?", choices:["Pľúca","Žalúdok","Nohy"], correctChoiceIndex:0},
    {id:"sk_90", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo nám pomáha tráviť jedlo?", choices:["Žalúdok","Ruka","Oko"], correctChoiceIndex:0},
    {id:"sk_91", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo nám pomáha vidieť?", choices:["Uši","Oči","Nohy"], correctChoiceIndex:1},
    {id:"sk_92", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Koľko zubov má dospelý človek?", choices:["20","32","42"], correctChoiceIndex:1},
    {id:"sk_93", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Koľko kostí má dospelý človek v tele?", choices:["106","206","306"], correctChoiceIndex:1},
    {id:"sk_94", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha myslieť?", choices:["Srdce","Mozog","Pľúca"], correctChoiceIndex:1},
    {id:"sk_95", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha cítiť vône?", choices:["Nos","Ucho","Ruka"], correctChoiceIndex:0},
    {id:"sk_96", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha počuť zvuky?", choices:["Ucho","Oko","Nos"], correctChoiceIndex:0},
    {id:"sk_97", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha chutiť jedlo?", choices:["Jazyk","Noha","Ruka"], correctChoiceIndex:0},
    {id:"sk_98", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha cítiť dotyk?", choices:["Koža","Oko","Ucho"], correctChoiceIndex:0},
    {id:"sk_99", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha filtrovať krv?", choices:["Pľúca","Obličky","Srdce"], correctChoiceIndex:1},
    {id:"sk_100", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha tvoriť krvné bunky?", choices:["Koža","Obličky","Kostná dreň"], correctChoiceIndex:2},
    {id:"sk_101", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha regulovať teplotu tela?", choices:["Koža","Srdce","Mozog"], correctChoiceIndex:0},
    {id:"sk_102", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha bojovať proti chorobám?", choices:["Imunitný systém","Nohy","Ruka"], correctChoiceIndex:0},
    {id:"sk_103", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha dýchať pod vodou?", choices:["Žalúdok","Pľúca","Žiadny"], correctChoiceIndex:2},
    {id:"sk_104", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha premeniť jedlo na energiu?", choices:["Žalúdok","Pečeň","Srdce"], correctChoiceIndex:1},
    {id:"sk_105", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha udržiavať rovnováhu?", choices:["Uši","Oči","Nohy"], correctChoiceIndex:0},
    {id:"sk_106", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha cítiť bolesť?", choices:["Nervový systém","Srdce","Ruka"], correctChoiceIndex:0},
    {id:"sk_107", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha cítiť teplotu?", choices:["Koža","Oko","Ucho"], correctChoiceIndex:0},
    {id:"sk_108", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha cítiť tlak?", choices:["Koža","Oko","Ucho"], correctChoiceIndex:0},
    {id:"sk_109", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha cítiť vlhkosť?", choices:["Koža","Oko","Ucho"], correctChoiceIndex:0},
    {id:"sk_110", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha cítiť suchosť?", choices:["Koža","Oko","Ucho"], correctChoiceIndex:0},
    {id:"sk_111", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorý orgán nám pomáha cítiť svrbenie?", choices:["Koža","Oko","Ucho"], correctChoiceIndex:0},
    {id:"sk_112", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Aká časť rastliny je zodpovedná za fotosyntézu?", choices:["Koreň","Stonka","List"], correctChoiceIndex:2},
    {id:"sk_113", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Ktorá časť rastliny sa nazýva kvet?", choices:["List","Kvet","Plod"], correctChoiceIndex:1},
    {id:"sk_114", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Ktorá časť rastliny sa nazýva koreň?", choices:["Kvet","Koreň","Stonka"], correctChoiceIndex:1},
    {id:"sk_115", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo rastliny potrebujú na rast?", choices:["Voda, svetlo a vzduch","Cukor a soľ","Mlieko a chlieb"], correctChoiceIndex:0},
    {id:"sk_116", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Aký typ rastliny je strom?", choices:["Kvetinová rastlina","Drevina","Bylina"], correctChoiceIndex:1},
    {id:"sk_117", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je to stonka?", choices:["Časť rastliny, ktorá podporuje listy a kvety","Časť rastliny, ktorá vyrába kyslík","Časť rastliny, ktorá vyrába cukor"], correctChoiceIndex:0},
    {id:"sk_118", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je to list?", choices:["Časť rastliny, ktorá podporuje listy a kvety","Časť rastliny, ktorá vyrába kyslík","Časť rastliny, ktorá vyrába cukor"], correctChoiceIndex:1},
    {id:"sk_119", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je to koreň?", choices:["Časť rastliny, ktorá podporuje listy a kvety","Časť rastliny, ktorá vyrába kyslík","Časť rastliny, ktorá sa nachádza pod zemou a nasáva vodu a živiny"], correctChoiceIndex:2},
    {id:"sk_120", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je to bylina?", choices:["Typ rastliny, ktorá má len jeden rok života","Typ rastliny, ktorá má viac ako jeden rok života","Typ rastliny, ktorá má len jeden mesiac života"], correctChoiceIndex:0},
    {id:"sk_121", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je to strom?", choices:["Typ rastliny, ktorá má len jeden rok života","Typ rastliny, ktorá má kvety","Typ rastliny, ktorá má pevný kmeň a vetvy"], correctChoiceIndex:2},
    {id:"sk_122", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je to krík?", choices:["Typ rastliny, ktorá má len jeden rok života","Typ rastliny, ktorá má kvety","Typ rastliny, ktorá má pevný kmeň a vetvy, ale je nižšia ako strom"], correctChoiceIndex:2},
    {id:"sk_123", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je to mach?", choices:["Typ rastliny, ktorá má len jeden rok života","Typ rastliny, ktorá sa leze po iných rastlinách","Typ rastliny, ktorá je malá a rastie na vlhkých miestach"], correctChoiceIndex:2},
    {id:"sk_124", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je to lišajník?", choices:["Typ rastliny, ktorá má len jeden rok života","Typ rastliny, ktorá sa leze po iných rastlinách","Typ rastliny, ktorá je malá a rastie na kameňoch a stromoch"], correctChoiceIndex:2},
    {id:"sk_125", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je to kaktus?", choices:["Typ rastliny, ktorá má len jeden rok života","Typ rastliny, ktorá sa leze po iných rastlinách","Typ rastliny, ktorá dokáže skladovať vodu v svojich listoch alebo stonkách a má ostne"], correctChoiceIndex:2},
    {id:"sk_126", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je to orchidea?", choices:["Typ rastliny, ktorá má len jeden rok života","Typ rastliny, ktorá má veľmi pekné kvety","Typ rastliny, ktorá dokáže skladovať vodu v svojich listoch alebo stonkách"], correctChoiceIndex:1},
    {id:"sk_127", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Aký typ rastliny je ruža?", choices:["Kvetinová rastlina","Drevina","Bylina"], correctChoiceIndex:0},
    {id:"sk_128", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Aký typ rastliny je pšenica?", choices:["Kvetinová rastlina","Drevina","Bylina"], correctChoiceIndex:2},
    {id:"sk_129", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je to fotosyntéza?", choices:["Proces, ktorým rastliny vyrábajú kyslík","Proces, ktorým rastliny vyrábajú cukor","Proces, ktorým rastliny vyrábajú vodu"], correctChoiceIndex:1},
    {id:"sk_130", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je to opeľovanie?", choices:["Proces, ktorým rastliny vyrábajú kyslík","Proces, ktorým rastliny vyrábajú cukor","Proces, ktorým rastliny vytvárajú semienka"], correctChoiceIndex:2},
    {id:"sk_131", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je to semeno?", choices:["Časť rastliny, ktorá môže vyrásť do novej rastliny","Časť rastliny, ktorá vyrába kyslík","Časť rastliny, ktorá vyrába cukor"], correctChoiceIndex:0},
    {id:"sk_132", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je to kvet?", choices:["Časť rastliny, ktorá môže vyrásť do novej rastliny","Časť rastliny, ktorá vyrába kyslík","Časť rastliny, ktorá vyrába cukor"], correctChoiceIndex:0},
    {id:"sk_133", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je to plod?", choices:["Časť rastliny, ktorá môže vyrásť do novej rastliny","Časť rastliny, ktorá vyrába kyslík","Časť rastliny, ktorá obsahuje semienka"], correctChoiceIndex:2},
    {id:"sk_134", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je to drevina?", choices:["Typ rastliny, ktorá má len jeden rok života","Typ rastliny, ktorá má viac ako jeden rok života","Typ rastliny, ktorá má len jeden mesiac života"], correctChoiceIndex:1},
    {id:"sk_135", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je to kvetinová rastlina?", choices:["Typ rastliny, ktorá má len jeden rok života","Typ rastliny, ktorá má kvety","Typ rastliny, ktorá má len jeden mesiac života"], correctChoiceIndex:1},
    {id:"sk_136", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je to tráva?", choices:["Typ rastliny, ktorá má len jeden rok života","Typ rastliny, ktorá má kvety","Typ rastliny, ktorá má tenké a dlhé stonky"], correctChoiceIndex:2},
    {id:"sk_137", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je to liana?", choices:["Typ rastliny, ktorá má len jeden rok života","Typ rastliny, ktorá sa leze po iných rastlinách","Typ rastliny, ktorá má tenké a dlhé stonky"], correctChoiceIndex:1},
    {id:"sk_138", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je to vodná rastlina?", choices:["Typ rastliny, ktorá má len jeden rok života","Typ rastliny, ktorá sa leze po iných rastlinách","Typ rastliny, ktorá rastie vo vode"], correctChoiceIndex:2},
    {id:"sk_139", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je to sukulenta?", choices:["Typ rastliny, ktorá má len jeden rok života","Typ rastliny, ktorá sa leze po iných rastlinách","Typ rastliny, ktorá dokáže skladovať vodu v svojich listoch alebo stonkách"], correctChoiceIndex:2},
    {id:"sk_140", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je to bambus?", choices:["Typ rastliny, ktorá má len jeden rok života","Typ rastliny, ktorá má veľmi pekné kvety","Typ rastliny, ktorá má veľmi dlhé a pevné stonky"], correctChoiceIndex:2},
    {id:"sk_141", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je to palmový strom?", choices:["Typ rastliny, ktorá má len jeden rok života","Typ rastliny, ktorá má veľmi pekné kvety","Typ rastliny, ktorá má veľké listy a rastie v tropických oblastiach"], correctChoiceIndex:2},
    {id:"sk_142", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Aká je najbližšia planéta k Slnku?", choices:["Mars","Merkúr","Venuša"], correctChoiceIndex:1},
    {id:"sk_143", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Ktorá planéta je známa ako Červená planéta?", choices:["Zem","Mars","Jupiter"], correctChoiceIndex:1},
    {id:"sk_144", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Koľko planét je v našej slnečnej sústave?", choices:["8","9","10"], correctChoiceIndex:0},
    {id:"sk_145", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je najväčšia planéta v našej slnečnej sústave?", choices:["Saturn","Jupiter","Uran"], correctChoiceIndex:1},
    {id:"sk_146", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Ktorá planéta je najďalej od Slnka?", choices:["Neptún","Uran","Saturn"], correctChoiceIndex:0},
    {id:"sk_147", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je Slnko?", choices:["Planéta","Hviezda","Mesiac"], correctChoiceIndex:1},
    {id:"sk_148", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je Mesiac?", choices:["Planéta","Hviezda","Satelit Zeme"], correctChoiceIndex:2},
    {id:"sk_149", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je Mars?", choices:["Planéta","Hviezda","Satelit Zeme"], correctChoiceIndex:0},
    {id:"sk_150", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je Jupiter?", choices:["Planéta","Hviezda","Satelit Zeme"], correctChoiceIndex:0},
    {id:"sk_151", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je Saturn?", choices:["Planéta","Hviezda","Satelit Zeme"], correctChoiceIndex:0},
    {id:"sk_152", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je hviezda?", choices:["Ohnivá guľa plynu","Planéta","Mesiac"], correctChoiceIndex:0},
    {id:"sk_153", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je galaxia?", choices:["Skupina hviezd","Planéta","Mesiac"], correctChoiceIndex:0},
    {id:"sk_154", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je kométa?", choices:["Skalnatý objekt vo vesmíre","Planéta","Mesiac"], correctChoiceIndex:0},
    {id:"sk_155", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je asteroid?", choices:["Skalnatý objekt vo vesmíre","Planéta","Mesiac"], correctChoiceIndex:0},
    {id:"sk_156", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.nature, question:"Čo je meteorit?", choices:["Skalnatý objekt vo vesmíre","Planéta","Mesiac"], correctChoiceIndex:0},
    {id:"sk_157", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorá planéta má najviac mesiacov?", choices:["Jupiter","Saturn","Mars"], correctChoiceIndex:0},
    {id:"sk_158", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorá planéta má prsteň?", choices:["Saturn","Mars","Venuša"], correctChoiceIndex:0},
    {id:"sk_159", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorá planéta je najmenšia?", choices:["Merkúr","Mars","Venuša"], correctChoiceIndex:0},
    {id:"sk_160", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorá planéta je najväčšia?", choices:["Jupiter","Saturn","Uran"], correctChoiceIndex:0},
    {id:"sk_161", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorá planéta je najbližšie k Zemi?", choices:["Mars","Venuša","Merkúr"], correctChoiceIndex:1},
    {id:"sk_162", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorá planéta je najďalej od Slnka?", choices:["Neptún","Uran","Saturn"], correctChoiceIndex:0},
    {id:"sk_163", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorá planéta má najviac prsteňov?", choices:["Saturn","Jupiter","Uran"], correctChoiceIndex:0},
    {id:"sk_164", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorá planéta má najviac mesiacov?", choices:["Jupiter","Saturn","Mars"], correctChoiceIndex:0},
    {id:"sk_165", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorá planéta je známa ako Modrá planéta?", choices:["Zem","Mars","Jupiter"], correctChoiceIndex:0},
    {id:"sk_166", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Ktorá planéta je známa ako Červená planéta?", choices:["Zem","Mars","Jupiter"], correctChoiceIndex:1},
    {id:"sk_167", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je Venuša?", choices:["Planéta","Hviezda","Satelit Zeme"], correctChoiceIndex:0},
    {id:"sk_168", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je Uran?", choices:["Planéta","Hviezda","Satelit Zeme"], correctChoiceIndex:0},
    {id:"sk_169", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je Neptún?", choices:["Planéta","Hviezda","Satelit Zeme"], correctChoiceIndex:0},
    {id:"sk_170", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je Merkúr?", choices:["Planéta","Hviezda","Satelit Zeme"], correctChoiceIndex:0},
    {id:"sk_171", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.nature, question:"Čo je Zem?", choices:["Planéta","Hviezda","Satelit Zeme"], correctChoiceIndex:0},
    {id:"sk_172", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Aká je základná jednotka života?", choices:["Bunka","Kvapka vody","Kameň"], correctChoiceIndex:0},
    {id:"sk_173", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Čo je to bunkové jadro?", choices:["Miesto, kde sa ukladá jedlo","Miesto, kde sa ukladá voda","Miesto, kde sa ukladá DNA"], correctChoiceIndex:2},
    {id:"sk_174", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Čo robia chloroplasty v rastlinných bunkách?", choices:["Vyrábajú kyslík","Vyrábajú cukor","Vyrábajú vodu"], correctChoiceIndex:1},
    {id:"sk_175", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Čo je to ekosystém?", choices:["Skupina rastlín a zvierat žijúcich spolu","Skupina hôr","Skupina riek"], correctChoiceIndex:0},
    {id:"sk_176", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Čo je to bunková stena?", choices:["Časť bunky, ktorá ju chráni","Časť bunky, ktorá vyrába jedlo","Časť bunky, ktorá vyrába vodu"], correctChoiceIndex:0},
    {id:"sk_177", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to mitochondria?", choices:["Časť bunky, ktorá vyrába energiu","Časť bunky, ktorá vyrába vodu","Časť bunky, ktorá vyrába jedlo"], correctChoiceIndex:0},
    {id:"sk_178", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to fotosyntéza?", choices:["Proces, ktorým rastliny vyrábajú jedlo","Proces, ktorým rastliny vyrábajú vodu","Proces, ktorým rastliny vyrábajú kyslík"], correctChoiceIndex:0},
    {id:"sk_179", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to cytoplazma?", choices:["Tekutina v bunke","Vzduch v bunke","Jedlo v bunke"], correctChoiceIndex:0},
    {id:"sk_180", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to bunková membrána?", choices:["Časť bunky, ktorá ju chráni","Časť bunky, ktorá kontroluje, čo vstupuje a vychádza z bunky","Časť bunky, ktorá vyrába jedlo"], correctChoiceIndex:1},
    {id:"sk_181", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to ribozóm?", choices:["Časť bunky, ktorá vyrába proteíny","Časť bunky, ktorá vyrába vodu","Časť bunky, ktorá vyrába jedlo"], correctChoiceIndex:0},
    {id:"sk_182", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to lysosóm?", choices:["Časť bunky, ktorá trávi odpad","Časť bunky, ktorá vyrába vodu","Časť bunky, ktorá vyrába jedlo"], correctChoiceIndex:0},
    {id:"sk_183", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to Golgiho aparát?", choices:["Časť bunky, ktorá balí a distribuuje proteíny","Časť bunky, ktorá vyrába vodu","Časť bunky, ktorá vyrába jedlo"], correctChoiceIndex:0},
    {id:"sk_184", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to endoplazmatické retikulum?", choices:["Časť bunky, ktorá vyrába a transportuje proteíny","Časť bunky, ktorá vyrába vodu","Časť bunky, ktorá vyrába jedlo"], correctChoiceIndex:0},
    {id:"sk_185", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to peroxizóm?", choices:["Časť bunky, ktorá rozkladá toxíny","Časť bunky, ktorá vyrába vodu","Časť bunky, ktorá vyrába jedlo"], correctChoiceIndex:0},
    {id:"sk_186", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to vakuola?", choices:["Časť bunky, ktorá ukladá vodu, jedlo a odpad","Časť bunky, ktorá vyrába vodu","Časť bunky, ktorá vyrába jedlo"], correctChoiceIndex:0},
    {id:"sk_187", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to centriola?", choices:["Časť bunky, ktorá pomáha pri delení buniek","Časť bunky, ktorá vyrába vodu","Časť bunky, ktorá vyrába jedlo"], correctChoiceIndex:0},
    {id:"sk_188", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to chromozóm?", choices:["Štruktúra v jadre bunky, ktorá obsahuje DNA","Časť bunky, ktorá vyrába vodu","Časť bunky, ktorá vyrába jedlo"], correctChoiceIndex:0},
    {id:"sk_189", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to gen?", choices:["Sekcia DNA, ktorá obsahuje inštrukcie pre výrobu proteínov","Časť bunky, ktorá vyrába vodu","Časť bunky, ktorá vyrába jedlo"], correctChoiceIndex:0},
    {id:"sk_190", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to DNA?", choices:["Molekula, ktorá obsahuje inštrukcie pre vývoj a fungovanie organizmu","Časť bunky, ktorá vyrába vodu","Časť bunky, ktorá vyrába jedlo"], correctChoiceIndex:0},
    {id:"sk_191", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to RNA?", choices:["Molekula, ktorá pomáha pri výrobe proteínov","Časť bunky, ktorá vyrába vodu","Časť bunky, ktorá vyrába jedlo"], correctChoiceIndex:0},
    {id:"sk_192", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to biotop?", choices:["Miesto, kde žije konkrétna skupina organizmov","Skupina hôr","Skupina riek"], correctChoiceIndex:0},
    {id:"sk_193", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to biocenóza?", choices:["Skupina organizmov žijúcich v rovnakom biotope","Skupina hôr","Skupina riek"], correctChoiceIndex:0},
    {id:"sk_194", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to potravný reťazec?", choices:["Sekvencia organizmov, ktoré sa navzájom živia","Skupina hôr","Skupina riek"], correctChoiceIndex:0},
    {id:"sk_195", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to potravná pyramída?", choices:["Grafické znázornenie počtu organizmov na rôznych úrovniach potravného reťazca","Skupina hôr","Skupina riek"], correctChoiceIndex:0},
    {id:"sk_196", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to symbióza?", choices:["Vzťah medzi dvoma druhmi, ktoré sa navzájom výhodne dopĺňajú","Skupina hôr","Skupina riek"], correctChoiceIndex:0},
    {id:"sk_197", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Aký je tretí stav látky okrem pevného a kvapalného?", choices:["Plynový","Drevený","Kovový"], correctChoiceIndex:0},
    {id:"sk_198", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Čo sa stane, keď zmiešate jedlú sódu a ocot?", choices:["Vytvorí sa pena","Vytvorí sa dúha","Nič sa nestane"], correctChoiceIndex:0},
    {id:"sk_199", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Čo je voda?", choices:["Plyn","Kvapalina","Pevná látka"], correctChoiceIndex:1},
    {id:"sk_200", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Čo je ľad?", choices:["Plyn","Kvapalina","Pevná látka"], correctChoiceIndex:2},
    {id:"sk_201", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Čo je para?", choices:["Plyn","Kvapalina","Pevná látka"], correctChoiceIndex:0},
    {id:"sk_202", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aký je názov procesu, keď voda mení svoj stav z kvapaliny na plyn?", choices:["Varenie","Mrazenie","Topenie"], correctChoiceIndex:0},
    {id:"sk_203", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aký je názov procesu, keď ľad mení svoj stav z pevnej látky na kvapalinu?", choices:["Varenie","Mrazenie","Topenie"], correctChoiceIndex:2},
    {id:"sk_204", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aký je názov procesu, keď voda mení svoj stav z kvapaliny na pevnú látku?", choices:["Varenie","Mrazenie","Topenie"], correctChoiceIndex:1},
    {id:"sk_205", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo sa stane, keď zmiešate jedlú sódu a ocot a potom pridáte červené farbivo?", choices:["Vytvorí sa červená pena","Vytvorí sa dúha","Nič sa nestane"], correctChoiceIndex:0},
    {id:"sk_206", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo sa stane, keď zmiešate jedlú sódu a ocot a potom pridáte modré farbivo?", choices:["Vytvorí sa modrá pena","Vytvorí sa dúha","Nič sa nestane"], correctChoiceIndex:0},
    {id:"sk_207", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo sa stane, keď zmiešate jedlú sódu a ocot a potom pridáte zelené farbivo?", choices:["Vytvorí sa zelená pena","Vytvorí sa dúha","Nič sa nestane"], correctChoiceIndex:0},
    {id:"sk_208", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo sa stane, keď zmiešate jedlú sódu a ocot a potom pridáte žlté farbivo?", choices:["Vytvorí sa žltá pena","Vytvorí sa dúha","Nič sa nestane"], correctChoiceIndex:0},
    {id:"sk_209", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo sa stane, keď zmiešate jedlú sódu a ocot a potom pridáte fialové farbivo?", choices:["Vytvorí sa fialová pena","Vytvorí sa dúha","Nič sa nestane"], correctChoiceIndex:0},
    {id:"sk_210", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo sa stane, keď zmiešate jedlú sódu a ocot a potom pridáte oranžové farbivo?", choices:["Vytvorí sa oranžová pena","Vytvorí sa dúha","Nič sa nestane"], correctChoiceIndex:0},
    {id:"sk_211", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo sa stane, keď zmiešate jedlú sódu a ocot a potom pridáte ružové farbivo?", choices:["Vytvorí sa ružová pena","Vytvorí sa dúha","Nič sa nestane"], correctChoiceIndex:0},
    {id:"sk_212", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo sa stane, keď zmiešate jedlú sódu a ocot a potom pridáte hnedé farbivo?", choices:["Vytvorí sa hnedá pena","Vytvorí sa dúha","Nič sa nestane"], correctChoiceIndex:0},
    {id:"sk_213", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo sa stane, keď zmiešate jedlú sódu a ocot a potom pridáte čierne farbivo?", choices:["Vytvorí sa čierna pena","Vytvorí sa dúha","Nič sa nestane"], correctChoiceIndex:0},
    {id:"sk_214", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo sa stane, keď zmiešate jedlú sódu a ocot a potom pridáte bielé farbivo?", choices:["Vytvorí sa biela pena","Vytvorí sa dúha","Nič sa nestane"], correctChoiceIndex:0},
    {id:"sk_215", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo sa stane, keď zmiešate jedlú sódu a ocot a potom pridáte sivé farbivo?", choices:["Vytvorí sa sivá pena","Vytvorí sa dúha","Nič sa nestane"], correctChoiceIndex:0},
    {id:"sk_216", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo sa stane, keď zmiešate jedlú sódu a ocot a potom pridáte zlaté farbivo?", choices:["Vytvorí sa zlatá pena","Vytvorí sa dúha","Nič sa nestane"], correctChoiceIndex:0},
    {id:"sk_217", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo sa stane, keď zmiešate jedlú sódu a ocot a potom pridáte strieborné farbivo?", choices:["Vytvorí sa strieborná pena","Vytvorí sa dúha","Nič sa nestane"], correctChoiceIndex:0},
    {id:"sk_218", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo sa stane, keď zmiešate jedlú sódu a ocot a potom pridáte bronzové farbivo?", choices:["Vytvorí sa bronzová pena","Vytvorí sa dúha","Nič sa nestane"], correctChoiceIndex:0},
    {id:"sk_219", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo sa stane, keď zmiešate jedlú sódu a ocot a potom pridáte medené farbivo?", choices:["Vytvorí sa medená pena","Vytvorí sa dúha","Nič sa nestane"], correctChoiceIndex:0},
    {id:"sk_220", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo sa stane, keď zmiešate jedlú sódu a ocot a potom pridáte oceľové farbivo?", choices:["Vytvorí sa oceľová pena","Vytvorí sa dúha","Nič sa nestane"], correctChoiceIndex:0},
    {id:"sk_221", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Čo je recyklácia?", choices:["Je to keď jedíme","Je to keď spíme","Je to keď premeníme odpad na niečo nové"], correctChoiceIndex:2},
    {id:"sk_222", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Čo môžeme recyklovať?", choices:["Jedlo","Papier, plast, sklo","Autá"], correctChoiceIndex:1},
    {id:"sk_223", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Čo znamená slovo znečistenie?", choices:["Čistenie","Špinenie našej planéty","Kreslenie"], correctChoiceIndex:1},
    {id:"sk_224", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Čo môžeme urobiť, aby sme zabránili znečisteniu?", choices:["Hrať sa vonku","Čistiť po sebe","Jesť sladkosti"], correctChoiceIndex:1},
    {id:"sk_225", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Prečo je dôležité chrániť prírodu?", choices:["Aby sme mali kde bývať","Aby sme mali čo jesť","Všetky odpovede sú správne"], correctChoiceIndex:2},
    {id:"sk_226", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je globálne otepľovanie?", choices:["Keď je vonku veľmi horúco","Keď sa zem zahrieva kvôli znečisťujúcim látkam","Keď je zima"], correctChoiceIndex:1},
    {id:"sk_227", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo spôsobuje eróziu pôdy?", choices:["Voda, vietor a ľudská činnosť","Slzy","Sneh"], correctChoiceIndex:0},
    {id:"sk_228", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to obnoviteľná energia?", choices:["Energia z jedla","Energia z vetra, slnka alebo vody","Energia z auta"], correctChoiceIndex:1},
    {id:"sk_229", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to kompostovanie?", choices:["Keď sa odpadky menia na pôdu","Keď sa odpadky menia na plast","Keď sa odpadky menia na vodu"], correctChoiceIndex:0},
    {id:"sk_230", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Prečo je dôležité šetriť vodou?", choices:["Aby sme mali čo piť","Aby sme mali čo jesť","Aby sme mali kde plávať"], correctChoiceIndex:0},
    {id:"sk_231", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to ozónová diera?", choices:["Diera v našom dome","Diera v našej obuvi","Miesto, kde je ozónová vrstva tenšia alebo chýba"], correctChoiceIndex:2},
    {id:"sk_232", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to biodiverzita?", choices:["Rôznorodosť života na Zemi","Rôznorodosť jedla","Rôznorodosť farieb"], correctChoiceIndex:0},
    {id:"sk_233", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to ekosystém?", choices:["Skupina rastlín a zvierat, ktoré spolu žijú a vzájomne na seba vplývajú","Skupina ľudí","Skupina aut"], correctChoiceIndex:0},
    {id:"sk_234", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to udržateľný rozvoj?", choices:["Rozvoj, ktorý uspokojuje potreby súčasnej generácie bez ohrozenia budúcich generácií","Rozvoj, ktorý je rýchly","Rozvoj, ktorý je pomalý"], correctChoiceIndex:0},
    {id:"sk_235", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to zelený domový efekt?", choices:["Efekt, ktorý zahrieva našu planétu","Efekt, ktorý chladí našu planétu","Efekt, ktorý mení farbu našej planéty"], correctChoiceIndex:0},
    {id:"sk_236", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to uhlíková stopa?", choices:["Stopa, ktorú zanechávame pri chôdzi","Množstvo uhlíka, ktoré vypustíme do ovzdušia","Stopa, ktorú zanechávame pri behu"], correctChoiceIndex:1},
    {id:"sk_237", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to recyklácia vody?", choices:["Keď pijeme vodu","Keď vodu používame znova a znova","Keď vodu vypustíme do rieky"], correctChoiceIndex:1},
    {id:"sk_238", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to smog?", choices:["Typ znečistenia vzduchu","Typ jedla","Typ auta"], correctChoiceIndex:0},
    {id:"sk_239", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to obnoviteľný zdroj?", choices:["Zdroj, ktorý sa môže obnoviť","Zdroj, ktorý sa nemôže obnoviť","Zdroj, ktorý sa môže zničiť"], correctChoiceIndex:0},
    {id:"sk_240", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to ekologická stopa?", choices:["Stopa, ktorú zanechávame pri chôdzi","Miera, ako naše činnosti ovplyvňujú planétu","Stopa, ktorú zanechávame pri behu"], correctChoiceIndex:1},
    {id:"sk_241", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to udržateľná energia?", choices:["Energia, ktorú môžeme používať znova a znova bez toho, aby sme poškodili planétu","Energia, ktorú môžeme používať len raz","Energia, ktorú môžeme používať len v noci"], correctChoiceIndex:0},
    {id:"sk_242", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to prírodná rezervácia?", choices:["Miesto, kde môžeme chrániť a študovať prírodu","Miesto, kde môžeme hrať futbal","Miesto, kde môžeme plávať"], correctChoiceIndex:0},
    {id:"sk_243", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to obnoviteľný materiál?", choices:["Materiál, ktorý môžeme používať znova a znova","Materiál, ktorý môžeme používať len raz","Materiál, ktorý môžeme používať len v noci"], correctChoiceIndex:0},
    {id:"sk_244", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to ekologická rovnováha?", choices:["Rovnováha medzi rôznymi druhmi života na Zemi","Rovnováha medzi jedlom a pitím","Rovnováha medzi hraním a učením"], correctChoiceIndex:0},
    {id:"sk_245", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je to udržateľná výroba?", choices:["Výroba, ktorá uspokojuje naše potreby bez toho, aby poškodila planétu","Výroba, ktorá je rýchla","Výroba, ktorá je pomalá"], correctChoiceIndex:0},
    {id:"sk_246", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Čo je gravitácia?", choices:["Silu, ktorá nás drží na zemi","Silu, ktorá nás tlačí do vzduchu","Silu, ktorá nás tlačí do strany"], correctChoiceIndex:0},
    {id:"sk_247", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Čo je trenie?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá nás drží na zemi","Silu, ktorá bráni pohybu"], correctChoiceIndex:2},
    {id:"sk_248", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Čo je jednoduchý stroj?", choices:["Nástroj, ktorý nám pomáha robiť prácu","Nástroj, ktorý nám bráni robiť prácu","Nástroj, ktorý nám pomáha hrať sa"], correctChoiceIndex:0},
    {id:"sk_249", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Čo je kolískový stroj?", choices:["Stroj, ktorý nám pomáha hrať sa","Stroj, ktorý nám pomáha robiť prácu","Stroj, ktorý nám bráni robiť prácu"], correctChoiceIndex:1},
    {id:"sk_250", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Čo je kladkostroj?", choices:["Stroj, ktorý nám pomáha hrať sa","Stroj, ktorý nám pomáha robiť prácu","Stroj, ktorý nám bráni robiť prácu"], correctChoiceIndex:1},
    {id:"sk_251", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je páka?", choices:["Typ jednoduchého stroja","Typ komplikovaného stroja","Typ stroja, ktorý nám pomáha hrať sa"], correctChoiceIndex:0},
    {id:"sk_252", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je skrutka?", choices:["Typ jednoduchého stroja","Typ komplikovaného stroja","Typ stroja, ktorý nám pomáha hrať sa"], correctChoiceIndex:0},
    {id:"sk_253", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je klin?", choices:["Typ jednoduchého stroja","Typ komplikovaného stroja","Typ stroja, ktorý nám pomáha hrať sa"], correctChoiceIndex:0},
    {id:"sk_254", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je koleso a os?", choices:["Typ jednoduchého stroja","Typ komplikovaného stroja","Typ stroja, ktorý nám pomáha hrať sa"], correctChoiceIndex:0},
    {id:"sk_255", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je magnetická sila?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá nás drží na zemi","Silu, ktorá môže pritiahnuť alebo odohnať predmety"], correctChoiceIndex:2},
    {id:"sk_256", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je elektrická sila?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá môže pritiahnuť alebo odohnať predmety","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_257", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá môže pritiahnuť alebo odohnať predmety","Silu, ktorá bráni pohybu"], correctChoiceIndex:2},
    {id:"sk_258", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila tiaže?", choices:["Silu, ktorá nás drží na zemi","Silu, ktorá nás tlačí do vzduchu","Silu, ktorá môže pritiahnuť alebo odohnať predmety"], correctChoiceIndex:0},
    {id:"sk_259", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila pružnosti?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá môže pritiahnuť alebo odohnať predmety","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_260", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila trenia?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_261", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila gravitácie?", choices:["Silu, ktorá nás drží na zemi","Silu, ktorá nás tlačí do vzduchu","Silu, ktorá môže pritiahnuť alebo odohnať predmety"], correctChoiceIndex:0},
    {id:"sk_262", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu vzduchu?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_263", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu vody?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_264", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu tepla?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_265", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu svetla?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_266", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu zvuku?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_267", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu magnetizmu?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_268", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu elektriny?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_269", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu gravitácie?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_270", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu tiaže?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_271", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu pružnosti?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_272", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu trenia?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_273", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu magnetizmu?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_274", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu elektriny?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_275", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu gravitácie?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_276", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu tiaže?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_277", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu pružnosti?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_278", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu trenia?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_279", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Čo je sila odporu magnetizmu?", choices:["Silu, ktorá nás tlačí do vzduchu","Silu, ktorá bráni pohybu","Silu, ktorá nás drží na zemi"], correctChoiceIndex:1},
    {id:"sk_280", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Aký druh oblakov je biely a hladký?", choices:["Cumulus","Stratus","Cirrus"], correctChoiceIndex:1},
    {id:"sk_281", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Aký druh oblakov je vysoko na oblohe a vyzerá ako perie?", choices:["Stratus","Cumulus","Cirrus"], correctChoiceIndex:2},
    {id:"sk_282", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Aký druh oblakov je nízko na oblohe a vyzerá ako bavlna?", choices:["Cumulus","Stratus","Cirrus"], correctChoiceIndex:0},
    {id:"sk_283", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Aký druh zrážok je sneh?", choices:["Zrážky","Vlhkosť","Rosu"], correctChoiceIndex:0},
    {id:"sk_284", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Aký druh zrážok je dážď?", choices:["Vlhkosť","Zrážky","Rosu"], correctChoiceIndex:1},
    {id:"sk_285", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Aké ročné obdobie je po lete?", choices:["Jeseň","Zima","Jar"], correctChoiceIndex:0},
    {id:"sk_286", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Aké ročné obdobie je po zime?", choices:["Jar","Leto","Jeseň"], correctChoiceIndex:0},
    {id:"sk_287", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.science, question:"Aké ročné obdobie je po jari?", choices:["Leto","Jeseň","Zima"], correctChoiceIndex:0},
    {id:"sk_288", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aký druh oblakov môže spôsobiť búrku?", choices:["Cumulonimbus","Stratus","Cirrus"], correctChoiceIndex:0},
    {id:"sk_289", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aký druh zrážok je hmla?", choices:["Vlhkosť","Zrážky","Rosu"], correctChoiceIndex:0},
    {id:"sk_290", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aké ročné obdobie je najteplejšie?", choices:["Leto","Jeseň","Zima"], correctChoiceIndex:0},
    {id:"sk_291", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aké ročné obdobie je najchladnejšie?", choices:["Zima","Jar","Leto"], correctChoiceIndex:0},
    {id:"sk_292", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aký druh zrážok je mráz?", choices:["Rosu","Zrážky","Vlhkosť"], correctChoiceIndex:1},
    {id:"sk_293", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aký druh oblakov je tenký a biely?", choices:["Cirrus","Cumulus","Stratus"], correctChoiceIndex:0},
    {id:"sk_294", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aký druh oblakov je nízko a šedý?", choices:["Stratus","Cumulus","Cirrus"], correctChoiceIndex:0},
    {id:"sk_295", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aký druh zrážok je dážď?", choices:["Zrážky","Vlhkosť","Rosu"], correctChoiceIndex:0},
    {id:"sk_296", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aké ročné obdobie je po lete?", choices:["Jeseň","Zima","Jar"], correctChoiceIndex:0},
    {id:"sk_297", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aké ročné obdobie je po zime?", choices:["Jar","Leto","Jeseň"], correctChoiceIndex:0},
    {id:"sk_298", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aké ročné obdobie je po jari?", choices:["Leto","Jeseň","Zima"], correctChoiceIndex:0},
    {id:"sk_299", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aký druh oblakov môže spôsobiť búrku?", choices:["Cumulonimbus","Stratus","Cirrus"], correctChoiceIndex:0},
    {id:"sk_300", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aký druh zrážok je hmla?", choices:["Vlhkosť","Zrážky","Rosu"], correctChoiceIndex:0},
    {id:"sk_301", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aké ročné obdobie je najteplejšie?", choices:["Leto","Jeseň","Zima"], correctChoiceIndex:0},
    {id:"sk_302", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aké ročné obdobie je najchladnejšie?", choices:["Zima","Jar","Leto"], correctChoiceIndex:0},
    {id:"sk_303", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aký druh zrážok je mráz?", choices:["Rosu","Zrážky","Vlhkosť"], correctChoiceIndex:1},
    {id:"sk_304", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.science, question:"Aký druh oblakov je tenký a biely?", choices:["Cirrus","Cumulus","Stratus"], correctChoiceIndex:0},
    {id:"sk_305", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Aký je tento komunikačný prístroj?", choices:["Telefón","Televízor","Rádio"], correctChoiceIndex:0},
    {id:"sk_306", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je to internet?", choices:["Miesto, kde môžeme hrať hry","Miesto, kde môžeme hľadať informácie","Obe odpovede sú správne"], correctChoiceIndex:2},
    {id:"sk_307", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo by sme nemali robiť na internete?", choices:["Hrať hry","Posielať svoje fotky neznámym ľuďom","Pozerať videá"], correctChoiceIndex:1},
    {id:"sk_308", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Aký je tento komunikačný prístroj?", choices:["Rádio","Počítač","Telefón"], correctChoiceIndex:2},
    {id:"sk_309", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je to e-mail?", choices:["Spôsob, ako posielať listy cez internet","Spôsob, ako hrať hry","Spôsob, ako kresliť obrázky"], correctChoiceIndex:0},
    {id:"sk_310", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Aký je tento komunikačný prístroj?", choices:["Tablet","Rádio","Telefón"], correctChoiceIndex:0},
    {id:"sk_311", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to sociálna sieť?", choices:["Miesto, kde môžeme zdieľať fotky a správy","Miesto, kde môžeme hrať hry","Miesto, kde môžeme kúpiť veci"], correctChoiceIndex:0},
    {id:"sk_312", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo by sme nemali robiť na sociálnych sieťach?", choices:["Zdieľať svoje osobné informácie","Pozerať videá","Hrať hry"], correctChoiceIndex:0},
    {id:"sk_313", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Aký je tento komunikačný prístroj?", choices:["Rádio","Počítač","Tablet"], correctChoiceIndex:1},
    {id:"sk_314", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to webová stránka?", choices:["Miesto na internete, kde môžeme nájsť informácie","Miesto, kde môžeme hrať hry","Miesto, kde môžeme kresliť obrázky"], correctChoiceIndex:0},
    {id:"sk_315", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to mobilný telefón?", choices:["Prístroj, ktorým môžeme volať a posielať správy","Prístroj, ktorým môžeme hrať hry","Obe odpovede sú správne"], correctChoiceIndex:2},
    {id:"sk_316", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to internetový prehliadač?", choices:["Program, ktorým môžeme prehliadať internet","Program, ktorým môžeme hrať hry","Program, ktorým môžeme kresliť obrázky"], correctChoiceIndex:0},
    {id:"sk_317", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to Wi-Fi?", choices:["Spôsob, ako sa pripojiť na internet bez káblov","Spôsob, ako hrať hry","Spôsob, ako kresliť obrázky"], correctChoiceIndex:0},
    {id:"sk_318", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to aplikácia?", choices:["Program, ktorý môžeme používať na našom telefóne alebo tablete","Program, ktorý môžeme hrať na našom telefóne alebo tablete","Obe odpovede sú správne"], correctChoiceIndex:2},
    {id:"sk_319", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to chat?", choices:["Spôsob, ako komunikovať s ľuďmi cez internet","Spôsob, ako hrať hry","Spôsob, ako kresliť obrázky"], correctChoiceIndex:0},
    {id:"sk_320", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to videohovor?", choices:["Spôsob, ako vidieť a hovoriť s ľuďmi cez internet","Spôsob, ako hrať hry","Spôsob, ako kresliť obrázky"], correctChoiceIndex:0},
    {id:"sk_321", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to textová správa?", choices:["Správa, ktorú môžeme poslať z nášho telefónu","Hra, ktorú môžeme hrať na našom telefóne","Obrázok, ktorý môžeme kresliť na našom telefóne"], correctChoiceIndex:0},
    {id:"sk_322", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to e-kniha?", choices:["Kniha, ktorú môžeme čítať na našom telefóne alebo tablete","Hra, ktorú môžeme hrať na našom telefóne alebo tablete","Obrázok, ktorý môžeme kresliť na našom telefóne alebo tablete"], correctChoiceIndex:0},
    {id:"sk_323", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to podcast?", choices:["Nahrávka, ktorú môžeme počúvať na našom telefóne alebo tablete","Hra, ktorú môžeme hrať na našom telefóne alebo tablete","Obrázok, ktorý môžeme kresliť na našom telefóne alebo tablete"], correctChoiceIndex:0},
    {id:"sk_324", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to blog?", choices:["Webová stránka, kde ľudia môžu zdieľať svoje myšlienky a nápady","Hra, ktorú môžeme hrať na internete","Obrázok, ktorý môžeme kresliť na internete"], correctChoiceIndex:0},
    {id:"sk_325", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je to počítač?", choices:["Hračka","Nástroj na prácu","Zviera"], correctChoiceIndex:1},
    {id:"sk_326", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je to klávesnica?", choices:["Nástroj na písanie na počítači","Nástroj na kreslenie","Nástroj na hranie hudby"], correctChoiceIndex:0},
    {id:"sk_327", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je to myš?", choices:["Zviera","Nástroj na ovládanie počítača","Nástroj na kreslenie"], correctChoiceIndex:1},
    {id:"sk_328", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je to obrazovka?", choices:["Nástroj na kreslenie","Nástroj na ovládanie počítača","Nástroj na zobrazovanie informácií z počítača"], correctChoiceIndex:2},
    {id:"sk_329", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je to tlačiareň?", choices:["Nástroj na vytváranie papierových kópií z počítača","Nástroj na kreslenie","Nástroj na hranie hudby"], correctChoiceIndex:0},
    {id:"sk_330", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to internet?", choices:["Súbor webových stránok","Hračka","Zviera"], correctChoiceIndex:0},
    {id:"sk_331", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to e-mail?", choices:["Spôsob komunikácie cez počítač","Nástroj na kreslenie","Nástroj na hranie hudby"], correctChoiceIndex:0},
    {id:"sk_332", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to programovanie?", choices:["Spôsob vytvárania hier a aplikácií na počítači","Spôsob kreslenia","Spôsob hrania hudby"], correctChoiceIndex:0},
    {id:"sk_333", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to operačný systém?", choices:["Program, ktorý ovláda počítač","Nástroj na kreslenie","Nástroj na hranie hudby"], correctChoiceIndex:0},
    {id:"sk_334", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to súbor?", choices:["Miesto na počítači, kde sa ukladajú informácie","Nástroj na kreslenie","Nástroj na hranie hudby"], correctChoiceIndex:0},
    {id:"sk_335", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to priečinok?", choices:["Miesto na počítači, kde sa ukladajú súbory","Nástroj na kreslenie","Nástroj na hranie hudby"], correctChoiceIndex:0},
    {id:"sk_336", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to hardvér?", choices:["Fyzické časti počítača","Nástroj na kreslenie","Nástroj na hranie hudby"], correctChoiceIndex:0},
    {id:"sk_337", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to softvér?", choices:["Programy a aplikácie na počítači","Nástroj na kreslenie","Nástroj na hranie hudby"], correctChoiceIndex:0},
    {id:"sk_338", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to procesor?", choices:["Srdce počítača, ktoré spracováva informácie","Nástroj na kreslenie","Nástroj na hranie hudby"], correctChoiceIndex:0},
    {id:"sk_339", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to pamäť RAM?", choices:["Miesto na počítači, kde sa ukladajú dočasné informácie","Nástroj na kreslenie","Nástroj na hranie hudby"], correctChoiceIndex:0},
    {id:"sk_340", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to pevný disk?", choices:["Miesto na počítači, kde sa ukladajú trvalé informácie","Nástroj na kreslenie","Nástroj na hranie hudby"], correctChoiceIndex:0},
    {id:"sk_341", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to grafická karta?", choices:["Časť počítača, ktorá spracováva obrazy","Nástroj na kreslenie","Nástroj na hranie hudby"], correctChoiceIndex:0},
    {id:"sk_342", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to zvuková karta?", choices:["Časť počítača, ktorá spracováva zvuk","Nástroj na kreslenie","Nástroj na hranie hudby"], correctChoiceIndex:0},
    {id:"sk_343", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to sieťová karta?", choices:["Časť počítača, ktorá umožňuje pripojenie k internetu","Nástroj na kreslenie","Nástroj na hranie hudby"], correctChoiceIndex:0},
    {id:"sk_344", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to USB port?", choices:["Miesto na počítači, kde sa pripájajú externé zariadenia","Nástroj na kreslenie","Nástroj na hranie hudby"], correctChoiceIndex:0},
    {id:"sk_345", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je to mikrovlnná rúra?", choices:["Je to hračka","Je to jedlo","Je to spotrebič na varenie"], correctChoiceIndex:2},
    {id:"sk_346", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Na čo slúži chladnička?", choices:["Na hranie hier","Na uchovávanie jedla","Na kreslenie"], correctChoiceIndex:1},
    {id:"sk_347", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je to televízor?", choices:["Je to náradie","Je to spotrebič na sledovanie programov","Je to jedlo"], correctChoiceIndex:1},
    {id:"sk_348", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Na čo slúži práčka?", choices:["Na pranie oblečenia","Na varenie","Na kreslenie"], correctChoiceIndex:0},
    {id:"sk_349", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je to rýchlovarná kanvica?", choices:["Je to hračka","Je to spotrebič na varenie vody","Je to jedlo"], correctChoiceIndex:1},
    {id:"sk_350", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to mixér?", choices:["Je to nástroj na miešanie potravín","Je to hračka","Je to jedlo"], correctChoiceIndex:0},
    {id:"sk_351", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Na čo slúži vysávač?", choices:["Na čistenie podlahy","Na varenie","Na hranie hier"], correctChoiceIndex:0},
    {id:"sk_352", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to kávovar?", choices:["Je to spotrebič na varenie kávy","Je to hračka","Je to jedlo"], correctChoiceIndex:0},
    {id:"sk_353", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Na čo slúži hriankovač?", choices:["Na hranie hier","Na pečenie hriankov","Na kreslenie"], correctChoiceIndex:1},
    {id:"sk_354", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to sušička?", choices:["Je to spotrebič na sušenie oblečenia","Je to hračka","Je to jedlo"], correctChoiceIndex:0},
    {id:"sk_355", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Na čo slúži žehlička?", choices:["Na žehlenie oblečenia","Na varenie","Na hranie hier"], correctChoiceIndex:0},
    {id:"sk_356", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to mobilný telefón?", choices:["Je to spotrebič na komunikáciu","Je to hračka","Je to jedlo"], correctChoiceIndex:0},
    {id:"sk_357", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Na čo slúži počítač?", choices:["Na hranie hier","Na prácu a učenie","Na kreslenie"], correctChoiceIndex:1},
    {id:"sk_358", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to tablet?", choices:["Je to mobilné zariadenie na prácu a hranie","Je to hračka","Je to jedlo"], correctChoiceIndex:0},
    {id:"sk_359", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Na čo slúži reproduktor?", choices:["Na varenie","Na prehrávanie hudby","Na kreslenie"], correctChoiceIndex:1},
    {id:"sk_360", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to fotoaparát?", choices:["Je to zariadenie na fotografovanie","Je to hračka","Je to jedlo"], correctChoiceIndex:0},
    {id:"sk_361", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Na čo slúži hodinky?", choices:["Na hranie hier","Na sledovanie času","Na kreslenie"], correctChoiceIndex:1},
    {id:"sk_362", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to tlačiareň?", choices:["Je to zariadenie na tlač dokumentov","Je to hračka","Je to jedlo"], correctChoiceIndex:0},
    {id:"sk_363", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Na čo slúži myš pre počítač?", choices:["Na ovládanie počítača","Na varenie","Na hranie hier"], correctChoiceIndex:0},
    {id:"sk_364", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to klávesnica?", choices:["Je to zariadenie na písanie na počítači","Je to hračka","Je to jedlo"], correctChoiceIndex:0},
    {id:"sk_365", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Na čo slúži monitor?", choices:["Na hranie hier","Na zobrazovanie informácií z počítača","Na kreslenie"], correctChoiceIndex:1},
    {id:"sk_366", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to router?", choices:["Je to zariadenie na pripojenie k internetu","Je to hračka","Je to jedlo"], correctChoiceIndex:0},
    {id:"sk_367", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Na čo slúži modem?", choices:["Na hranie hier","Na pripojenie k internetu","Na kreslenie"], correctChoiceIndex:1},
    {id:"sk_368", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to USB kľúč?", choices:["Je to zariadenie na ukladanie dát","Je to hračka","Je to jedlo"], correctChoiceIndex:0},
    {id:"sk_369", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Na čo slúži slúchadlá?", choices:["Na hranie hier","Na počúvanie hudby","Na kreslenie"], correctChoiceIndex:1},
    {id:"sk_370", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to webkamera?", choices:["Je to zariadenie na videohovory","Je to hračka","Je to jedlo"], correctChoiceIndex:0},
    {id:"sk_371", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Na čo slúži mikrofón?", choices:["Na hranie hier","Na nahrávanie zvuku","Na kreslenie"], correctChoiceIndex:1},
    {id:"sk_372", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to nabíjačka?", choices:["Je to zariadenie na nabíjanie elektronických zariadení","Je to hračka","Je to jedlo"], correctChoiceIndex:0},
    {id:"sk_373", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Na čo slúži diaľkové ovládanie?", choices:["Na ovládanie elektronických zariadení na diaľku","Na varenie","Na hranie hier"], correctChoiceIndex:0},
    {id:"sk_374", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to batéria?", choices:["Je to zdroj energie pre elektronické zariadenia","Je to hračka","Je to jedlo"], correctChoiceIndex:0},
    {id:"sk_375", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo robia roboty?", choices:["Pomáhajú ľuďom","Hrajú sa","Spia"], correctChoiceIndex:0},
    {id:"sk_376", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Ako sa roboty pohybujú?", choices:["Chôdzia","Lietajú","Plávajú"], correctChoiceIndex:0},
    {id:"sk_377", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo roboty nemôžu robiť?", choices:["Cítiť","Hovoriť","Chodiť"], correctChoiceIndex:0},
    {id:"sk_378", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Ako roboty komunikujú?", choices:["Hovorí","Píše","Bliká"], correctChoiceIndex:2},
    {id:"sk_379", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo roboty potrebujú na fungovanie?", choices:["Jedlo","Baterky","Vodu"], correctChoiceIndex:1},
    {id:"sk_380", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Ako roboty pomáhajú ľuďom?", choices:["Robia prácu","Hrajú hry","Spievajú piesne"], correctChoiceIndex:0},
    {id:"sk_381", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je robotický inžinier?", choices:["Človek, ktorý vytvára roboty","Človek, ktorý opravuje autá","Človek, ktorý vyrába hračky"], correctChoiceIndex:0},
    {id:"sk_382", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Prečo sú roboty dôležité?", choices:["Pomáhajú ľuďom robiť veci","Sú pekné","Sú zábavné"], correctChoiceIndex:0},
    {id:"sk_383", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo roboty nemôžu robiť?", choices:["Cítiť emócie","Hrať futbal","Písať"], correctChoiceIndex:0},
    {id:"sk_384", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Ako roboty vedia, čo majú robiť?", choices:["Sú naprogramované","Sú naučené","Sú inšpirované"], correctChoiceIndex:0},
    {id:"sk_385", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je umelá inteligencia?", choices:["Schopnosť robotov učiť sa","Schopnosť robotov lietať","Schopnosť robotov plávať"], correctChoiceIndex:0},
    {id:"sk_386", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Ako roboty vedia, kde sú?", choices:["Používajú senzory","Používajú mapu","Používajú kompas"], correctChoiceIndex:0},
    {id:"sk_387", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to robot?", choices:["Stroj, ktorý môže vykonávať úlohy","Hračka","Auto"], correctChoiceIndex:0},
    {id:"sk_388", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo môže robot robiť?", choices:["Môže vykonávať úlohy","Môže jesť","Môže spať"], correctChoiceIndex:0},
    {id:"sk_389", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Ako roboty vedia, čo majú robiť?", choices:["Sú naprogramované","Sú naučené","Sú inšpirované"], correctChoiceIndex:0},
    {id:"sk_390", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to programovanie?", choices:["Písanie inštrukcií pre roboty","Písanie kníh","Písanie listov"], correctChoiceIndex:0},
    {id:"sk_391", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to senzor?", choices:["Zariadenie, ktoré roboty používajú na zisťovanie vecí","Zariadenie, ktoré roboty používajú na hranie","Zariadenie, ktoré roboty používajú na jedlo"], correctChoiceIndex:0},
    {id:"sk_392", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to kódovanie?", choices:["Písanie inštrukcií pre počítače a roboty","Písanie básní","Písanie príbehov"], correctChoiceIndex:0},
    {id:"sk_393", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to algoritmus?", choices:["Sada inštrukcií, ktoré robot sleduje","Sada pravidiel pre hru","Sada receptov na varenie"], correctChoiceIndex:0},
    {id:"sk_394", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je to softvér?", choices:["Programy, ktoré roboty používajú na vykonávanie úloh","Hračky, ktoré roboty používajú na hranie","Jedlo, ktoré roboty jedí"], correctChoiceIndex:0},
    {id:"sk_395", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Aký druh dopravy lieta vo vzduchu?", choices:["Auto","Vlak","Lietadlo"], correctChoiceIndex:2},
    {id:"sk_396", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je bicykel?", choices:["Druh jedla","Druh dopravy","Druh zvieraťa"], correctChoiceIndex:1},
    {id:"sk_397", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Aký druh dopravy pláva na vode?", choices:["Autobus","Loď","Bicykel"], correctChoiceIndex:1},
    {id:"sk_398", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je autobus?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_399", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Aký druh dopravy jazdí po koľajniciach?", choices:["Auto","Vlak","Bicykel"], correctChoiceIndex:1},
    {id:"sk_400", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je auto?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_401", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Aký druh dopravy môže jazdiť po ceste?", choices:["Vlak","Auto","Lietadlo"], correctChoiceIndex:1},
    {id:"sk_402", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je motorka?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_403", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Aký druh dopravy môže jazdiť po vode?", choices:["Auto","Bicykel","Loď"], correctChoiceIndex:2},
    {id:"sk_404", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je lietadlo?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_405", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je auto?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_406", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Aký druh dopravy môže jazdiť po ceste?", choices:["Vlak","Auto","Lietadlo"], correctChoiceIndex:1},
    {id:"sk_407", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je motorka?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_408", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Aký druh dopravy môže jazdiť po vode?", choices:["Auto","Bicykel","Loď"], correctChoiceIndex:2},
    {id:"sk_409", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je lietadlo?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_410", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je auto?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_411", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Aký druh dopravy môže jazdiť po ceste?", choices:["Vlak","Auto","Lietadlo"], correctChoiceIndex:1},
    {id:"sk_412", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je motorka?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_413", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Aký druh dopravy môže jazdiť po vode?", choices:["Auto","Bicykel","Loď"], correctChoiceIndex:2},
    {id:"sk_414", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.technology, question:"Čo je lietadlo?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_415", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je loď?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_416", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Aký druh dopravy môže jazdiť po ceste?", choices:["Vlak","Auto","Lietadlo"], correctChoiceIndex:1},
    {id:"sk_417", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je vlak?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_418", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Aký druh dopravy môže jazdiť po vode?", choices:["Auto","Bicykel","Loď"], correctChoiceIndex:2},
    {id:"sk_419", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je lietadlo?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_420", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je loď?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_421", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Aký druh dopravy môže jazdiť po ceste?", choices:["Vlak","Auto","Lietadlo"], correctChoiceIndex:1},
    {id:"sk_422", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je vlak?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_423", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Aký druh dopravy môže jazdiť po vode?", choices:["Auto","Bicykel","Loď"], correctChoiceIndex:2},
    {id:"sk_424", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je lietadlo?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_425", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je loď?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_426", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Aký druh dopravy môže jazdiť po ceste?", choices:["Vlak","Auto","Lietadlo"], correctChoiceIndex:1},
    {id:"sk_427", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je vlak?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_428", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Aký druh dopravy môže jazdiť po vode?", choices:["Auto","Bicykel","Loď"], correctChoiceIndex:2},
    {id:"sk_429", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je lietadlo?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_430", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je loď?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_431", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Aký druh dopravy môže jazdiť po ceste?", choices:["Vlak","Auto","Lietadlo"], correctChoiceIndex:1},
    {id:"sk_432", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je vlak?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_433", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Aký druh dopravy môže jazdiť po vode?", choices:["Auto","Bicykel","Loď"], correctChoiceIndex:2},
    {id:"sk_434", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.technology, question:"Čo je lietadlo?", choices:["Druh dopravy","Druh zvieraťa","Druh jedla"], correctChoiceIndex:0},
    {id:"sk_435", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Čo robí učiteľ?", choices:["Učí deti","Hasi požiare","Lieči ľudí"], correctChoiceIndex:0},
    {id:"sk_436", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Čo robí doktor?", choices:["Učí deti","Lieči ľudí","Hasi požiare"], correctChoiceIndex:1},
    {id:"sk_437", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Čo robí hasič?", choices:["Lieči ľudí","Učí deti","Hasi požiare"], correctChoiceIndex:2},
    {id:"sk_438", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Kde pracuje učiteľ?", choices:["V nemocnici","V škole","Na požiarnom úrade"], correctChoiceIndex:1},
    {id:"sk_439", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Kde pracuje doktor?", choices:["V nemocnici","V škole","Na požiarnom úrade"], correctChoiceIndex:0},
    {id:"sk_440", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Kde pracuje hasič?", choices:["V nemocnici","V škole","Na požiarnom úrade"], correctChoiceIndex:2},
    {id:"sk_441", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Prečo je dôležitá práca učiteľa?", choices:["Pomáha ľuďom byť zdravými","Učí deti nové veci","Hasi požiare"], correctChoiceIndex:1},
    {id:"sk_442", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Prečo je dôležitá práca doktora?", choices:["Učí deti nové veci","Hasi požiare","Pomáha ľuďom byť zdravými"], correctChoiceIndex:2},
    {id:"sk_443", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Prečo je dôležitá práca hasiča?", choices:["Pomáha ľuďom byť zdravými","Hasi požiare","Učí deti nové veci"], correctChoiceIndex:1},
    {id:"sk_444", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aké nástroje používa učiteľ pri práci?", choices:["Stetoskop","Učebnice","Hadica na vodu"], correctChoiceIndex:1},
    {id:"sk_445", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aké nástroje používa doktor pri práci?", choices:["Stetoskop","Učebnice","Hadica na vodu"], correctChoiceIndex:0},
    {id:"sk_446", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aké nástroje používa hasič pri práci?", choices:["Stetoskop","Učebnice","Hadica na vodu"], correctChoiceIndex:2},
    {id:"sk_447", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Čo by si robil, keby si bol učiteľom?", choices:["Liečil by som ľudí","Učil by som deti","Hasil by som požiare"], correctChoiceIndex:1},
    {id:"sk_448", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Čo by si robil, keby si bol doktorom?", choices:["Liečil by som ľudí","Učil by som deti","Hasil by som požiare"], correctChoiceIndex:0},
    {id:"sk_449", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Čo by si robil, keby si bol hasičom?", choices:["Liečil by som ľudí","Učil by som deti","Hasil by som požiare"], correctChoiceIndex:2},
    {id:"sk_450", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Kto pomáha ľuďom, keď sú chorí?", choices:["Učiteľ","Doktor","Hasič"], correctChoiceIndex:1},
    {id:"sk_451", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Kto pomáha deti učiť sa nové veci?", choices:["Učiteľ","Doktor","Hasič"], correctChoiceIndex:0},
    {id:"sk_452", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Kto pomáha hasiť požiare?", choices:["Učiteľ","Doktor","Hasič"], correctChoiceIndex:2},
    {id:"sk_453", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Kto nosí biely plášť pri práci?", choices:["Učiteľ","Doktor","Hasič"], correctChoiceIndex:1},
    {id:"sk_454", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Kto nosí oblečenie na ochranu pred ohňom pri práci?", choices:["Učiteľ","Doktor","Hasič"], correctChoiceIndex:2},
    {id:"sk_455", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Kto nosí oblečenie na učenie pri práci?", choices:["Učiteľ","Doktor","Hasič"], correctChoiceIndex:0},
    {id:"sk_456", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Kto používa knihy pri práci?", choices:["Učiteľ","Doktor","Hasič"], correctChoiceIndex:0},
    {id:"sk_457", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Kto používa medicínske nástroje pri práci?", choices:["Učiteľ","Doktor","Hasič"], correctChoiceIndex:1},
    {id:"sk_458", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Kto používa hadicu na vodu pri práci?", choices:["Učiteľ","Doktor","Hasič"], correctChoiceIndex:2},
    {id:"sk_459", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Kto pomáha ľuďom v núdzi?", choices:["Učiteľ","Doktor","Hasič"], correctChoiceIndex:2},
    {id:"sk_460", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Aká je najzdravšia raňajka?", choices:["Cukrovinky","Ovsené vločky","Hamburger"], correctChoiceIndex:1},
    {id:"sk_461", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ktoré ovocie je najlepšie pre tvoje zuby?", choices:["Jablká","Banány","Pomaranče"], correctChoiceIndex:0},
    {id:"sk_462", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ktorá zelenina je najbohatšia na vitamín C?", choices:["Mrkva","Brokolica","Paprika"], correctChoiceIndex:2},
    {id:"sk_463", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ktoré jedlo je najlepšie pre tvoje srdce?", choices:["Slanina","Ryža","Ryby"], correctChoiceIndex:2},
    {id:"sk_464", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ktoré mlieko je najzdravšie?", choices:["Čokoládové mlieko","Kravské mlieko","Mandľové mlieko"], correctChoiceIndex:2},
    {id:"sk_465", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ktoré jedlo je najlepšie pre tvoj mozog?", choices:["Cukrovinky","Ryby","Hranolky"], correctChoiceIndex:1},
    {id:"sk_466", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ktoré jedlo je najlepšie pre tvoje svaly?", choices:["Cukrovinky","Ryby","Mäso"], correctChoiceIndex:2},
    {id:"sk_467", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ktoré jedlo je najlepšie pre tvoje vlasy?", choices:["Cukrovinky","Ryby","Orechy"], correctChoiceIndex:2},
    {id:"sk_468", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ktoré jedlo je najlepšie pre tvoje oči?", choices:["Cukrovinky","Ryby","Mrkva"], correctChoiceIndex:2},
    {id:"sk_469", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ktoré jedlo je najlepšie pre tvoje kosti?", choices:["Cukrovinky","Ryby","Mlieko"], correctChoiceIndex:2},
    {id:"sk_470", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ktorý vitamín je najdôležitejší pre zdravé kosti?", choices:["Vitamín A","Vitamín D","Vitamín C"], correctChoiceIndex:1},
    {id:"sk_471", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ktorý vitamín pomáha pri hojení rán?", choices:["Vitamín A","Vitamín B","Vitamín C"], correctChoiceIndex:2},
    {id:"sk_472", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ktorý minerál je dôležitý pre zdravé zuby a kosti?", choices:["Železo","Vápnik","Zinok"], correctChoiceIndex:1},
    {id:"sk_473", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ktorý vitamín je dôležitý pre zdravé oči?", choices:["Vitamín A","Vitamín B","Vitamín C"], correctChoiceIndex:0},
    {id:"sk_474", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ktorý vitamín je dôležitý pre zdravú pokožku?", choices:["Vitamín A","Vitamín B","Vitamín C"], correctChoiceIndex:0},
    {id:"sk_475", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ktorý vitamín je dôležitý pre zdravé vlasy?", choices:["Vitamín A","Vitamín B","Vitamín C"], correctChoiceIndex:0},
    {id:"sk_476", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ktorý vitamín je dôležitý pre zdravé nechty?", choices:["Vitamín A","Vitamín B","Vitamín C"], correctChoiceIndex:0},
    {id:"sk_477", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ktorý vitamín je dôležitý pre zdravý imunitný systém?", choices:["Vitamín A","Vitamín B","Vitamín C"], correctChoiceIndex:2},
    {id:"sk_478", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ktorý vitamín je dôležitý pre zdravé srdce?", choices:["Vitamín A","Vitamín B","Vitamín C"], correctChoiceIndex:0},
    {id:"sk_479", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ktorý vitamín je dôležitý pre zdravý mozog?", choices:["Vitamín A","Vitamín B","Vitamín C"], correctChoiceIndex:0},
    {id:"sk_480", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Koľko je 1 euro plus 1 euro?", choices:["2 eurá","3 eurá","4 eurá"], correctChoiceIndex:0},
    {id:"sk_481", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ak máš 5 eur a kúpiš si hračku za 2 eurá, koľko eur ti ostane?", choices:["3 eurá","2 eurá","1 euro"], correctChoiceIndex:0},
    {id:"sk_482", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Koľko je 2 eurá plus 2 eurá?", choices:["4 eurá","5 eurá","6 eurá"], correctChoiceIndex:0},
    {id:"sk_483", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ak máš 10 eur a kúpiš si sladoled za 3 eurá, koľko eur ti ostane?", choices:["7 eur","6 eur","5 eur"], correctChoiceIndex:0},
    {id:"sk_484", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Koľko je 3 eurá plus 1 euro?", choices:["4 eurá","5 eurá","6 eurá"], correctChoiceIndex:0},
    {id:"sk_485", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ak máš 20 eur a kúpiš si knihu za 15 eur, koľko eur ti ostane?", choices:["5 eur","4 eur","3 eur"], correctChoiceIndex:0},
    {id:"sk_486", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Koľko je 10 eur plus 10 eur?", choices:["20 eur","30 eur","40 eur"], correctChoiceIndex:0},
    {id:"sk_487", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ak máš 50 eur a kúpiš si hračku za 20 eur, koľko eur ti ostane?", choices:["30 eur","20 eur","10 eur"], correctChoiceIndex:0},
    {id:"sk_488", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Koľko je 5 eur plus 5 eur?", choices:["10 eur","15 eur","20 eur"], correctChoiceIndex:0},
    {id:"sk_489", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ak máš 100 eur a kúpiš si bicykel za 70 eur, koľko eur ti ostane?", choices:["30 eur","20 eur","10 eur"], correctChoiceIndex:0},
    {id:"sk_490", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Koľko je 25 eur plus 25 eur?", choices:["50 eur","75 eur","100 eur"], correctChoiceIndex:0},
    {id:"sk_491", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ak máš 30 eur a kúpiš si topánky za 20 eur, koľko eur ti ostane?", choices:["10 eur","15 eur","20 eur"], correctChoiceIndex:0},
    {id:"sk_492", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Koľko je 15 eur plus 15 eur?", choices:["30 eur","45 eur","60 eur"], correctChoiceIndex:0},
    {id:"sk_493", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ak máš 40 eur a kúpiš si šaty za 25 eur, koľko eur ti ostane?", choices:["15 eur","20 eur","25 eur"], correctChoiceIndex:0},
    {id:"sk_494", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Koľko je 20 eur plus 20 eur?", choices:["40 eur","60 eur","80 eur"], correctChoiceIndex:0},
    {id:"sk_495", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ak máš 60 eur a kúpiš si hračku za 30 eur, koľko eur ti ostane?", choices:["30 eur","20 eur","10 eur"], correctChoiceIndex:0},
    {id:"sk_496", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Koľko je 30 eur plus 30 eur?", choices:["60 eur","90 eur","120 eur"], correctChoiceIndex:0},
    {id:"sk_497", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ak máš 70 eur a kúpiš si knihu za 40 eur, koľko eur ti ostane?", choices:["30 eur","20 eur","10 eur"], correctChoiceIndex:0},
    {id:"sk_498", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Koľko je 35 eur plus 35 eur?", choices:["70 eur","105 eur","140 eur"], correctChoiceIndex:0},
    {id:"sk_499", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ak máš 80 eur a kúpiš si bicykel za 50 eur, koľko eur ti ostane?", choices:["30 eur","20 eur","10 eur"], correctChoiceIndex:0},
    {id:"sk_500", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ako prechádzaš cez cestu?", choices:["Bežím cez cestu.","Prechádzam cez cestu bez pozretia sa.","Pozriem sa na obe strany a prejdem, keď je to bezpečné."], correctChoiceIndex:2},
    {id:"sk_501", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Čo by si mal robiť, keď jazdíš na bicykli?", choices:["Nosím prilbu.","Jazdím bez prilby.","Jazdím na bicykli s klobúkom."], correctChoiceIndex:0},
    {id:"sk_502", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Koho by si mal zavolať v prípade núdze?", choices:["Môjho kamaráta.","Políciu, hasičov alebo záchranku.","Môjho učiteľa."], correctChoiceIndex:1},
    {id:"sk_503", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Čo by si mal robiť, keď si doma sám?", choices:["Hrať sa s zapálenými sviečkami.","Otvoriť dvere neznámym ľuďom.","Nikdy neotvárať dvere neznámym ľuďom."], correctChoiceIndex:2},
    {id:"sk_504", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Čo by si mal robiť, keď vidíš požiar?", choices:["Hrať sa s ohňom.","Zavolať hasičov.","Skryť sa pod posteľ."], correctChoiceIndex:1},
    {id:"sk_505", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Kedy je bezpečné prejsť cez cestu?", choices:["Keď je zelená na semafore.","Keď je červená na semafore.","Keď je auto na ceste."], correctChoiceIndex:0},
    {id:"sk_506", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Čo by si mal robiť, keď si na plavárni?", choices:["Bežať okolo bazéna.","Skákať do bazéna bez dozoru.","Plávať len pod dozorom dospelých."], correctChoiceIndex:2},
    {id:"sk_507", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Koho by si mal zavolať, keď sa zraníš?", choices:["Môjho kamaráta.","Môjho učiteľa.","Záchranku."], correctChoiceIndex:2},
    {id:"sk_508", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Čo by si mal robiť, keď si v kuchyni?", choices:["Hrať sa s nožmi.","Používať kuchynské spotrebiče len pod dozorom dospelých.","Jesť koláčiky."], correctChoiceIndex:1},
    {id:"sk_509", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Čo by si mal robiť, keď vidíš zlodeja?", choices:["Pozvať ho na večeru.","Zavolať políciu.","Povedať mu, aby odišiel."], correctChoiceIndex:1},
    {id:"sk_510", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Kedy je bezpečné hrať sa vonku?", choices:["Keď je tma.","Keď je svetlo a sú dospelí v blízkosti.","Keď je búrka."], correctChoiceIndex:1},
    {id:"sk_511", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Čo by si mal robiť, keď si v aute?", choices:["Hrať sa s dverami.","Sedieť na zadnom sedadle a mať pripútaný bezpečnostný pás.","Stáť na sedadle."], correctChoiceIndex:1},
    {id:"sk_512", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Čo by si mal robiť, keď si na ihrisku?", choices:["Hrať sa sám.","Hrať sa len na bezpečných zariadeniach.","Hrať sa na strome."], correctChoiceIndex:1},
    {id:"sk_513", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Čo by si mal robiť, keď si v obchode?", choices:["Bežať po obchode.","Držať sa mamy alebo otca.","Hrať sa s hračkami v obchode."], correctChoiceIndex:1},
    {id:"sk_514", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Čo by si mal robiť, keď si na chodníku?", choices:["Bežať do cesty.","Chodiť len po chodníku.","Hrať sa na chodníku."], correctChoiceIndex:1},
    {id:"sk_515", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Čo by si mal robiť, keď si v škole?", choices:["Hrať sa s nožmi.","Držať sa pravidiel školy.","Bežať po chodbách."], correctChoiceIndex:1},
    {id:"sk_516", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Čo by si mal robiť, keď si v parku?", choices:["Hrať sa sám.","Hrať sa len na bezpečných zariadeniach.","Hrať sa na strome."], correctChoiceIndex:1},
    {id:"sk_517", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Čo by si mal robiť, keď si na pláži?", choices:["Plávať sám.","Plávať len pod dozorom dospelých.","Hrať sa s pieskom."], correctChoiceIndex:1},
    {id:"sk_518", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Čo by si mal robiť, keď si v lese?", choices:["Chodiť sám.","Chodiť len s dospelým.","Hrať sa s divými zvieratami."], correctChoiceIndex:1},
    {id:"sk_519", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Čo by si mal robiť, keď si v zoo?", choices:["Hrať sa so zvieratami.","Držať sa pravidiel zoo.","Kŕmiť zvieratá."], correctChoiceIndex:1},
    {id:"sk_520", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ktorý deň nasleduje po pondelku?", choices:["utorok","streda","štvrtok"], correctChoiceIndex:0},
    {id:"sk_521", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ktorý deň je pred piatkom?", choices:["streda","štvrtok","utorok"], correctChoiceIndex:1},
    {id:"sk_522", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ktorý mesiac nasleduje po januári?", choices:["február","marec","apríl"], correctChoiceIndex:0},
    {id:"sk_523", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ktorý mesiac je pred decembrom?", choices:["november","október","september"], correctChoiceIndex:0},
    {id:"sk_524", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Koľko dní má týždeň?", choices:["5","6","7"], correctChoiceIndex:2},
    {id:"sk_525", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ktorý deň nasleduje po strede?", choices:["štvrtok","piatok","sobota"], correctChoiceIndex:0},
    {id:"sk_526", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ktorý mesiac nasleduje po apríli?", choices:["máj","jún","júl"], correctChoiceIndex:0},
    {id:"sk_527", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Ktorý mesiac je pred májom?", choices:["apríl","márc","jún"], correctChoiceIndex:0},
    {id:"sk_528", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Koľko sekúnd má minúta?", choices:["50","55","60"], correctChoiceIndex:2},
    {id:"sk_529", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ktorý deň nasleduje po sobote?", choices:["nedeľa","pondelok","utorok"], correctChoiceIndex:0},
    {id:"sk_530", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ktorý mesiac je pred júlom?", choices:["jún","august","september"], correctChoiceIndex:0},
    {id:"sk_531", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Koľko mesiacov má rok?", choices:["10","11","12"], correctChoiceIndex:2},
    {id:"sk_532", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Koľko hodín má deň?", choices:["22","23","24"], correctChoiceIndex:2},
    {id:"sk_533", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Koľko minút má hodina?", choices:["50","55","60"], correctChoiceIndex:2},
    {id:"sk_534", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ktorý deň je pred nedelou?", choices:["sobota","piatok","štvrtok"], correctChoiceIndex:0},
    {id:"sk_535", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Ktorý mesiac je pred októbrom?", choices:["september","august","júl"], correctChoiceIndex:0},
    {id:"sk_536", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Koľko dní má február v bežnom roku?", choices:["28","29","30"], correctChoiceIndex:0},
    {id:"sk_537", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Koľko dní má apríl?", choices:["30","31","32"], correctChoiceIndex:0},
    {id:"sk_538", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Koľko hodín má pol dňa?", choices:["10","11","12"], correctChoiceIndex:2},
    {id:"sk_539", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Koľko minút má pol hodiny?", choices:["25","30","35"], correctChoiceIndex:1},
    {id:"sk_540", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na rezanie dreva?", choices:["Nožnice","Píla","Vrták"], correctChoiceIndex:1},
    {id:"sk_541", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na skrutkovanie?", choices:["Kliešte","Skrutkovač","Píla"], correctChoiceIndex:1},
    {id:"sk_542", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie dĺžky?", choices:["Metre","Kliešte","Vrták"], correctChoiceIndex:0},
    {id:"sk_543", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na kreslenie?", choices:["Pero","Píla","Vrták"], correctChoiceIndex:0},
    {id:"sk_544", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na maľovanie?", choices:["Štetec","Nožnice","Vrták"], correctChoiceIndex:0},
    {id:"sk_545", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na lepenie?", choices:["Lepidlo","Píla","Vrták"], correctChoiceIndex:0},
    {id:"sk_546", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na čistenie zubov?", choices:["Zubná kefka","Nožnice","Vrták"], correctChoiceIndex:0},
    {id:"sk_547", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na čistenie podlahy?", choices:["Mop","Píla","Vrták"], correctChoiceIndex:0},
    {id:"sk_548", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na strihanie vlasov?", choices:["Nožnice","Píla","Vrták"], correctChoiceIndex:0},
    {id:"sk_549", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na pečenie?", choices:["Rúra","Nožnice","Vrták"], correctChoiceIndex:0},
    {id:"sk_550", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na vŕtanie dier?", choices:["Vrták","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_551", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie teploty?", choices:["Teplomer","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_552", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie času?", choices:["Hodinky","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_553", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie hmotnosti?", choices:["Váha","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_554", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie vzdialenosti?", choices:["Odometer","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_555", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie rýchlosti?", choices:["Rýchlostomer","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_556", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie tlaku?", choices:["Manometer","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_557", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie výšky?", choices:["Výškomer","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_558", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie hĺbky?", choices:["Hĺbkomer","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_559", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie objemu?", choices:["Objemomer","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_560", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie prúdu?", choices:["Ampermeter","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_561", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie napätia?", choices:["Voltmeter","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_562", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie odporu?", choices:["Ohmmeter","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_563", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie svetla?", choices:["Luxmeter","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_564", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie zvuku?", choices:["Decibelmeter","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_565", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie vlhkosti?", choices:["Hygrometer","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_566", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie sily?", choices:["Dynamometer","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_567", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie rýchlosti vetra?", choices:["Anemometer","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_568", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie smeru vetra?", choices:["Vetrná ružica","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_569", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.everyDayLife, question:"Aký nástroj používame na meranie zrážok?", choices:["Pluviometer","Nožnice","Píla"], correctChoiceIndex:0},
    {id:"sk_570", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Aká je základná farba?", choices:["Červená","Fialová","Hnedá"], correctChoiceIndex:0},
    {id:"sk_571", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Aká je základná farba?", choices:["Modrá","Ružová","Sivá"], correctChoiceIndex:0},
    {id:"sk_572", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Aká je základná farba?", choices:["Žltá","Oranžová","Čierna"], correctChoiceIndex:0},
    {id:"sk_573", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Čo je to maľba?", choices:["Kreslenie farbami","Písanie perom","Hranie na hudobný nástroj"], correctChoiceIndex:0},
    {id:"sk_574", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Čo je to socha?", choices:["Tvarovaný predmet","Písaný príbeh","Hudobná skladba"], correctChoiceIndex:0},
    {id:"sk_575", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aká je základná farba?", choices:["Zelená","Ružová","Hnedá"], correctChoiceIndex:0},
    {id:"sk_576", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aká je základná farba?", choices:["Čierna","Oranžová","Sivá"], correctChoiceIndex:0},
    {id:"sk_577", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Čo je to maľba?", choices:["Obraz vytvorený farbami","Písanie perom","Hranie na hudobný nástroj"], correctChoiceIndex:0},
    {id:"sk_578", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Čo je to socha?", choices:["Predmet vytvorený tvarovaním materiálu","Písaný príbeh","Hudobná skladba"], correctChoiceIndex:0},
    {id:"sk_579", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký typ umenia je maľba?", choices:["Výtvarné umenie","Literatúra","Hudba"], correctChoiceIndex:0},
    {id:"sk_580", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký typ umenia je socha?", choices:["Výtvarné umenie","Literatúra","Hudba"], correctChoiceIndex:0},
    {id:"sk_581", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aká je základná farba?", choices:["Biela","Ružová","Hnedá"], correctChoiceIndex:0},
    {id:"sk_582", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aká je základná farba?", choices:["Červená","Oranžová","Sivá"], correctChoiceIndex:0},
    {id:"sk_583", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Čo je to maľba?", choices:["Obraz vytvorený farbami","Písanie perom","Hranie na hudobný nástroj"], correctChoiceIndex:0},
    {id:"sk_584", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Čo je to socha?", choices:["Predmet vytvorený tvarovaním materiálu","Písaný príbeh","Hudobná skladba"], correctChoiceIndex:0},
    {id:"sk_585", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký typ umenia je maľba?", choices:["Výtvarné umenie","Literatúra","Hudba"], correctChoiceIndex:0},
    {id:"sk_586", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký typ umenia je socha?", choices:["Výtvarné umenie","Literatúra","Hudba"], correctChoiceIndex:0},
    {id:"sk_587", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aká je základná farba?", choices:["Modrá","Ružová","Hnedá"], correctChoiceIndex:0},
    {id:"sk_588", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aká je základná farba?", choices:["Žltá","Oranžová","Sivá"], correctChoiceIndex:0},
    {id:"sk_589", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Čo je to maľba?", choices:["Obraz vytvorený farbami","Písanie perom","Hranie na hudobný nástroj"], correctChoiceIndex:0},
    {id:"sk_590", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Aký druh tanečnej formy je balet?", choices:["Hip Hop","Salsa","Balet"], correctChoiceIndex:2},
    {id:"sk_591", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Ktorý tanečný štýl je populárny v Latinskej Amerike?", choices:["Salsa","Breakdance","Belly Dance"], correctChoiceIndex:0},
    {id:"sk_592", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Čo je to tap dance?", choices:["Tanečný štýl s použitím topánok s kovovými podrážkami","Tanečný štýl s použitím rúk","Tanečný štýl s použitím šálu"], correctChoiceIndex:0},
    {id:"sk_593", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Aký druh tanečnej formy je Flamenco?", choices:["Tanečný štýl z Indie","Tanečný štýl z Španielska","Tanečný štýl z Ruska"], correctChoiceIndex:1},
    {id:"sk_594", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Ktorý tanečný štýl je populárny v Indii?", choices:["Bharatanatyam","Samba","Jazz"], correctChoiceIndex:0},
    {id:"sk_595", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý tanečný štýl je známy svojou rýchlosťou a energiou?", choices:["Waltz","Quickstep","Tango"], correctChoiceIndex:1},
    {id:"sk_596", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý tanečný štýl je známy svojou pomalosťou a gráciou?", choices:["Waltz","Hip Hop","Salsa"], correctChoiceIndex:0},
    {id:"sk_597", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý tanečný štýl je známy svojou vášnivou a dramatickou povahou?", choices:["Belly Dance","Tango","Bharatanatyam"], correctChoiceIndex:1},
    {id:"sk_598", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý tanečný štýl je známy svojou vysokou energiou a akrobatickými pohybmi?", choices:["Ballet","Breakdance","Waltz"], correctChoiceIndex:1},
    {id:"sk_599", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý tanečný štýl je známy svojou gráciou a eleganciou?", choices:["Hip Hop","Ballet","Salsa"], correctChoiceIndex:1},
    {id:"sk_600", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý tanečný štýl je známy svojou rýchlosťou a presnosťou?", choices:["Quickstep","Belly Dance","Hip Hop"], correctChoiceIndex:0},
    {id:"sk_601", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý tanečný štýl je známy svojou pomalosťou a romantickou povahou?", choices:["Waltz","Breakdance","Bharatanatyam"], correctChoiceIndex:0},
    {id:"sk_602", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý tanečný štýl je známy svojou vysokou energiou a rýchlymi pohybmi?", choices:["Hip Hop","Ballet","Salsa"], correctChoiceIndex:0},
    {id:"sk_603", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý tanečný štýl je známy svojou gráciou a precíznosťou?", choices:["Ballet","Hip Hop","Salsa"], correctChoiceIndex:0},
    {id:"sk_604", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý tanečný štýl je známy svojou vášnivou a dramatickou povahou?", choices:["Belly Dance","Tango","Bharatanatyam"], correctChoiceIndex:1},
    {id:"sk_605", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý tanečný štýl je známy svojou vysokou energiou a akrobatickými pohybmi?", choices:["Ballet","Breakdance","Waltz"], correctChoiceIndex:1},
    {id:"sk_606", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý tanečný štýl je známy svojou gráciou a eleganciou?", choices:["Hip Hop","Ballet","Salsa"], correctChoiceIndex:1},
    {id:"sk_607", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý tanečný štýl je známy svojou rýchlosťou a presnosťou?", choices:["Quickstep","Belly Dance","Hip Hop"], correctChoiceIndex:0},
    {id:"sk_608", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý tanečný štýl je známy svojou pomalosťou a romantickou povahou?", choices:["Waltz","Breakdance","Bharatanatyam"], correctChoiceIndex:0},
    {id:"sk_609", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý tanečný štýl je známy svojou vysokou energiou a rýchlymi pohybmi?", choices:["Hip Hop","Ballet","Salsa"], correctChoiceIndex:0},
    {id:"sk_610", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Aký je začiatok príbehu?", choices:["Keď sa postavy stretnú","Keď sa postavy rozlúčia","Keď sa postavy smejú"], correctChoiceIndex:0},
    {id:"sk_611", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Čo je koniec príbehu?", choices:["Keď sa postavy stretnú","Keď sa postavy rozlúčia","Keď sa postavy smejú"], correctChoiceIndex:1},
    {id:"sk_612", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Čo je v strede príbehu?", choices:["Keď sa postavy stretnú","Keď sa postavy rozlúčia","Keď sa postavy smejú"], correctChoiceIndex:2},
    {id:"sk_613", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Kto napísal rozprávku O červenej čiapočke?", choices:["Hans Christian Andersen","Charles Perrault","Brothers Grimm"], correctChoiceIndex:1},
    {id:"sk_614", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Kto napísal rozprávku O troch prasiatkach?", choices:["Hans Christian Andersen","Charles Perrault","Brothers Grimm"], correctChoiceIndex:2},
    {id:"sk_615", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Kto je hlavná postava v rozprávke O červenej čiapočke?", choices:["Červená čiapočka","Vlk","Babička"], correctChoiceIndex:0},
    {id:"sk_616", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Kto je hlavná postava v rozprávke O troch prasiatkach?", choices:["Prasiatko","Vlk","Babička"], correctChoiceIndex:0},
    {id:"sk_617", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Kto je zlý vo rozprávke O červenej čiapočke?", choices:["Červená čiapočka","Vlk","Babička"], correctChoiceIndex:1},
    {id:"sk_618", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Kto je zlý vo rozprávke O troch prasiatkach?", choices:["Prasiatko","Vlk","Babička"], correctChoiceIndex:1},
    {id:"sk_619", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Kto napísal rozprávku O maličkej morskej víle?", choices:["Hans Christian Andersen","Charles Perrault","Brothers Grimm"], correctChoiceIndex:0},
    {id:"sk_620", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Kto napísal rozprávku O spiaci kráľovnej?", choices:["Hans Christian Andersen","Charles Perrault","Brothers Grimm"], correctChoiceIndex:1},
    {id:"sk_621", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Kto napísal rozprávku O snehovej kráľovnej?", choices:["Hans Christian Andersen","Charles Perrault","Brothers Grimm"], correctChoiceIndex:0},
    {id:"sk_622", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Kto napísal rozprávku O Popoluške?", choices:["Hans Christian Andersen","Charles Perrault","Brothers Grimm"], correctChoiceIndex:1},
    {id:"sk_623", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Kto napísal rozprávku O Jankovi a Marienke?", choices:["Hans Christian Andersen","Charles Perrault","Brothers Grimm"], correctChoiceIndex:2},
    {id:"sk_624", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Kto je hlavná postava v rozprávke O maličkej morskej víle?", choices:["Maličká morská víla","Král Triton","Ursula"], correctChoiceIndex:0},
    {id:"sk_625", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Kto je hlavná postava v rozprávke O spiaci kráľovnej?", choices:["Spiaca kráľovná","Krásna kráľovná","Zlá kráľovná"], correctChoiceIndex:0},
    {id:"sk_626", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Kto je hlavná postava v rozprávke O snehovej kráľovnej?", choices:["Snehová kráľovná","Gerda","Kai"], correctChoiceIndex:0},
    {id:"sk_627", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Kto je hlavná postava v rozprávke O Popoluške?", choices:["Popoluška","Krásna kráľovná","Zlá kráľovná"], correctChoiceIndex:0},
    {id:"sk_628", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Kto je hlavná postava v rozprávke O Jankovi a Marienke?", choices:["Janko","Marie","Čarodejnica"], correctChoiceIndex:0},
    {id:"sk_629", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Kto je zlý vo rozprávke O maličkej morskej víle?", choices:["Maličká morská víla","Král Triton","Ursula"], correctChoiceIndex:2},
    {id:"sk_630", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Aký nástroj má klávesy a struny?", choices:["Piano","Trombón","Bicie"], correctChoiceIndex:0},
    {id:"sk_631", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Aký nástroj hráte tým, že ho potrebujete?", choices:["Bicie","Flauta","Gitara"], correctChoiceIndex:0},
    {id:"sk_632", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Aký nástroj má šesť strún?", choices:["Violin","Gitara","Flauta"], correctChoiceIndex:1},
    {id:"sk_633", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Aký nástroj hráte tým, že doňho dýchate?", choices:["Trombón","Flauta","Piano"], correctChoiceIndex:1},
    {id:"sk_634", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Aký nástroj hráte tým, že ho potrebujete a má veľa rôznych zvukov?", choices:["Bicie","Piano","Flauta"], correctChoiceIndex:0},
    {id:"sk_635", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký nástroj má čierne a biele klávesy?", choices:["Piano","Trombón","Bicie"], correctChoiceIndex:0},
    {id:"sk_636", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký nástroj hráte tým, že ho potrebujete a má veľa rôznych zvukov?", choices:["Bicie","Piano","Flauta"], correctChoiceIndex:0},
    {id:"sk_637", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký nástroj má štyri struny a hrá sa na ňom smyčcom?", choices:["Violin","Gitara","Flauta"], correctChoiceIndex:0},
    {id:"sk_638", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký nástroj hráte tým, že doňho dýchate a má veľa kláves?", choices:["Saxofón","Flauta","Piano"], correctChoiceIndex:0},
    {id:"sk_639", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký nástroj hráte tým, že ho potrebujete a má veľa rôznych zvukov?", choices:["Bicie","Piano","Flauta"], correctChoiceIndex:0},
    {id:"sk_640", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký nástroj má čierne a biele klávesy?", choices:["Piano","Trombón","Bicie"], correctChoiceIndex:0},
    {id:"sk_641", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký nástroj hráte tým, že ho potrebujete a má veľa rôznych zvukov?", choices:["Bicie","Piano","Flauta"], correctChoiceIndex:0},
    {id:"sk_642", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký nástroj má štyri struny a hrá sa na ňom smyčcom?", choices:["Violin","Gitara","Flauta"], correctChoiceIndex:0},
    {id:"sk_643", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký nástroj hráte tým, že doňho dýchate a má veľa kláves?", choices:["Saxofón","Flauta","Piano"], correctChoiceIndex:0},
    {id:"sk_644", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký nástroj hráte tým, že ho potrebujete a má veľa rôznych zvukov?", choices:["Bicie","Piano","Flauta"], correctChoiceIndex:0},
    {id:"sk_645", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký nástroj má čierne a biele klávesy?", choices:["Piano","Trombón","Bicie"], correctChoiceIndex:0},
    {id:"sk_646", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký nástroj hráte tým, že ho potrebujete a má veľa rôznych zvukov?", choices:["Bicie","Piano","Flauta"], correctChoiceIndex:0},
    {id:"sk_647", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký nástroj má štyri struny a hrá sa na ňom smyčcom?", choices:["Violin","Gitara","Flauta"], correctChoiceIndex:0},
    {id:"sk_648", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký nástroj hráte tým, že doňho dýchate a má veľa kláves?", choices:["Saxofón","Flauta","Piano"], correctChoiceIndex:0},
    {id:"sk_649", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Aký nástroj hráte tým, že ho potrebujete a má veľa rôznych zvukov?", choices:["Bicie","Piano","Flauta"], correctChoiceIndex:0},
    {id:"sk_650", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Aký jazyk hovoria v Španielsku?", choices:["Angličtina","Španielčina","Francúzština"], correctChoiceIndex:1},
    {id:"sk_651", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Aké je hlavné mesto Francúzska?", choices:["Londýn","Paríž","Berlín"], correctChoiceIndex:1},
    {id:"sk_652", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Aký je tradičný tanec v Írsku?", choices:["Flamenco","Tango","Riverdance"], correctChoiceIndex:2},
    {id:"sk_653", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Aký jazyk hovoria v Číne?", choices:["Čínština","Angličtina","Španielčina"], correctChoiceIndex:0},
    {id:"sk_654", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.artAndCulture, question:"Aký je národný šport v Kanade?", choices:["Futbal","Hokej","Basketbal"], correctChoiceIndex:1},
    {id:"sk_655", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý festival sa slávi v Indii, kde ľudia hádžu farebný prášok?", choices:["Holi","Diwali","Navaratri"], correctChoiceIndex:0},
    {id:"sk_656", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý jazyk hovoria v Brazílii?", choices:["Španielčina","Portugalčina","Angličtina"], correctChoiceIndex:1},
    {id:"sk_657", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorá krajina je známa svojou operou Sydney?", choices:["Austrália","Anglicko","USA"], correctChoiceIndex:0},
    {id:"sk_658", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý festival sa slávi v Mexiku ako Deň mŕtvych?", choices:["Cinco de Mayo","Día de los Muertos","Navidad"], correctChoiceIndex:1},
    {id:"sk_659", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý jazyk hovoria v Japonsku?", choices:["Kórejčina","Japončina","Čínština"], correctChoiceIndex:1},
    {id:"sk_660", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý festival sa slávi v USA ako Deň vďakyvzdania?", choices:["Halloween","Deň vďakyvzdania","Vianoce"], correctChoiceIndex:1},
    {id:"sk_661", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý jazyk hovoria v Rusku?", choices:["Rusky","Anglicky","Nemecky"], correctChoiceIndex:0},
    {id:"sk_662", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý festival sa slávi v Číne ako Nový rok?", choices:["Vianoce","Veľká noc","Čínsky Nový rok"], correctChoiceIndex:2},
    {id:"sk_663", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý jazyk hovoria v Nemecku?", choices:["Nemecky","Anglicky","Francúzsky"], correctChoiceIndex:0},
    {id:"sk_664", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý festival sa slávi v Brazílii ako karneval?", choices:["Karneval","Vianoce","Veľká noc"], correctChoiceIndex:0},
    {id:"sk_665", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý jazyk hovoria v Taliansku?", choices:["Taliansky","Anglicky","Španielsky"], correctChoiceIndex:0},
    {id:"sk_666", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý festival sa slávi v Španielsku ako La Tomatina?", choices:["La Tomatina","Vianoce","Veľká noc"], correctChoiceIndex:0},
    {id:"sk_667", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý jazyk hovoria v Grécku?", choices:["Grécky","Anglicky","Taliansky"], correctChoiceIndex:0},
    {id:"sk_668", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý festival sa slávi v Nemecku ako Oktoberfest?", choices:["Oktoberfest","Vianoce","Veľká noc"], correctChoiceIndex:0},
    {id:"sk_669", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.artAndCulture, question:"Ktorý jazyk hovoria v Anglicku?", choices:["Anglicky","Nemecky","Francúzsky"], correctChoiceIndex:0},
    {id:"sk_670", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ktorý objekt je dlhší, auto alebo vlak?", choices:["Auto","Vlak","Oba sú rovnako dlhé"], correctChoiceIndex:1},
    {id:"sk_671", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ktorý objekt je kratší, ceruzka alebo metla?", choices:["Ceruzka","Metla","Oba sú rovnako dlhé"], correctChoiceIndex:0},
    {id:"sk_672", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ktorý objekt je ťažší, jablko alebo melón?", choices:["Jablko","Melón","Oba sú rovnako ťažké"], correctChoiceIndex:1},
    {id:"sk_673", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ktorý objekt je ľahší, pero alebo kniha?", choices:["Pero","Kniha","Oba sú rovnako ľahké"], correctChoiceIndex:0},
    {id:"sk_674", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ktorý objekt má väčší objem, šálka alebo hrnček?", choices:["Šálka","Hrnček","Oba majú rovnaký objem"], correctChoiceIndex:1},
    {id:"sk_675", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ktorý objekt má menší objem, fľaša alebo vedro?", choices:["Fľaša","Vedro","Oba majú rovnaký objem"], correctChoiceIndex:0},
    {id:"sk_676", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ktorý objekt je dlhší, stôl alebo posteľ?", choices:["Stôl","Posteľ","Oba sú rovnako dlhé"], correctChoiceIndex:1},
    {id:"sk_677", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ktorý objekt je kratší, hračka alebo stolička?", choices:["Hračka","Stolička","Oba sú rovnako dlhé"], correctChoiceIndex:0},
    {id:"sk_678", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ktorý objekt je ťažší, kameň alebo perie?", choices:["Kameň","Perie","Oba sú rovnako ťažké"], correctChoiceIndex:1},
    {id:"sk_679", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ktorý objekt je ľahší, list papiera alebo notebook?", choices:["List papiera","Notebook","Oba sú rovnako ľahké"], correctChoiceIndex:0},
    {id:"sk_680", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt má väčší objem, balón alebo lopta?", choices:["Balón","Lopta","Oba majú rovnaký objem"], correctChoiceIndex:1},
    {id:"sk_681", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt má menší objem, pohár alebo fľaša?", choices:["Pohár","Fľaša","Oba majú rovnaký objem"], correctChoiceIndex:0},
    {id:"sk_682", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je dlhší, rieka alebo potok?", choices:["Rieka","Potok","Oba sú rovnako dlhé"], correctChoiceIndex:1},
    {id:"sk_683", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je kratší, most alebo tunel?", choices:["Most","Tunel","Oba sú rovnako dlhé"], correctChoiceIndex:0},
    {id:"sk_684", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je ťažší, slon alebo myš?", choices:["Slon","Myš","Oba sú rovnako ťažké"], correctChoiceIndex:1},
    {id:"sk_685", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je ľahší, vankúš alebo matrac?", choices:["Vankúš","Matrac","Oba sú rovnako ľahké"], correctChoiceIndex:0},
    {id:"sk_686", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt má väčší objem, bazén alebo vana?", choices:["Bazén","Vana","Oba majú rovnaký objem"], correctChoiceIndex:1},
    {id:"sk_687", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt má menší objem, kanvica alebo hrnček?", choices:["Kanvica","Hrnček","Oba majú rovnaký objem"], correctChoiceIndex:0},
    {id:"sk_688", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je dlhší, autobus alebo bicykel?", choices:["Autobus","Bicykel","Oba sú rovnako dlhé"], correctChoiceIndex:1},
    {id:"sk_689", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je kratší, kľúč alebo meter?", choices:["Kľúč","Meter","Oba sú rovnako dlhé"], correctChoiceIndex:0},
    {id:"sk_690", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je ťažší, auto alebo motorka?", choices:["Auto","Motorka","Oba sú rovnako ťažké"], correctChoiceIndex:1},
    {id:"sk_691", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je ľahší, telefón alebo počítač?", choices:["Telefón","Počítač","Oba sú rovnako ľahké"], correctChoiceIndex:0},
    {id:"sk_692", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt má väčší objem, dom alebo byt?", choices:["Dom","Byt","Oba majú rovnaký objem"], correctChoiceIndex:1},
    {id:"sk_693", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt má menší objem, skrinka alebo šatník?", choices:["Skrinka","Šatník","Oba majú rovnaký objem"], correctChoiceIndex:0},
    {id:"sk_694", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je dlhší, cesta alebo chodník?", choices:["Cesta","Chodník","Oba sú rovnako dlhé"], correctChoiceIndex:1},
    {id:"sk_695", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je kratší, reťaz alebo lano?", choices:["Reťaz","Lano","Oba sú rovnako dlhé"], correctChoiceIndex:0},
    {id:"sk_696", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je ťažší, zem alebo mesiac?", choices:["Zem","Mesiac","Oba sú rovnako ťažké"], correctChoiceIndex:1},
    {id:"sk_697", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je ľahší, list alebo vetvička?", choices:["List","Vetvička","Oba sú rovnako ľahké"], correctChoiceIndex:0},
    {id:"sk_698", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt má väčší objem, oceán alebo jazero?", choices:["Oceán","Jazero","Oba majú rovnaký objem"], correctChoiceIndex:1},
    {id:"sk_699", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt má menší objem, fľaša alebo pohár?", choices:["Fľaša","Pohár","Oba majú rovnaký objem"], correctChoiceIndex:0},
    {id:"sk_700", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je dlhší, vlak alebo autobus?", choices:["Vlak","Autobus","Oba sú rovnako dlhé"], correctChoiceIndex:1},
    {id:"sk_701", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je kratší, pero alebo ceruzka?", choices:["Pero","Ceruzka","Oba sú rovnako dlhé"], correctChoiceIndex:0},
    {id:"sk_702", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je ťažší, kov alebo drevo?", choices:["Kov","Drevo","Oba sú rovnako ťažké"], correctChoiceIndex:1},
    {id:"sk_703", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je ľahší, papier alebo plast?", choices:["Papier","Plast","Oba sú rovnako ľahké"], correctChoiceIndex:0},
    {id:"sk_704", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt má väčší objem, hrad alebo dom?", choices:["Hrad","Dom","Oba majú rovnaký objem"], correctChoiceIndex:1},
    {id:"sk_705", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt má menší objem, sklenička alebo fľaša?", choices:["Sklenička","Fľaša","Oba majú rovnaký objem"], correctChoiceIndex:0},
    {id:"sk_706", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je dlhší, reťaz alebo lano?", choices:["Reťaz","Lano","Oba sú rovnako dlhé"], correctChoiceIndex:1},
    {id:"sk_707", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je kratší, kľúč alebo meter?", choices:["Kľúč","Meter","Oba sú rovnako dlhé"], correctChoiceIndex:0},
    {id:"sk_708", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je ťažší, slon alebo myš?", choices:["Slon","Myš","Oba sú rovnako ťažké"], correctChoiceIndex:1},
    {id:"sk_709", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý objekt je ľahší, vankúš alebo matrac?", choices:["Vankúš","Matrac","Oba sú rovnako ľahké"], correctChoiceIndex:0},
    {id:"sk_710", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko centimetrov je v metre?", choices:["100","10","1000"], correctChoiceIndex:0},
    {id:"sk_711", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko gramov je v kilograme?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"sk_712", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko litrov je v galóne?", choices:["3.785","4.546","5"], correctChoiceIndex:0},
    {id:"sk_713", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko milimetrov je v centimetre?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"sk_714", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko metrov je v kilometri?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"sk_715", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko decimetrov je v centimetre?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"sk_716", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko milimetrov je v decimetre?", choices:["100","10","1000"], correctChoiceIndex:0},
    {id:"sk_717", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko metrov je v decimetre?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"sk_718", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko litrov je v metre kubickom?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"sk_719", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko mililitrov je v centimetre kubickom?", choices:["1","10","100"], correctChoiceIndex:0},
    {id:"sk_720", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko gramov je v mililitri?", choices:["1","10","100"], correctChoiceIndex:0},
    {id:"sk_721", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko mililitrov je v decimetre kubickom?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"sk_722", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko gramov je v decimetre kubickom?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"sk_723", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko litrov je v centimetre kubickom?", choices:["0.001","0.01","0.1"], correctChoiceIndex:0},
    {id:"sk_724", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko mililitrov je v milimetre kubickom?", choices:["1","10","100"], correctChoiceIndex:0},
    {id:"sk_725", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko gramov je v milimetre kubickom?", choices:["1","10","100"], correctChoiceIndex:0},
    {id:"sk_726", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko litrov je v milimetre kubickom?", choices:["0.000001","0.00001","0.0001"], correctChoiceIndex:0},
    {id:"sk_727", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko mililitrov je v mikrometre kubickom?", choices:["0.000001","0.00001","0.0001"], correctChoiceIndex:0},
    {id:"sk_728", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko gramov je v mikrometre kubickom?", choices:["0.000001","0.00001","0.0001"], correctChoiceIndex:0},
    {id:"sk_729", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko litrov je v mikrometre kubickom?", choices:["0.000000001","0.00000001","0.0000001"], correctChoiceIndex:0},
    {id:"sk_730", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko mililitrov je v nanometre kubickom?", choices:["0.000000000001","0.00000000001","0.0000000001"], correctChoiceIndex:0},
    {id:"sk_731", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko gramov je v nanometre kubickom?", choices:["0.000000000001","0.00000000001","0.0000000001"], correctChoiceIndex:0},
    {id:"sk_732", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko decimetrov je v metre?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"sk_733", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko centimetrov je v decimetre?", choices:["10","100","1000"], correctChoiceIndex:0},
    {id:"sk_734", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko mililitrov je v litre?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"sk_735", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko gramov je v libra?", choices:["453.592","500","400"], correctChoiceIndex:0},
    {id:"sk_736", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko kilogramov je v tone?", choices:["1000","100","10"], correctChoiceIndex:0},
    {id:"sk_737", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko decimetrov je v kilometri?", choices:["10000","1000","100"], correctChoiceIndex:0},
    {id:"sk_738", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko centimetrov je v kilometri?", choices:["100000","10000","1000"], correctChoiceIndex:0},
    {id:"sk_739", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko milimetrov je v kilometri?", choices:["1000000","100000","10000"], correctChoiceIndex:0},
    {id:"sk_740", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko kilogramov je v litre?", choices:["1","10","100"], correctChoiceIndex:0},
    {id:"sk_741", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko ton je v metre kubickom?", choices:["1","10","100"], correctChoiceIndex:0},
    {id:"sk_742", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko litrov je v decimetre kubickom?", choices:["1","10","100"], correctChoiceIndex:0},
    {id:"sk_743", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko kilogramov je v decimetre kubickom?", choices:["1","10","100"], correctChoiceIndex:0},
    {id:"sk_744", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko ton je v decimetre kubickom?", choices:["0.001","0.01","0.1"], correctChoiceIndex:0},
    {id:"sk_745", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko kilogramov je v milimetre kubickom?", choices:["0.001","0.01","0.1"], correctChoiceIndex:0},
    {id:"sk_746", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko ton je v milimetre kubickom?", choices:["0.000001","0.00001","0.0001"], correctChoiceIndex:0},
    {id:"sk_747", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko kilogramov je v mikrometre kubickom?", choices:["0.000000001","0.00000001","0.0000001"], correctChoiceIndex:0},
    {id:"sk_748", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko ton je v mikrometre kubickom?", choices:["0.000000000001","0.00000000001","0.0000000001"], correctChoiceIndex:0},
    {id:"sk_749", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko kilogramov je v nanometre kubickom?", choices:["0.000000000000001","0.00000000000001","0.0000000000001"], correctChoiceIndex:0},
    {id:"sk_750", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 1 + 1?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"sk_751", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 2 + 2?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"sk_752", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 3 + 3?", choices:["6","7","8"], correctChoiceIndex:0},
    {id:"sk_753", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 4 + 4?", choices:["8","9","10"], correctChoiceIndex:0},
    {id:"sk_754", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 5 - 2?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"sk_755", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 6 - 3?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"sk_756", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 7 - 4?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"sk_757", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 8 - 5?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"sk_758", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 9 - 6?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"sk_759", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 10 - 7?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"sk_760", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 1 + 2?", choices:["3","4","5"], correctChoiceIndex:0},
    {id:"sk_761", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 2 + 3?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"sk_762", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 3 + 4?", choices:["7","8","9"], correctChoiceIndex:0},
    {id:"sk_763", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 4 + 5?", choices:["9","10","11"], correctChoiceIndex:0},
    {id:"sk_764", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 5 - 1?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"sk_765", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 6 - 2?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"sk_766", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 7 - 3?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"sk_767", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 8 - 4?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"sk_768", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 9 - 5?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"sk_769", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 10 - 6?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"sk_770", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 1 + 3?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"sk_771", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 2 + 4?", choices:["6","7","8"], correctChoiceIndex:0},
    {id:"sk_772", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 3 + 5?", choices:["8","9","10"], correctChoiceIndex:0},
    {id:"sk_773", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 4 + 6?", choices:["10","11","12"], correctChoiceIndex:0},
    {id:"sk_774", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 5 - 3?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"sk_775", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 6 - 4?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"sk_776", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 7 - 5?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"sk_777", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 8 - 6?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"sk_778", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 9 - 7?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"sk_779", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 10 - 8?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"sk_780", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 11 + 11?", choices:["22","23","24"], correctChoiceIndex:0},
    {id:"sk_781", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 12 + 12?", choices:["24","25","26"], correctChoiceIndex:0},
    {id:"sk_782", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 13 + 13?", choices:["26","27","28"], correctChoiceIndex:0},
    {id:"sk_783", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 14 + 14?", choices:["28","29","30"], correctChoiceIndex:0},
    {id:"sk_784", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 15 - 7?", choices:["8","9","10"], correctChoiceIndex:0},
    {id:"sk_785", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 16 - 8?", choices:["8","9","10"], correctChoiceIndex:0},
    {id:"sk_786", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 17 - 9?", choices:["8","9","10"], correctChoiceIndex:0},
    {id:"sk_787", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 18 - 10?", choices:["8","9","10"], correctChoiceIndex:0},
    {id:"sk_788", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 19 - 11?", choices:["8","9","10"], correctChoiceIndex:0},
    {id:"sk_789", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 20 - 12?", choices:["8","9","10"], correctChoiceIndex:0},
    {id:"sk_790", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 11 + 12?", choices:["23","24","25"], correctChoiceIndex:0},
    {id:"sk_791", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 12 + 13?", choices:["25","26","27"], correctChoiceIndex:0},
    {id:"sk_792", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 13 + 14?", choices:["27","28","29"], correctChoiceIndex:0},
    {id:"sk_793", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 14 + 15?", choices:["29","30","31"], correctChoiceIndex:0},
    {id:"sk_794", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 15 - 6?", choices:["9","10","11"], correctChoiceIndex:0},
    {id:"sk_795", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 16 - 7?", choices:["9","10","11"], correctChoiceIndex:0},
    {id:"sk_796", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 17 - 8?", choices:["9","10","11"], correctChoiceIndex:0},
    {id:"sk_797", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 18 - 9?", choices:["9","10","11"], correctChoiceIndex:0},
    {id:"sk_798", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 19 - 10?", choices:["9","10","11"], correctChoiceIndex:0},
    {id:"sk_799", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 20 - 11?", choices:["9","10","11"], correctChoiceIndex:0},
    {id:"sk_800", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 2 + 2?", choices:["4","5","6"], correctChoiceIndex:0},
    {id:"sk_801", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ak máš 5 jablk a dáš 2 priateľovi, koľko ti ostane?", choices:["3","2","4"], correctChoiceIndex:0},
    {id:"sk_802", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ak máš 3 cukríky a kúpiš si ďalšie 2, koľko cukríkov budeš mať celkom?", choices:["5","6","4"], correctChoiceIndex:0},
    {id:"sk_803", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ak máš 4 autíčka a strácaš 1, koľko ti ostane?", choices:["3","2","1"], correctChoiceIndex:0},
    {id:"sk_804", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 3 + 3?", choices:["6","5","7"], correctChoiceIndex:0},
    {id:"sk_805", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 1 + 1?", choices:["2","3","4"], correctChoiceIndex:0},
    {id:"sk_806", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ak máš 3 jablká a dáš 1 priateľovi, koľko ti ostane?", choices:["2","1","3"], correctChoiceIndex:0},
    {id:"sk_807", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ak máš 2 cukríky a kúpiš si ďalšie 1, koľko cukríkov budeš mať celkom?", choices:["3","4","2"], correctChoiceIndex:0},
    {id:"sk_808", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ak máš 5 autíčok a strácaš 2, koľko ti ostane?", choices:["3","4","2"], correctChoiceIndex:0},
    {id:"sk_809", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 4 + 4?", choices:["8","7","9"], correctChoiceIndex:0},
    {id:"sk_810", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 3 + 2?", choices:["5","6","4"], correctChoiceIndex:0},
    {id:"sk_811", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ak máš 4 jablká a dáš 2 priateľovi, koľko ti ostane?", choices:["2","3","1"], correctChoiceIndex:0},
    {id:"sk_812", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ak máš 3 cukríky a kúpiš si ďalšie 3, koľko cukríkov budeš mať celkom?", choices:["6","7","5"], correctChoiceIndex:0},
    {id:"sk_813", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ak máš 6 autíčok a strácaš 3, koľko ti ostane?", choices:["3","4","2"], correctChoiceIndex:0},
    {id:"sk_814", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 5 + 3?", choices:["8","9","7"], correctChoiceIndex:0},
    {id:"sk_815", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 4 + 2?", choices:["6","7","5"], correctChoiceIndex:0},
    {id:"sk_816", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ak máš 5 jablk a dáš 3 priateľovi, koľko ti ostane?", choices:["2","3","1"], correctChoiceIndex:0},
    {id:"sk_817", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ak máš 4 cukríky a kúpiš si ďalšie 4, koľko cukríkov budeš mať celkom?", choices:["8","9","7"], correctChoiceIndex:0},
    {id:"sk_818", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ak máš 7 autíčka a strácaš 4, koľko ti ostane?", choices:["3","4","2"], correctChoiceIndex:0},
    {id:"sk_819", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 6 + 2?", choices:["8","9","7"], correctChoiceIndex:0},
    {id:"sk_820", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 5 + 1?", choices:["6","7","5"], correctChoiceIndex:0},
    {id:"sk_821", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ak máš 6 jablk a dáš 4 priateľovi, koľko ti ostane?", choices:["2","3","1"], correctChoiceIndex:0},
    {id:"sk_822", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ak máš 5 cukríky a kúpiš si ďalšie 5, koľko cukríkov budeš mať celkom?", choices:["10","11","9"], correctChoiceIndex:0},
    {id:"sk_823", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ak máš 8 autíčka a strácaš 5, koľko ti ostane?", choices:["3","4","2"], correctChoiceIndex:0},
    {id:"sk_824", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko je 7 + 1?", choices:["8","9","7"], correctChoiceIndex:0},
    {id:"sk_825", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 7 peňazí a strácaš 2, koľko ti ostane?", choices:["5","6","4"], correctChoiceIndex:0},
    {id:"sk_826", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 8 cukríkov a dáš 3 priateľovi, koľko ti ostane?", choices:["5","6","4"], correctChoiceIndex:0},
    {id:"sk_827", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 10 autíčok a strácaš 4, koľko ti ostane?", choices:["6","7","5"], correctChoiceIndex:0},
    {id:"sk_828", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 5 + 5?", choices:["10","9","11"], correctChoiceIndex:0},
    {id:"sk_829", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 6 peňazí a kúpiš si za 2 cukríky, koľko ti ostane?", choices:["4","3","5"], correctChoiceIndex:0},
    {id:"sk_830", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 9 peňazí a strácaš 3, koľko ti ostane?", choices:["6","7","5"], correctChoiceIndex:0},
    {id:"sk_831", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 7 cukríkov a dáš 2 priateľovi, koľko ti ostane?", choices:["5","6","4"], correctChoiceIndex:0},
    {id:"sk_832", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 8 autíčok a strácaš 3, koľko ti ostane?", choices:["5","6","4"], correctChoiceIndex:0},
    {id:"sk_833", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 6 + 6?", choices:["12","11","13"], correctChoiceIndex:0},
    {id:"sk_834", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 7 peňazí a kúpiš si za 3 cukríky, koľko ti ostane?", choices:["4","5","3"], correctChoiceIndex:0},
    {id:"sk_835", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 10 peňazí a strácaš 4, koľko ti ostane?", choices:["6","7","5"], correctChoiceIndex:0},
    {id:"sk_836", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 9 cukríkov a dáš 4 priateľovi, koľko ti ostane?", choices:["5","6","4"], correctChoiceIndex:0},
    {id:"sk_837", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 7 autíčok a strácaš 2, koľko ti ostane?", choices:["5","6","4"], correctChoiceIndex:0},
    {id:"sk_838", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 7 + 7?", choices:["14","13","15"], correctChoiceIndex:0},
    {id:"sk_839", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 8 peňazí a kúpiš si za 3 cukríky, koľko ti ostane?", choices:["5","6","4"], correctChoiceIndex:0},
    {id:"sk_840", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 11 peňazí a strácaš 5, koľko ti ostane?", choices:["6","7","5"], correctChoiceIndex:0},
    {id:"sk_841", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 10 cukríkov a dáš 5 priateľovi, koľko ti ostane?", choices:["5","6","4"], correctChoiceIndex:0},
    {id:"sk_842", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 9 autíčok a strácaš 4, koľko ti ostane?", choices:["5","6","4"], correctChoiceIndex:0},
    {id:"sk_843", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 8 + 8?", choices:["16","15","17"], correctChoiceIndex:0},
    {id:"sk_844", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 9 peňazí a kúpiš si za 4 cukríky, koľko ti ostane?", choices:["5","6","4"], correctChoiceIndex:0},
    {id:"sk_845", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 12 peňazí a strácaš 6, koľko ti ostane?", choices:["6","7","5"], correctChoiceIndex:0},
    {id:"sk_846", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 11 cukríkov a dáš 6 priateľovi, koľko ti ostane?", choices:["5","6","4"], correctChoiceIndex:0},
    {id:"sk_847", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 10 autíčok a strácaš 5, koľko ti ostane?", choices:["5","6","4"], correctChoiceIndex:0},
    {id:"sk_848", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko je 9 + 9?", choices:["18","17","19"], correctChoiceIndex:0},
    {id:"sk_849", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ak máš 10 peňazí a kúpiš si za 5 cukríky, koľko ti ostane?", choices:["5","6","4"], correctChoiceIndex:0},
    {id:"sk_850", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Aký tvar má guľa?", choices:["Kruh","Štvorec","Trojuholník"], correctChoiceIndex:0},
    {id:"sk_851", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Aký tvar má kocka?", choices:["Kruh","Štvorec","Trojuholník"], correctChoiceIndex:1},
    {id:"sk_852", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Aký tvar má pyramída?", choices:["Kruh","Štvorec","Trojuholník"], correctChoiceIndex:2},
    {id:"sk_853", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Aký tvar má valček?", choices:["Kruh","Štvorec","Trojuholník"], correctChoiceIndex:0},
    {id:"sk_854", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Aký tvar má kruh?", choices:["Kruh","Štvorec","Trojuholník"], correctChoiceIndex:0},
    {id:"sk_855", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Aký tvar má hviezda?", choices:["Kruh","Hviezda","Trojuholník"], correctChoiceIndex:1},
    {id:"sk_856", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Aký tvar má srdce?", choices:["Kruh","Srdce","Trojuholník"], correctChoiceIndex:1},
    {id:"sk_857", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Aký tvar má mesiac?", choices:["Kruh","Mesiac","Trojuholník"], correctChoiceIndex:1},
    {id:"sk_858", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Aký tvar má kvapka?", choices:["Kruh","Kvapka","Trojuholník"], correctChoiceIndex:1},
    {id:"sk_859", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Aký tvar má oblak?", choices:["Kruh","Oblak","Trojuholník"], correctChoiceIndex:1},
    {id:"sk_860", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Aký tvar má list?", choices:["Kruh","List","Trojuholník"], correctChoiceIndex:1},
    {id:"sk_861", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko strán má štvorec?", choices:["4","3","5"], correctChoiceIndex:0},
    {id:"sk_862", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko strán má trojuholník?", choices:["4","3","5"], correctChoiceIndex:1},
    {id:"sk_863", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko strán má päťuholník?", choices:["4","3","5"], correctChoiceIndex:2},
    {id:"sk_864", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko strán má šesťuholník?", choices:["4","3","5"], correctChoiceIndex:2},
    {id:"sk_865", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko strán má oktogón?", choices:["4","3","5"], correctChoiceIndex:2},
    {id:"sk_866", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko strán má kruh?", choices:["0","1","2"], correctChoiceIndex:0},
    {id:"sk_867", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko strán má elipsa?", choices:["0","1","2"], correctChoiceIndex:0},
    {id:"sk_868", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko strán má diamant?", choices:["4","3","5"], correctChoiceIndex:0},
    {id:"sk_869", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko strán má trapezoid?", choices:["4","3","5"], correctChoiceIndex:0},
    {id:"sk_870", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko strán má paralelogram?", choices:["4","3","5"], correctChoiceIndex:0},
    {id:"sk_871", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko strán má obdĺžnik?", choices:["4","3","5"], correctChoiceIndex:0},
    {id:"sk_872", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko strán má lichobežník?", choices:["4","3","5"], correctChoiceIndex:0},
    {id:"sk_873", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko strán má romb?", choices:["4","3","5"], correctChoiceIndex:0},
    {id:"sk_874", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko strán má kosoštvorec?", choices:["4","3","5"], correctChoiceIndex:0},
    {id:"sk_875", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko hodín ukazuje hodiny, keď sú malá ručička na 3 a veľká ručička na 12?", choices:["3 hodiny","6 hodiny","9 hodiny"], correctChoiceIndex:0},
    {id:"sk_876", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko minút ukazuje hodiny, keď je veľká ručička na 6?", choices:["15 minút","30 minút","45 minút"], correctChoiceIndex:1},
    {id:"sk_877", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ktorý mesiac nasleduje po januári?", choices:["február","marec","apríl"], correctChoiceIndex:0},
    {id:"sk_878", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ktorý deň nasleduje po pondelku?", choices:["utorok","streda","štvrtok"], correctChoiceIndex:0},
    {id:"sk_879", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko mesiacov má rok?", choices:["10 mesiacov","12 mesiacov","14 mesiacov"], correctChoiceIndex:1},
    {id:"sk_880", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko minút ukazuje hodiny, keď je veľká ručička na 9?", choices:["15 minút","45 minút","30 minút"], correctChoiceIndex:1},
    {id:"sk_881", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ktorý mesiac nasleduje po apríli?", choices:["máj","jún","júl"], correctChoiceIndex:0},
    {id:"sk_882", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ktorý deň nasleduje po sobote?", choices:["nedeľa","pondelok","utorok"], correctChoiceIndex:0},
    {id:"sk_883", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko ročných období má rok?", choices:["2 ročné obdobia","4 ročné obdobia","6 ročné obdobia"], correctChoiceIndex:1},
    {id:"sk_884", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko hodín ukazuje hodiny, keď sú malá ručička na 6 a veľká ručička na 12?", choices:["6 hodiny","12 hodiny","18 hodiny"], correctChoiceIndex:0},
    {id:"sk_885", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko minút ukazuje hodiny, keď je veľká ručička na 3?", choices:["15 minút","30 minút","45 minút"], correctChoiceIndex:0},
    {id:"sk_886", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ktorý mesiac nasleduje po júli?", choices:["august","september","október"], correctChoiceIndex:0},
    {id:"sk_887", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Ktorý deň nasleduje po strede?", choices:["štvrtok","piatok","sobota"], correctChoiceIndex:0},
    {id:"sk_888", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.mathematics, question:"Koľko dní má týždeň?", choices:["5 dní","7 dní","9 dní"], correctChoiceIndex:1},
    {id:"sk_889", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko sekúnd je v jednej minúte?", choices:["30 sekúnd","60 sekúnd","90 sekúnd"], correctChoiceIndex:1},
    {id:"sk_890", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý mesiac je desiaty v roku?", choices:["október","november","december"], correctChoiceIndex:0},
    {id:"sk_891", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko dní je v týždni?", choices:["5 dní","7 dní","9 dní"], correctChoiceIndex:1},
    {id:"sk_892", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko hodín je v jednom dni?", choices:["12 hodín","24 hodín","36 hodín"], correctChoiceIndex:1},
    {id:"sk_893", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý ročný obdobie nasleduje po lete?", choices:["jeseň","zima","jar"], correctChoiceIndex:0},
    {id:"sk_894", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko minút je v jednej hodine?", choices:["30 minút","60 minút","90 minút"], correctChoiceIndex:1},
    {id:"sk_895", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý mesiac je prvý v roku?", choices:["január","február","marec"], correctChoiceIndex:0},
    {id:"sk_896", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko týždňov je v jednom mesiaci?", choices:["2 týždne","4 týždne","6 týždne"], correctChoiceIndex:1},
    {id:"sk_897", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko minút je v jednom dni?", choices:["720 minút","1440 minút","2880 minút"], correctChoiceIndex:1},
    {id:"sk_898", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Ktorý ročný obdobie nasleduje po zime?", choices:["jar","leto","jeseň"], correctChoiceIndex:0},
    {id:"sk_899", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.mathematics, question:"Koľko sekúnd je v jednej hodine?", choices:["1800 sekúnd","3600 sekúnd","7200 sekúnd"], correctChoiceIndex:1},
    {id:"sk_900", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Prečo je dôležité cvičiť?", choices:["Aby sme boli silní","Aby sme mohli jesť viac sladkostí","Aby sme mohli dlho hrať videohry"], correctChoiceIndex:0},
    {id:"sk_901", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo by sme mali robiť pred jedlom?", choices:["Hrať sa","Umyť si ruky","Pozerať televíziu"], correctChoiceIndex:1},
    {id:"sk_902", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Prečo by sme mali piť veľa vody?", choices:["Aby sme mohli plávať","Aby sme neboli smädní","Aby sme mohli robiť bubliny"], correctChoiceIndex:1},
    {id:"sk_903", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo by sme mali robiť po hraní vonku?", choices:["Umyť si ruky","Jesť sladkosti","Pozerať televíziu"], correctChoiceIndex:0},
    {id:"sk_904", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Prečo je dôležité jesť ovocie a zeleninu?", choices:["Aby sme mali veľa energie","Aby sme mohli hrať videohry","Aby sme mohli jesť viac sladkostí"], correctChoiceIndex:0},
    {id:"sk_905", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Prečo je dôležité mať dostatok spánku?", choices:["Aby sme mohli hrať viac videohier","Aby sme boli zdraví a mali energiu","Aby sme mohli dlho pozerať televíziu"], correctChoiceIndex:1},
    {id:"sk_906", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Ako často by sme mali cvičiť?", choices:["Raz za mesiac","Každý deň","Len v sobotu"], correctChoiceIndex:1},
    {id:"sk_907", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Prečo je dôležité jesť zdravé jedlo?", choices:["Aby sme mohli hrať videohry","Aby sme mohli jesť viac sladkostí","Aby sme boli zdraví a silní"], correctChoiceIndex:2},
    {id:"sk_908", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo by sme mali robiť, keď sme chorí?", choices:["Hrať sa vonku","Ostať doma a odpočívať","Jesť veľa sladkostí"], correctChoiceIndex:1},
    {id:"sk_909", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Prečo je dôležité umývať si zuby?", choices:["Aby sme mohli jesť viac sladkostí","Aby sme mali pekný úsmev a zdravé zuby","Aby sme mohli hrať videohry"], correctChoiceIndex:1},
    {id:"sk_910", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Ako často by sme mali umývať si zuby?", choices:["Raz za týždeň","Po každom jedle","Len v sobotu"], correctChoiceIndex:1},
    {id:"sk_911", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Prečo je dôležité nosiť klobúk v horúcich dňoch?", choices:["Aby sme mohli hrať videohry","Aby sme mohli jesť viac sladkostí","Aby sme chránili hlavu pred slnkom"], correctChoiceIndex:2},
    {id:"sk_912", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Prečo by sme mali piť veľa vody, keď je vonku horúco?", choices:["Aby sme mohli robiť bubliny","Aby sme neboli smädní","Aby sme mohli hrať videohry"], correctChoiceIndex:1},
    {id:"sk_913", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo by sme mali robiť, keď sme unavení po cvičení?", choices:["Pokračovať v cvičení","Jesť sladkosti","Odpočívať"], correctChoiceIndex:2},
    {id:"sk_914", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Prečo je dôležité nosiť prilbu pri jazde na bicykli?", choices:["Aby sme mohli hrať videohry","Aby sme chránili hlavu","Aby sme mohli jesť viac sladkostí"], correctChoiceIndex:1},
    {id:"sk_915", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký šport môžeš robiť vo vode?", choices:["Futbal","Plávanie","Beh"], correctChoiceIndex:1},
    {id:"sk_916", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký šport robíš na koni?", choices:["Jazda na koni","Plávanie","Futbal"], correctChoiceIndex:0},
    {id:"sk_917", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký šport robíš na gymnastickom kruhu?", choices:["Plávanie","Jazda na koni","Gymnastika"], correctChoiceIndex:2},
    {id:"sk_918", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký šport robíš na lyžiach?", choices:["Lyžovanie","Plávanie","Beh"], correctChoiceIndex:0},
    {id:"sk_919", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký šport robíš na bicykli?", choices:["Cyklistika","Plávanie","Beh"], correctChoiceIndex:0},
    {id:"sk_920", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký šport robíš na tenisovom kurte?", choices:["Futbal","Tenis","Plávanie"], correctChoiceIndex:1},
    {id:"sk_921", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký šport robíš na golfovom ihrisku?", choices:["Golf","Plávanie","Futbal"], correctChoiceIndex:0},
    {id:"sk_922", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký šport robíš na boxerskom ringu?", choices:["Box","Plávanie","Futbal"], correctChoiceIndex:0},
    {id:"sk_923", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký šport robíš na stolnom tenise?", choices:["Stolný tenis","Plávanie","Futbal"], correctChoiceIndex:0},
    {id:"sk_924", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký šport robíš na skateboardovom parku?", choices:["Skateboarding","Plávanie","Futbal"], correctChoiceIndex:0},
    {id:"sk_925", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký šport robíš na klzisku?", choices:["Korčuľovanie","Plávanie","Futbal"], correctChoiceIndex:0},
    {id:"sk_926", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký šport robíš na strelisku?", choices:["Streľba","Plávanie","Futbal"], correctChoiceIndex:0},
    {id:"sk_927", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký šport robíš na bowlingovej dráhe?", choices:["Bowling","Plávanie","Futbal"], correctChoiceIndex:0},
    {id:"sk_928", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký šport robíš na badmintonovom kurte?", choices:["Badminton","Plávanie","Futbal"], correctChoiceIndex:0},
    {id:"sk_929", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký šport robíš na squashovom kurte?", choices:["Squash","Plávanie","Futbal"], correctChoiceIndex:0},
    {id:"sk_930", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Akú obuv by si mal obliecť na turistiku?", choices:["Sandále","Topánky na beh","Pohodlné topánky"], correctChoiceIndex:2},
    {id:"sk_931", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo by si mal vždy robiť, keď si v prírode?", choices:["Hodiť odpadky na zem","Kričať na zvieratá","Rešpektovať prírodu"], correctChoiceIndex:2},
    {id:"sk_932", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo by si mal robiť, keď si na turistike?", choices:["Bežať čo najrýchlejšie","Pozerať sa okolo a obdivovať prírodu","Hrať videohry"], correctChoiceIndex:1},
    {id:"sk_933", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo by si mal vždy nosiť, keď ides na turistiku?", choices:["Plavky","Pohodlné oblečenie","Večerné šaty"], correctChoiceIndex:1},
    {id:"sk_934", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo by si mal robiť, keď nájdeš odpadky v prírode?", choices:["Zjesť ich","Nechať ich tam","Vziať ich so sebou a vyhodiť do koša"], correctChoiceIndex:2},
    {id:"sk_935", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Prečo je dôležité rešpektovať prírodu?", choices:["Aby sme mohli mať pekné fotky","Aby sme mohli zachovať prírodu pre budúce generácie","Aby sme mohli mať viac miesta na parkovanie"], correctChoiceIndex:1},
    {id:"sk_936", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo by si mal robiť, keď vidíš zviera v prírode?", choices:["Pokusit sa ho chytiť","Nechať ho na pokoji","Kŕmiť ho s jedlom z domu"], correctChoiceIndex:1},
    {id:"sk_937", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Prečo je dôležité nosiť pohodlné topánky na turistiku?", choices:["Aby sme mohli rýchlejšie bežať","Aby sme mohli skákať vyššie","Aby sme mohli dlhšie chodiť bez bolesti nôh"], correctChoiceIndex:2},
    {id:"sk_938", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo by si mal robiť, keď si unavený z turistiky?", choices:["Pokračovať bez prestávky","Vziať si chvíľu na odpočinok","Začať behať"], correctChoiceIndex:1},
    {id:"sk_939", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Prečo je dôležité nosiť pohodlné oblečenie na turistiku?", choices:["Aby sme mohli lepšie vyzerať","Aby sme mohli byť pohodlnejší počas chôdze","Aby sme mohli nosiť viac vecí"], correctChoiceIndex:1},
    {id:"sk_940", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo by si mal robiť, keď vidíš rastlinu, ktorú nepoznáš?", choices:["Zjesť ju","Dotknúť sa jej","Nechať ju na pokoji"], correctChoiceIndex:2},
    {id:"sk_941", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Prečo je dôležité vziať si vodu na turistiku?", choices:["Aby sme mohli uhasiť oheň","Aby sme mohli piť, keď sme smädní","Aby sme mohli robiť bubliny"], correctChoiceIndex:1},
    {id:"sk_942", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo by si mal robiť, keď sa stratíš na turistike?", choices:["Bežať čo najrýchlejšie","Zostať na jednom mieste a volať o pomoc","Hľadať poklad"], correctChoiceIndex:1},
    {id:"sk_943", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Prečo je dôležité mať mapu pri turistike?", choices:["Aby sme mohli kresliť na ňu","Aby sme vedeli, kde sme","Aby sme mohli robiť papierové lietadlá"], correctChoiceIndex:1},
    {id:"sk_944", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo by si mal robiť, keď je zle počasie na turistike?", choices:["Pokračovať bez ohľadu na počasie","Nájsť bezpečné miesto a počkať, kým sa počasie nezlepší","Tancovať v daždi"], correctChoiceIndex:1},
    {id:"sk_945", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Aká je hlavná pravidlo hry na schovávačku?", choices:["Vždy sa musíte schovať na rovnakom mieste","Musíte sa schovať a ostatní vás musia nájsť","Musíte bežať okolo a kričať"], correctChoiceIndex:1},
    {id:"sk_946", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo je dôležité pri hre na škatuľu?", choices:["Vždy vyhrať","Dobrý tím a spolupráca","Mať najlepšiu škatuľu"], correctChoiceIndex:1},
    {id:"sk_947", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo robíte, keď vás niekto chytí pri hre na škatuľu?", choices:["Začnete plakať","Zastavíte sa a počkáte, kým vás niekto zachráni","Bežíte preč"], correctChoiceIndex:1},
    {id:"sk_948", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo robíte, keď ste na rade pri hre na schovávačku?", choices:["Schovávate sa","Hľadáte ostatných","Bežíte preč"], correctChoiceIndex:1},
    {id:"sk_949", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo robíte, keď ste na rade pri hre na škatuľu?", choices:["Schovávate sa","Hľadáte ostatných","Bežíte preč"], correctChoiceIndex:1},
    {id:"sk_950", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo robíte, keď ste na rade pri hre na škatuľu?", choices:["Schovávate sa","Hľadáte ostatných","Bežíte preč"], correctChoiceIndex:1},
    {id:"sk_951", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo robíte, keď ste na rade pri hre na schovávačku?", choices:["Schovávate sa","Hľadáte ostatných","Bežíte preč"], correctChoiceIndex:1},
    {id:"sk_952", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo robíte, keď ste na rade pri hre na škatuľu?", choices:["Schovávate sa","Hľadáte ostatných","Bežíte preč"], correctChoiceIndex:1},
    {id:"sk_953", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Aké sú pravidlá hry na škatuľu?", choices:["Musíte sa schovať a ostatní vás musia nájsť","Musíte bežať okolo a kričať","Musíte sa dotknúť škatuľy a potom bežať preč"], correctChoiceIndex:2},
    {id:"sk_954", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Prečo je dôležitá spolupráca pri hre na škatuľu?", choices:["Aby ste mohli vyhrať","Aby ste sa mohli zabaviť","Obe odpovede sú správne"], correctChoiceIndex:2},
    {id:"sk_955", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo robíte, keď vás niekto chytí pri hre na škatuľu?", choices:["Začnete plakať","Zastavíte sa a počkáte, kým vás niekto zachráni","Bežíte preč"], correctChoiceIndex:1},
    {id:"sk_956", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Aké sú pravidlá hry na schovávačku?", choices:["Musíte sa schovať a ostatní vás musia nájsť","Musíte bežať okolo a kričať","Musíte sa dotknúť škatuľy a potom bežať preč"], correctChoiceIndex:0},
    {id:"sk_957", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Prečo je dôležitá spolupráca pri hre na schovávačku?", choices:["Aby ste mohli vyhrať","Aby ste sa mohli zabaviť","Obe odpovede sú správne"], correctChoiceIndex:2},
    {id:"sk_958", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Aké sú pravidlá hry na škatuľu?", choices:["Musíte sa schovať a ostatní vás musia nájsť","Musíte bežať okolo a kričať","Musíte sa dotknúť škatuľy a potom bežať preč"], correctChoiceIndex:2},
    {id:"sk_959", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Prečo je dôležitá spolupráca pri hre na škatuľu?", choices:["Aby ste mohli vyhrať","Aby ste sa mohli zabaviť","Obe odpovede sú správne"], correctChoiceIndex:2},
    {id:"sk_960", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Koľko hráčov je na futbalovom tíme?", choices:["11","10","12"], correctChoiceIndex:0},
    {id:"sk_961", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký tvar má basketbalová lopta?", choices:["Kvadrát","Kruh","Trojuholník"], correctChoiceIndex:1},
    {id:"sk_962", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo nosia futbalisti na nohách?", choices:["Topánky","Rukavice","Klobúk"], correctChoiceIndex:0},
    {id:"sk_963", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Koľko košov musíš dať, aby si vyhral v basketbale?", choices:["10","Viacej ako druhý tím","5"], correctChoiceIndex:1},
    {id:"sk_964", language:QuestionLanguage.SK, age:QuestionAge.age_4_6, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo je to ofsajd vo futbale?", choices:["Keď hráč kopne loptu mimo ihriska","Keď hráč stojí pred posledným obrancom","Keď hráč padne"], correctChoiceIndex:1},
    {id:"sk_965", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký je rozmer futbalového ihriska?", choices:["100x60 metrov","50x30 metrov","80x40 metrov"], correctChoiceIndex:0},
    {id:"sk_966", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Koľko hráčov je na basketbalovom tíme na ihrisku?", choices:["5","6","7"], correctChoiceIndex:0},
    {id:"sk_967", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký je trest za faul vo futbale?", choices:["Vylúčenie","Pokutový kop","Žltá karta"], correctChoiceIndex:2},
    {id:"sk_968", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo je to trojkový pokus v basketbale?", choices:["Hod do koša z veľkej vzdialenosti","Hod do koša z malej vzdialenosti","Hod do koša z polovice ihriska"], correctChoiceIndex:0},
    {id:"sk_969", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Aký je trest za technický faul v basketbale?", choices:["Vylúčenie","Pokutový hod","Žltá karta"], correctChoiceIndex:1},
    {id:"sk_970", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo je to rohový kop vo futbale?", choices:["Kop z rohu ihriska","Kop z polovice ihriska","Kop z vlastnej polovice"], correctChoiceIndex:0},
    {id:"sk_971", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo je to dribling v basketbale?", choices:["Keď hráč behá s loptou bez toho, aby ju kopával","Keď hráč kopá loptu","Keď hráč hodí loptu do koša"], correctChoiceIndex:0},
    {id:"sk_972", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo je to penalta vo futbale?", choices:["Kop z polovice ihriska","Kop z rohu ihriska","Kop z pokutového miesta"], correctChoiceIndex:2},
    {id:"sk_973", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo je to zakončenie v basketbale?", choices:["Hod do koša","Kop do lopty","Beh s loptou"], correctChoiceIndex:0},
    {id:"sk_974", language:QuestionLanguage.SK, age:QuestionAge.age_6_8, category:QuestionCategory.sportAndPhysicalEducation, question:"Čo je to striedanie vo futbale?", choices:["Keď hráč vystrieda iného hráča na ihrisku","Keď hráč ide na toaletu","Keď hráč ide na prestávku"], correctChoiceIndex:0},
]


