import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import translationEnglish from "./Translation/English/translation.json";
import translationFrench from "./Translation/French/translation.json";
import translationDeutsch from "./Translation/Deutsch/translation.json";
import translationSlovak from "./Translation/Slovak/translation.json";


const resources = {
    en: { home: translationEnglish },
    fr: { home: translationFrench },
    de: { home: translationDeutsch },
    sk: { home: translationSlovak },
}

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng:"en", //default language
    });

export default i18next;